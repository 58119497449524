@use "../../../sass/colors"as colors;
@use "../../../sass/variables.scss"as v;
@import "../../../sass/mixins";
@import "../../../sass/_mixins/global";
.all-body {
    display: flex;
    margin: 2rem 0;
    padding: 1rem;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
    & .list-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        & .card-brands {
            display: flex;
            position: relative;
            justify-content: center;
            width: 250px;
            height: 310px;
            margin: 1rem;
            box-shadow: 0px 5px 30px #F0F0F0;
            border-radius: 15px;
            background-color: var(--main-white-color);
            align-items: center;
            transition: .2s all ease-in-out;
            a {
                position: absolute;
                cursor: pointer;
            }
            &:hover {
                box-shadow: 0px 5px 80px #e7e7e7;
                & .card-brands-edit {
                    display: block;
                }
            }
            .card-brands-link {
                text-decoration: none;
                top: 0;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                bottom: 0;
                left: 0;
                right: 0;
                justify-content: center;
                padding: 4px 11px;
            }
            .card-brands-edit {
                display: none;
                top: 10px;
                right: 10px;
            }
            & h2 {
                font-family: Hurme-Black;
                font-style: normal;
                font-weight: 900;
                font-size: 30px;
                line-height: 39px;
                text-align: center;
                color: var(--main-blue);
            }
            &.card-brands-first {
                background-color: #F6F8FF;
                box-shadow: none;
                .btn-add {
                    width: 75px;
                    height: 75px;
                    border: none;
                    border-radius: 100%;
                    background-color: var(--main-blue-color-bg);
                    color: var(--main-white-color);
                    font-size: 25px;
                }
                & .card-brands-link {
                    flex-direction: column;
                    span {
                        font-family: Hurme-Black;
                        font-size: 15px;
                        line-height: 19px;
                        text-align: center;
                        color: #A9BBFF;
                        margin-top: 15px;
                    }
                }
                &:hover {
                    box-shadow: 0px 5px 10px #f0f0f0;
                }
            }
        }
    }
}