@use '../../../../sass/colors'as colors;
@use '../../../../sass/variables'as variables;
@import '../../../../sass/mixins';
@import '../../../../sass/partials/buttons';
.content-modal-cart {
    position: relative;
    width: 660px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--main-white-color);
    overflow: hidden;
    box-shadow: 0px 10px 50px colors.$grey-text;
    //padding                : 80px 10px 40px 10px;
    border-radius: 12px;
    //   position          : relative;
    & h2.disabled {
        @include disabled
    }
}

.content-modal-cart-nfv {
    width: 400px;
    height: 580px;
    position: relative;
    p {
        text-align: left !important;
        font-size: 1.5ch !important;
        margin: 0 1%;
        span {
            display: inline;
            position: relative;
            font-size: inherit;
            background: linear-gradient(180deg, transparent 50%, #FDD443 50%);
        }
    }
    b {
        position: relative;
        font-family: Hurme-Black;
        color: #000;
        text-transform: uppercase;
    }
    button {
        margin-bottom: 5%;
    }
}

// @include tablet {
//     .content-modal-cart {
//         width: 80vw;
//     }
// }
@include mobile {
    .content-modal-cart {
        width: 100vw;
        height: 100vh;
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}