@use "../../../../../../../sass/colors" as colors;
@use "../../../../../../../sass/variables.scss" as v;
@import '../../../../../../../sass/mixins';
@import '../../../../../../../sass/_mixins/global';

.pickers-container {
    width: 100%;

    & > .products, .addresses {
        width: 100%;
        border-radius: 10px;
        &.products {
            background: colors.$magnolia;
            padding: 2rem;
        }
        &.addresses {
            background: colors.$alice-blue2;
            padding: 2rem;
        }
        h3 {
            width: 100%;
            font-family: Hurme-Black;
            font-size: v.$px-ts-nml;
            color: var(--main-perano-purple);
            text-transform: uppercase;
        }
    
        p {
            font-family: Roboto;
            font-size: v.$p;
            color: colors.$grey-dark;
            font-weight: 400;
        }
    }

    @include mobile {
        margin-bottom: 1rem;
        section {
            .card_products {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
            }
    
            &:nth-of-type(2) {
                min-height: 79px;
                width: 100% !important;
                padding: 0px 30px;
            }
        }
    }
    
    @include tablet() {
    
        section {
            text-align: center;
    
            .card_products {
                grid-template-columns: repeat(2, 1fr);
    
                >div {
                    .card_product {}
                }
            }
    
            &:nth-of-type(1) {
                align-items: center;
            }
    
            &:nth-of-type(2) {
                min-height: 113px;
                background-color: rgba(255, 255, 255, .5);
                backdrop-filter: blur(5px);
            }
        }
    }
}

.form-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;

    button {
        min-width: 50%;
    }
}