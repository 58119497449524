@use "../../../../../../sass//colors" as colors;
@use "../../../../../../sass/variables.scss" as variables;
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/mixins";
@import "../../../../../../sass/_mixins/global";
@import "../../../../../../sass/_mixins/animated-shine.scss";
$width-bar-messages: 400px;
$avtr-sze: 7rem;

.profile-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;

    &.video-intro {
        & figure a>img:first-child {
            border: 4px solid transparent;
            background: linear-gradient(91.67deg, #7AB9EE 0%, #8480FF 100%) border-box;
        }
    }

    .profile-info {
        display: flex;
        justify-content: center;

        p {
            font-family: Roboto;
            color: var(--main-dark-color);
        }

        &>div {
            display: flex;

            & figure {
                margin: 0px 12px 0px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & img {
                    @include avatar($avtr-sze);
                }
            }

            & .card-desc-applicant {
                align-items: center;
                padding-top: 10px;
                margin-left: 12px;
                z-index: 10;

                .div-btn-msg {
                    display: none;
                }

                & .gradient-verified {
                    display: flex;
                    align-items: center;

                    & .gradient-text-verified {
                        color: var(--main-grey-medium);
                        margin: 0px 6px 0px 0px;

                        a {
                            font-size: 13px;
                            text-decoration: underline;

                            i {
                                margin-right: 5px
                            }

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    & img {
                        width: 20px;
                        height: 20px;
                    }
                }

                & div {
                    display: flex;
                    color: colors.$blue-fair;
                    // margin-right: 20px;
                    align-items: center;
                    position: relative;
                    align-items: center;

                    & h3 {
                        font-size: 20px !important;
                        font-family: Hurme-Bold;
                        color: var(--main-blue-maya);
                        margin-right: 6px;

                        &:nth-child(1) {
                            color: black;
                        }
                    }

                    & span {
                        font-family: Roboto;
                        font-style: normal;
                        line-height: normal;
                        margin-left: 6px;
                        font-weight: 400;
                        font-size: variables.$medium-size-text * .8 !important;
                        color: var(--main-blue-maya);
                        display: flex;
                    }

                    & .vogzter-right {
                        box-shadow: rgba(149, 157, 165, 0.11) 0px 8px 24px;
                        border-radius: 10px;
                        padding: .5rem 1rem;
                        margin-top: .5rem;
                        max-width: 500px;
                        display: flex;
                        flex-wrap: wrap;

                        & div {
                            align-items: center;
                        }

                        & div:nth-of-type(3) {
                            margin-right: 12px;
                        }
                    }

                    & div {
                        & span:nth-of-type(1) {
                            font-size: variables.$mid-title-size-text !important;
                            margin-right: 12px;
                        }
                    }
                }

                & .btn-view-profil {
                    border-radius: 5px;
                    background-color: var(--main-blue-color-bg);
                    color: var(--main-white-color);
                    width: 100%;

                    &:hover {
                        background-color: var(--main-white-color);
                        color: var(--main-blue-color-bg);
                    }
                }

                & .social-icons {
                    display: flex;
                    gap: 10px;

                    .social-icon {
                        padding: .5rem;
                        border-radius: 5px;
                        box-shadow: none;
                        transition: .2s ease-in-out;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;

                        .followers {
                            text-align: center;
                            color: black;
                            margin: 7px auto 0px auto;
                            padding: 0 5px;
                            width: 100%;
                            font-size: 14px;
                            border-radius: 5px;
                            font-weight: bold;

                            &.nano {
                                background-color: var(--vogzter-followers-nano);
                            }

                            &.micro {
                                background-color: var(--vogzter-followers-micro);
                            }

                            &.macro {
                                background-color: var(--vogzter-followers-macro);
                            }

                            &.mega {
                                background-color: white;
                                border: 1px solid black;
                            }

                            &.unclassified {
                                background-color: var(--vogzter-followers-unclassified);
                            }
                        }

                        img {
                            border-radius: 5px;
                            width: auto;
                            height: 30px;
                            object-fit: cover;
                            transition: .2s ease-in-out;
                        }

                        &:hover {
                            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                            transition: .2s ease-in-out;

                            img {
                                scale: 1.1;
                                transition: .2s ease-in-out;
                            }

                        }

                        &:active {
                            img {
                                scale: 1;
                                transition: .2s ease-in-out;
                            }
                        }
                    }
                }

                & .btn-portfolio {
                    display: flex;
                    width: 150px;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    font-family: Hurme-Bold;
                    font-size: 1.2ch !important;
                    padding: 2.5px 35px;
                    border-radius: 15px;
                    color: white;
                    text-shadow: 0px 4px 10px #507DC2;
                    transition: 0.25s;
                    background:
                        linear-gradient(100deg, #7588D9, transparent 25%),
                        linear-gradient(45deg, #DFE5FF, transparent 30%),
                        linear-gradient(90deg, #93BEFF, transparent 100%),
                        linear-gradient(30deg, transparent 10%, #7588D9),
                        linear-gradient(120deg, transparent 60%, #DFE5FF);

                    & i {
                        margin-left: 7px;
                        color: inherit;
                    }

                    &:hover {
                        background-position-x: 150px;
                    }
                }

                & .div-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: stretch;
                    width: 100%;
                    margin-inline: auto;

                    .btn-send {
                        display: flex;
                        width: 100%;

                        @media only screen and (max-width: 950px) {
                            flex-direction: column;
                        }
                    }

                    button {
                        font-family: Hurme-Bold;
                        border-radius: 5px;
                        padding: 2px 5px;
                        min-width: 160px;
                        width: 100%;
                        font-size: variables.$pMobil;
                    }

                    .btn-send-tips {
                        color: colors.$blue-shamrock;
                        border: 1px solid colors.$blue-shamrock;
                        background-color: #F2FDFF;
                        margin-right: .5rem;

                        &:hover {
                            background-color: colors.$blue-shamrock;
                            color: var(--main-white-color);
                        }
                    }

                    .btn-send-product {
                        color: colors.$purple-anemone;
                        border: 1px solid colors.$purple-anemone;
                        background-color: #F7F2FF;

                        &:hover {
                            background-color: colors.$purple-anemone;
                            color: var(--main-white-color);
                        }
                    }

                    .btn-close-collab {
                        background-color: #F2F6FF;

                        &:hover {
                            background-color: var(--main-blue-color-bg);
                            color: var(--main-white-color);
                        }
                    }

                    hr {
                        width: 1px;
                        height: 30px;
                        margin: 5px .5rem 0;
                        border-left: 1px solid black;
                    }
                }
            }
        }

        & h3 {
            font-family: Hurme-Black;
            font-size: variables.$h1 !important;
        }

        .verified {
            position: absolute;
            width: 30px;
            height: 30px;
            transform: translateX(100%);
        }
    }

    .profil-section {
        display: flex;
        align-items: center;
        width: 100%;

        @include tablet() {
            flex-direction: column;
            gap: 20px;

            hr {
                display: none;
            }
        }

        hr {
            border: none;
            border-left: 1px solid var(--main-grey-text);
            height: 100px;
            width: 1px !important;
            margin: auto 1rem !important;
        }
        
        .tag {
            display: flex;
            width: 100%;
            max-width: 400px;

            p {
                margin: .15rem;
                padding: 2px 25px;
                font-size: variables.$p;
                background: var(--my-castings-alice-blue);
                border: 0.5px solid var(--main-blue-color-bg);
                border-radius: 2px;
                color: var(--main-blue-color-bg);
                font-family: Roboto;
                line-height: normal;
                font-weight: 400;
            }
        }
    }

    .profile-bio {
        position: relative;
        text-align: left;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        max-width: 700px;
        width: 100%;
        padding: 10px 10px 0;

        .head-bio {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tag {
                display: flex;

                p {
                    margin: .15rem;
                    padding: 2px 25px;
                    font-size: variables.$p;
                    background: var(--my-castings-alice-blue);
                    border: 0.5px solid var(--main-blue-color-bg);
                    border-radius: 2px;
                    color: var(--main-blue-color-bg);
                    font-family: Roboto;
                    line-height: normal;
                    font-weight: 400;
                }
            }
        }

        h3 {
            font-family: hurme-bold;
            min-width: 6.25rem;
        }

        p {
            font-size: 16px;
            color: var(--main-grey-medium);
        }
    }

    .part-media-sold {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        width: calc(100% + 40px);
        background: #EEF7FF;
        padding: 2rem 0;
        width: 100%;

        .swiper {
            display: flex;
            width: 100%;
            height: 100%;

            .swiper-media-sold {
                min-width: 200px;
            }

            .card-media-sold {
                display: flex;
                height: 100%;
                align-items: center;
                width: 100%;
                justify-content: center;
                min-width: 200px;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    margin-right: .5rem;
                }

                h3 {
                    font-family: Hurme-Bold;
                    font-size: variables.$h2;
                    color: var(--main-dark-color);
                    margin-right: .5rem;
                }

                .nb-media-sold {
                    display: flex;
                    align-items: center;

                    h3 {
                        color: var(--main-blue-maya);
                        font-size: variables.$h2Mob;

                        span {
                            font-size: variables.$h1;
                        }
                    }

                    p {
                        font-family: Roboto;
                        font-size: variables.$p;
                        color: var(--main-blue-maya);
                        line-height: normal;
                    }
                }
            }

            .swiper-wrapper {
                display: flex;
            }
        }



    }

    .btn-msg {

        // position: absolute;
        // bottom: -2px;
        .blue-little-btn {
            position: relative;
            // display: none;
            @include little-btn-inverse(var(--main-blue-color-bg));
            border-radius: 50px;
            font-size: variables.$p;
            line-height: normal;
            display: flex;

            span {
                position: absolute;
                background-color: var(--main-alert-color);
                color: var(--main-white-color) !important;
                padding: 5px;
                border-radius: 50px;
                width: 22px;
                height: 22px;
                top: -10px;
                right: -10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    hr {
        width: 90%;
        margin: 30px auto 10px auto;
    }

    & .disqualify-btn {
        @include little-btn(colors.$hard-grey-dark);
        background-color: var(--main-white-color);
        font-size: variables.$px-ts-sm;
        padding: 3px 11px;
        min-width: 100px;
        border-radius: 25px;
        line-height: normal;

        &:hover {
            background-color: var(--main-alert-color);
            border: 1px solid var(--main-alert-color);
            text-shadow: 0px 2px 4px #A60000;
        }
    }
}

.icon-chevron {
    display: flex;
    width: 35px;
    height: 35px;
    color: #66B6FF;
    font-size: 1.3ch;
    justify-content: center;
    align-items: center;
    border: solid 1px #66B6FF;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.2s;
}

@include miniPc() {
    .card-desc-applicant {
        & div {
            justify-content: center;
        }
    }
}

@include tablet() {
    .profile-info-wrapper {
        width: 100% !important;

        .profile-info {
            span {
                &:nth-child(1) {
                    font-size: 14px !important;
                }
            }

            h3 {
                font-size: 18px !important;
            }

            &>div {
                & figure {
                    margin: 10px 5px 0px 0px;
                }

                & .card-desc-applicant {
                    .part-name {
                        flex-direction: column;
                        align-items: center;
                    }

                    .div-btn-msg {
                        display: flex;
                        align-items: center;
                    }

                    & .gradient-verified {
                        justify-content: center;
                    }

                    & div {
                        & h3 {
                            font-size: 2rem !important;

                            &:nth-child(1) {
                                font-size: 1.9rem !important;
                            }

                        }

                        & .vogzter-right {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;

                            & div:nth-of-type(3) {
                                margin-right: 12px;
                            }
                        }

                        & div {
                            & span:nth-of-type(1) {
                                font-size: variables.$mid-title-size-text * 1.3 !important;
                                margin-right: 5%;
                                font-size: 2rem !important;
                            }
                        }
                    }

                    & .social-icons {
                        flex-wrap: wrap;
                        max-width: none;
                    }
                }
            }

            & h3 {
                font-family: Hurme-Black;
                font-size: variables.$xxl-size !important;
            }
        }

        .blue-little-btn {
            font-size: variables.$pMobil !important;
            border-radius: 50px;
            line-height: normal;
            display: flex;
        }

        hr {
            width: 70%;
        }
    }
}

@include tablet2() {
    .profile-info-wrapper .profile-info .card-desc-applicant {
        .div-action {
            button {
                font-size: variables.$low-size-text !important;
                min-width: 132px !important;
            }
        }
    }
}

@include mobile() {
    .profile-info-wrapper {
        .profile-info {
            &>div {
                & .card-desc-applicant {
                    margin-left: 0;

                    .div-action {
                        flex-direction: column;
                        align-items: center;

                        hr {
                            display: none;
                        }

                        .btn-send-tips {
                            margin-right: 0;
                        }
                    }

                    & .gradient-verified {
                        & img {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    & div {

                        & h3 {
                            font-size: 1.3rem !important;

                            &:nth-child(1) {
                                color: black;
                            }
                        }

                        & span {
                            font-family: Roboto;
                            font-style: normal;
                            line-height: normal;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: variables.$medium-size-text * .8 !important;
                        }

                        & .vogzter-right {
                            & div:nth-of-type(3) {
                                margin-right: 12px;
                            }
                        }

                        & div {
                            & span:nth-of-type(1) {
                                font-size: variables.$mid-title-size-text !important;
                                // margin-right: 12px;
                            }
                        }
                    }
                }
            }

            & h3 {
                font-family: Hurme-Black;
                font-size: variables.$mid-title-size-text !important;
            }
        }
    }
}