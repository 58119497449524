@use "../../../sass/colors" as colors;
@use "../../../sass/variables" as variables;
@import "../../../sass/mixins";

.main-new{
  background: linear-gradient(45deg, #CED6FE ,  #C1C4FC,#CFD9FE );
  height: 100%;
  & h3{
    background-color: var(--main-gargoyle-gas);
    text-shadow: 0px 0px 20px #b4af94;

  }
}
.start-collab {
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 575px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px #F1F1F1;
  background: colors.$white;
  transition: .5s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 10px 50px #dadada;
  }
  > header {
    position: relative;
    display: flex;
    width: 100%;
    height: 290px;
    border-radius: inherit;
    overflow: hidden;
    background: colors.$white;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 50%;
      height: 150%;
      margin: auto;
      background: colors.$white;
      opacity: .4;
      filter: blur(50px);
      z-index: 1;
    }
    > img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      object-fit: contain;
      margin: auto;
      z-index: 2;
    }
    > div {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: center;
      font-size: variables.$px-ts-sm;
      text-align: center;
      font-family: Hurme-Black;
      text-transform: uppercase;
      background: var(--main-gargoyle-gas);
      z-index: 2;
    }
  }
  > section {
    display: flex;
    flex-direction: column;
    width: 83%;
    height: 100px;
    justify-content: flex-start;
    text-align: center;
    > h1 {
      font-size: variables.$h1Mob;
      font-family: Hurme-Black;
      text-transform: uppercase;
      margin-bottom: 10px;
      > span {
        font-family: Roboto;
      }
    }
    > p {
      font-size: variables.$px-ts-md;
      color: colors.$grey-dark;
      text-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
      > b {
        color: colors.$independance;
        font-family: Hurme-Black;
      }
    }
  }
  button{
    text-transform: initial;
  }
  > footer {
    display: flex;
    width: 90%;
    align-items: flex-end;
    padding-bottom: 25px;
    > * {
      width: 100%;
      height: fit-content;
    }
  }
  &.casting {
    &:hover {
      .deal {
        filter: grayscale(1);
      }
    }
    > header {
      background: #BAB7FC;
      &::after {
        rotate: 45deg;
      }
      > img {
        transform: rotate(9deg);
        height: 55%;
      }
    }
    & h1 {
      color: colors.$blue-dark2;
    }
  }
  &.deal {
    &:hover {
      .casting {
        filter: grayscale(1);
      }
    }
    > header {
      &::after {
        rotate: -45deg;
      }
      background: #FEDF90;
    }
  }
}
@include tablet2(){
  .start-collab {
    flex-direction: column;
    & h1 {
      font-size: variables.$h2Mob !important;
    }
    & p {
      font-size: variables.$pMobil !important;
      line-height: normal;
    }
  }
  .main-new{
    & h3{
      font-size: variables.$px-ts-sm;
    }
  }
}