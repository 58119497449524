.brief-type {
  display: flex;
  width: fit-content;
  height: 100%;
  justify-content: center;
  margin: 0 4px;
  padding: 0 10px;
  border-radius: 3px;
  line-height: 150%;

  &.simple {
    padding: 0;
    line-height: 154%;
  }

  &.casting-plus {
    background: linear-gradient(93.26deg, #a785f0 0%, #627aff 100%);
    color: white;
  }
  
  &.deal-plus {
    background: linear-gradient(151.35deg, #FFD806 0%, #FFEC84 100%);
    color: black;
  }
  
  &.on-spot {
    background: linear-gradient(151.35deg, #5EDCE4 0%, #33A8FC 100%);
    color: white;
  }
}