@use '../../../../sass/variables' as v;
@import '../../../../sass/mixins';
.casting-page {
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  .filter-container{
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 21;
    border-radius: 40px;
    display: flex;
    background-color: #dadada;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    gap: 5px;
    & button{
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      width: 35px;
      height: 35px;
      transition: all .3s ease-in-out;
      border-radius: 50%;
      &.active, &:hover {
        background-color: var(--main-blue-fair-color-bg);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
      i{
        opacity: .3;
        transition: all .3s ease-in-out;
      }
      &.active i, &:hover i{
        color:  var(--main-blue);
        opacity: 1;
      }
      &:not(.active):hover {
        opacity: .3;
      }
    }
  }
  .placeholder-casting {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    img {
      max-width: 700px;
      width: 100%;
    }
    button {
      min-width: 0;
    }
  }
  section {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    &:nth-of-type(1) {
      padding-top: 20px;
      position: sticky;
      top: 0;
      flex-direction: column;
      align-items: center;
      display: flex;
      z-index: 10;
      & > .input-search-container {
        background-color: var(--main-white-color-trans);
      }
      .btns-container {
        top: 60px;
        z-index: 20;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 40px 2em;
        button {
          font-family: Hurme-Bold;
        }
        .btns-filter {
          margin-left: auto;
          position: relative;
          .menu-action{
            position: absolute;
            right: 0px;
            width: 220px;
            z-index: 25;
            background-color: var(--main-white-color);
            border-radius: 10px;
            box-shadow: 0px 10px 50px #dadada;
            & li{
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: Hurme-Black;
              padding: .5em;
              font-size: v.$low-size-text;
              text-transform: uppercase;
              color: var(--main-blue-color-bg);
              cursor: pointer;
              &.close-casting{
                cursor: wait;
                .close-loading{
                  background-color: var(--main-blue-color-bg);
                  opacity: 0.5;
                }
              }
              &:hover{
                background-color: var(--main-blue-color-bg);
                transition: 0.3s;
                color: var(--main-white-color);
              }
              &:nth-last-child(1){
                color: var(--main-violet-color-text);
                border-radius: 0 0 10px 10px;
                &:hover{
                  color: var(--main-white-color);
                  background-color: var(--main-violet-color-text);
                }
              }
              &:first-child {
                border-radius: 10px 10px 0 0;
              }
            }
          }
          &:nth-child(1) {
            justify-content: center;
            display: flex;
            position: absolute;
          }
          &:nth-child(2) {
          }
        }
      }
    }
    &:nth-of-type(2) {
    }
  }
  .header-table{
    font-family: Hurme-Bold !important;
  }
  .section-castings{
    .table{
      color: var(--main-black-color) !important;
      font-weight: 400;
      border-left:0 !important;
      border-right:0  !important;
      border-radius: 0 !important;
    }

  }
  @include mobile {
    padding-top: 0;
    section {
      &:nth-of-type(1) {
        top: 70px;
        padding-bottom: 72px;
      }
    }
  }
  @include tablet {
    section {
      &:nth-of-type(1) {
        padding: 20px 0 65px 0;
        .btns-container {
          padding: 10px 0px;
          .btns-filter {
            &:nth-child(2) {
              bottom: 3px;
              position: absolute;
              right: 50px;
            }
          }
        }
      }
    }
  }
}
