@use './_colors.scss'as colors;
@use './variables.scss'as variables;
@import './others/grid.sass';
@import './partials/buttons';
@import './slick-register.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    height: 100vh;
}

#__next {}

.css-8v0fa4-MuiGrid-root {
    max-width: 100% !important;
}

.css-1xgjpwf-MuiGrid-root {
    justify-content: center;
    margin-top: 8px !important;
}

.css-1xgjpwf-MuiGrid-root>.MuiGrid-item {
    padding-top: 0 !important;
}

.eIOjFb {
    height: 100% !important;
}

.splide__track {
    overflow-x: hidden;
    overflow-y: visible;
}

.splide__list {
    min-height: 440px;
    align-items: center;
}
.slide {
    display: flex;
    justify-content: center;
    flex-direction: inherit !important;
    width: 50vw;
}

#chat-panel {
    justify-content: flex-end;
    flex: auto !important;
    overflow: scroll !important;
    height: 71vh !important;
}

.tooltip-casting,
.tooltip-team {
    & span {
        font-family: Hurme-Black;
    }
}

#chat-history {}

.chat-messages {
    &>div {
        margin: 0 !important;
        overflow: hidden !important;
        // display       : flex;
        // flex-direction: column;
    }
}

.sc-kDThTU {
    max-width: 60%;
    padding: 18px 22px !important;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}

h2,
h1,
h3,
h4,
h5,
h6 {
    font-size: 1.3rem;
    margin: 0;
    line-height: initial;
}

p {
    margin: 0;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.badge {
    width: 17px;
    height: 17px;
    border-radius: 12px;
    background-color: colors.$alert;
    position: absolute;
    top: -12px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: variables.$p !important;
    color: var(--main-white-color) !important;
    font-weight: 600;
}

.error {
    color: colors.$error-txt;
    font-size: variables.$low-size-text;
    font-family: Hurme-Bold;
}

hr.vertical {
    width: 0.5px !important;
    min-height: 30px;
    height: 100%;
    border: 1px solid colors.$grey;
    margin: 0px 10px !important;
}

.disabled {
    pointer-events: none;
}

input[type="checkbox"].checkbox {
    position: relative;
    display: flex;
    width: 14px;
    height: 14px;
    justify-content: center;
    align-items: center;
    
    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 2px;
        cursor: pointer;
    }
    &:before {
        background: white;
        border: solid 0.5px colors.$light-blue-magenta;
    }
    &:checked:before {
        background: colors.$blue-dark;
        border: none;
    }
    &:checked:after {
        content: '\f00c';
        width: 100%;
        height: 100%;
        font-family: "Font Awesome 6 Pro";
        font-size: 8px;
        -webkit-text-stroke: 1.5px white;
        color: white;
    }
  }

.scroll-container-x, .scroll-container-y {
        
    &::-webkit-scrollbar-track {
        background: colors.$new-blue-fair; 
        border-radius: 100px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #E4EAFF;
        border-radius: 100px;
    }
    
    &::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
    }
}

.scroll-container-x {
    overflow-x: auto;
}

.scroll-container-y {
    overflow-y: auto;
}

.scroll-container-x::-webkit-scrollbar {
    height: 5px;
}

.scroll-container-y::-webkit-scrollbar {
    width: 5px;
}

.pac-container {
    // prevents modal from hiding Google autocomplete options
    z-index: 90000 !important;
}

// .__react_component_tooltip {
//     visibility: visible;
//     position: absolute !important;
//     // z-index          : 999;
//     &.show {
//         background-color: #222;
//         border: 1px solid transparent;
//         z-index: 999;
//     }
// }
// react modal animation
:focus-visible {
    outline: none;
}

.ReactModal__Overlay {
    opacity: 0;
    z-index: 10002;
    //transform: translateX(-100px);
    transition: all 500ms ease-in-out;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    //transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    //transform: translateX(-100px);
}

.ReactModal__Content {
    transition: all 500ms ease-in-out !important;
    //transform: translateX(-100px) !important;
    @media (max-width: 600px) {
        //    top: 500px !important;
    }
}

.ReactModal__Content--after-open {
    transform: translate(-50%, -50%);
}

@media screen and (max-height:767px) {
    // .ReactModal__Content--after-open {
    //     display: block !important
    // }
}

@include mobile {
    // .ReactModal__Content--after-open {
    //     display: block !important;
    //     pointer-events: none;
    // }
    // .ReactModal__Content--after-open {
    //     transform: translate(-50%, -43%) !important;
    // }
}

.ReactModal__Content--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Body--open {
    overflow: hidden;
}

[aria-label="video-label"] {
    z-index: 100000;
}

.Toastify__toast {
    box-shadow: 0px 0px 30px 1px rgb(209 209 209 / 50%) !important;
    border-radius: 12px !important;
    padding: 18px !important;
}


/* toastify css override to have better toast in front */

.Toastify__toast-theme--colored.Toastify__toast--error {
    backdrop-filter: blur(6px) saturate(180%);
    background: rgba(231, 76, 60, 0.8) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    backdrop-filter: blur(6px) saturate(180%);
    background: rgba(21, 209, 74, 0.8) !important;
    padding: 10px 15px !important;
}

.Toastify__toast-container--top-right {
    min-width: 400px;
    z-index: 999999 !important;
}


/*
* Flickity css
*/

.is-selected {
    height: 200px !important;
    opacity: 1 !important;
}


/*
* End flickity css
*/

@font-face {
    font-family: 'Roboto-Bold';
    src: 'assets/fonts/Roboto/Roboto-Bold.ttf';
}

@media screen and (min-width: 400px) {
    .validate-btn {
        width: 50%;
    }
}

@media screen and (min-width: 972px) {
    .box-img {
        width: 100% !important;
    }
}

@media screen and (min-width: 1877px) {
    .box-img {
        width: 80% !important;
    }
    .box-heading {
        font-size: 70px !important;
    }
    .box-text {
        font-size: 25px !important;
    }
    .validate-bt {
        height: 45px !important;
    }
    .btn-retour {
        height: 45px !important;
    }
}

@media screen and (max-width: 576px) {
    .desktop {
        display: none !important;
    }
    .mobile {
        flex-direction: column !important;
    }
    .boxChat {
        width: 100% !important;
    }
}

@media screen and (min-width: 576px) {
    .responsiveMobile {
        display: none !important;
    }
}

.span {
    color: #5fefe3;
}

.basic-link {
    color: var(--main-blue-color-text);
    font-family: Hurme-Black;
    font-size: variables.$p;
    transition: 0.2s color ease-in-out;
    text-decoration: none;
    margin-bottom: -3px;
    &:hover {
        color: lighten($color: colors.$blue-dark2, $amount: 20);
    }
}

.required:after {
    content: ' *';
    color: colors.$blue-dark;
    font-family: Hurme-Bold;
}

@keyframes grow-animation {
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1.1);
    }
}

.Toastify__toast-container {
    width: 7% !important;
    .toast-success,
    .toast-error {
        justify-content: center !important;
        width: min(400px, 100vw) !important;
        padding: 10px !important;
        div {
            div {
                div {
                    justify-content: center;
                    aside {
                        h3 {
                            color: black !important;
                            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
                            font-size: 14px !important;
                        }
                        i {
                            font-size: 16px;
                            color: black !important;
                        }
                    }
                }
            }
        }
    }
    .toast-error {
        div {
            div {
                div {
                    aside {
                        h3 {
                            color: white !important;
                        }
                        i {
                            color: white !important;
                        }
                    }
                }
            }
        }
    }
}

//loading style
.loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12.5px);
    -webkit-backdrop-filter: blur(12.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.2s ease-in-out;
    .text-loading {
        animation: grow-animation 10s linear infinite;
        font-family: Hurme-Bold;
    }
}

.stripe__ico {
    position: absolute;
    bottom: 20px;
    right: 30px;
    width: 70px;
}

// page dashboard style
.Page {
    width: calc(100% - 116px);
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    transition: margin-left 0.2s ease-in-out;
    .hamburger-menu-div {
        display: none;
        padding: 12px;
        //margin-bottom: 20px;
        //box-shadow: 0px 0px 23px 8px #dedede;
        backdrop-filter: blur(10px);
        position: sticky;
        top: -2px;
        z-index: 20;
        width: 100%;
        background: rgba(255, 255, 255, 0.4);
        //position: absolute;
    }
    .overlay {
        display: none;
    }
    span {
        &[marker] {
            background: linear-gradient(to top, #fcd81a 50%, transparent 50%);
        }
        &[special] {
            font-family: Hurme-Bold;
            text-align: center;
            color: #67a4ff;
        }
    }
    @include mobile {
        .hamburger-menu-div {
            display: block;
            margin-bottom: 0;
        }
        .body-page-home {
            padding: 40px 0;
        }
        width: 100vw !important;
    }
    &.mobile {
        margin-left: 117px;
        margin-right: -117px;
        .overlay {
            display: block;
            position: fixed;
        }
    }
    // &.active {
    //     width: calc(100% - 280px) !important;
    // }
}