@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as variables;
@import '../../../../sass/partials/_buttons';
@import '../../../../sass/mixins';
@import '../../../../sass/_mixins/global';
@import '../../../../sass/_mixins/animated-shine.scss';
$animation-duration: 1.6s;
$grid-prefix: 'namespace-';
.grid {
    width: 90%;
    flex-direction: column;
}

.brief-page {
    position: relative;
    display: flex;
    flex-direction: column;
    & span {
        line-height: 18px;
    }
    & button {
        position: relative;
    }
    & .skeleton-brief-page {
        width: 70vw;
        max-width: 470px;
        height: 15px;
    }
    & .badge {
        position: absolute;
        right: 1px;
        top: -6px;
        color: var(--main-white-color);
        background-color: colors.$blue-dark;
        font-size: 11px;
        width: 18px;
        height: 18px;
        display: flex;
        padding: 0 !important;
        justify-content: center;
        border-radius: 100%;
        align-items: center;
        & p {
            font-size: 12px;
        }
    }
    &>header {
        position: sticky;
        top: 0px;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(10px);
        z-index: 11;
        margin-top: 20px;
        margin-bottom: -30px;
        display: flex;
        justify-content: space-between;
        padding: 20px 12px 45px 12px;
        a {
            z-index: 10;
        }
        & .btn-badge {
            border-radius: 12px;
            min-height: 43px;
            text-decoration: none;
            font-size: 0.9rem;
            background-color: #f5f7ff;
            font-family: Hurme-Bold;
            color: colors.$blue-dark;
            position: relative;
            width: 12em;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.2s ease-in-out;
            & i {
                margin-left: 8px;
            }
            &:hover {
                background-color: #ccd7ff;
            }
        }
        >p{
            border-radius: 5px;
            border: 0.5px solid colors.$input-border-grey;
            background: colors.$green-honeydew;
            color: var(--main-blue-color-text);
            font-family: Hurme-Bold;
            font-size: variables.$medium-size;
            text-transform: uppercase;
            padding: 10px 15px;
            margin-right: 5rem;
        }
    }
    .header-private {
        padding-inline: 5vw;
        padding-top: 1.25vw;
        padding-bottom: 1.25vw;
        background: none;
        >p{
            margin-right: 25rem;
        }
    }
    .add-selection {
        align-items: center;
        transition: all 0.2s ease-in !important;
        .badge {
            background-color: colors.$alert;
            right: -3px;
            top: -6px;
        }
    }
    & .infos-brief-info {
        z-index: 12;
        background: linear-gradient( 180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        position: fixed;
        bottom: 0;
        /* left: 0; */
        // right: 0;
        min-height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 20px;
        transition: all 2s ease-in;
        width: calc(100vw - 280px);
        &>button {
            background-color: colors.$lavender-blue;
            border: 1px solid var(--main-white-color);
            padding: 12px 19px;
            border-radius: 12px;
            font-size: 18px;
            margin: 0 auto;
            cursor: pointer;
            font-size: variables.$px-ts-md * 0.9;
            box-shadow: 0px 3px 20px colors.$lavender-blue;
            font-family: Hurme-Black;
            color: var(--main-white-color);
            position: relative;
            transition: 0.2s ease-in-out;
            &.active {
                background-color: colors.$blue-dark;
            }
            &:hover {
                background-color: colors.$perano-blue;
                box-shadow: 0px 3px 20px colors.$perano-blue;
            }
        }
        span {
            background: #38b2f6;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-radius: 12px;
            max-width: 30rem;
            box-shadow: 0px 2px 30px lighten($color: colors.$blue-dark2, $amount: 20);
            transition: all 2s ease-in;
            cursor: pointer;
            >div {
                margin-left: 20px;
            }
            h4 {
                font-size: variables.$mid-title-size-text * 0.9;
                font-family: Hurme-Bold;
                color: var(--main-white-color);
                // text-align: center;
            }
            i {
                font-size: variables.$mid-title-size-text * 2;
                color: var(--main-white-color);
                // margin-right: 20px;
            }
            .info-alert-sub {
                p {
                    margin-top: 5px;
                    font-size: variables.$medium-size-text;
                    font-family: roboto;
                    color: lighten($color: colors.$grey-darken, $amount: 100);
                }
            }
        }
    }
    & .header-brief-page {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 28px 0 18px 0;
        & .container-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            .lock-icon {
                margin-left: 12px;
                margin-top: 6px;
                width: 20px;
                height: 20px;
            }
        }
        & .tags {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & div {
                margin: 3px 5px;
            }
        }
        & h2 {
            margin-top: 12px;
            font-size: variables.$mid-title-size-text;
            font-family: Hurme-Bold;
        }
        & p {
            color: colors.$grey-dark;
            font-size: variables.$medium-size-text;
            line-height: 18px;
            margin-top: 5px;
            text-align: center;
            font-family: roboto;
            max-width: 470px;
        }
    }
    & svg:last-child {
        display: none;
    }
    &.brief-page_all {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    & .all-body {
        width: 100%;
        margin: 10px 0 59px 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        & section:nth-child(1) {
            position: sticky;
            width: 80%;
            display: flex;
            justify-content: center;
            top: 12px;
            //top: 28px;
            z-index: 10;
            & .steps {
                display: flex;
                // width: 80%;
                // max-width: 700px;
                align-self: center;
                height: auto;
                & .step {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 12px;
                    height: auto !important;
                    & h2 {
                        transition: 0.2s color ease-in;
                        color: colors.$blue-dark;
                        &.not-selected-text {
                            color: colors.$blue-not-selected;
                        }
                    }
                    @include btn-ctr;
                    & h2.disabled {
                        @include disabled;
                    }
                    & span {
                        margin-top: 6px;
                        font-size: variables.$medium-size-text;
                        font-family: roboto;
                        display: flex;
                        align-items: center;
                        color: colors.$main-blue;
                        &>i {
                            font-size: variables.$medium-size-text * 0.85;
                            margin: 0.2px 0 0 3px;
                        }
                        &.disable {
                            color: colors.$grey-dark;
                        }
                    }
                    & button {
                        &:hover {}
                    }
                }
            }
        }
        & section:nth-child(2) {
            position: sticky;
            top: 107px;
            z-index: 9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 2rem 1em;
            width: 100%;
            &.card-send-product {
                flex-direction: column;
            }
            & .alert-info {
                padding: 7px 2em;
                border-radius: 7px;
                @include background-gradient(colors.$blue-menthe, #38b2f6);
                animation: shine-lines $animation-duration infinite linear;
                background: #38b2f6;
                box-shadow: 0px 2px 10px rgba(190, 190, 190, 0.7);
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                & span {
                    font-family: Hurme-Bold;
                    font-size: variables.$medium-size-text;
                    color: var(--main-white-color);
                }
                &.deadline2 {
                    background-color: colors.$alt-info-violet;
                }
            }
            .btns-filter {
                margin-left: auto;
                flex: 1.5;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                    justify-content: center;
                    display: flex;
                    position: absolute;
                }
                &:nth-child(2) {}
            }
            .search-bar {
                flex: 1.5;
                display: flex;
                justify-content: center;
                padding-left: 2em;
                & input {
                    width: 80%;
                }
            }
        }
        & .applicant-vogz {
            & .placeholder-nodata {
                width: 100%;
                display: flex;
                -webkit-flex-direction: column;
                flex-direction: column;
                align-items: center;
                & img {
                    width: 20rem;
                }
                & h3 {
                    color: #ccd6ff;
                    font-size: 1.5em;
                    font-family: Hurme-Bold;
                    text-align: center;
                }
            }
        }
        & section:nth-child(3) {
            // width  : 100%;
            // display: flex;
            // max-width: 80%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 10px;
            & div:nth-of-type(1) {
                // max-width : 1570px;
                position: relative;
                align-self: center;
                &>.badge {
                    margin: -2px 0 0 -18px !important;
                }
            }
        }
        & section:last-of-type {
            min-height: 70%;
            width: 100%;
            max-width: 1497px;
        }
        & .grid2 {
            max-width: 1541px;
            align-self: center;
            display: flex !important;
            width: 100%;
            & .placeholder-nodata {
                width: 100%;
                display: flex;
                -webkit-flex-direction: column;
                flex-direction: column;
                align-items: center;
                & img {
                    width: 30rem;
                }
                & h3 {
                    color: #e7ebff;
                    font-size: 1.5em;
                    font-family: Hurme-Bold;
                    text-align: center;
                }
            }
            & div>div>footer {
                max-width: 1541px;
                align-self: center;
                // width     : 100%;
                &.bought {
                    width: fit-content !important;
                }
            }
        }
        // 3 third section with applicant and all medias
        & .applicant-vogz {
            width: 100%;
            margin: 0 !important;
            & figure {
                margin: 0px 12px 0px 0px;
                & img {
                    @include avatar(140px);
                }
            }
            max-width: 100% !important;
            &>div {
                margin-bottom: 23px;
                width: 100% !important;
                & footer {
                    //padding                  : 40px 24px 16px 24px;
                    padding: 40px 24px 47px 24px;
                    overflow-x: scroll;
                    background-color: colors.$blue-btn;
                    margin: 0 0 12px 0;
                    // border-radius         : 12px;
                    border-top-left-radius: 0;
                    display: flex;
                    &::-webkit-scrollbar {
                        width: 15px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: rgba(0, 0, 0, 0.6);
                    }
                    & article:not(:nth-child(1)) {
                        margin-left: 3rem;
                    }
                }
                & header {
                    display: flex;
                    width: 100%;
                    height: 100px;
                    /* flex-direction: row; */
                    justify-content: space-between;
                    & .card-applicant-left {
                        background-color: colors.$blue-btn;
                        & .card-desc-briefapplication {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
                            & h3 {
                                font-family: Hurme-Bold;
                                font-size: variables.$mid-title-size-text;
                            }
                            & button {
                                @include outlined-btn;
                                margin-top: 5px;
                                //padding: 5px 12px;
                                width: 60%;
                                min-width: 112px;
                                border-radius: 22px;
                                width: 90%;
                                cursor: pointer;
                                font-family: Hurme-Bold;
                                font-size: variables.$low-size-text;
                            }
                        }
                        span {
                            position: relative;
                            &.badge-red {
                                @include badge("red");
                                position: absolute;
                                padding: 3px 12px;
                                z-index: 7;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                color: var(--main-white-color);
                                font-size: variables.$medium-size-text;
                                font-family: Hurme-Black;
                                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                                height: 16px;
                                font-size: variables.$medium-size-text * .7;
                                border-radius: 10px;
                                display: flex;
                                left: 46px;
                                bottom: 0px;
                                align-items: center;
                                box-shadow: none
                            }
                        }
                    }
                    aside {
                        display: flex;
                        align-items: center;
                    }
                    aside:nth-of-type(1) {
                        // border-top-right-radius: 12px;
                        // border-top-left-radius : 12px;
                        transform-style: preserve-3d;
                        padding: 12px 0px 0 27px;
                        width: 18rem;
                        // &::before {
                        //     content               : "";
                        //     position              : absolute;
                        //     background-color      : transparent;
                        //     bottom                : -13px;
                        //     height                : 50px;
                        //     right                 : -37px;
                        //     width                 : 25px;
                        //     border-top-left-radius: 25px;
                        //     -webkit-box-shadow    : 0 -25px 0 0 colors.$blue-btn;
                        //     box-shadow            : 0 -25px 0 0 colors.$blue-btn;
                        //     z-index               : -1;
                        //     transform             : rotate(-90deg);
                        // }
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: -35px;
                            width: 83%;
                            height: 103%;
                            border-style: solid;
                            border-color: #f5f7ff;
                            border-width: 2px 2px 2px 0;
                            border-radius: 0 8px 0 0;
                            -webkit-transform: skewX(20deg);
                            transform: skewX(20deg);
                            background-color: inherit;
                            z-index: -1;
                        }
                    }
                    aside:nth-of-type(2) {
                        border-bottom-left-radius: 12px;
                        align-self: flex-end;
                        padding: 11px 39px;
                        flex: 1 1;
                        height: 100%;
                        justify-content: flex-end;
                        background-color: #dde4ff;
                        & div {
                            button {
                                background-color: colors.$lavender-blue;
                                border: 1px solid var(--main-white-color);
                                padding: 10px 19px;
                                border-radius: 12px;
                                cursor: pointer;
                                box-shadow: 0px 3px 20px colors.$lavender-blue;
                                font-family: Hurme-Black;
                                color: var(--main-white-color);
                                transition: 0.2s ease-in-out;
                                font-size: variables.$px-ts-sm;
                                i {
                                    font-size: variables.$low-size-text * 1.2;
                                }
                                &.active {
                                    background-color: colors.$blue-dark;
                                }
                                &:hover {
                                    background-color: colors.$perano-blue;
                                    box-shadow: 0px 3px 20px colors.$perano-blue;
                                }
                            }
                            & span {
                                background-color: colors.$alert;
                                position: absolute;
                                margin: -7px 0 0 -18px;
                                border-radius: 100%;
                                text-align: center;
                                font-size: variables.$low-size-text;
                                color: var(--main-white-color);
                                font-family: Hurme-Bold;
                                width: 18px;
                                height: 18px;
                                -webkit-border-radius: 9px;
                                -moz-border-radius: 9px;
                                border-radius: 100%;
                            }
                        }
                        // & div {
                        //     display     : flex;
                        //     color       : colors.$blue-fair;
                        //     margin-right: 20px;
                        //     align-items : center;
                        //     & h3 {
                        //         font-size  : variables.$mid-title-size-text * 2;
                        //         font-family: Hurme-Bold;
                        //     }
                        //     & span {
                        //         font-family: Roboto-Regular;
                        //         font-style : normal;
                        //         line-height: 17px;
                        //         margin-left: 6px;
                        //         margin-top : -10px;
                        //         font-weight: 400;
                        //         font-size  : variables.$medium-size-text;
                        //     }
                        // }
                    }
                }
                width: 95%;
            }
        }
    }
}
.parent-modal{
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(7.5px);
    position: absolute;
    z-index: 100000;
    display: flex;
    top: -70px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .product-shipment-modal {
        display: flex;
        width: 500px;
        flex-direction: column;
        border-radius: 1rem;
        --custom-black: #2F2E41;
        border-radius: 5px;
        border: 4px solid #FFF;
        div {
            display: flex;
            flex-direction: column;
            padding: 5% 12% 7% 12%;
            &.top-part {
                flex: 1;
                background: colors.$purple-anemone;
                position: relative;
                h1 {
                    font-family: Hurme-Black;
                    font-size: 2ch;
                    margin-bottom: 0.3ch;
                    color: var(--main-white-color);
                    text-shadow: 0px 4px 20px #5531B9;
                }
                p {
                    font-size: 1ch;
                    line-height: 2ch;
                    color: var(--main-white-color);
                }
                button{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: var(--main-white-color);
                }
            }
            &.bottom-part {
                background: var(--main-light-grey);
                h5 {
                    font-family: Hurme-Bold;
                    font-size: 1.65ch;
                    margin: 0.6ch 0;
                    color: var(--custom-black);
                }
                b {
                    font-family: Hurme-Bold;
                    font-size: 1.2ch;
                    line-height: 1ch;
                    color: colors.$purple-anemone;
                    &:last-of-type {
                        color: var(--custom-black);
                    }
                }
                br {
                    display: block;
                    content: '';
                    margin-top: 1.7ch;
                }
                p {
                    font-size: 1.1ch;
                    height: min-content;
                }
                .form {
                    padding: 0;
                    margin-top: 0.1ch;
                    * {
                        padding: 0.2ch 0.5ch;
                        border: none;
                        color: #5777FF;
                    }
                    .text-field {
                        input {
                            height: 2.5rem;
                            border: solid 0.1rem #D2D2D2;
                            border-radius: 0.3rem;
                            background: white;
                            padding: 0 1ch;
                            transition: 0.15s;
                            &::placeholder {
                                opacity: 0.7;
                            }
                            &:focus {
                                border-color: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
    .tips-modal {
        display: flex;
        width: 500px;
        flex-direction: column;
        border-radius: 1rem;
        --custom-black: #2F2E41;
        border-radius: 5px;
        border: 4px solid #FFF;
        div {
            display: flex;
            flex-direction: column;
            padding: 5vh 4vw 5vh 4vw;
            &.top-part {
                flex: 1;
                background: colors.$blue-shamrock;
                position: relative;
                h1 {
                    font-family: Hurme-Black;
                    font-size: 2ch;
                    margin-bottom: 0.3ch;
                    color: var(--main-white-color);
                    text-shadow: 0px 4px 20px colors.$blue-shamrock;
                }
                p {
                    font-size: 1ch;
                    line-height: 2ch;
                    color: var(--main-white-color);
                }
                button{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: var(--main-white-color);
                }
            }
            &.bottom-part {
                background: var(--main-light-grey2);
                > h5 {
                    font-family: Hurme-Bold;
                    font-size: 1.65ch;
                    margin: 0.6ch 0;
                    color: var(--custom-black);
                }
                > b {
                    font-family: Hurme-Bold;
                    font-size: 1.2ch;
                    line-height: 1ch;
                    color: colors.$purple-anemone;
                    &:last-of-type {
                        color: var(--custom-black);
                    }
                }
               > br {
                    display: block;
                    content: '';
                    margin-top: 1.7ch;
                }
               > p {
                    font-size: 1.1ch;
                    height: min-content;
                }
                > .form {
                    padding: 0;
                    margin-top: 0.1ch;
                    * {
                        padding: 0.2ch 0.5ch;
                        border: none;
                    }
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .input-tips{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap:  1rem;
                        padding: 0;
                        padding-top: 0.5rem;
                        .text-field{
                            width: 45%;
                            input{
                                text-align: center;
                                font-family: Hurme-bold;
                                &::placeholder{
                                    color:var(--my-castings-grey-color-text);
                                }
                                &:disabled{
                                    background-color: var(--main-grey);
                                    color: var(--main-grey-darken);
                                }
                            }
                        }
                        button{
                            min-width: 0;
                            width: 55%;
                            padding: 8px 12px !important;
                            height: 2.5rem;
                        }
                    }
                    .text-field {
                        resize: none;
                        width: 100%;
                        color: #5777FF;
                        input {
                            height: 2.5rem;
                            border-radius: 0.3rem;
                            background: white;
                            padding: 0 1ch;
                            transition: 0.15s;
                            &::placeholder {
                                opacity: 0.7;
                            }
                            &:focus {
                                border-color: inherit;
                            }
                        }
                    }
                    p{
                        text-align: center;
                    }
                }
            }
        }
    }
}
.onspot-modal{
    div{
        padding:0;
        &.top-part {
            border-radius: 10px 10px 0px 0px;
            background: var(--modal-confirmation-rdv-info);
            padding: 26px 29px 28px 31px;
            h1 {
                color:  colors.$white ;
                text-shadow: 0px 4px 20px var(--modal-confirmation-rdv-info);
            }
            p {
                color: colors.$white;
            }
        }
        &.bottom-part {
            background: colors.$light-grey;
            padding: 21px 31px 24px 31px;
            border-radius:  0px 0px 10px 10px;
            h5 {
                &.name-applicant{
                    color: var(--custom-black);
                }
                color: var(--modal-confirmation-rdv-info);
            }
            b {
                color:  var(--modal-confirmation-rdv-info);
                &:last-of-type {
                    color:  var(--modal-confirmation-rdv-info);
                }
            }
            input,select {
                height: 2.5rem;
                border: solid 0.1rem colors.$input-border-grey;
                border-radius: 0.3rem;
                background: colors.$white;
                padding: 0 1ch;
                transition: 0.15s;
                &::placeholder {
                    opacity: 0.7;
                }
                &:focus {
                    border-color: inherit;
                }
            }
        }
    }
}
.absolute-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.grid2 {
    display: flex !important;
    margin-bottom: 40px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.deal {
    background-color: colors.$lavender-blue;
    border: 1px solid var(--main-white-color);
    padding: 6px 25px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0px 3px 20px colors.$lavender-blue;
    font-family: Hurme-Black;
    color: var(--main-white-color);
    transition: 0.2s ease-in-out;
    font-size: variables.$px-ts-sm;
    margin-bottom: 10em;
    position: relative;
    i {
        font-size: variables.$low-size-text * 1.2;
    }
    &.active {
        background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);
    }
    &:hover {
        box-shadow: 0px 3px 20px colors.$perano-blue;
        opacity: .8;
    }
    span{
        line-height: normal;
        height: 23px;
        width: 23px;
    }
}

@include tablet {
    .parent-modal> .tips-modal, 
    .parent-modal> .calendar-modal,
    .parent-modal> .product-shipment-modal {
        margin-left: 270px;
    }
    .parent-modal> .calendar-modal{
        width: 500px;
        .bottom-part {
            flex-direction: column!important;
            align-items: center;
            & .form-part{
                margin-bottom: 1rem;
                width: 100%;
            }
        }
    }
    .brief-page {
        overflow-x: unset;
        &>header {
            &>.btn-badge {
                border-radius: 12px;
                font-size: variables.$medium-size-text;
                width: 9em;
                & .badge {
                    width: 15px;
                    height: 15px;
                    & p {
                        font-size: variables.$medium-size-text * 0.7;
                    }
                }
            }
        }
        & .header-brief-page {
            & .tags {
                margin-top: 30px;
            }
        }
        & .all-body {
            & section:nth-child(1) {
                top: 0
            }
            & section:nth-child(1) {
                width: 100%;
                overflow-y: scroll;
                position: relative;
                z-index: 5;
                & .steps {
                    width: 100%;
                }
            }
            & section:nth-child(3) {
                flex-direction: column;
                align-items: center;
            }
            & .applicant-vogz {
                margin: 0 !important;
                &>div {
                    width: 100%;
                    footer {
                        border-radius: 0;
                    }
                    & aside:nth-of-type(2) div:not(:first-child) {
                        display: none;
                    }
                }
            }
            & .filter-section {
                flex-direction: column;
                & .search-bar {
                    width: 80%;
                    margin-bottom: 20px;
                    padding-left: 0 !important;
                    & input {
                        width: 100% !important;
                    }
                }
                & .alert-info {
                    width: 80%;
                    max-width: 435px;
                    text-align: center;
                }
                & .btns-filter {
                    margin: 3px 15px auto auto;
                }
            }
        }
    }
    .absolute-container {
        width: 90%;
    }
}
@include tablet2(){
    .parent-modal> .tips-modal,
    .parent-modal> .calendar-modal,
    .parent-modal> .product-shipment-modal {
        width: 60%;
    }
    .brief-page{
        .header-private {
            >p{
                font-size: variables.$low-size-text;
                margin-right: 1rem;
            }
        }
    }
}
@include mobile() {
    .brief-page {
        .infos-brief-info {
            width: 100% !important;
            &>span {
                // width: 80%;
                padding: 5px 10px;
                h4 {
                    font-size: variables.$mid-title-size-text * 0.8;
                }
            }
        }
        &>header {
            padding: 16px 22px 20px;
            top: 71px;
            margin-bottom: 0px;
            &>.btn-badge {
                border-radius: 12px;
                font-size: variables.$medium-size-text;
                width: 9em;
                & .badge {
                    width: 15px;
                    height: 15px;
                    & p {
                        font-size: variables.$medium-size-text * 0.7;
                    }
                }
            }
        }
        aside:nth-of-type(2) {
            padding-right: 5px !important;
            & div {
                button {
                    width: 100%;
                    max-width: 70px;
                    margin: 0 1rem;
                    i {
                        font-size: variables.$low-size-text * 1.5 !important;
                    }
                }
            }
        }
        .header-private {
            margin-top: 0;
            backdrop-filter: unset;
        }
        & .header-brief-page {
            margin-top: 0;
            & .tags {
                margin-top: 0px !important;
            }
        }
        & .all-body {
            & section:nth-child(1) {
                top: 0
            }
            & section:nth-child(2) {
                position: static;
                margin-top: 0;
            }
            & section:nth-child(3),
            & .applicant-vogz {
                & .grid2,
                div>footer {
                    & .placeholder-nodata {
                        & img {
                            width: 100%;
                        }
                        & h3 {
                            color: #e7ebff;
                            font-size: 1.2em;
                            text-align: center;
                        }
                    }
                }
                & div>footer .placeholder-nodata h3 {
                    color: #ccd6ff;
                }
            }
        }
        & .all-body {
            & .filter-section {
                flex-direction: column;
                & .search-bar {
                    width: 80%;
                    margin-top: 3rem;
                    margin-bottom: 20px;
                    padding-left: 0 !important;
                    & input {
                        width: 100% !important;
                    }
                }
                & .alert-info {
                    width: 80%;
                    text-align: center;
                }
                & .btns-filter {
                    margin: 3px 15px auto auto;
                }
            }
        }
    }
    .product-shipment-modal {
        width: 95%;
    }
    .absolute-container {
        width: 95%;
    }
    .parent-modal> .tips-modal,
    .parent-modal> .calendar-modal,
    .parent-modal> .product-shipment-modal {
        width: 95%;
        margin-left: 0 !important;
    }
}

@media (max-width: 1440px) {
    .infos-brief-info {
        width: calc(100vw - 116px) !important;
    }
    .parent-modal> .tips-modal,
    .parent-modal> .calendar-modal,
    .parent-modal> .product-shipment-modal {
        width: 80%;
        margin-left: 116px;
    }
}

@media (min-width: 1799px) {
    .grid2 {
        width: 100%;
    }
}