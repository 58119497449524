@use "../../../../../sass/colors" as colors;
@use "../../../../../sass/variables.scss"as variables;
@import "../../../../../sass/mixins";


.vogzter-search-item {
  --heart-icon-size: variables.$px-ts-md;
  --heart-icon-size-sm: 15px;
  display: flex;
  flex-direction: column;
  // width: 300px;
  width: 320px;
  height: 395px;
  justify-content: center;
  align-items: center;
  background: colors.$white;
  border-radius: 15px;
  border: 0.5px solid #C9C9C9;
  box-shadow: 0px 2px 15px rgba(150, 150, 150, 0.25);
  transition: .1s;
  overflow: hidden;

  & .top-part {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    padding: 25px 0 7px 0;
    margin-bottom: 5px;
    & span {
      display: flex;
      > h4 {
        display: flex;
        width: fit-content;
        align-items: center;
        font-family: Hurme-Bold;
        font-size: variables.$px-ts-md;
        line-height: 0;
      }
      > button {
        line-height: normal;
        > i {
          margin-left: 5px;
          font-size: var(--heart-icon-size);
          cursor: pointer;
          transition: .2s;
          &:active {
            animation: heart-animation .5s ease-in-out infinite;
          }
        }
      }
    }
    & .location {
      width: fit-content;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: variables.$px-ts-sm;
      padding-right: var(--icon-width);
      color: #B1B1B1;
      line-height: 14px;
      cursor: zoom-in;
      &::before {
        content: '\f3c5';
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        margin-right: 5px;
      }

      a {
        text-decoration: underline;
        cursor: inherit;
      }
    }
    & .media-preview {
      position: relative;
      width: 145px;
      height: 100%;
      border-radius: 5px;
      margin: 0 5px;
      overflow: hidden;
      flex-shrink: 0;
      cursor: pointer;
      > i {
        position: absolute;
        display: flex;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        color: colors.$white;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
      > img {
        width: 100%;
        height: 220px;
        object-fit: cover;
      }
    }
    & .media-placeholder {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 100%;
      align-self: center;
      justify-content: center;
      align-items: center;
      background: linear-gradient(129.12deg, #E6E7FEAA 0%, #E6FEFBAA 100%);
      border-radius: 5px;

      > * {
        color: #2F2E41;
      }
      > :nth-child(1) {
        text-transform: uppercase;
      }
      > :nth-child(2) {
        font-size: variables.$px-ts-md;
      }
    }
  }

  & .bottom-info {
    position: relative;
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: space-evenly;
    align-items: center;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    overflow: hidden;

    > .bg-gradient {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #D5FFFA;
      filter: blur(18px) saturate(1.3);

      > div {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
        &:nth-of-type(1) {
          left: -15%;
          bottom: 40%;
          width: 105.05px;
          height: 97.27px;
          background: #EDECFF;
        }
        &:nth-of-type(2) {
          left: 0;
          right: 0;
          width: 134.85px;
          height: 102.87px;
          background: #EAE0FF;
        }
        &:nth-of-type(3) {
          right: -20%;
          bottom: 40%;
          width: 105.05px;
          height: 97.27px;
          background: #D7D6FF;
        }
      }
    }

    > span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26%;
      height: 30px;
      background: colors.$white;
      border-radius: 40px;
      font-size: variables.$px-ts-nml;
      font-family: Hurme-Black;
      color: colors.$gunmetal;
      z-index: 2;
      &:hover {
        > p {
          opacity: 1;
        }
      }
      > p {
        opacity: 0;
        position: fixed;
        display: flex;
        flex-shrink: 0;
        font-family: Roboto;
        font-weight: 400;
        font-size: 10px;
        margin-top: 6%;
        background: colors.$white;
        padding: 0 10px;
        border: solid 1px;
        pointer-events: none;
        z-index: 2;
        transition: .1s;
        &::after {
          --arrow-size: 10px;
          content: '';
          position: absolute;
          bottom: calc(100% - var(--arrow-size) / 2);
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: var(--arrow-size);
          height: var(--arrow-size);
          background: linear-gradient(135deg, colors.$white 50%, transparent 50%);
          border: solid 1px;
          border-right-width: 0;
          border-bottom-width: 0;
        }
      }
    }
  }

  & *::-webkit-scrollbar {
    height: 3px !important;
  }

  & *::-webkit-scrollbar-track {
    background: #F2F2F2; 
    border-radius: 100px;
    margin: 0 10px;
  }

  & *::-webkit-scrollbar-thumb {
      background: #CCCCCC;
      border-radius: 100px;
  }

  @keyframes heart-animation {
    0% {
        font-size: var(--heart-icon-size-sm);
    }
    50% {
        font-size: var(--heart-icon-size);
    }
    100% {
      font-size: var(--heart-icon-size-sm);
    }
  }
}

@include giantScreen() {
  .vogzter-search-item {
    --heart-icon-size: 22px;
    width: 375px;
    height: 600px;

    & .top-part {
      padding: 30px 0 7px 0;
      margin-bottom: 5px;
      & .social-networks {
        * {
          font-size: 20px;
        }
      }
      & span {
        display: flex;
        > h4 {
          font-size: 22px;
        }
        > button {
          > i {
            font-size: var(--heart-icon-size);
            &:active {
              animation: heart-animation .5s ease-in-out infinite;
            }
          }
        }
      }
      & .location {
        font-size: 16px;
        padding-right: var(--icon-width);
        line-height: 16px;
      }
      & .media-preview {
        width: 200px;
        > img {
          height: 396px;
        }
      }
      & .media-placeholder {  
        > :nth-child(1) {
          font-size: 24px !important;
        }
        > :nth-child(2) {
          font-size: 20px;
        }
      }
    }
  
    & .bottom-info {
      height: 75px;
      > .bg-gradient {  
        > div {
          &:nth-of-type(1) {
            width: 160px;
          }
          &:nth-of-type(2) {
            width: 150px;
          }
          &:nth-of-type(3) {
            width: 150px;
          }
        }
      }
  
      > span {
        height: 50px;
        font-size: 25px;
        > p {
          font-size: 17px;
          margin-top: 4.5%;
          padding: 5px 17px;
          &::after {
            --arrow-size: 17px;
          }
        }
      }
    }
  }
}

