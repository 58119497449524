@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
.container-all-body {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    overflow: auto;
    .btn-back {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
    .all-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 600px;
        position: relative;
        section {
            display: flex;
            .card-choose {
                display: flex;
                cursor: pointer;
                flex-direction: column;
                align-items: center;
                margin: 0 12px;
                opacity: .5;
                transition: all .2s ease-in-out;
                &:nth-of-type(2) {
                    figure img {
                        width: 86%;
                        position: absolute;
                        bottom: 0;
                    }
                }
                input[type='checkbox']:checked {
                    &~figure {
                        border: 1px solid #C6C3FF;
                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
                input[type="checkbox"] {
                    display: none;
                }
                figure {
                    position: relative;
                    display: flex;
                    width: 315px;
                    height: 306px;
                    background: #FFFFFF;
                    box-shadow: 0px 5px 30px #f1f1f1;
                    border-radius: 15px;
                    justify-content: center;
                    border: 1px solid transparent;
                    img {
                        width: 86%;
                        &:nth-type(1) {
                            position: absolute;
                            bottom: 0;
                        }
                    }
                }
            }
            .textInput {}
            form {
                .container-form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex-direction: column;
                    .divInputcgv {
                        .FormFieldCGV {
                            display: flex;
                            .check-box {
                                width: 15px;
                                height: 15px;
                                left: 601px;
                                display: block;
                                margin-top: 7px;
                                border-radius: 2px;
                                margin-right: 12px;
                            }
                            .label-sub-heading {
                                font-size: 13px;
                                text-align: center;
                                width: 90%;
                                display: flex;
                                flex-direction: column;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                color: var(--main-grey-color-text);
                                &.errored-field {
                                    outline: 1px solid var(--main-alert-color);
                                }
                                a {
                                    line-height: 15px;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 13px;
                                    line-height: 15px;
                                    color: #5777FF;
                                    text-decoration: none;
                                    & :hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .FormField {
                .code {
                    font-family: Hurme-Bold;
                    font-size: .84rem;
                    color: #7c7c7c;
                    text-align: center;
                }
            }
        }
        .text-bottom {
            display: flex;
            margin-top: 20px;
            line-height: 30px;
            align-items: center;
            & p {
                margin-right: 10px;
            }
        }
    }
    @include tablet() {}
    @include mobile() {
        //padding-top: 10px;
        padding-bottom: 15rem;
        overflow: auto;
        justify-content: normal;
        .all-body {
            width: 85%;
            &>p {
                line-height: 15px;
            }
            section {
                flex-direction: column;
                .card-choose {
                    margin-bottom: 20px;
                    figure {
                        width: 80%;
                        height: 60vw;
                        img {
                            width: 60%;
                            height: 100%;
                        }
                    }
                }
                h2 {
                    font-size: v.$medium-size !important;
                }
            }
            .text-bottom {
                width: 100%;
                line-height: 20px;
                flex-direction: column;
                & p {
                    margin-right: 0px;
                }
            }
        }
    }
}