@use '../../../../../../sass/colors'as colors;
@use "../../../../../../sass/variables"as variables;
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/_mixins/global";

.modal-set-publication{
    top: -0px !important;
    right: auto !important;
}
.parent-modal{
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(7.5px);
    position: absolute;
    z-index: 100000;
    display: flex;
    top: -70px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .calendar-modal{
        display: flex;
        width: 680px;
        flex-direction: column;
        border-radius: 1rem;
        --custom-black: #2F2E41;
        border-radius: 5px;
        border: 4px solid #FFF;
        box-shadow: 0px 4px 35px 0px rgba(152, 152, 152, 0.25);
        > div {
            padding: 5vh 4vw 5vh 4vw;
            display: flex;
            flex-direction: column;
            &.top-part {
                flex: 1;
                background: var(--main-very-dark-violet);
                position: relative;
                h1 {
                    font-family: Hurme-Black;
                    font-size: 2ch;
                    margin-bottom: 0.3ch;
                    color: var(--main-white-color);
                    text-shadow: 0px 4px 20px #26018b;
                }
                p {
                    font-size: 1.3ch;
                    line-height: 2ch;
                    color: var(--main-white-color);
                }
                button{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: var(--main-white-color);
                }
            }
            &.bottom-part {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background: var(--main-white-color);
                h4 {
                    font-family: Hurme-Bold;
                    font-size: 1.65ch;
                    margin: 0.6ch 0;
                    color: var(--dashboard-blue-fair);
                }
                label {
                    font-family: Hurme-Bold;
                    font-size: 1.2ch;
                    line-height: 1ch;
                    color: var(--main-very-dark-violet);
                    margin-bottom: .5rem;
                }
                input{
                    margin-bottom: 1rem;
                    font-family: Roboto;
                    text-transform: uppercase;
                }
                br {
                    display: block;
                    content: '';
                    margin-top: 1.7ch;
                }
                p {
                    font-size: 1.1ch;
                    height: min-content;
                }
                .form {
                    padding: 0;
                    margin-top: 0.1ch;
                    * {
                        border: none;
                    }
                    input {
                        height: 2.5rem;
                        border: solid 0.1rem #D2D2D2;
                        border-radius: 0.3rem;
                        background: white;
                        padding: 0 1ch;
                        transition: 0.15s;
                        &::placeholder {
                            opacity: 0.7;
                        }
                        &:focus {
                            border-color: inherit;
                        }
                    }
                }
            }
        }
    }
}

@include tablet { 
    .parent-modal> .calendar-modal {
        margin-left: 270px;
    }
    .parent-modal> .calendar-modal{
        width: 500px;
        .bottom-part {
            flex-direction: column!important;
            align-items: center;
            & .form-part{
                margin-bottom: 1rem;
                width: 100%;
            }
        }
    }
    .absolute-container {
        width: 90%;
    }
}
@include tablet2(){
    .parent-modal> .calendar-modal{
        width: 60%;
    }

}
@include mobile {
    .parent-modal> .calendar-modal {
        width: 95%;
        margin-left: 0 !important;
    }
}

@media (max-width: 1440px) {
    .parent-modal> .calendar-modall {
        width: 80%;
        margin-left: 116px;
    }
}