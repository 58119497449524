@use "../../../../../../../sass/colors"as colors;
@use "../../../../../../../sass/variables.scss"as v;
@import '../../../../../../../sass/mixins';
@import '../../../../../../../sass/_mixins/global';

.card-brand{
    margin: 2rem;
    width: 15.25rem;
    height: 20rem;
    box-shadow: 0px 5px 30px hsl(0, 0%, 94%,.5);
    border-radius: 15px;
    transition:all .5s ease;
    font-family: Hurme-Black;
    font-size: v.$h2;
    display: flex;
    align-items: center;
    color: var(--main-blue);
    justify-content: center;
    &:hover{
        box-shadow: 0px 5px 80px hsl(0, 0%, 91%,.8);
    }
    img{
        width: auto;
        max-width: 70%;
        border-radius: 10px;
    }
}
