@use "../../../../sass/variables"as v;
@use "../../../../sass/colors"as colors;
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
@import "../../../../sass/partials/buttons";

.new-brand-page {
    display: flex;
    align-items: safe center;
    justify-content: center;
    height: 100%;
    min-height: -moz-min-content; // not implemented
    min-height: -webkit-min-content;
    min-height: min-content;
    main {
        max-width: 1183px;
        width: 100%;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: scroll;
        margin: auto;
        @include mobile() {
            //    padding: 60px 10px;
        }
        header {
            position: absolute;
            top: 0;
            left: 12px
        }
        .Container {
            /* border:1px solid white; */
        }
        .Row {
            /* border:1px solid white; */
        }
        .Col {
            /* border:1px solid white; */
            margin-bottom: 15px;
        }
        section:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            align-items: center;
            h1 {
                @include h1Global;
                @include mobile() {
                    font-size: v.$mid-title-size-text;
                }
                font-size: v.$xxl-size;
            }
            form {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                @include mobile() {
                    width: 100%;
                }
                align-items: center;
                hr {
                    // height      : .5px;
                    width: 83px;
                    border: .3px solid #615AF6;
                    margin: 8px 0 15px 0;
                }
                .textdanger {
                    color: colors.$alert;
                    font-family: Hurme-Regular;
                    font-size: v.$medium-size-text;
                    margin-bottom: 5px;
                    align-self: flex-start;
                }
                label {
                    font-family: Hurme-Bold;
                    font-size: v.$medium-size-text;
                    line-height: 17px;
                    text-align: left;
                    color: colors.$grey-dark;
                    margin-bottom: 6px;
                    align-self: flex-start;
                }
                .container-input {
                    display: flex;
                    @include mobile() {
                        width: 100%;
                        &.container-input3 {
                            max-width: none !important;
                        }
                    }
                    flex-direction: column;
                    &.container-input3 {
                        max-width: 100%
                    }
                    @media (max-width: 800px) {
                        &.container-input3 {
                            max-width: 360px;
                        }
                    }
                    @media (max-width: 1881px) {
                        &.container-input3 {
                            max-width: 740px;
                        }
                    }
                }
                input {
                    height: 45px;
                    width: 358px;
                    @include mobile() {
                        width: 100%;
                    }
                    border-radius: 7px;
                    padding: 0 12px;
                    outline: none;
                    font-family: Roboto;
                    font-size: v.$medium-size-text;
                    color: colors.$main-blue;
                    // background-color: colors.$blue-btn;
                    // border          : .5px solid colors.$new-blue-fair;
                    background: #FCFCFF;
                    border: 1px solid var(--main-light-grey);
                    margin-bottom: 12px;
                    position: relative;
                    &.error {
                        margin-bottom: 0px;
                        background-color: lighten($color: colors.$alert, $amount: 40);
                        border: .5px solid colors.$alert;
                        &::placeholder {
                            color: colors.$alert;
                        }
                    }
                    &::placeholder {
                        color: #9FAAD9;
                    }
                }
                .new-user {
                    @include outlined-btn;
                    width: 60%;
                    max-width: 250px;
                    padding: 7px 0;
                    border-radius: 19px;
                    font-family: Hurme-Black;
                    font-size: v.$p;
                    cursor: pointer;
                    &:hover {
                        // text-decoration: underline;
                    }
                    @include mobile() {
                        font-size: v.$pMobil;
                    }
                }
                .confirm-user {
                    width: 100%;
                    max-width: 320px;
                    margin-top: 53px;
                }
            }
             ::placeholder {
                color: colors.$grey;
                font-size: v.$medium-size-text;
            }
        }
        .email-guests-container {
            display: flex;
            justify-content: center;
            .email-guests {
                width: 750px;
                @media (max-width: 800px) {
                    width: 100%;
                }
                display: flex;
                flex-wrap: wrap;
                .email-guest {
                    width: 46%;
                    @media (max-width: 800px) {
                        width: 100%;
                    }
                    margin: 12px;
                    display: flex;
                    justify-content: space-between;
                    //max-width: 240px;
                    span {
                        background-color: colors.$blue-dark2;
                        padding: 8px 12px;
                        border-radius: 7px;
                        display: flex;
                        width: 75%;
                        font-size: v.$medium-size-text;
                        color: var(--main-white-color);
                        font-family: Hurme-Regular;
                        font-weight: 600;
                    }
                    button {
                        color: colors.$blue-dark2;
                        opacity: 0.3;
                        border: 0.5px solid colors.$blue-dark2;
                        box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.25);
                        border-radius: 7px;
                        cursor: pointer;
                        min-width: 80px;
                        font-family: Hurme-Bold;
                        font-size: v.$medium-size-text * .8;
                        transition: .2s all ease-in-out;
                        &:hover {
                            color: colors.$blue-dark2;
                            opacity: .8;
                        }
                    }
                }
            }
        }
    }
    @include mobile {
        padding-top: 10px;
    }
}