@use "../../../../../sass/colors" as colors;
@use "../../../../../sass/variables.scss" as variables;


.badge {
  display: flex;
  flex-shrink: 0;
  height: fit-content;
  margin: 5px 10px;
  padding: 2px 10px;
  font-size: variables.$px-ts-sm;
  border: solid 1px colors.$tiffany-blue;
  align-items: center;
  background: colors.$azure;
  color: colors.$tiffany-blue;
  border-radius: 5px;
  user-select: none;
  -moz-user-select: none; 
  -webkit-user-select: none;

  > i {
    cursor: pointer;
  }
}