@use "../../../../../../sass/colors" as colors;
@use "../../../../../../sass/variables.scss" as variables;
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/mixins";
@import "../../../../../../sass/_mixins/global";
@import "../../../../../../sass/_mixins/animated-shine.scss";

.offer-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    max-width: 400px;
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .offer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        padding: 0px 30px;

        h2 {
            font-size: 20px;
            font-weight: 700;
            color: #1a1a1a;
            margin: 0;
            font-family: Hurme-Black;
            margin: 0px;
        }

        p {
            border-radius: 5px;
            background: linear-gradient(135deg, #A073FF 0%, #78BCED 100%);
            color: white;
            padding: 5px 10px;
            font-family: Hurme-Black;
            font-size: 16px;

            span {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 300;
            }
        }
    }

    .offer-body {
        padding: 0px 30px;

        p {
            font-size: 15px;
            font-weight: 300;
            color: #1a1a1a;
            margin: 5px auto;
        }

        .offer-duration {
            color: var(--main-text-condition-color);
            font-size: 14px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            margin: 10px auto;

            span {
                font-size: 15px;
                font-family: Hurme-Black;
                color: var(--main-text-condition-color);
                margin: 0;
                margin-top: 10px;
            }
        }
    }

    .offer-social {
        background-color: #F5F9FF;
        display: flex;
        padding: 5px 30px;
        margin: 10px 0px 0px 0px;
        overflow-y: scroll;

        img {
            width: 40px;
            height: 20px;
            object-fit: cover;
            margin: auto 10px;
            border-radius: 5px;
        }

        .camera {
            padding: 0px 15px;
            margin: auto 10px auto 0px;
            font-size: 1rem;
            background-color: white;
            border-radius: 5px;
        }
    }
    @include mobile() {
        .offer-header {
            padding: 0px 15px;
    
            h2 {
                font-size: 1.2rem;
            }
    
            p {
                font-size: 1rem;
            }
        }
        .offer-body {
            padding: 0px 15px;
    
            p {
                font-size: 1rem;
            }
    
            .offer-duration {
                font-size: 14px;
    
                span {
                    font-size: .8rem;
                }
            }
        }
        .offer-social {
            padding: 5px 15px;
        }
    }
}
