@use "../../../sass/colors"as colors;
@use "../../../sass/variables.scss"as variables;
@import '../../../sass/partials/_buttons';
@import '../../../sass/mixins';
@import '../../../sass/_mixins/global';
@import '../../../sass/_mixins/animated-shine.scss';
.body-extend-casting {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  header {
    display: flex;
    position: sticky;
    top: 0;
    padding: 20px 0;
    z-index: 5;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    & h2 {
      font-size: variables.$xxl-size;
      font-family: Hurme-Black;
      text-align: center;
    }
    & p {
      font-size: variables.$medium-size-text;
      line-height: 1;
      color: colors.$grey-dark;
      font-family: roboto;
      text-align: center;
    }
  }
  & section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    h3 {
    font-size: variables.$xxl-size;
    font-family: Hurme-Black;
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    }
    & .icon-card {
    position: absolute;
    top: 8px;
    right: 21px;
    }
    p,
    span {
    text-align: center;
    width: 90%;
    font-family: roboto;
    font-size: variables.$medium-size-text;
    color: colors.$grey-dark;
    margin-top: 8px;
    & strong {
        font-family: Hurme-Bold;
        color: colors.$main-blue;
    }
  
    }
    
  }
  .date-result-container {
    align-self: center;

    & p, h3 {
      width:100%;
      line-height: 0.3;
    }
  }
  .rotatable-responsive td:nth-of-type(1) div{
    transform: translateX(15px);
    opacity: 30%;
  }
  .rotatable-responsive td:nth-of-type(2) img {
    transform: translateX(-15px);
  }
  @include tablet {
    & section {
      flex-direction: column;
      align-items: center;
      p {
        width: 80%;
        font-size: variables.$medium-size-text;
      }
    }
    & .calendar-container {
      height: auto;
      flex-direction: column;
    }
    & .date-result-container {
      flex-direction: column;
      justify-content: center;

      & div {
        margin:auto
      }

      & p, h3 {
        display: inline;
        text-align: center;
      }

      & img {
        align-self: center;
        margin: 3.5em 0;
        transform: rotate(90deg);
        height: max-content;
      }
    }
    & .rotatable-responsive {
      transform: rotate(90deg);

      &.rotatable-responsive td:nth-of-type(1) img {
        transform: translateX(80px);
        opacity: 30%;
      }
      &.rotatable-responsive td:nth-of-type(2) img {
        transform: translateX(-15px);
      }
    }
    & .disappear-responsive {
      display: none;
    }
  }
  @include mobile {
    & header {
      top: 70px;
      & h2,
      p {
        width: 90%;
      }
    }
    padding: 28px 0px;
    & section {
      padding-bottom: 5em;
    > p {
        width: 80%;
        font-size: variables.$medium-size-text;
        width: 90%;
      }
      & .illustration-card {
        width: 100%;
      }
    }
    & .calendar-container {
      height: auto;
    }
  }
}





