@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as variables;
@import '../../../../sass/partials/_buttons';
@import '../../../../sass/mixins';
@import '../../../../sass/_mixins/global';
@import '../../../../sass/_mixins/animated-shine.scss';
$width-bar-messages: 400px;
$avtr-sze: 7rem;
@include miniPc(){
    .profile-info .info-profile-container .section-name{
        justify-content: space-between !important;
    }
    
}
.profile-info{
    .parentSectionName{
        justify-content: center!important;
    }
}
.all-page {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    & .header-page {
        position: relative;
        left: 0;
        right: 0;
        width: 80%;
        max-width: 1750px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 10px 20px;
        margin-top: 2rem;
        & button.step2-btn {
            background-color: colors.$lavender-blue;
            border: 1px solid var(--main-white-color);
            border-radius: 12px;
            cursor: pointer;
            box-shadow: 0px 4px 10px #f0f0f0;
            font-family: Hurme-Black;
            color: var(--main-white-color);
            transition: 0.2s ease-in-out;
            padding: 8px 16px;
            font-size: variables.$p;
            line-height: normal;
            &.active {
                background-color: colors.$blue-dark;
            }
            &:hover {
                background-color: colors.$perano-blue;
                box-shadow: 0px 3px 20px colors.$perano-blue;
            }
        }
    }
    & .blue-little-btn {
        @include little-btn(colors.$main-blue);
        border-radius: 50px;
        font-size: variables.$p !important;
        line-height: normal;
    }
    & .outlined-btn {
        @include outlined-btn;
    }
    & aside {
        height: 100%;
        & .sticky {
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100% !important;
            position: sticky;
            top: 0;
            flex-direction: column;
            align-items: center;
            display: flex;
            z-index: 30;
        }
    }
    & #success-vogzter {}
    & .tip-container {
        border-radius: 12px;
        background-color: #ffffff !important;
        box-shadow: 0px 4px 15px darken($color: colors.$white, $amount: 10);
        height: 189px;
        width: 200px;
        padding: 12px;
        z-index: 1000;
        opacity: 1 !important;
        visibility: revert;
        overflow: scroll;
        &:before {
            content: '\A';
            border-style: solid;
            border-width: 10px 15px 10px 0;
            border-color: transparent white transparent transparent;
            position: absolute;
            top: -8px;
            left: 50%;
            transform: rotate(90deg);
            margin-left: -10px;
        }
        & .tip-content {
            display: flex;
            background-color: #ffffff !important;
            box-shadow: 0px 2px 6px #f5f5f5;
            border-radius: 2px;
            align-items: center;
            // width             : fit-content;
            // justify-content: center;
            padding: 5px 20px;
            width: 100%;
            color: colors.$black-dark;
            & img {
                width: 25px;
                height: 25px;
                border-radius: 100%;
            }
            & h4 {
                font-family: Hurme-Bold;
                font-style: normal;
                font-weight: 700;
                font-size: variables.$mid-title-size-text;
                line-height: 15px;
                text-align: center;
                margin-left: 10px;
            }
        }
    }
    @include tablet() {
        & .header-page {
            width: 100%;
            & button {
                max-width: 150px;
                &:nth-of-type(2) {
                    padding: 0 10px;
                    height: 44px;
                    line-height: initial;
                    font-size: variables.$pMobil;
                }
            }
        }
        & aside:nth-of-type(1) {
            margin-right: 0!important;
            & .start-collab-onspot{
                max-width: 280px !important;
            }
            & section:nth-of-type(1) {
                min-width: auto !important;
                & .card-desc-applicant div {
                    flex-wrap: wrap;
                }
                & .profile-info {
                    & h3 {
                        font-family: Hurme-Black;
                        font-size: variables.$mid-title-size-text;
                    }
                }
            }
        }
    }
    & aside:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-right: 26.25rem;
        & .start-collab-onspot{
            background: linear-gradient(91.67deg, #7AB9EE 0%, #8480FF 100%);
            box-shadow: 0px 7px 15px #CFCFCF;
            border-radius: 69px;
            animation: rightpanel_new-collab-animation__3lIe9 4s ease infinite;
            transition: 0.3s;
            position: fixed;
            bottom: 3rem;
            width: 100%;
            max-width: 310px;
            max-height: 40px;
            height: 100%;
            font-size: variables.$px-ts-md;
            &:hover{
                color: var(--main-white-color);
                border: none;
                background-position-x: right;
            }
            &:disabled{
                @include disabled();
                background: var(--main-white-color);
                border: 1px solid var(--main-grey-color-text)
            }
        }
        & section:nth-of-type(1) {
            justify-content: center;
            // margin-top: 2rem;
            display: flex;
            max-width: 1050px;
            width: 85%;
            min-width: 800px;
            & .profile-info {
                &>div {
                    display: flex;
                    &.deal-responsive {
                        display: none;
                    }
                    & figure {
                        margin: 0px 12px 0px 0px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        & img {
                            @include avatar($avtr-sze);
                        }
                        & button {
                            @include little-btn(colors.$hard-grey-dark);
                            font-size: variables.$low-size-text;
                            padding: 3px 11px;
                            min-width: 100px;
                            border-radius: 25px;
                            line-height: normal;
                        }
                    }
                    & .card-desc-applicant {
                        align-items: center;
                        padding-top: 10px;
                        margin-left: 12px;
                        & .gradient-verified {
                            display: flex;
                            align-items: center;
                            & .gradient-text-verified {
                                @include gradient-text;
                                font-family: Hurme-Bold;
                                margin: 0px 6px 0px 0px;
                            }
                            & img {
                                width: 20px;
                                height: 20px;
                            }
                            margin-bottom: 10px;
                        }
                        & div.card-exclu {
                            & h3 {
                                font-size: variables.$small-size-p !important;
                            }
                            & span {
                                margin-top: -4px !important;
                                font-size: variables.$h2Mob !important;
                            }
                            & span:nth-of-type(1) {
                                margin-right: 4px !important;
                            }
                        }
                        & div {
                            display: flex;
                            color: colors.$blue-fair;
                            // margin-right: 20px;
                            align-items: center;
                            & .card-deal {
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                .deal-status {
                                    margin-bottom: 1.5rem !important;
                                    margin: 0;
                                }
                                button {
                                    width: 220px;
                                    padding: 5px 12px;
                                }
                            }
                            & h3 {
                                font-size: variables.$mid-title-size-text * 1.8;
                                font-family: Hurme-Bold;
                                color: colors.$blue-dark;
                            }
                            & span {
                                font-family: Roboto;
                                font-style: normal;
                                line-height: 17px;
                                margin-left: 6px;
                                margin-top: -10px;
                                font-weight: 400;
                                font-size: variables.$medium-size-text * 0.8;
                            }
                            & .vogzter-right {
                                background-color: #e9e9e9;
                                & div:nth-of-type(3) {
                                    margin-right: 12px;
                                }
                            }
                            & div {
                                & span:nth-of-type(1) {
                                    font-size: variables.$mid-title-size-text * 1.5;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }
                & h3 {
                    font-family: Hurme-Black;
                    font-size: variables.$h1;
                }
            }
            & .action-profile {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .deal-status {
                font-family: Hurme-Bold;
                color: var(--main-white-color);
                padding: 7px 20px;
                border-radius: 50px;
                font-size: variables.$pMobil !important;
                text-transform: uppercase;
                border: 1px solid var(--main-white-color);
                display: flex;
                max-width: 150px;
                line-height: normal;
                align-items: center;
                height: 32px;
                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background-color: var(--main-white-color);
                }
            }
            .deal-status-applied {
                background-color: var(--main-apply-status);
                box-shadow: 0px 0px 14px var(--main-apply-status);
                .circle {
                    box-shadow: 0px 2px 6px var(--main-apply-status);
                }
            }
            .deal-status-created {
                background-color: var(--modal-warning-info);
                box-shadow: 0px 0px 14px var(--modal-warning-info);
                .circle {
                    box-shadow: 0px 2px 6px var(--modal-warning-info);
                }
            }
            .deal-status-declined {
                background-color: var(--main-alert-color);
                box-shadow: 0px 0px 14px var(--main-alert-color);
                .circle {
                    box-shadow: 0px 2px 6px var(--main-alert-color);
                }
            }
        }
        & .options-set {
            margin: 20px 0 30px 0;
            display: flex;
            z-index: 5;
            width: 80%;
            justify-content: center;
            & button {
                width: fit-content !important;
                padding: 12px 30px;
                width: 60%;
                background: colors.$blue-btn;
                border: none;
                border-radius: 10px;
                width: 90%;
                cursor: pointer !important;
                pointer-events: all !important;
                font-family: Hurme-Bold;
                font-size: variables.$medium-size-text;
                margin: 0 8px;
                @include disabled;
                border-radius: 12px;
                transition: 0.3s all ease-in-out;
                cursor: pointer;
                z-index: 9;
                &.disabled {
                    @include disabled();
                    cursor: not-allowed !important;
                }
                &.active {
                    color: colors.$blue-dark;
                    background-color: var(--main-white-color);
                    box-shadow: 0px 4px 15px #f0f0f0;
                    &:hover {
                        background-color: darken($color: colors.$white, $amount: 2);
                        box-shadow: 0px 4px 15px darken($color: #f0f0f0, $amount: 8);
                    }
                }
                &:hover {
                    background-color: darken($color: colors.$blue-btn, $amount: 2);
                    // color: var(--main-white-color);
                }
            }
        }
        & section:nth-of-type(2) {
            width: 100%;
            & .grid2 {
                display: flex !important;
                margin-bottom: 40px;
                row-gap: 41px;
                padding: 3rem;
                align-items: center;
                flex-wrap: wrap;
                & article {
                    margin: 1rem 0.5rem 0;
                }
                & .placeholder-nodata {
                    margin-top: 4em;
                    width: 100%;
                    display: flex;
                    -webkit-flex-direction: column;
                    flex-direction: column;
                    align-items: center;
                    & img {
                        width: 20rem;
                    }
                    & h3 {
                        color: #ccd6ff;
                        font-size: 1.5em;
                        font-family: Hurme-Bold;
                        text-align: center;
                    }
                }
            }
        }
        & hr {
            width: 70%;
            margin: 30px auto 10px auto;
        }
    }
    & aside:nth-of-type(2) {
        width: $width-bar-messages;
        background-color: var(--main-white-color);
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding: 22px 12px 0px;
        -webkit-box-shadow: -6px 0px 24px 9px rgba(204, 204, 204, 0.67);
        box-shadow: -6px 0px 24px 9px rgba(204, 204, 204, 0.67);
        z-index: 99999;
        /*half the width*/
        & section:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            align-items: center;
            & .tags {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                & div {
                    margin: 3px 5px;
                }
            }
            &>h2 {
                margin-top: 12px;
                font-size: variables.$mid-title-size-text * 0.8;
                font-family: Hurme-Bold;
            }
            & p {
                color: colors.$grey-dark;
                font-size: variables.$medium-size-text;
                line-height: 18px;
                margin-top: 6px;
                margin-bottom: 12px;
                text-align: center;
                font-family: Roboto;
            }
        }
        & button:nth-of-type(1) {
            border: 0;
            background: none;
            position: absolute;
            right: 10px;
            cursor: pointer;
            transition: color 0.2s ease-in-out;
            & i {
                font-size: variables.$medium-size-text * 1.5;
            }
            &:hover {
                color: colors.$grey-fair;
            }
        }
        & section:nth-of-type(2) {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
            &>div:nth-of-type(1)>div>div div:not(:last-child) {
                //margin: 0px 0 0px 0 !important;
                & div {
                    margin-bottom: 6px;
                }
                & svg {
                    display: none !important;
                }
            }
        }
    }
}

.container-dash-message-container {
    section:nth-of-type(2) {
        >div>div>div div {
            margin-bottom: 0px !important;
        }
    }
}

.input-container-casting {
    flex-grow: 0;
    display: flex;
    padding-block: 0.5em;
    flex: 0.16;
}

.input-container-casting .input-content-casting {
    width: 100%;
    max-height: 5.5em;
    height: 100%;
    position: relative;
    border-radius: 12px;
    display: flex;
    align-items: center;
    // padding-bottom: 9px;
}

.input-container-casting div:nth-of-type(2) {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    >p {
        cursor: pointer !important;
        font-size: 17px;
        color: var(--main-blue-color-bg);
    }
}

.input-container-casting>textarea {
    width: 100%;
    vertical-align: center;
}

.input-container-casting .casting-message:focus {
    outline: 0;
}

.input-container-casting .casting-message {
    border: 1px solid #e9e9e9;
    padding-block: 10px;
    padding-inline: 19px 20px;
    width: 100%;
    border-radius: 16px;
    resize: none;
    font-size: 13px;
    font-family: Hurme-Bold;
}

@include mobile() {
    .all-page {
        & .profile-info {
            &>div {
                flex-direction: column;
                & figure {
                    margin: 0 !important;
                }
            }
            & .card-desc-applicant {
                & div {
                    justify-content: center;
                }
                margin-left: 0px !important;
            }
            & h3 {
                text-align: center;
            }
        }
        aside {
            margin: 0 10px 0 10px !important;
            width: 100% !important;
            & .sticky {
                position: initial;
                z-index: 9;
                margin-top: 0 !important;
                padding-top: 0 !important;
            }
            & .options-set {
                width: 100% !important;
                & button {
                    padding: 8px 12px !important;
                }
            }
            & :nth-of-type(1) section:nth-of-type(1) {
                margin-top: 0;
            }
        }
        & aside:nth-of-type(1){
            .start-collab-onspot{
                font-size: variables.$px-ts-sm !important;
                max-width: 220px !important;
                max-height: 30px !important;
            }
        }
    }
}

@include tablet() {
    .profile-info {
        position: relative;
    }
    .deal-responsive {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;
    }
    .card-deal {
        margin: 1rem .5rem;
        position: static !important;
        .deal-status {
            margin-bottom: 1.5rem !important;
            margin: 0;
            display: none !important;
        }
        button {
            width: 220px;
            padding: 5px 12px;
        }
    }
}