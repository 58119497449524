@import "../../../sass/mixins";


$medium-size: 25px;
$sm-xxl-size: 18px;
$sm-size    : 15px;
$low-text   : 12px;

$card-offer-width: 350px;

.main-offers {
    padding: 80px 0;
    height: 100%;
    overflow: scroll;
    width: 100%;
    position: relative;
    

    .main-section-head {
        width         : 100%;
        display       : flex;
        align-items   : center;
        flex-direction: column;
        min-height    : 460px;
        margin-top    : 10px;

        @include mobile {
            padding: 0 20px;
        }

        >h1 {
            color      : #000;
            text-align : center;
            font-family: Hurme-Bold;
            font-size  : $medium-size;
            font-style : normal;
            font-weight: 900;
            line-height: normal;
        }

        >p {
            font-family: Roboto;
            font-weight: 300;
            text-align : center;
        }

        &::after {
            content      : '';
            position     : absolute;
            z-index      : -1;
            width        : 100%;
            top          : 0px;
            height       : 430px;
            background   : linear-gradient(180deg, #E0DFFF -9.88%, rgba(228, 245, 255, 0.57) 44.62%, rgba(228, 245, 255, 0.00) 82.23%);
            border-radius: 0 0 10rem 10rem;
        }
    }
}

.main-section-foot__info {
    width          : 100%;
    padding        : 30px 0;
    background     : #F9FDFF;
    display        : flex;
    justify-content: center;
    //margin-bottom  : 80px;

    .main-section-foot__content_info {
        width          : 70%;
        max-width      : 630px;
        display        : flex;
        align-items    : center;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
        }

        @include tablet2 {
            width    : 100%;
            max-width: 90%;
        }

        >div:nth-child(1) {
            width: 300px;

            >h3 {
                @include mobile {
                    font-size: $sm-size;
                }

                font-size     : $sm-xxl-size;
                font-family   : Hurme-Black;
                text-transform: initial;
            }

            >p {
                @include mobile {
                    font-size: $low-text;
                }

                font-size: $sm-size;
                color    : #7C7C7C;
            }
        }

        @include mobile {
            >div:nth-child(2) {
                margin-top: 12px;
            }
        }

    }
}