@use '../../../../../../sass/variables.scss'as variables;
@use '../../../../../../sass/colors'as colors;
@import '../../../../../../sass/partials/buttons';
@import '../../../../../../sass/mixins';
@import '../../../../../../sass/_mixins/global';
$body-card-width: 17rem;
$body-card-height: 25rem;
$medium-size-round: 3em;
.card-vogz-client {
    // width         : 100%;
    height: $body-card-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin: 1rem 1rem 0 !important;
    .countdown-vogz-client {
        text-transform: uppercase;
        font-size: variables.$p;
        color: colors.$blue-time;
        font-family: Hurme-Bold;
    }
    &.card-vogz-exclusive {
        gap: 9px;
        height: 510px;
        &>div {
            height: 80%;
        }
    }
    &>div {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        .card-applicant-exclusif {
            display: flex;
            align-items: center;
            figure img {
                width: 100px;
                height: auto;
                object-fit: cover;
                border-radius: 100%;
                box-shadow: 0px 0px 15px 0px rgb(179 179 179 / 25%);
            }
            & .card-desc-briefapplication {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                & h3 {
                    font-family: Hurme-Bold;
                    font-size: variables.$mid-title-size-text;
                }
                & button {
                    @include outlined-btn;
                    margin-top: 5px;
                    //padding: 5px 12px;
                    width: 60%;
                    min-width: 112px;
                    border-radius: 22px;
                    width: 90%;
                    cursor: pointer;
                    font-family: Hurme-Bold;
                    font-size: variables.$low-size-text;
                }
            }
        }
        .cancel-choice {
            background-color: colors.$blue-dark;
            width: 30px;
            padding: 0 6px;
            height: 30px;
            position: absolute;
            z-index: 19;
            border-radius: 60px;
            right: 0;
            margin: -8px -7px 0 0px;
            border: 1px solid colors.$blue-dark;
            align-items: center;
            justify-content: flex-end;
            font-size: 16px;
            cursor: pointer;
            color: var(--main-white-color);
            display: inline-flex;
            overflow: hidden;
            width: auto;
            max-width: 30px;
            /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
            -webkit-transition: max-width 0.5s;
            transition: max-width 0.5s;
            & .info-cancel {
                white-space: nowrap;
                padding-right: 7px;
                font-family: Roboto-Regular;
                font-size: 14px;
            }
            & i {
                display: flex;
                align-items: center
            }
            &:hover {
                max-width: 300px;
                background-color: var(--main-white-color);
                color: colors.$blue-dark;
            }
        }
        & .card-body {
            width: $body-card-width;
            height: 90%;
            min-height: 22rem;
            border-radius: 12px;
            position: relative;
            border-radius: 12px;
            position: relative;
            margin-bottom: 15px;
            .card-overlay {
                transform: scale(0, 0);
                // transition: all .2s ease-in-out;
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                opacity: 0.7;
                border-radius: 10px;
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 10px;
                &>span {
                    color: var(--main-white-color);
                    font-family: Roboto-Regular;
                    font-size: variables.$medium-size;
                    text-align: center;
                    text-shadow: 0px 3px 6px #000000;
                    & strong {
                        font-family: Hurme-Bold;
                        text-align: center;
                    }
                }
            }
            &>span {
                z-index: 12;
                position: absolute;
                background-color: var(--main-white-color);
                padding: 3px 12px;
                margin-top: 16px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                color: colors.$blue-fair;
                font-size: variables.$medium-size-text;
                font-family: Hurme-Black;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                &.badge-red {
                    z-index: 11;
                    @include badge("red");
                    height: 16px;
                    font-size: variables.$medium-size-text * .7;
                    border-radius: 10px;
                    right: 10px;
                    display: flex;
                    align-items: center;
                    z-index: 3;
                    box-shadow: none
                }
            }
            & button {
                border: 1px solid colors.$blue-fair;
                color: colors.$blue-fair;
                background-color: var(--main-white-color);
                position: absolute;
                // padding         : 10px;
                font-size: variables.$medium-size-text;
                border-radius: 100%;
                right: -12px;
                top: -12px;
                height: 40px;
                width: 40px;
                cursor: pointer;
                &:hover {
                    background-color: colors.$blue-fair;
                    color: var(--main-white-color);
                }
            }
            & button.active {
                background-color: colors.$blue-fair;
                color: var(--main-white-color);
                border: 1px solid colors.$blue-fair;
            }
            & img:nth-of-type(1) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: $medium-size-round;
                height: $medium-size-round;
                cursor: pointer;
            }
            & img:nth-last-child(1) {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                object-fit: cover;
            }
            &.exclusive {
                height: 60%;
            }
        }
        & .card-footer {
            display: flex;
            justify-content: space-between;
            button {
                width: 47%;
                height: 33px;
                cursor: pointer;
                border: 0;
                border-radius: 5px;
                font-family: Hurme-Bold;
                transition: all .2s ease-in-out;
                &.not-visible {
                    width: 0;
                    overflow: hidden;
                    padding: 0;
                }
                &.btn-rfs {
                    background: colors.$grey-fair;
                    color: colors.$grey-dark;
                    &.lost {
                        background: colors.$alert;
                        color: var(--main-white-color);
                        font-size: 14px;
                        width: 100%;
                        z-index: 3;
                        &:hover {
                            background: colors.$alert;
                            border: 0;
                            color: var(--main-white-color);
                        }
                    }
                    &.step2 {
                        background: colors.$alert;
                        color: var(--main-white-color);
                        &:hover {
                            background: lighten(colors.$alert, 4);
                        }
                    }
                }
                &.btn-vlt {
                    background: colors.$blue-dark;
                    color: var(--main-white-color);
                    &.access {
                        background: colors.$green;
                        width: 100%;
                        &:hover {
                            background: colors.$green;
                            border: 0;
                            color: var(--main-white-color);
                        }
                    }
                    &:hover {
                        @include outlined-btn();
                    }
                    &.step2 {
                        background: colors.$grey-fair;
                        color: colors.$grey-dark;
                        &:hover {
                            border: none;
                            background: lighten(colors.$grey-fair, 4);
                        }
                    }
                }
                &:hover {
                    background: lighten(colors.$grey-fair, 4);
                }
            }
        }
    }
}