@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import "../../../../sass/mixins";
@import '../../../../sass/partials/_buttons';
@import "../../../../sass/_mixins/global";
.btn1 {
    background-color: colors.$blue-dark;
    min-width: 300px;
    padding: 6px 0px;
    border-radius: 7px;
    font-family: Hurme-Bold;
    cursor: pointer;
    border: 1.5px solid transparent;
    color: var(--main-white-color);
    font-size: v.$btn-txt-md;
    transition: .2s all ease-in-out;
    &[disabled] {
        opacity: .7;
        cursor: not-allowed;
    }
    &:hover {
        border: 1.5px solid colors.$blue-dark;
        background-color: transparent;
        color: colors.$blue-dark;
    }
}

.btn-selectable1 {
    @include btn-ctr;
    @include mobile {
        min-width: 0;
        padding: 8px 12px
    }
    font-family: Hurme-Bold;
    background: var(--main-white-color);
    box-shadow: 0px 4px 15px #F0F0F0;
    color: colors.$blue-dark;
    &:hover {
        border: 1px solid transparent;
    }
}

//dropdown btn
.btn-dropdown-container {
    .btn-dropdown {
        >div {
            font-family: Hurme-Bold;
            font-size: v.$px-ts-md;
            line-height: 17px;
            text-align: center;
            color: var(--main-dark-color) !important;
        }
        .menu {
            background-color: red;
        }
    }
}

.btn-basic {
    font-family: Hurme-Bold;
    text-transform: uppercase;
    font-size: v.$px-ts-sm;
    padding: 8px 12px;
    border-radius: 7px;
    min-width: 90%;
    transition: all .2s ease-in-out;
    &.dark-blue {
        background-color: var(--main-blue-dark);
        color: var(--main-white-color);
        &:hover {
            background-color: var(--main-white-color);
            color: var(--main-blue-dark);
        }
    }
    &.disabled {
        cursor: not-allowed;
        background-color: lighten(colors.$blue-dark, 20%);
    }
    &[disabled] {
        cursor: not-allowed;
        background-color: lighten(colors.$blue-dark, 20%);
    }
    &.sky-blue {
        &:hover {}
    }
    &.inverted-sky-violet {
        background-color: var(--main-blue-color-bg);
        border: 1px solid transparent;
        color: var(--main-white-color);
        &.disabled {
            cursor: not-allowed;
            background-color: lighten(colors.$blue-dark, 20%);
        }
        &:hover {
            background-color: var(--main-white-color);
            border: 1px solid var(--main-blue-color-bg);
            color: var(--main-blue-color-bg);
        }
    }
    &.sky-violet {
        background-color: var(--main-white-color);
        color: var(--main-blue-color-bg);
        &:hover {
            background-color: var(--main-blue-color-bg);
            color: var(--main-white-color);
        }
    }
    &.pastel-blue {
        background-color: var(--main-white-color);
        color: var(--dashboard-blue-fair);
        &:hover {
            background-color: var(--dashboard-blue-fair);
            color: var(--main-white-color);
        }
    }
    &.pastel-blue-actif {
        background-color: var(--dashboard-blue-fair);
        color: var(--main-white-color);
        &:hover {
            background-color: var(--main-white-color);
            color: var(--dashboard-blue-fair);
        }
    }
    &.sky-violet-bordered {
        background-color: var(--main-white-color);
        border: 1px solid var(--main-blue-color-bg);
        color: var(--main-blue-color-bg);
        &:hover {
            background-color: var(--main-blue-fair-color-bg);
            border: 1px solid var(--main-blue-fair-color-bg);
            color: var(--main-white-color);
        }
    }
}

.h2-heading {
    color: colors.$blue-dark2;
    font-family: Hurme-Bold;
    font-size: v.$mid-title-size-text;
}