@use '/src/sass/colors' as colors;
@use '/src/sass/variables.scss' as variables;

.dropdown-checkbox {
  --select-height: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
  margin: 10px;
  transition: .3s;
  & .select {
    min-width: 180px;
    display: flex;
    height: var(--select-height);
    justify-content: space-between;
    align-items: center;
    padding: 0 13px;
    border: solid 1.5px colors.$blue-dark;
    background: colors.$white;
    border-radius: 5px;
    cursor: pointer;

    & .title {
      font-family: Hurme-Bold;
      font-size: variables.$px-ts-sm;
      color: colors.$blue-dark;
      user-select: none;
      -moz-user-select: none; 
      -webkit-user-select: none;
    }
  }
  & .menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 285px;
    width: fit-content;
    max-height: 145px;
    padding: 5px 20px;
    margin-top: var(--select-height);
    box-shadow: 0px 4px 10px rgba(173, 173, 173, 0.25);
    border-radius: 5px;
    overflow-y: auto;
    z-index: 20;
    transition: .2s;
    transform: none;
    background: colors.$white;
    z-index: 1;
    & .search-bar {
      display: flex;
      align-items: center;
        & input {
          color: colors.$vodka;
          font-family: Hurme-Bold;
          &::placeholder {
            color: colors.$vodka;
          }
        }
        & input {
          flex: 1;
          font-size: variables.$px-ts-sm;
        }
        & i {
          font-size: 10px;
          color: colors.$vodka;
          margin: 0 5px;
        }
    }

    & hr {
      border-color: colors.$vodka;
      margin-bottom: 5px;
    }

    & .menu-item {
      position: relative;
      display: flex;
      width: max-content;
      min-width: 100%;
      padding: 0 20px;
      align-items: center;
      font-family: Roboto;
      font-size: variables.$px-ts-sm;
      cursor: pointer;
      transition: 2s;
      &::before {
        content: '';
        position: absolute;
        display: flex;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background: colors.$blue-very-pale;
        transition: 0.4s;
        opacity: 0.2;
      }
      z-index: 10;
      &:hover {
        &::before {
          width: 100%;
        }
      }
      > input {
        margin-right: 5px;
      }
    }
  }
  & .menu-hidden {
    min-width: 0;
    opacity: 0;
    margin-top: 0;
    pointer-events: none;
    padding: 0;
  }
  & .selection-count {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    background: colors.$azure;
    border: solid 1px colors.$tiffany-blue;
    border-radius: 100%;
    > b {
      font-family: Hurme-Bold;
      font-size: variables.$px-ts-sm;
      color: colors.$tiffany-blue;
    }
  }
  & i {
    font-size: variables.$px-ts-sm;
    color: colors.$blue-dark;
  }
}