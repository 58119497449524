@use '../../../../../sass/variables.scss'as variables;
@import '../../../../../sass/mixins';
@import '../../../../../sass/_mixins/global';
.progress-enroll {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .progress-user {
        justify-content: center;
        position: absolute;
        width: 41px;
        border-radius: 120px;
        height: 41px;
        display: flex;
        align-items: flex-end;
        background: linear-gradient(94.27deg, #CEE4F9 0%, #EAD0FA 100%);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 2px 8px rgb(125 125 125 / 25%);
        left: -21px;
        z-index: 10;
        cursor: pointer;
        bottom: -21px;
        >img {
            width: 65%;
        }
        .progress-number {
            background-color: var(--main-blue);
            color: var(--main-white-color);
            border-radius: 200px;
            height: 20px;
            display: flex;
            align-items: center;
            font-family: Hurme-Bold;
            font-size: variables.$px-ts-sm;
            width: 20px;
            top: -5px;
            right: -5px;
            justify-content: center;
            position: absolute;
        }
    }
    >.progress-bar-enroll {
        min-width: 20rem;
    }
    svg {
        display: block !important;
    }
    .info-enroll {
        &::after {
            border-bottom-color: #fff;
        }
    }
    .info-enroll_ {
        z-index: 4;
        background-color: var(--main-white-color);
        display: flex;
        border-radius: 12px;
        box-shadow: 0px 4px 24px rgba(207, 207, 207, 0.8);
        padding: 15px;
        backdrop-filter: blur(5px);
        img {
            width: 25px;
        }
        .info-enroll_text_side {
            margin-left: 12px;
            h5 {
                font-family: Hurme-Bold;
                font-size: variables.$px-ts-md;
                color: var(--main-dark-color);
            }
            p {
                line-height: 0;
            }
        }
    }
    @include mobile() {
        >.progress-bar-enroll {
            min-width: 15rem;
        }
    }
}