@use '../../../../sass/colors'as colors;
@use '../../../../sass/variables'as variables;
@import '../../../../sass/mixins';
@import '../../../../sass/partials/buttons';
.content-modal-cart {
    position: relative;
    width: 360px;
    height: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--main-white-color);
    overflow: hidden;
    box-shadow: 0px 10px 50px colors.$grey-text;
    //padding                : 80px 10px 40px 10px;
    border-radius: 12px;
    //   position          : relative;
    &.extend-modal {
        height: 460px;
        & section:nth-of-type(2) {
            padding: 2em 0;
        }
        span {
            color: var(--main-violet-color-text);
            font-family: Hurme-Bold;
        }
        a {
            color: var(--main-violet-color-text);
            font-size: 1.1em;
            font-family: Hurme-Bold;
        }
    }
    & h2.disabled {
        @include disabled
    }
    & section:nth-of-type(1) {
        flex: .5;
        background-color: colors.$blue-dark;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        &>i {
            font-size: 4.5rem;
            color: colors.$white;
        }
        &.warning {
            background-color: var(--modal-warning-info);
        }
        &.confirmation {
            background-color: var(--main-blue-color-bg);
        }
        &.confirmation-rdv {
            background-color: var(--modal-confirmation-rdv-info);
            &>i {
                color: var(--main-white-color);
            }
        }
        &.warning-brief {
            background-color: var(--modal-warning-brief-info);
        }
        &.launch-casting {
            background-color: var(--modal-launch-casting-info);
        }
        &.stripe-valid {
            background-color: var(--modal-stripe-valid-info);
        }
        &.stripe-error {
            background-color: var(--modal-stripe-error-info);
        }
        &.close-casting {
            background-color: var(--modal-close-casting);
        }
        &.no-favorite-vogzter {
            background-color: var(--modal-no-favorite-vogzter);
            i:nth-of-type(1) {
                color: #FDD443;
            }
        }
        &.message-restriction {
            background-color: var(--main-blue-color-bg);
            i:nth-of-type(1) {
                color: white;
            }
        }
        &.extend-brief {
            background-color: var(--modal-warning-brief-info);
            i:nth-of-type(1) {
                color: white;
            }
        }
        &.header-extend {
            > div {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                align-items: center;
                height: 40%;
                hr {
                    height: 100%;
                    width: 1px;
                    background-color: var(--main-white-color);
                }
                span {
                    color: var(--main-white-color);
                    font-size: 40px;
                    font-family: Hurme-Bold;
                }
            }
        }
        .exit-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 2.5ch;
            cursor: pointer;
        }
    }
    & section:nth-of-type(2) {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        & h2 {
            color: colors.$grey-dark;
            font-size: variables.$mid-title-size-text;
            font-family: Hurme-Bold;
            text-align: center;
        }
        & p {
            font-size: variables.$p;
            color: colors.$grey-dark;
            text-align: center;
        }
        & .not-selected {
            background: colors.$blue-btn !important;
            color: colors.$grey-dark;
            cursor: pointer;
            transition: none;
            &:hover {
                border: 0
            }
        }
        & div {
            width: 78%;
        }
        & div:nth-of-type(2) {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
    }
}

.content-modal-cart-nfv {
    width: 400px;
    height: 580px;
    position: relative;
    p {
        text-align: left !important;
        font-size: 1.5ch !important;
        margin: 0 1%;
        span {
            display: inline;
            position: relative;
            font-size: inherit;
            background: linear-gradient(180deg, transparent 50%, #FDD443 50%);
        }
    }
    b {
        position: relative;
        font-family: Hurme-Black;
        color: #000;
        text-transform: uppercase;
    }
    button {
        margin-bottom: 5%;
    }
}

// @include tablet {
//     .content-modal-cart {
//         width: 80vw;
//     }
// }
@include mobile {
    .content-modal-cart {
        width: 95vw;
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}