@keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(+5px);
    }
    100% {
      transform: translatey(0px);
    }
  }