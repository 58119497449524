@mixin background-gradient($base-color, $shine-color: #e8e8e8) {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size : 600px;
}

@keyframes shine-lines {
    0% {
        background-position: -300px;
    }

    100% {
        background-position: 300px;
    }
}