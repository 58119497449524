@use "../../../../../sass/colors" as colors;
@use "../../../../../sass/variables.scss" as v;
@import '../../../../../sass/mixins';
@import '../../../../../sass/_mixins/global';

.cards-brand {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.body-brand {
  width: 100%;
  margin-top: 4rem;

  .img-brand {
    max-height: 110px;
    max-width: 110px;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 60px;
    padding: .5rem;

    img {
      max-height: 110px;
      max-width: 110px;
      width: 100%;
      height: 100%;
      border-radius: 60px;
    }

    i {
      color: var(--main-blue-color-bg);
      padding: 5px;
      border: .5px solid var(--main-blue-color-bg);
      border-radius: 5px;
      background-color: var(--main-white-color);
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }

  .name-brand {
    max-width: 60%;

    h3 {
      font-family: Hurme-Black;
      font-size: v.$h1;
      color: var(--main-violet-color-text);
      text-transform: uppercase;
    }

    p {
      font-family: Roboto;
      font-size: v.$p;
      color: var(--main-grey-text);
      font-weight: 400;
    }
  }

  .info-brand {
    display: flex;
    margin-top: 2rem;

    .part-info {
      width: 55%;
      margin-right: 1rem;

      textarea {
        height: 100%;
        max-height: 140px;
        background: var(--main-light-grey-bg);
        border: 1px solid var(--main-light-grey);
        border-radius: 4px;
        padding: 5px;
        color: var(--main-grey-color-text);
        font-family: Roboto;
        font-weight: 400;
        font-size: v.$pMobil;
        resize: none;
        &::placeholder {
          color: var(--main-grey-blue);
        }
      }

      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: v.$pMobil;
        color: var(--home-violet-dark-color-text);

        span {
          font-family: Hurme-Bold;
        }
      }
    }

    hr {
      border-top-width: 15rem;
      width: 1px;
    }
  }

  .socials-brand {
    width: 45%;
    margin-left: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .5em;

    h1 {
      font-family: Hurme-Bold;
      margin-top: .5em;
      margin-bottom: .5em;
    }

    hr {
      border-top: 1px solid #E4E4E4;
      margin: 1em auto;
      width: 70%;
    }

    .site {
      label {
        color: black !important;
        background-color: var(--main-light-grey) !important;
      }
    }

    .social-input {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: auto;
      width: 100%;
      position: relative;
      margin-top: .5rem;

      &[data-type="tiktok"] label[for="info.social_links[1].url"]  {
        background: linear-gradient(45deg,
            #69C9D0,
            #010101,
            #EE1D52,
            #69C9D0,
            #010101,
            #EE1D52,
          );
        animation: wave 40s infinite alternate !important;
        text-shadow: 1px 1px 2px #010101;
      }

      label {
        position: absolute;
        width: 8em;
        padding: 6.8px 1em;
        color: white;
        background-color: black;
        font-size: min(2.5vw, 16px);
        font-weight: 600;
        left: 0px;
        top: 0px;
        background-size: 200% 200% !important;
        animation: wave 10s ease-in-out infinite alternate;
        text-align: center;
        border-radius: 4px 0px 0px 4px;
      }

      input {
        border: 1px solid var(--main-light-grey);
        border-radius: 5px;
        background: white;
        padding: 6px 2em;
        width: 100%;
        padding-left: 9em;
        font-size: min(1.5vw, 16px);
      }

      &[data-type="instagram"] {
        label {
          background: linear-gradient(45deg,
              #405de6,
              #5851db,
              #833ab4,
              #c13584,
              #e1306c,
              #fd1d1d);
        }

        text-shadow: 1px 1px 2px #010101;
      }

      &:nth-of-type(2) {
        label {
          background-color: black;
        }
      }
    }


    button {
      &:hover {
        background-color: var(--my-castings-thick-blue);
        color: white;
      }

      i {
        margin-right: 15px;
      }

      font-family: hurme-bold;
      color: var(--my-castings-thick-blue);
      border: 1px solid var(--my-castings-thick-blue);
      padding: 0.3em 1.3em;
      border-radius: 60px;
      transition: 0.3s;

      font-size: 15px;
    }

    .check-to-save {
      display: flex;
      gap: 15px;
      justify-content: center;
      align-items: flex-start;
      margin-top: .5em;

      input {
        margin-top: 5px;
        accent-color: white;
      }

      span {
        font-family: hurme-bold;
        color: var(--main-dark-blue-text-color);
      }
    }
  }

  .info-brand {
    .part-info {
      .category {
        margin-top: .5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(45deg, var(--dashboard-blue-color-footer-sidemenu), var(--dashboard-blue-color-active-menu));
        background-size: 200% 200%;
        border-radius: 5px;
        animation: wave 3s ease-in-out infinite;

        hr {
          border-top-width: 1px;
        }

        h3 {
          font-family: Hurme-Bold;
          font-size: min(2.5vw, 16px);
          color: var(--main-dark-blue-text-color);
          margin-left: 1rem;
        }
      }
    }
  }
}

@include tablet {
  .info-brand {
    flex-direction: column;

    .part-info {
      width: 90% !important;
      margin: .5rem !important;
    }

    hr {
      border-top-width: 1px !important;
      border-top: 1px solid #E4E4E4;
      margin: 1em auto;
      width: 70% !important;
    }
  }

  .socials-brand {
    width: 90% !important;
    margin-left: 0% !important;

    input {
      font-size: min(3.5vw, 16px) !important;
    }
  }
}

@include mobile {
  .body-brand {
    .name-brand {
      h3 {
        font-size: v.$h1Mob;
      }
    }
  }

}