@import "../../../../sass/mixins";

.main-page-404 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-layer {
        pointer-events: none;
        position: fixed;
        right: 0;
        height: 100%;
        object-fit: cover;
    }

    .title {
        background: linear-gradient( 160deg, #5C6FFD, #A655FF);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .subtitle {
        font-size: 2ch;
    }

    @include tablet() {
        .img-layer {
            display: none;
        }
        .btnHome {
            width: 70%;
        }
    }

    @include mobile() {
        .btnHome {
            width: 90%;
        }
    }

}