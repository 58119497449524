@use "../../../../sass/variables.scss"as v;
@import "../../../../sass/mixins";

$medium-text: 14px;
$low-text   : 12px;

$card-offer-width: 350px;

.card-offer__article {
    background-color: white;
    width           : 350px;
    box-shadow      : 0px 4px 50px 0px rgba(168, 168, 168, 0.25);
    border-radius   : 30px;
    padding         : 30px 20px 30px 20px;

    @include mobile() {
        $card-offer-width: 100%;
        width            : 95%;
    }

    .card-offer__article_price {
        text-align : center;
        font-size  : 50px;
        font-family: Hurme-Black;
        color      : var(--main-blue-color-bg);

        >span {
            font-size     : 14px;
            font-family   : Hurme-Bold;
            text-transform: lowercase;
        }
    }

    .offer_sponsorship__section {
        text-align: center;
        padding   : 27px 0 17px 0;

        >h5 {
            color      : var(--main-text-condition-color);
            font-family: Hurme-Black;
            font-size  : v.$p;
        }

        >p {
            color      : var(--main-grey-color-text);
            font-family: Roboto;
            font-size  : v.$pMobil;
            font-weight: 300;
        }

        >input {
            border          : 1px solid var(--main-blue-color-bg);
            background-color: var();
            color           : var(--main-blue-color-bg);
            padding         : 5px 20px;
            font-size       : v.$p;
            border-radius   : 6px;
            font-family     : Hurme-Bold;
            text-transform: uppercase;

            text-align: center;
            &::placeholder {
                font-family: Roboto-Regular;
                text-transform: initial;
            }
        }
    }

    .card-offer__article_info1 {
        border-radius: 5px;
        background   : #F5F2FF;
        color        : #6758AC;
        font-family  : Hurme-Bold;
        font-size    : 13px;
        font-style   : normal;
        font-weight  : 700;
        padding      : 5px 0;
        line-height  : normal;
        text-align   : center;
    }

    .advantages__ {
        margin-top: 22px;

        .advantages__content {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            .advantages__content_right {
                width: 80%;

                >h4 {
                    font-family   : Hurme-Bold;
                    font-size     : 15px;
                    text-transform: initial
                }

                >p {
                    color      : #7C7C7C;
                    font-family: Roboto;
                    font-size  : $medium-text;
                    font-style : normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }

            >i {
                color    : #1DECBA;
                font-size: 25px
            }
        }
    }

    .btn-subscribe {
        margin-top: 20px;
        font-size : 23px;
        padding   : 8px 0;

        a {
            text-transform: initial !important;
        }
    }

    .advantages__info_ {
        color      : #5661C2;
        font-family: Roboto;
        font-size  : $low-text;
        font-style : normal;
        font-weight: 500;
        line-height: normal;
        background : linear-gradient(90deg, rgba(238, 247, 255, 0.00) 0%, #F2F9FF 51.06%, rgba(238, 247, 255, 0.00) 100%);
        width      : 100%;
        display    : block;
        margin-top : 10px;
        padding    : 11px 0;
        text-align : center;
    }
}

.card-offer__info_nb {
    color      : #7C7C7C;
    font-family: Roboto;
    font-size  : $low-text;
    font-style : normal;
    font-weight: 300;
    line-height: normal;
    display    : block;
    max-width  : $card-offer-width;
    margin-top : 22px;
    text-align : center;

    >span {
        color      : #856CFC;
        text-align : center;
        font-family: Roboto;
        font-style : normal;
        font-weight: 600;
        line-height: normal;
    }
}