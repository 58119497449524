@import '../../../../../../../../../../sass/mixins';

.custom-slider {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  --american-blue: #3A4161;

  .custom-slider-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;

    .custom-slider-div {
      display: flex;
      width: inherit;
      justify-content: flex-start;
      transition: 0.3s;
      align-items: center;
      // background: #FFDDDD;
    }

    .custom-slider-item-wrapper {
      display: flex;
      align-items: center;
      height: 25rem;

      .custom-slider-item {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0.2;
        pointer-events: none;
        z-index: 0;
        transition: 0.5s;

        div {
          transition: 0.3s !important;
        }
      }

      .custom-slider-item-left {
        transform: translateX(-13rem);
      }

      .custom-slider-item-right {
        transform: translateX(13rem);
      }

      .custom-slider-item-left, .custom-slider-item-right { 

        div {
          height: 20rem;
          justify-content: center;
        }
      }
  
      .custom-slider-item-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;
      }
    
    }
  }

  i {
    font-size: 3.2ch;
    z-index: 2;
    margin: 0 1rem 0 1rem;
    color: var(--american-blue);
  }

  button {
    border: none;
    background: transparent;
    transition: 0.2s;
  }

  .arrow-btn-left, .arrow-btn-right {
    transition: 0.2s;
    margin: 0;
  }

  .arrow-btn-left:active {
    transform: translateX(-10%);
  }

  .arrow-btn-right:active {
    transform: translateX(10%);
  }

  .nav-bubble, .nav-bubble-dot {
    font-size: 1.5ch;
    margin: 0.3rem;
    transition: 0.3s;
  }

  .nav-bubble {
    color: #D4DDFF;
  }

  .nav-bubble-dot {
    color: var(--american-blue);
    font-size: 2.4ch;
  }
}

@include mobile {
  .custom-slider {

    .custom-slider-item-wrapper {

      .custom-slider-item-left {
        transform: translateX(-13rem);
      }

      .custom-slider-item-right {
        transform: translateX(13rem);
      }

      .custom-slider-item {

        div {
          height: 23rem;
        }
      }

      .custom-slider-item-left, .custom-slider-item-right { 

        div {
          height: 15rem !important;
          justify-content: center;
        }
      }
    
    }
  }
}