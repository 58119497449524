@import '../../../../../../../../sass/mixins';

.portfolio {
  position: fixed;
  display: flex;
  height: 100%;
  left: 280px;
  right: 0;
  flex-direction: column;
  align-items: center;
  background: white;
  z-index: 30;
  padding: 2rem;
  transition: all 0.2s ease-in-out;
  pointer-events: all;

  button {
    display: flex;
    flex: 0.1;
    margin-bottom: 2.5rem;
  }

  .close-btn {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #2F2E41;
    border: solid 1px #2F2E41;
    border-radius: 100%;
  }

  h1{
    display: flex;
    flex: 0.1;
    font-family: Hurme-Black;
    text-transform: uppercase;
    font-size: 2.4rem;
    color: #2F2E41;
    margin-bottom: 1rem;
  }

  .slider-container {
    display: flex;
    flex: 0.6;
  }
}

// This media query comes from rightpanel.module.scss
@media screen and (max-width: 1440px) {
  .portfolio {
    left: 116px;
  }
}

@include mobile {
  .portfolio {
    left: 0;
    padding: 0;
    padding-left: 1rem;

    h1 {
      margin-bottom: 0;
    }
  }
}