@use "../../../../../sass/variables.scss"as v;
@import '../../../../../sass/partials/_buttons';
@import "../../../../../sass/mixins";
@import "../../../../../sass/_mixins/global";

.card-favourite-bord{
    
    border: .5px solid var(--main-blue);
    &.active{
        background: var(--main-blue-very-pale);
    }
}
.card-favourite, .card-favourite-bord{
    width           : 255px;
    height          : 223px;
    background-color: var(--main-white-color);
    border-radius   : 12px;
    box-shadow      : 0px 4px 30px rgba(190, 190, 190, 0.25);
    position        : relative;
    display         : flex;    
    flex-direction  : column;
    align-items     : center;
    justify-content : flex-end;
    & .favourite{
        position: absolute;
        top     : 13px;
        right   : 13px;
        & i{
            height  : 17px;
            cursor  : pointer;
            filter  : invert(77%) sepia(3%) saturate(1830%) hue-rotate(314deg) brightness(98%) contrast(142%);
        }
        & i:hover{
            filter: invert(46%) sepia(83%) saturate(6651%) hue-rotate(348deg) brightness(92%) contrast(108%);
        }
        & .is-favourite{
            filter: invert(46%) sepia(83%) saturate(6651%) hue-rotate(348deg) brightness(92%) contrast(108%);
        }
        & .is-favourite:hover{
            filter  : invert(77%) sepia(3%) saturate(1830%) hue-rotate(314deg) brightness(98%) contrast(142%);
        }
    }
    & .favourite-cursor-none{
        cursor: default !important;
        & i{
            cursor: default !important;
        }
        & .is-favourite:hover{
            filter: invert(46%) sepia(83%) saturate(6651%) hue-rotate(348deg) brightness(92%) contrast(108%) !important;
        }
    }
    & .card-body {
        text-align: center;
        text-align: -webkit-center;    
        & .img-vogzter{
            width   : 81px;
            height  : 81px;
            border-radius: 50px;
        }
        & .name-vogzter{
            font-family : Hurme-Bold;
            font-size   : calc(v.$p*1.25);
            color: var(--main-dark-color) !important;
        }
        & .certify-vogzter{
            display                 : inline-flex;
            font-family             : Hurme-Black;
            font-size               : calc(v.$p/1.55);
            background              : linear-gradient(91.03deg, #A685FE 0%, #5F79FF 100%);
            -webkit-background-clip : text;
            -webkit-text-fill-color : transparent;
            background-clip         : text;
            text-shadow             : 0px 2px 8px rgba(152, 146, 146, 0.25);
        }
    }
    & .card-footer{
        background-color            : var(--main-blue-color-bg);
        width                       : 100%;
        border-bottom-left-radius   : 12px;
        border-bottom-right-radius  : 12px;
        text-align                  : center;
        height                      : 40px;
        display                     : flex;
        align-items                 : center;
        margin-top                  : 20px;
        & button{
            color           : var(--main-white-color);
            font-family     : Hurme-Black;
            width           : 100%;
            padding         : 10px 0;
            cursor          : pointer;
            text-decoration :none;
            border          : 1.5px solid transparent;
            font-size       : v.$btn-txt-md;
            &:hover{
                color: var(--main-blue-dark);
            }
        }
        transition      : .2s all ease-in-out;
        &:hover {
            border: 1.5px solid var(--main-blue-color-bg);
            background-color: transparent !important;
            color: var(--main-blue-dark);
        }
    }
}
@include tablet() {
    .card-favourite{
        width           : 220px;
        height          : 210px;
    }
}
