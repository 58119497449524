@use '../../../../../../sass/variables.scss' as variables;
@use '../../../../../../sass/colors' as colors;
@import '../../../../../../sass/partials/buttons';
@import '../../../../../../sass/mixins';
@import '../../../../../../sass/_mixins/global';
$body-card-width: 17rem;
$body-card-height: 22rem;
$medium-size-round: 3em;

.card-briefapplication {
    // width         : 100%;
    height: $body-card-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    //margin: 1rem 1rem 0 !important;
    .countdown-vogz-client {
        text-transform: uppercase;
        font-size: variables.$p;
        color: colors.$blue-time;
        font-family: Hurme-Bold;
    }

    &>div {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .cancel-choice {
            background-color: colors.$blue-dark;
            width: 30px;
            height: 30px;
            position: absolute;
            z-index: 19;
            border-radius: 60px;
            right: 0;
            margin: -8px 7px 0 0px;
            border: 1px solid colors.$blue-dark;
            align-items: center;
            justify-content: flex-end;
            font-size: 16px;
            cursor: pointer;
            color: var(--main-white-color);
            display: inline-flex;
            overflow: hidden;
            width: auto;
            max-width: 30px;
            /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
            -webkit-transition: max-width 0.5s;
            transition: max-width 0.5s;

            & .info-cancel {
                white-space: nowrap;
                padding-right: 7px;
                font-family: Roboto-Regular;
                font-size: 14px;
            }

            & i {
                display: flex;
                align-items: center
            }

            &:hover {
                max-width: 300px;
                background-color: var(--main-white-color);
                color: colors.$blue-dark;
            }
        }

        &>input {
            border: 0.5px solid colors.$blue-dark;
            padding: 8px 5px;
            border-radius: 4px;
            outline: none;
            text-align: center;
            color: colors.$blue-dark;
            font-family: Hurme-Bold;
            text-transform: uppercase;

            &::placeholder {
                color: colors.$blue-fair;
                font-family: Hurme-Bold;
                text-transform: uppercase;
            }
        }

        & .card-body {
            width: $body-card-width;
            height: 100%;
            min-height: 21rem;
            border-radius: 12px;
            position: relative;
            border-radius: 12px;
            border: 1px solid #d5d5d5;
            //margin-bottom: 24px;
            position: relative;

            & .badge-offered {
                background-color: var(--main-bright-turquoise-offered);
                padding: 10px;
                border-radius: 100px;
                font-size: variables.$px-ts-md;
                right: 15px;
                top: 15px;
                margin-top: unset;
                left: unset;
            }

            & .bookmarked_btn {
                position: absolute;
                width: 35px;
                height: 35px;
                z-index: 0 !important;
                background-color: var(--main-blue-color-bg);
                color: colors.$white;
                right: 10px;
                top: 10px;
                border-radius: 100%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.1s;

                &.active {
                    background-color: var(--main-blue-color-bg);
                    color: colors.$red-ryb;
                    border: none;

                    &:hover {
                        opacity: .8;
                    }
                }

                i {
                    font-size: variables.$px-ts-sm;
                }
            }

            & .open-btn {
                position: absolute;
                width: 35px;
                height: 35px;
                z-index: 0;
                background-color: var(--main-blue-color-bg);
                color: var(--main-white-color);
                right: 10px;
                transform: translateY(865%);
                border-radius: 100%;
                border: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .5s;

                i {
                    font-size: variables.$px-ts-sm;
                }

                &:hover {
                    background-color: #94a7fc;
                    transition: all .5s;
                }
            }

            & span {
                position: absolute;
                left: 0;
                background-color: var(--main-white-color);
                padding: 3px 12px;
                z-index: 7;
                margin-top: 16px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                color: colors.$blue-fair;
                font-size: variables.$medium-size-text;
                font-family: Hurme-Black;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

                &.badge-red {
                    @include badge("red");
                    right: 0;
                    left: auto;
                    height: 16px;
                    font-size: variables.$medium-size-text * .7;
                    border-radius: 10px;
                    right: 10px;
                    display: flex;
                    align-items: center;
                    box-shadow: none
                }
            }

            & .span-won {
                right: 11px;
                font-family: Hurme-Bold;
                color: var(--main-white-color);
                background-color: var(--main-apple-green);
                border-radius: 100px;
                font-size: calc(variables.$medium-size-text/1.7);
                padding: 4px 11px;
                line-height: 11px;
                margin-top: 20px;
            }

            & button {
                border: 1px solid colors.$blue-fair;
                color: colors.$blue-fair;
                background-color: var(--main-white-color);
                position: absolute;
                // padding         : 10px;
                font-size: variables.$medium-size-text;
                border-radius: 100%;
                right: -12px;
                top: -12px;
                height: 40px;
                width: 40px;
                cursor: pointer;

                &:hover {
                    background-color: colors.$blue-fair;
                    color: var(--main-white-color);
                }
            }

            & button.active {
                background-color: colors.$blue-fair;
                color: var(--main-white-color);
                border: 1px solid colors.$blue-fair;
            }

            & .play-btn {
                position: absolute !important;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: $medium-size-round;
                height: $medium-size-round;
                cursor: pointer;
                backdrop-filter: blur(4px);
                background: #0000005b;
                border-radius: 50%;
                z-index: 1;

                i {
                    color: white;
                    width: 100%;
                    font-size: 25px;
                    transform: translate(9%, 25%);
                }
            }

            & img {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                object-fit: cover;
            }
        }
    }

    & .card-briefapplication-stars-container {
        display: flex;
        justify-content: center;
        padding: 12px 0;

        & .card-briefapplication-stars,
        & span {
            overflow: unset !important;
        }
    }

    & .bottom-streamer {
        display: flex;
        justify-content: center;
        padding: 0.3rem 0;
        background: linear-gradient(98.26deg, #5C6FFD 0%, #A655FF 100%);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        & b {
            color: var(--main-white-color);
            font-family: Hurme-Black;
            font-size: variables.$px-ts-sm;
            letter-spacing: 0.02em;
            text-transform: uppercase;

            &::before,
            &::after {
                display: inline-block;
                content: '';
                margin: 0 5px;
            }
        }
    }

    & .winner-streamer {
        background: var(--main-gargoyle-gas);

        & b {
            color: colors.$black-dark;

            &::before,
            &::after {
                display: inline-block;
                content: '🏆';
                margin: 0 5px;
            }
        }
    }

    .add-to-cart_btn {
        background-color: var(--main-white-color);
        width: 100%;
        top: 5px;
        position: relative;
        padding: 7px 4px;
        border-radius: 20px;
        box-shadow: 0px 4px 15px rgba(202, 202, 202, 0.25);

        &.active {
            background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);

            i {
                color: var(--main-white-color);
                background-clip: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: var(--main-white-color);
                background: transparent;
                font-size: variables.$px-ts-nml;
                border-radius: 99%;
                box-shadow: 0px 4px 10px #5979DE;
            }

            &:hover {
                opacity: .8;
            }
        }

        & span {
            background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: Hurme-Bold;
            line-height: 20px;
            font-size: variables.$px-ts-md;

            i {
                background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &:hover {
            background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);

            & span,
            i {
                color: var(--main-white-color);
                background-clip: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: var(--main-white-color);
                background: transparent;
            }
        }
    }

    .offered {
        background: var(--main-bright-turquoise-offered) !important;
        font-family: Hurme-Black;
        text-shadow: 0px 4px 15px #009874;
        color: var(--main-white-color) !important;
        text-transform: uppercase;
        cursor: default;

        &:hover {
            background: var(--main-bright-turquoise-offered);
        }

        span {
            background: initial;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
            background-clip: unset;
        }
    }
}