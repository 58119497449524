@use '../../../../../../sass/variables.scss' as variables;
@use '../../../../../../sass/colors' as colors;

.certified-vogzter {
  & b {
    font-family: Hurme-Black !important;
    text-transform: uppercase !important;
    font-size: variables.$px-ts-sm !important;
    margin-right: 5px;
    background: linear-gradient(91.03deg, colors.$lavender-floral 0%, colors.$blue-dark 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 2px 8px rgba(152, 146, 146, 0.25);
  }
  .icon-certif {
    color: colors.$blue-dark;
    align-self: center;
    font-size: variables.$px-ts-sm !important;
  }
}