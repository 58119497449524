@use '../../../../sass/colors'as colors;
@use '../../../../sass/variables'as variables;
@import '../../../../sass/mixins';
@import '../../../../sass/partials/buttons';
.content-modal-cart {
    width: 60vw;
    max-width: 800px;
    max-height: 100%;
    overflow-y: auto;
    box-shadow: 0px 4px 20px rgba(183, 183, 183, 0.5);
    //height          : 70vh;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    background-color: var(--main-white-color);
    //padding: 63px 10px 22px 10px;
    border-radius: 12px;
    position: relative;
    .content-modal-btn {
        position: absolute;
        top: 19px;
        left: 20px;
        background: var( --main-blue-color-text);
        height: 30px;
        width: 30px;
        color: var(--main-white-color);
        border-radius: 40px;
    }
    & h3 {
        font-family: Hurme-Bold;
        font-size: variables.$mid-title-size-text * 1.3;
        z-index: 2;
    }
    & p:nth-of-type(1) {
        color: colors.$grey-dark;
        z-index: 12;
        & strong {
            color: colors.$black-dark;
        }
    }
    & .bubleProfile {
        background-color: var(--main-white-color);
        padding: 30px 35px;
        position: absolute;
        border-radius: 100%;
        top: -64px;
        & img {
            @include avatar(90px);
        }
    }
    & .content-card-modal-body {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        & article {
            margin: 0 12px;
        }
        & .selection-vogzs {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 50% 50%;
        }
        & .alert-info {
            width: 60%;
            display: flex;
            background-color: colors.$warning-info;
            align-items: center;
            border-radius: 12px;
            padding: 20px 40px;
            & i {
                font-size: variables.$medium-size-text * 3;
                color: var(--main-white-color);
            }
            & p {
                color: var(--main-white-color);
                margin-left: 20px;
                font-size: variables.$medium-size-text;
                & strong {
                    color: var(--main-white-color);
                }
            }
        }
        & .btn-animate {
            @include effect-btn(colors.$blue-dark, 12px, '\f058');
            margin-top: 32px;
            &::before {
                font-weight: 300;
            }
            &.loading {
                padding-inline: 45px;
                text-indent: -20px;
                &:before {
                    content: '\f3f4';
                    opacity: 1;
                    text-indent: 0px;
                    transform: rotate(30deg);
                    animation-name: spin;
                    animation-duration: 2000ms;
                    animation-iteration-count: infinite;
                }
            }
        }
        & .caroussel-vogz {
            width: 100%;
        }
    }
}

@include tablet {
    .content-modal-cart {
        max-width: 80vw;
        & .content-card-modal-body {
            & .selection-vogzs {
                width: 100%;
            }
            & .alert-info {
                width: 80%;
            }
            & .caroussel-vogz {
                width: 80%;
            }
        }
    }
}

@include mobile {
    .content-modal-cart {
        width: 95vw;
        & .content-card-modal-body {
            & .selection-vogzs {
                width: 100%;
            }
            & .alert-info {
                width: 90%;
                flex-direction: column;
                & p {
                    margin-top: 20px;
                    margin-left: auto;
                    text-align: center;
                }
            }
            & .caroussel-vogz {
                width: 80vw;
            }
        }
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}