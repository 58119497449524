@use "../../../../../../sass/variables.scss"as v;
@import "../../../../../../sass/mixins";
@import "../../../../../../sass/_mixins/global";

.box-casting-type{
  display: flex;
  width: 23vw !important;
  height: 30vw !important;
  max-width: 470px;
  max-height: 640px;
  flex-direction: column;
  justify-content: space-between;
  background: #FFF;
  box-shadow: 0px 4px 50px rgba(151, 151, 151, 0.25);
  border-radius: 20px !important;
  .img-container{
    width: 100%;
    height: 60%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 0.7vw;
    }
    
  }

  .txt-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 1rem;
    align-items: center;
    height: 40%;
    position: relative;
    padding-inline: 1.5rem;
    *{
      text-align: left;
    }
    h2{
      font-size: min(2vw, 1.45ch);
      font-family: Hurme-Black;
      margin-bottom: min(0.5vw, 0.3em);
      background: linear-gradient(92.76deg, #A073FF 0%, #78BCED 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: 100%;
    }

    p{
      font-size: min(1.6vw, 1.3ch);
      line-height: min(1.7vw, 1.05em);
      font-weight: 300;
      color: var(--main-grey-color-text);
      font-family: Roboto;
      padding-right: 1rem;
    }

  }
}
@include tablet() {
  .box-casting-type{
    .txt-container span{
        font-size: v.$px-ts-md;
        padding: 0.3rem 1rem;
    }
  }
}
@include mobile() {
  .box-casting-type{
    border-radius: 4px;
    width: 15rem;
    height: 14rem;
  
    .txt-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50%;
      
      *{
        text-align: left;
      }
      h2{
        font-size: v.$px-ts-md;
        font-family: Hurme-Black;
        text-transform: uppercase;
        margin-bottom: min(0.5vw, 0.3em);
      }
  
      p{
        font-size: 0.7rem;
        line-height: 0.8rem;
        font-weight: 300;
        color: #3A4161;
      }
  
    }
  }
}

.clickable{
  cursor: pointer;
}