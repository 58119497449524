@use "/src/sass/colors" as colors;
@use "../../../../sass/variables.scss" as v;
@import '../../../../sass/others/animations.scss';
@import '../../../../sass/mixins';
@import '../../../../sass/partials/_buttons';
@import "../../../../sass/_mixins/global";
@import "../../../../sass/_mixins/animated-shine.scss";

.top-nav-bar {
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 20px;
    margin-top: -80px;
    box-shadow: 0px 4px 25px #F5F5F5;
    background: rgba(255, 255, 255, 0.947);
    backdrop-filter: blur(10px);
    z-index: 12;
    top: 80px;

    .logo a {
        background: -webkit-linear-gradient(#d1b2ff, #5777ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        transition: .3s;
        font-family: Hurme-Black;
        text-transform: uppercase;
        font-size: v.$h1Mob;
        margin: 0 15px;
    }

    .menu-button {
        display: none;
        font-size: v.$h1Mob;
        top: -80px;
        transition: .3s;
        cursor: pointer;

        &:hover {
            color: var(--main-blue-color-bg);
        }
    }

    .menu-container a {
        font-family: Hurme-Semi-Bold;
        text-transform: uppercase;
        font-size: v.$pMobil;
        margin: 0 15px;
        color: var(--main-dark-color);
    }

    .btn-login {
        background: var(--main-white-color);
        border: 1px solid var(--main-blue-color-bg);
        box-shadow: 0px 2px 15px rgba(202, 202, 202, 0.25);
        border-radius: 100px;
        color: var(--main-blue-color-bg);
        text-transform: uppercase;
        min-width: 110px;
        font-size: v.$pMobil;

        &:hover {
            background: var(--main-blue-color-bg);
            color: var(--main-white-color);
        }
    }
}

.deal-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    padding: 7rem 0rem;
    max-width: 1000px;

    .vogzter-section {
        display: flex;
        justify-content: space-between;
        align-self: center;
        width: 100%;

        .vogzter-name {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 10px;
        }
    }

    .offer-section {
        display: flex;
        justify-content: space-between;

        @include tablet {
            flex-direction: column-reverse;
        }

        .offer-info {
            flex: 1;

            h2 {
                font-size: 25px;
                font-weight: 700;
                color: #131153;
            }

            p {
                font-size: 15px;
                margin: 5px auto;

                @include tablet {
                    text-align: start;
                    margin: 0;
                }
            }

            .offer-duration {
                color: var(--main-text-condition-color);
                font-size: 14px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                margin: 10px auto;

                span {
                    font-size: 15px;
                    font-family: Hurme-Black;
                    color: var(--main-text-condition-color);
                    margin: 0;
                    margin-top: 10px;
                }
            }

            .offer-social {
                display: flex;
                padding: 5px 0px;
                margin: 10px 0px;
                overflow-y: scroll;

                img {
                    width: 60px;
                    height: 30px;
                    object-fit: cover;
                    margin: auto 10px;
                    border-radius: 5px;
                }

                .camera {
                    padding: 3px 15px;
                    margin: auto 10px auto 0px;
                    font-size: 1rem;
                    background-color: #D4F4FB;
                    border-radius: 5px;
                }
            }
        }


        .offer-price {
            flex: 1 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 0px 15px;
            margin: 10px 0px;
            gap: 100px;

            @include tablet {
                padding: 0;
                justify-content: space-between;
                gap: 40px;
            }

            @include mobile() {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
            }
        }
    }

    section {
        margin: 10px 0;

        &[data-errored='true'] {
            border: 1px solid var(--main-error-txt);
        }

        &[data-recommended='true'] {
            &.section-form .title-section-form::after {
                content: '(recommandé)' !important;
                margin-left: 5px;
                color: rgba(132, 128, 255, 1);
            }
        }

        &[data-optional='true'] {
            padding-top: 3em !important;
        }

        p[data-conditions='true'] {
            font-size: v.$px-ts-sm;
            line-height: 17px;
            max-width: 75%;
            margin-top: 2em;
            color: var(--text-conditions-color);
            font-family: Roboto;

            strong {
                font-family: Hurme-Bold;
            }
        }

        p[data-required='true'] {
            font-size: v.$px-ts-sm;
            color: rgba(103, 164, 255, 1);
            line-height: 17px;
            max-width: 75%;

            //margin: 10px 0;
            strong {
                font-family: Hurme-Bold;
            }
        }

        input {
            &[data-input-brief] {
                font-size: v.$px-ts-sm;
                padding: 10px 10px;
                outline: 0;
                width: 100%;
                border-radius: 4px;
                border: 1px solid var(--main-light-grey);
            }

            &[data-input-media] {
                overflow: hidden;
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
        }

        button {
            &[data-btn-add] {
                font-family: Hurme-Bold;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: var(--main-blue-fair-color-bg);
                padding: 8px 20px;
                width: 100%;
                justify-content: center;
                max-width: 237.79px;
                border: 1px solid var(--main-blue-fair-color-bg);
                border-radius: 30px;
                display: flex;
                align-items: center;
                transition: 0.2s all ease-in-out;

                >i {
                    font-size: v.$px-ts-md;
                    margin-right: 8px;
                }

                font-size: v.$px-ts-sm;

                &:hover {
                    color: var(--main-white-color);
                    background-color: var(--main-blue-fair-color-bg);
                }
            }

            &[data-btn-minus] {
                color: #bcc9ff;
                font-size: v.$px-ts-sm;

                &:hover {
                    color: var(--main-alert-color) !important;
                }
            }
        }

        .tip-section-form {
            background-color: var(--main-blue-color-bg);
            color: white;
            font-size: v.$px-ts-sm;
            padding: 0px 20px;
            text-align: center;
            border-radius: 40px;

            i {
                margin-left: 10px;
            }
        }

        .form-aside-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            >.section-first-form {
                max-width: 400px;

                &.first-part {
                    display: flex;
                    align-items: center;
                    // width: 50%;
                    justify-content: space-between;

                    >p {
                        color: var(--home-violet-dark-color-text);
                    }
                }

                // &.btns-next {
                //     display: flex;
                //     width: 50%;
                //     justify-content: center;
                //     align-items: center;
                //     button {
                //         min-width: 0;
                //         width: 100%;
                //         max-width: 151px;
                //         height: fit-content;
                //         text-transform: capitalize;
                //     }
                // }
            }
        }

        [data-styleform='true'] {
            color: var(--text-conditions-color);
            font-size: v.$px-ts-sm;
        }

        &.active {}
    }

    .form-elements {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;


        section {

            h3,
            .title-section-form,
            label[data-h4='true'] {
                font-family: 'Hurme-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: v.$px-ts-nml;
                line-height: 26px;
                color: var(--main-dark-color);
                display: flex;
                flex-wrap: wrap;

                &::after {
                    font-size: v.$px-ts-sm;
                    content: '(optionnel)';
                    margin-left: 5px;
                    color: var(--main-blue-color-bg);
                }

                &.show-recommanded::after {
                    font-size: v.$px-ts-sm;
                    content: '(recommandé)';
                    margin-left: 5px;
                    color: var(--main-blue-color-bg);
                }
            }

            &:nth-of-type(2) {
                .section-first-form {
                    max-width: 400px;
                }
            }

            .word-count {
                float: right;
                right: 35px;
                padding: 4px 10px;

                & span {
                    color: var(--main-blue-color-text);
                    font-family: Hurme-Bold;
                    font-size: v.$px-ts-sm;
                }
            }

            .text-area-describe-brief {
                border: 1px solid var(--main-light-grey);
                // margin-top: 20px;
                outline: 0;
                width: 100%;
                min-height: 200px;
                border-radius: 0.75rem;
                padding: 7px;
                font-size: v.$px-ts-sm;
            }

            .desc-brief {
                .examples-brief {
                    display: flex;

                    >div {
                        box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);

                        >p {
                            font-size: v.$px-ts-sm;
                            color: var(--main-grey-placeholder-color-text);
                        }
                    }
                }

                .ai {
                    background-color: var(--main-violet-color);
                    border-radius: 10px;

                    .ai-container {
                        display: flex;
                        padding: 1em 2em;
                        width: 85%;
                        margin: auto;

                        // justify-content: space-evenly;
                        hr {
                            border: none;
                            border-left: 1px solid rgba(255, 255, 255, 0.445);
                            height: 8em;
                            width: 1px !important;
                            margin: auto 3em auto auto;
                        }

                        .ai-img {
                            img {
                                background: none;
                                max-height: none;
                                width: min(250px, 30vw);
                                margin: auto 1em auto auto;
                                object-fit: contain;
                                padding: 0px;
                                cursor: auto;
                                max-height: 300px;
                            }
                        }

                        .ai-text {
                            display: flex;
                            flex-direction: column;
                            margin: 1em auto;
                            width: 100%;

                            h1 {
                                font-weight: bold;
                                color: white;
                                margin-bottom: 0.2em;
                                font-family: Hurme-Bold;
                            }

                            p {
                                color: white;
                                font-size: min(16px, 1.5vw);
                            }

                            p:nth-child(3) {
                                font-family: Hurme-Bold;
                            }

                            input {
                                height: 2em !important;
                                padding: 1.5em 1.5em;
                                font-size: min(16px, 3.5vw);
                            }

                            .buttons {
                                button:first-child {
                                    width: 40%;
                                }
                            }

                            .ai-btn {
                                width: 80%;
                                color: white;
                                box-shadow: 0px 3px 18px rgba(31, 31, 31, 0.178);
                                border: 1px solid white;
                                font-family: Hurme-Bold;
                                border-radius: 10px;
                                font-size: min(20px, 1.4vw);
                                margin-top: 1.5em;
                                margin-bottom: 0.5em;
                                padding: 0.5em 1em;
                                transition: all 0.3s ease;

                                &:hover {
                                    background-color: white;
                                    color: var(--main-violet-color);
                                }
                            }
                        }
                    }
                }
            }
        }

        .content-medias {
            & .div-media {
                max-width: 470px;
                width: 100%;
                height: 40px;
                background: #ffffff;
                box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                color: var(--main-text-condition-color);
                font-family: Hurme-Bold;
                font-size: v.$low-size-text;

                & i:nth-of-type(2) {
                    color: #afc0ff;
                    cursor: pointer;

                    &:hover {
                        color: var(--main-text-condition-color);
                    }
                }
            }
        }

        .div-input-file {
            & label {
                width: 100%;
            }

            & .label-file {
                border: 2.5px dashed var(--home-violet-dark-color-text);
                border-radius: 4px;
                background: colors.$alice-blue3;
                height: 250px;
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                flex-direction: column;
                font-size: v.$px-ts-sm;
                justify-content: center;
                padding: 20px;
                font-family: Hurme-Bold;
                color: var(--home-violet-dark-color-text);
                text-align: center;

                & i {
                    color: colors.$periwinkle-blue !important;
                    font-size: 45px;
                    line-height: normal;
                    -webkit-text-fill-color: colors.$periwinkle-blue;
                }

                & p {
                    color: var(--home-violet-dark-color-text);
                }

                & p>span {
                    font-family: Roboto;
                    font-weight: 400;
                    color: colors.$periwinkle-blue;
                }
            }
        }

        .name-brief {
            >h4 {
                font-size: v.$px-ts-nml !important;

                >span {
                    color: var(--text-conditions-color);
                    font-size: 13px;
                    margin-left: 6px;
                }
            }

            .mentions-inputs {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }

            .remarks {
                background: var(--main-blue-very-pale);
                box-shadow: 0px 4px 10px rgba(215, 215, 215, 0.25);
                border-radius: 5px;
                padding: 5px 15px;
                font-size: v.$px-ts-sm !important;
            }

            h3 {
                font-family: Hurme-Bold;
                font-style: normal;
                font-size: v.$px-ts-md;
                line-height: 26px;
                color: var(--main-dark-color);
            }

            .steps-scenario {
                .step-scenario {
                    background-color: var(--blue-v1);
                    color: var(--main-white-color);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    max-width: 400px;

                    >span {
                        font-family: Hurme-Bold;
                        font-size: v.$px-ts-sm;
                        line-height: 20px;
                        text-align: center;
                        padding: 4px 8px;

                        >strong {
                            font-size: v.$px-ts-nml;
                        }
                    }

                    >input {
                        height: 95%;
                        width: 100%;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        margin: 1px;
                        outline: none;
                        padding: 0 10px;
                        color: var(--main-dark-color);
                        font-size: v.$px-ts-md;

                        &::placeholder {
                            font-size: v.$px-ts-sm;
                        }
                    }
                }
            }

            .desc-remuneration {
                text-align: center;
                color: #7c7c7c;
                font-family: Roboto;
                font-weight: 300;
                font-size: v.$px-ts-sm;
                max-width: 320px;
            }
        }

        .submit-section {
            background-color: #FBFBFF;
            width: 100%;
            display: flex;
            position: sticky;
            bottom: 0;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding: 20px 50px;
            backdrop-filter: blur(10px);
            box-shadow: 0px -4px 50px rgba(190, 190, 190, 0.25);
            border-radius: 20px 20px 0px 0px;

            .price-recap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                width: 80%;
                margin: 10px 0px;

                .price-total {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .price-total-title {
                        font-family: Hurme-Black;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        color: #000;

                        @include tablet() {
                            font-size: 20px !important;
                        }
                    }

                    .price-total-value {
                        font-family: Hurme-Black;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        color: #000;

                        @include tablet() {
                            font-size: 24px !important;
                        }
                    }

                    @include tablet {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 15px;
                    }
                }

                .price-disclaimer {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 300;
                    width: 50%;
                    line-height: 1 !important;
                    align-self: flex-start;
                    font-size: 15px;
                    line-height: 26px;
                    color: #7C7C7C;

                    @include tablet {
                        width: 100%;
                    }
                }
            }

            .submit-btn {
                width: 80%;
                margin-top: 10px;

                >button {
                    width: 100%;
                    height: 50px;
                    border-radius: 5px;
                    background-color: #FFECA7;
                    color: #000;
                    font-size: 16px;
                    font-family: Hurme-Bold;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    text-align: center;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background-color: #FFD700;
                    }
                }
            }

            @include tablet {
                padding: 10px 0px;

                .price-recap {
                    .price-total {
                        .price-total-title {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .section-first-form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-self: center;
            width: 100%;


            input[data-gradient-input='true'] {
                width: 80px;
                outline: 0;
                font-size: 50px;
                text-align: center;
                font-family: Hurme-Bold;
                background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            fieldset {
                border: none;
                display: flex;
                align-items: flex-start;
            }

            label {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .card-add-fav {
                &:nth-child(1) {
                    border: double 2px transparent;
                    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #d7e7ff 0%, #96c0ff 100%);
                    background-origin: border-box;
                    background-clip: content-box, border-box;
                    cursor: pointer;

                    & img {
                        border-radius: 0px;
                    }

                    & p {
                        background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        font-family: Hurme-Bold;
                        font-size: v.$px-ts-ul-sm;
                    }
                }

                position: relative;
                display: flex;
                box-shadow: 0px 4px 50px rgba(190, 190, 190, 0.25);
                border-radius: 10px;
                width: 146px;
                height: 140px;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                & i {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;

                    &:hover {
                        color: #7c7c7c;
                    }
                }

                & img {
                    padding: 0;
                    width: 72px;
                    height: 72px;
                    border-radius: 50px;
                }

                & .name-vogzter {
                    font-family: Hurme-Bold;
                    margin-top: 10px;
                    font-size: calc(v.$p * 1.25);
                    color: var(--main-dark-color) !important;
                }

                .btn-selection {
                    bottom: 0px;
                    //positon: absolute;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    flex-direction: row;
                }
            }
        }
    }
}

@include tablet2 {
    .menu-button {
        display: flex !important;
    }

    .menu-container,
    .menu-mobile {
        top: -300%;
        transition: all 0.6s ease-in-out;
        transform: scale(0.9);
        width: 100%;
        position: absolute;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: rgba(255, 255, 255, 0.947);
        z-index: 20;
        box-shadow: 0px 4px 25px #F5F5F5;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}