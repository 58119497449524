@use "../../../../sass/colors" as colors;
@use "../../../../sass/variables.scss" as v;
@import '../../../../sass/mixins';
@import '../../../../sass/partials/_buttons';
@import '../../../../sass/_mixins/global';
@import '../../../../sass/_mixins/animation-float.scss';

.notAgency {
  display: none !important;
}

.brandswitcher {
  display: flex;
  position: absolute;
  top: 3em;
  right: 7em;
  z-index: 20;
  cursor: pointer;

  i {
    font-size: 1.1em;
    color: var(--main-violet-color-text);
    cursor: pointer;
    animation: float 2s ease-in-out infinite;
  }

  .BrandList {
    position: absolute;
    right: -5em;
    top: 5em;
    width: 300px;
    max-height: 300px !important;
    overflow-y: scroll;
    border-radius: 10px;
    animation: dropdown .1s ease-in-out;

    ul li {
      color: var(--main-violet-color-text);
      font-family: Hurme-Black;
      font-size: 1em;
      display: flex;
      align-items: center;
      background-color: #f5f7ff;
      padding: 1em 0.5em 1em 2em;
      margin: auto;
      cursor: pointer;

      &:hover {
        background-color: var(--main-violet-color-text);
        color: white;
        transition: 0.3s;
      }

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      @include mobile {
        font-size: 0.8em;
      }

      span {
        font-size: v.$pMobil;
        color: var(--main-white-color);
        background-color: var(--main-alert-color);
        display: flex;
        border-radius: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        line-height: initial;
        opacity: 1 !important;
        align-items: center;
        justify-content: center;
        font-family: Hurme-Bold;
        z-index: 3;
        margin-left: 15px;

        @include mobile {
          font-size: 0.8em;
        }
      }
    }

    .active {
      background-color: #6d67f7 !important;
      color: white !important;
      transition: 0.3s !important;
    }

    @include tablet {
      right: 0px;
      width: 250px;
    }

    @include mobile {
      top: 3.3em;
      width: 200px;
    }
  }

  @include tablet {
    position: sticky;
    float: right;
    top: 1em;
    right: 3em;
  }

  @include mobile {
    position: sticky;
    float: right;
    right: 0.5em;
    margin-top: -65px;
  }
}

.logo {
  .no-brand-selected {
    background-color: #F5F7FF;
    padding: .5rem 1rem;
    border-radius: 10px;
    color: var(--main-violet-color-text);
    font-family: Hurme-Bold;
    font-size: 14px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
  }

  .logo-alt-container {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: var(--main-blue-fair-color-bg);
    border: 3px solid var(--main-yellow);
    z-index: 10;
    display: flex;
    align-items: center;

    .logo-alt {
      margin: auto;
      font-family: Hurme-Bold;
      font-size: 1.5em;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 3px solid var(--main-white-color);
    z-index: 10;
    object-fit: cover;

    @include tablet {
      width: 3.5em;
      height: 3.5em;
    }

    @include mobile {
      width: 3em;
      height: 3em;
    }
  }
}