@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

@keyframes toggleOnRadio {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #00ff048c, inset 0 0 10px -10px #00ff048c;
  }
  to {
    box-shadow: 0 0 10px 10px #00ff048c, inset 0 0 10px -10px #00ff048c;
  }
}

@keyframes wave {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@keyframes pulse-animation {
  10% {
    font-size: 60px;
  }
  18% {
    font-size: 64px;
  }
  26% {
    font-size: 60px;
  }
}

@keyframes pulse-animation-fast {
  0% {
    font-size: 60px;
  }
  50% {
    font-size: 64px;
  }
  100% {
    font-size: 60px;
  }
}

