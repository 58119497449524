@use "../../../../sass/variables.scss"as v;
@import '../../../../sass/mixins';
@import '../../../../sass/_mixins/global';

.register-process {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    height        : 100%;
    overflow      : scroll;
    position      : relative;

    & .step-2-bottom-child-mobile {
        height: 83vh;
    }

    .btn-next-step {
        width: 100%;

        button {
            border-radius : 52px;
            padding       : 10px 40px !important;
            text-transform: initial;
        }
    }

    .btn-step-4 {
        border        : none;
        padding       : 0 !important;
        text-transform: none;

    }

    & .btn-back-desktop {
        display: block;
    }

    & .btn-back-mobile {
        display: none;
    }

    & .step-container {
        width       : 100%;
        min-height: 100%;
        display     : flex;
    }

    & .step-end-process {
        // height: 100vw;
    }

    &>div {
        display        : flex;
        justify-content: center;
        align-items    : center;
    }
}

.step-1-wrapper {
    display        : flex;
    justify-content: center;
    position: relative;
    .step-1-video-wrapper {
        position       : relative;
        width          : 850px;
        height         : 450px;
        display        : flex;
        margin-bottom  : 40px;
        justify-content: center;
        align-items    : center;
        @include tablet {
            width: 80vw;
        }
        & div {
            flex-direction: column;
        }

        .step-1-bg {
            width        : 100%;
            height       : 100%;
            background   : linear-gradient(92.76deg, #A073FF 0%, #78BCED 100%);
            border-radius: 8px;
            transform    : translateX(-2vw) translateY(3vh);
        }

        .step-1 {
            position     : absolute;
            width        : 100%;
            height       : 100%;
            border-radius: 8px;
            background   : white;

            video {
                width        : 100%;
                height       : 100%;
                border       : solid var(--blue-gulf) 1.2px;
                border-radius: 8px;
                object-fit   : cover;
            }

            & .step-1-title {
                background             : linear-gradient(101.31deg, #bbefff 0%, #d4a9ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip        : text;
                text-transform         : uppercase;
                text-fill-color        : transparent;
            }

            & .step-1-desc {
                margin     : 0 15%;
                color      : var(--black-russian);
                text-align : center;
                font-size  : 1.7ch;
                font-weight: 300;
            }
        }

    }

    .icon-wrapper {
        position     : absolute;
        bottom       : 40px;
        transform    : translateY(5vh);
        background   : var(--black-russian);
        border-radius: 100%;
        padding      : 30px;

        & img {
            transform: translateX(0.2vw);
        }
    }
}

.step-2 {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    width          : 100%;
    // height         : 100%;

    & .step-2-bottom {
        width : 100%;
        // height: 100%;
    }

    & div {
        display        : flex;
        justify-content: center;
        align-items    : center;
    }
}

.step-3 {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    height        : 100%;

    .swiper {
        display: none;
    }

    .element-child {
        display    : flex;
        align-items: start;
    }

    & .step-3-tab-mobile {
        display: none;
    }

    & .step-3-tab-desktop {
        display       : flex;
        flex-direction: row;
        align-items   : flex-start;
        height        : 100%;
    }

    .step-3-tab-desktop-left,
    .step-3-tab-desktop-right,
    .step-3-tab-mobile {
        position      : relative;
        width         : 40%;
        height        : max-content;
        flex-direction: column;
        align-items   : flex-start;
        padding       : 0 40px;
        margin        : 30px 0;
        background    : var(--main-white-color);
        min-width     : 630px;

        & p,
        span {
            font-family   : Hurme-Black;
            font-size     : 1.1ch;
            text-transform: uppercase;
            padding       : 1em 0;
        }

        & p {
            width: 60%;
            color: var(--blue-gulf);
        }

        & hr {
            width              : 100%;
            // padding-left: 50px;
            // border-top: 0.5px solid #B9BFD8;
            border             : 1px solid;
            border-bottom      : none;
            border-image-slice : 1;
            border-image-source: linear-gradient(90deg, transparent, #dfe3ff, transparent);
        }
    }

    .step-3-element-tab {
        display    : flex;
        padding    : 1rem;
        height     : 110px;
        align-items: center;

        .circle {
            max-width    : 85px;
            max-height   : 85px;
            width        : 100%;
            height       : 85px;
            border-radius: 50px;

            img {
                height: 65%;
                filter: drop-shadow(0px 4px 15px #b5deff);
            }
        }

        .text-tab {
            margin-left   : 1rem;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;

            h3 {
                font-family   : Hurme-Black;
                font-size     : v.$px-ts-nml;
                line-height   : normal;
                text-transform: uppercase;
                color         : var(--blue-gulf);

                span {
                    color         : var(--main-blueberry-color);
                    text-transform: none;
                }
            }

            p {
                font-family   : Roboto;
                font-size     : v.$medium-size;
                line-height   : normal;
                text-transform: none;
                color         : var(--main-grey-color-text);
                padding       : 0;
            }
        }
    }

    & .absolute-tab {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        position       : absolute;
        right          : 60px;
        width          : 12%;
        text-align     : center;
        height         : max-content;
        border-radius  : 10px;
        background     : linear-gradient(136.36deg, #A073FF 24.03%, #78BCED 75.22%);
        border-radius  : 10px;
        box-shadow     : 0px 4px 40px rgba(212, 212, 212, 0.25);
        height         : 100%;

        span {
            height        : 110px;
            align-items   : center;
            display       : flex;
            color         : var(--main-white-color);
            text-transform: unset !important;
            font-size     : v.$h2Mob !important;

            & i {
                font-size: v.$h1;
            }

        }

        & hr {
            width              : 70%;
            border-image-source: linear-gradient(90deg, transparent, var(--main-white-color), transparent);
        }

        & p {
            font-family   : Roboto;
            text-transform: lowercase;
            text-align    : center;
        }
    }
}

.btn-action {
    width      : 250px !important;
    height     : min-content !important;
    align-self : center;
    position   : sticky;
    bottom     : 2rem;
    z-index    : 1;
    padding-top: 2rem;
}

.btn-step-1 {
    width: 350px !important;
}

.swiper {
    width  : 90%;
    height : 100%;
    padding: 0 5%;

    & .swiper-scrollbar {
        display: none !important;
    }
}

.swiper-slide-custom {
    height       : 15vw !important;
    width        : 25vw !important;
    border-radius: 0.5vw;
    cursor       : pointer;

    h1 {
        font-size: 1.4vw !important;
    }

    p {
        font-size: 0.6vw !important;
    }
}

.swiper-slide-box {
    width        : 17vw;
    height       : 16vw;
    border-radius: max(0.2vw, 0.3em);
    max-width    : none;
    max-height   : none;
    cursor       : pointer;

    h2 {
        font-size: min(2.7vw, 2.1ch) !important;
    }

    p {
        font-size  : min(0.75vw, 2ch) !important;
        line-height: min(1.7vw, 1.3em) !important;
    }
}

.swiper-slide {
    & {
        z-index: 2 !important;
    }
}

.swiper-inactive {
    opacity   : 0.3;
    transition: 0.1s;
    transform : translateX(12.5vw);
}

.swiper-active {
    position : relative;
    transform: scale(1.20) translateX(10vw);
    opacity  : 1;
    z-index  : 1;
}

.swiper-scrollbar {
    display: none !important;
}

.swiper-btn {
    display: block;
}

.swiper-btn-mobile {
    display: none !important;
}

.swiper-btn-mobile-container {
    display: none !important;
}

.circle-violet {
    width        : 20px;
    height       : 20px;
    align-self   : center;
    background   : linear-gradient(98.26deg, #5c6ffd 0%, #a655ff 100%);
    border-radius: 100%;
}

.circle-lightblue {
    width        : 20px;
    height       : 20px;
    align-self   : center;
    background   : linear-gradient(91.25deg, #01c6fe 0%, #01fddf 100%);
    border-radius: 100%;
}

.circle-yellow {
    width        : 20px;
    height       : 20px;
    align-self   : center;
    background   : #ffeca7;
    border-radius: 100%;
}

.step-6-desktop {
    width          : 100%;
    max-width      : 26rem;
    height         : 10rem;
    margin         : 1rem;
    display        : flex;
    flex-direction : row;
    border-radius  : 10px;
    justify-content: space-evenly !important;

    .bottom-card {
        display        : flex;
        justify-content: space-between;
        width          : 40%;
        flex-direction : column;
        align-items    : center;

        .part-price {
            margin-bottom: .5rem;
        }

        button {
            min-width              : 150px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-radius          : 50px;
        }

    }

    .header-card,
    .part-price {
        display       : flex;
        align-items   : center;
        flex-direction: column;

        h3 {
            font-family: Hurme-Black;
            font-size  : v.$h2;
            color      : #273572;
        }

        p {
            font-family: Roboto;
            color      : #6b6b6b;
            font-weight: 300;
        }
    }

    .img-card {
        position: relative;

        img {
            height: 6.5rem;
        }

        >p {
            bottom        : 10px;
            right         : 10px;
            font-family   : Hurme-Bold;
            font-size     : v.$h2Mob;
            color         : #ffffffb2;
            position      : absolute;
            z-index       : 1;
            text-transform: uppercase;
            cursor        : default;
        }
    }

    button {
        background             : linear-gradient(90deg, #d7d6ff 0%, #abc7ff 24.48%, #615af6 50%, #abc7ff 73.44%, #d7d6ff 100%);
        box-shadow             : 0px 4px 15px rgba(121, 121, 121, 0.25);
        border                 : none !important;
        position               : relative;
        overflow               : hidden;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-radius          : 50px;

        &:before {
            background-color  : #d7d6ff;
            content           : '';
            position          : absolute;
            width             : 20px;
            height            : 35px;
            filter            : blur(1px);
            opacity           : 0;
            -webkit-transition: none;
            -moz-transition   : none;
            -ms-transition    : none;
            -o-transition     : none;
            transition        : 0.7s;
            transform         : translateX(-160px);
        }

        &:after {
            background-color  : #d7d6ff;
            content           : '';
            position          : absolute;
            width             : 20px;
            height            : 35px;
            filter            : blur(1px);
            opacity           : 0;
            -webkit-transition: none;
            -moz-transition   : none;
            -ms-transition    : none;
            -o-transition     : none;
            transition        : 0.7s;
            transform         : translateX(160px);
        }

        &:hover {
            color: #d7d6ff;

            &:before {
                transform: translate(160px, -5px);
                opacity  : 0.6;
            }

            &:after {
                transform: translate(-180px, -5px);
                opacity  : 0.6;
            }
        }
    }
}

.popular {
    background-color: var(--main-violet-color-text) !important;
    position        : relative;

    .text-popular {
        position        : absolute;
        font-family     : Hurme-Bold;
        padding         : 5px 15px;
        top             : 10px;
        right           : 10px;
        background-color: var(--main-white-color);
        color           : var(--main-violet-color-text);
        font-size       : v.$px-ts-ul-sm;
        border-radius   : 2.5rem;
        line-height     : normal;
    }

    .part-nb-vogz {
        box-shadow               : 0px 4px 32px rgba(0, 0, 0, 0.25);
        border-top-left-radius   : 20px;
        border-bottom-left-radius: 20px;

        h3 {
            color: var(--main-white-color) !important;
        }
    }

    .part-pricing {
        h3 {
            color: var(--main-white-color) !important;

            span {
                color: #fdd443 !important;
            }
        }

        p {
            color: var(--main-white-color) !important;
        }

        button {
            border          : 1px solid var(--main-white-color) !important;
            background-color: var(--main-violet-color-text);
            color           : var(--main-white-color);

            &:hover {
                background-color: var(--main-white-color) !important;
                color           : var(--main-violet-color-text) !important;
            }
        }
    }
}

.header {
    display       : flex;
    flex-direction: column;
    padding-top   : 4rem;

    h2 {
        font-family   : Hurme-Black;
        font-size     : v.$h2;
        text-align    : center;
        text-transform: uppercase;
        color         : var(--blue-gulf);
    }

    p {
        padding-top: 0.5rem;
        width      : 60%;
        text-align : center;
        font-family: Roboto;
        font-weight: 300;
        font-size  : v.$h2Mob;
        color      : var(--blue-gulf);
    }
}

.step-6 {
    .swiper {
        display: none;
    }
}

.booking {
    position       : absolute;
    width          : 100%;
    z-index        : 20;
    height         : 100%;
    display        : flex;
    justify-content: center;
    flex-direction : column;
    align-items    : center;
    backdrop-filter: blur(20px);
    background     : rgba(255, 255, 255, 0.3);

    .booking-card {
        box-shadow      : 0px 10px 50px #dcdcdc;
        background-color: var(--main-white-color);
        border-radius   : 25px;
        width           : 100%;
        height          : 100%;
        display         : flex;
        max-width       : 28rem;
        display         : flex;
        flex-direction  : column;
        max-height      : 41rem;

        .booking-header {
            background-color       : var(--home-violet-color-bg);
            width                  : 100%;
            border-top-left-radius : 25px;
            border-top-right-radius: 25px;

            h3 {
                color      : var(--main-white-color);
                font-family: Hurme-Black;
                font-size  : v.$h2;
                padding    : 1rem;
                text-align : center;
            }
        }

        .booking-body {
            height         : 100%;
            display        : flex;
            flex-direction : column;
            align-items    : center;
            justify-content: center;

            p {
                font-family: Roboto;
                font-weight: 300;
                font-size  : v.$px-ts-md;
                color      : var(--main-grey-color-text);
                text-align : center;
                padding    : 1rem;
                width      : 85%;
            }

            a {
                font-family: Hurme-Bold;
                font-size  : v.$px-ts-md;
                color      : var(--main-blue-mobile);
            }

            .img-booking {
                width         : 70%;
                padding-top   : 2rem;
                padding-bottom: 2rem;
            }

            button {
                background-color: var(--main-white-color);
                border          : 1.5px solid var(--main-blue-color-bg);
                color           : var(--main-blue-color-bg);

                &:hover {
                    background-color: var(--main-blue-color-bg);
                    color           : var(--main-white-color);
                    border          : 1.5px solid transparent;
                }
            }
        }
    }
}

.end-process {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    height         : 100%;
    width          : 100%;
    max-width      : 75rem;

    h3 {
        color      : var(--main-violet-color-text);
        font-family: Hurme-Black;
        text-align : center;
        font-size  : calc(v.$h1Mob + 5px);
        padding    : 1rem;
        max-width  : 90vh;
    }

    p {
        font-family: Roboto;
        font-weight: 300;
        font-size  : v.$px-ts-md;
        color      : var(--main-grey-color-text);
        text-align : center;
        padding    : 1rem;
        max-width  : 60vh;
        line-height: normal;
    }

    button {
        font-size  : v.$px-ts-md;
        padding    : 10px 20px;
        line-height: normal;
        margin-top : 1.5rem;
    }

    .img-end-process {
        position : absolute;
        z-index  : -1;
        max-width: 25rem;
        bottom   : 0;
        right    : 0;
    }
}

@include tablet() {
    .register-process {
        .btn-action {
            bottom: 0;
        }

        & .step-2-bottom-child-mobile {
            transform: scale(1.2);
            height   : 55vw !important;
        }

        .swiper-slide-box {
            // width: 17vw;
            // height: 24vw ;
            width : 26vw !important;
            height: 34vw !important;

            h2 {
                font-size: 1.3vw !important;
            }

            p {
                font-size: min(1vw, 2ch) !important;
            }
        }

        & .step-3-subtitle {
            width: 40%;
        }

        & .step-3-tab-desktop {

            & .step-3-tab-desktop-left,
            .step-3-tab-desktop-right {
                padding     : 0 10px !important;
                // width: 70%;
                // min-width: 37.5rem;
                width       : 40%;
                min-width   : 17.5rem;

                & .absolute-tab {
                    right: 0;
                }
            }
        }

        .step-3-element-tab {
            height: 9.25rem;

            .circle {
                max-height: 3.75rem !important;
                max-width : 3.75rem !important;
            }

            .text-tab {
                h3 {
                    font-size: v.$medium-size !important;
                }

                p {
                    font-size: v.$low-size-text !important;
                }
            }
        }

        .absolute-tab {
            span {
                height   : 9.25rem;
                font-size: v.$pMobil !important;

                i {
                    max-width: 25px;
                    font-size: v.$h2Mob;
                }
            }
        }
    }

    .header {
        display: none;
    }

    .step-6 {
        h2 {
            font-family   : Hurme-Black;
            font-size     : v.$h2;
            text-transform: uppercase;
            color         : var(--blue-gulf);
        }

        p {
            font-family: Roboto;
            font-weight: 300;
            font-size  : v.$h2Mob;
            color      : var(--blue-gulf);
        }

        .step-6-desktop {
            display: none;
        }

        .swiper {
            display: flex;

            //height: 60vh;
            .swiper-slide-box {
                width          : 50vw !important;
                height         : 24vh !important;
                padding        : 3px !important;
                flex-direction : column;
                justify-content: space-evenly;
                border         : none;
                text-align     : center;
                align-items    : center;
            }

            .swiper-inactive {
                display: none !important;

                &.card-pricing {
                    display: none !important;
                }

                &.popular {
                    display: none !important;
                }
            }

            .swiper-active {
                transform: scale(1.15) translateX(0.1vw);
                display  : flex !important;

                &.card-pricing {
                    display       : flex !important;
                    height        : 22rem;
                    flex-direction: column;
                    min-width     : 40rem;
                    position      : relative;
                    width         : 100vw;
                    margin        : 1rem;

                    .header-card {
                        display       : flex;
                        align-items   : center;
                        flex-direction: column;

                        h3 {
                            font-family: Hurme-Black;
                            font-size  : v.$h2Mob;
                            color      : #273572;
                        }

                        p {
                            font-family: Roboto;
                            color      : #6b6b6b;
                            font-size  : v.$pMobil;
                        }
                    }

                    .img-card {
                        position: relative;

                        img {
                            height: 7.5rem;
                        }

                        >p {
                            bottom        : 10px;
                            right         : 10px;
                            font-family   : Hurme-Bold;
                            font-size     : v.$h2Mob;
                            color         : #ffffffb2;
                            position      : absolute;
                            z-index       : 1;
                            text-transform: uppercase;
                            cursor        : default;
                        }
                    }

                    button {
                        background             : linear-gradient(90deg, #d7d6ff 0%, #abc7ff 24.48%, #615af6 50%, #abc7ff 73.44%, #d7d6ff 100%);
                        box-shadow             : 0px 4px 15px rgba(121, 121, 121, 0.25);
                        border-radius          : 4px;
                        position               : relative;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        border-radius          : 50px;
                        overflow               : hidden;

                        &:before {
                            background-color  : #d7d6ff;
                            content           : '';
                            position          : absolute;
                            width             : 9px;
                            height            : 27px;
                            filter            : blur(1px);
                            opacity           : 0;
                            -webkit-transition: none;
                            -moz-transition   : none;
                            -ms-transition    : none;
                            -o-transition     : none;
                            transition        : 0.7s;
                            transform         : translateX(-160px);
                        }

                        &:after {
                            background-color  : #d7d6ff;
                            content           : '';
                            position          : absolute;
                            width             : 9px;
                            height            : 27px;
                            filter            : blur(1px);
                            opacity           : 0;
                            -webkit-transition: none;
                            -moz-transition   : none;
                            -ms-transition    : none;
                            -o-transition     : none;
                            transition        : 0.7s;
                            transform         : translateX(160px);
                        }

                        &:hover {
                            color: #d7d6ff;

                            &:before {
                                transform: translate(120px, -4px);
                                opacity  : 0.6;
                            }

                            &:after {
                                transform: translate(-125px, -4px);
                                opacity  : 0.6;
                            }
                        }
                    }

                    .rounded-img {
                        height   : 80px;
                        min-width: 80px;

                        img {
                            max-width : 40px;
                            max-height: 55px;
                        }
                    }

                    .percentage {
                        position               : absolute;
                        top                    : 0;
                        width                  : 0;
                        right                  : 0px;
                        border-top-right-radius: 19px;
                        height                 : 0;
                        border-right           : 65px solid var(--blue-gulf);
                        border-bottom          : 50px solid transparent;
                        border-left            : 25px solid transparent;
                        border-top             : 20px solid var(--blue-gulf);

                        p {
                            font-family: Hurme-Black;
                            font-size  : v.$h2Mob;
                            color      : var(--main-white-color);
                            position   : absolute;
                            top        : 50%;
                            right      : -25px;
                            transform  : translate(60%, -60%);
                        }
                    }

                    button {
                        min-width  : 10.75rem;
                        font-size  : v.$medium-size;
                        line-height: normal;
                    }

                    h3 {
                        align-items: center;
                        font-size  : v.$pMobil;
                        margin-left: 0;

                        span {
                            font-size: v.$h2;
                        }
                    }

                    .part-pricing {
                        margin-bottom: 0.5rem;

                        p {
                            font-size  : v.$low-size-text;
                            margin-left: 0;
                            line-height: normal;
                            width      : 80%;
                            text-align : center;
                        }
                    }
                }

                .part-nb-vogz {
                    width : 100%;
                    height: auto;

                    h3 {
                        display         : flex;
                        flex-direction  : column;
                        font-family     : Hurme-Black;
                        align-items     : center;
                        width           : 100%;
                        font-size       : v.$h2Mob;
                        color           : var(--main-white-color);
                        background-color: var(--main-violet-color-text);
                        padding         : 2px 0;
                    }
                }

                &.popular {
                    display: flex !important;

                    .text-popular {
                        left : 10px;
                        right: auto;
                    }

                    .part-nb-vogz {
                        h3 {
                            color           : var(--main-violet-color-text) !important;
                            background-color: var(--main-white-color);
                        }
                    }
                }
            }

            .text-tab {
                align-items: center;
                margin-left: 0;

                h3 {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .swiper-btn-mobile {
            display: block !important;
            width  : 30px;
        }

        .swiper-btn-mobile-container {
            display        : flex !important;
            width          : 40%;
            justify-content: space-between;
            z-index        : 2;
        }

        & .swiper-btn {
            display: none;
        }

        & .swiper-btn-mobile {
            display: block !important;
            width  : 30px;
        }

        .swiper-btn-mobile-container {
            display        : flex;
            width          : 40%;
            justify-content: space-between;
            z-index        : 2;
        }

        & .swiper-navigation-bubble {
            width        : 8px;
            height       : 8px;
            margin       : 0 2px;
            border       : #d4ddff solid 1px;
            border-radius: 100%;
            transition   : 0.7s;
        }

        & .swiper-navigation-bubble__active {
            width     : 10px;
            height    : 10px;
            position  : relative;
            background: radial-gradient(#3a4161 30%, transparent 60%, #3a4161 20%);
            border    : none;
        }
    }
}

@include tablet2 {
    .step-new-4-bottom {
        display: none !important;
    }

    .step-new-4-bottom-mobile {
        width          : 100%;
        height         : 40%;
        justify-content: center;
        display        : flex !important;
        flex-direction : column;
    }

    .register-process {
        & .step-end-process {
            height: auto;
        }

        .step-3 {
            & .step-3-tab-desktop {
                display: none;
            }

            & .step-3-tab-mobile {
                display       : flex;
                flex-direction: column;
                padding       : 0;
                width         : 100%;
                min-width     : 0;
                padding-top   : 2rem;

                &>span {
                    border-radius: 70px;
                    padding      : 2px 10px;
                    left         : 40%;
                    top          : 10px;
                }

                & .absolute-tab {
                    right                     : 0;
                    border-top-right-radius   : 0;
                    border-bottom-right-radius: 0;

                    span {
                        height: 7.25rem;
                    }
                }

                & .step-3-element-tab {
                    align-items   : flex-start;
                    flex-direction: column;
                    height        : 7.25rem;
                    margin-right  : 12%;

                    .title-mobile {
                        width          : 100%;
                        justify-content: start;
                        margin-left    : 1rem;

                        img {
                            width : auto;
                            height: 30px;
                        }

                        h3 {
                            font-family   : Hurme-Black;
                            font-size     : v.$medium-size !important;
                            line-height   : normal;
                            text-transform: uppercase;
                            color         : var(--blue-gulf);

                            span {
                                color         : var(--main-blueberry-color);
                                text-transform: none;
                            }
                        }

                        hr {
                            transform: rotate(90deg);
                            width    : 20px;
                            border   : 0.5px solid #C7D3D9;
                        }
                    }

                    .text-tab {
                        p {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .end-process {
            h3 {
                font-size: v.$h2Mob;
            }

            p,
            button {
                font-size: v.$pMobil;
            }
        }
    }
}

@include mobile() {
    .register-process {
        padding-bottom: 5em;

        .swiper-inactive {
            opacity: 0;
        }

        .swiper-slide-custom {
            position     : absolute;
            bottom       : 1.4vh;
            height       : 18vh !important;
            width        : 30vh !important;
            border-radius: 1vw !important;
            cursor       : pointer;

            h1 {
                font-size: 1.7ch !important;
            }

            p {
                font-size: 0.85vh !important;
            }
        }

        .swiper-slide-box {
            position  : absolute;
            width     : 45vw !important;
            height    : 40vh !important;
            max-width : none;
            max-height: none;
            cursor    : pointer;

            img {
                border-radius: 2px !important;
            }

            h2 {
                font-size: 1.6vh !important;
            }

            p {
                font-size  : 1.1vh !important;
                line-height: 1.4em !important;
            }
        }

        & .btn-back-desktop {
            display: none;
        }

        & .btn-back-mobile {
            display  : block;
            transform: scale(2.5);
            color    : black;
        }

        .btn-next-step {
            margin-top: 1em;
        }

        & .step-1-wrapper {
            & .step-1-bg {
                transform: translateX(-2.5vw) translateY(1.5vh);
            }

            & .step-1-title {
                font-size    : 1.7ch !important;
                margin-bottom: 10px;
            }

            & .step-1-desc {
                margin        : 0 15%;
                font-size     : 1.25ch;
                line-height   : 1.2em;
                padding-bottom: 20px;
            }

            & .icon-wrapper {
                bottom   : 0;
                transform: translateY(4vh);
                padding  : 13px;

                & img {
                    transform: scale(0.6) translateX(0.4vw) !important;
                }
            }
        }

        & .step-2 {
            & .step-2-bottom {
                flex-direction: column !important;
                overflow      : visible;
            }

            & .step-2-bottom-child-mobile {
                height: auto;
            }
        }

        & .step-3 {
            .swiper {
                display: flex;
                height : 35vh;

                .swiper-slide-box {
                    width          : 50vw !important;
                    height         : 24vh !important;
                    padding        : 3px !important;
                    flex-direction : column;
                    justify-content: space-evenly;
                    border         : none;
                    text-align     : center;
                    align-items    : center;
                }

                .text-tab {
                    align-items: center;
                    margin-left: 0;

                    h3 {
                        margin-bottom: 0.5rem;
                    }
                }
            }

        }

        .header {
            display       : flex;
            flex-direction: column;
            padding       : 1rem;

            h2 {
                font-family   : Hurme-Black;
                font-size     : v.$h2Mob;
                text-align    : center;
                text-transform: uppercase;
                color         : var(--blue-gulf);
            }

            p {
                padding-top: 0.5rem;
                width      : 100%;
                text-align : center;
                font-family: Roboto;
                font-weight: 300;
                font-size  : v.$px-ts-md;
                color      : var(--blue-gulf);
            }
        }

        .step-6 {
            .swiper {
                height: 60vh !important;
            }
        }

        & .swiper-btn {
            display: none;
        }

        & .swiper-btn-mobile {
            display: block;
            width  : 30px;
        }

        .swiper-btn-mobile-container {
            display        : flex;
            width          : 40%;
            justify-content: space-between;
            z-index        : 2;
        }

        & .swiper {
            width : 100%;
            height: 60vh;
        }

        & .swiper-navigation-bubble {
            width        : 8px;
            height       : 8px;
            margin       : 0 2px;
            border       : #d4ddff solid 1px;
            border-radius: 100%;
            transition   : 0.7s;
        }

        & .swiper-navigation-bubble__active {
            width     : 10px;
            height    : 10px;
            position  : relative;
            background: radial-gradient(#3a4161 30%, transparent 60%, #3a4161 20%);
            border    : none;
        }
    }

    .swiper-mobile {
        height: 70vh;
    }

    .booking {
        .booking-card {
            width : 90vw;
            height: 75vh;

            .booking-header {
                h3 {
                    padding: 1rem;
                }
            }

            .booking-body {
                height         : 100%;
                display        : flex;
                flex-direction : column;
                align-items    : center;
                justify-content: center;

                p {
                    font-size  : v.$pMobil;
                    line-height: normal;
                }

                a {
                    font-size: v.$pMobil;
                }

                .img-booking {
                    padding-top   : 1rem;
                    padding-bottom: 1rem;
                }

                button {
                    min-width: 80vw;
                }
            }
        }
    }
}