@use '../../../../../sass/variables.scss'as variables;
@use '../../../../../sass/colors'as colors;
@import '../../../../../sass/partials/buttons';
@import '../../../../../sass/mixins';
@import '../../../../../sass/_mixins/global';
$body-card-width: 20rem;
$body-card-height: 23.5rem;
$medium-size-round: 3em;
.height-card{
    height: 614px;
    header{
        z-index: 0;
    }
}
.card-briefapplication {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .badge-red {
        position: absolute;
        bottom: 45px;
        cursor: pointer;
        @include badge("red");
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .5s;
        height: 20px;
        display: flex;
        align-items: center;
        z-index: 3;
        &:hover {
            background-color: darken(colors.$alert, 10%);
        }
    }
    & header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        & figure {
            margin: 0px 12px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            &.bought {
                margin: 0px !important;
            }
            & img {
                @include avatar;
            }
            & .badge-red-avatar {
                line-height: 10px;
                position: absolute;
                bottom: 0px;
                @include badge("red");
                padding: 2px 6px 2px;
                font-size: 10px;
            }
        }
        & h3 {
            font-family: Hurme-Black;
        }
        & .card-desc-user-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 1rem;
            opacity: .4;
            div:nth-of-type(1) {
                margin-bottom: .5rem;
                min-width: 115px;
                background-color: #F8F9FF;
                min-height: 18px ;
            }
            div:nth-of-type(2) {
                min-width: 85px;
                background-color: #D7E1FF;
                min-height: 14px ;
            }
            & button {
                @include outlined-btn;
                margin-top: 5px;
                //padding: 5px 12px;
                width: 60%;
                min-width: 112px;
                border-radius: 22px;
                cursor: pointer;
                font-family: Hurme-Bold;
                font-size: variables.$low-size-text;
            }
        }
    }
    & footer {
        position: relative;
        & .card-body {
            width: $body-card-width;
            height: $body-card-height;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            overflow: hidden;
            //margin-bottom: 24px;
            position: relative;
            & span {
                position: absolute;
                background-color: var(--main-white-color);
                padding: 3px 12px;
                margin-top: 16px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                color: colors.$blue-fair;
                font-size: variables.$medium-size-text;
                font-family: Hurme-Black;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            }
            & img:nth-of-type(1) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: $medium-size-round;
                height: $medium-size-round;
                cursor: pointer;
            }
            & div {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: #F3F6FF;
                opacity: 0.3;
            }
        }
        & button {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            width: 100%;
            font-family: Hurme-Black;
            z-index: 4;
            overflow: hidden;
            opacity: 0.3;
            background-color: #5777FF !important;
            color: var(--main-white-color) !important;
        }
        .sub-footer{
            margin-top: 1rem;
            width: 20rem;
            border: 1px solid var(--main-blue-navy);
            box-shadow: 0px 4px 30px rgba(185, 185, 185, 0.25);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: fixed;
            background-color: var(--main-white-color);
            z-index: 2;
            .header{
                height: 60px;
                display: flex;
                width: 100%;
                flex-direction: column;
                text-align: center;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                justify-content: center;
                p{
                    font-family: Roboto;
                    font-size: variables.$pMobil;
                    color: var(--main-blue-navy);
                }
            }
            p{
                font-family: Roboto-Regular;
                font-size: variables.$pMobil;
                color: var(--main-grey-color-text);
                line-height: normal;
            }
            h3{
                font-family: Hurme-Bold;
                font-size: variables.$pMobil;
                color: var(--main-blue-navy);
                margin-left: .5rem;
            }
            .fair-green-background {
                background-color: var(--my-castings-real-fair-green);
            }
            .alice-blue-background {
                background-color: var(--my-castings-alice-blue);
            }  
            .medium-champagne-background {
                background-color: var(--my-castings-medium-champagne);
            }
        }
    }
}