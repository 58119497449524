@use "../../../sass/colors" as colors;
@use "../../../sass/variables.scss"as v;
@import '../../../sass/partials/_buttons';
@import "../../../sass/mixins";
@import "../../../sass/_mixins/global";


.favorites-page{
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    .brand-switcher {
        // position: static;
        top: 20px;
        justify-content: end;
        margin-right: 0rem;
    }
}
.all-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .p-horizontal {
        padding: 0 10%;
    }
    
    & .header {
        position: sticky;
        display: flex;
        width: 100%;
        flex-direction: column;
        top: 0;
        z-index: 10;
        background: rgba(255, 255, 255, 0.73);
        backdrop-filter: blur(15px);
        padding-top: 2rem;

        & .search-bar-wrapper {
            display: flex;
            max-width: 95%;
            justify-content: flex-end;
            & .search-bar {
                width: 380px !important;
                max-width: 90vw;
                background: #F9FAFF;
            }
        }

        & .filter-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-bottom: 10px;
            // overflow-x: scroll;
            // overflow-y: visible;

            & .input-filter{
                max-width: 180px;
                display: flex;
                > div{
                    min-width: 0;
                    position: relative;
                    margin-top: 10px;
                    &::after{
                        content: "€";
                        display: flex;
                        color: var(--main-blue-color-bg);
                        position: absolute;
                        height: 100%;
                        right: 10px;
                        align-items: center;
                        justify-content: flex-end;
                        height: 40px;
                        font-family: 'Hurme-Bold';
                        cursor: text !important; 
                    }
                }
                & input{
                    display: flex;
                    font-family: Hurme-Bold;
                    color: var(--main-blue-color-bg);
                    background: white;
                    transition: 0.15s ease-in-out;
                    min-width: 180px;
                    height: 40px;
                    font-size: v.$pMobil;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 13px;
                    border: solid 1.5px var(--main-blue-color-bg);
                    border-radius: 5px;
                    &::placeholder{
                        color: var(--main-blue-color-bg);
                        font-family: Hurme-Bold;
                    }
                }
                
            }
            &::-webkit-scrollbar {
                height: 5px;
            }
            &::-webkit-scrollbar-track {
            background: colors.$new-blue-fair; 
            border-radius: 100px;
            }

            &::-webkit-scrollbar-thumb {
            background: #D1D8F2;
            border-radius: 100px;
            }
            &::-webkit-scrollbar-thumb:active {
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
            }

            & button {
                display: flex;
                flex-shrink: 0;
                border: solid 1px colors.$blue-fair;
                border-radius: 100px;
                padding: 0.2rem 1.5rem;
                margin-right: 1rem;
                font-family: Hurme-Bold;
                font-size: 0.85rem;
                color: colors.$blue-fair;
                background: white;
                transition: 0.15s ease-in-out;
            }
            & .selected-filter {
                background: colors.$blue-fair;
                color: white;
            }
        }
    }
    & .section-favourite{
        display: flex;
        justify-content: center;
        overflow-anchor: none;

        & .div-favourite{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1600px;
            justify-content: center;
        } 
    }
}

@include tablet() {
    .all-body{
        & .p-horizontal {
            padding: 0 3%;
        }
        & .div-favourite{
            grid-template-columns : repeat(2, 1fr) !important ;
        }
        .btn-tri {
            right: 1rem !important;
            top  : auto;
            bottom: -70%;
            & .dropdown-menu {
                right     : 0rem;
                margin-top: 0;
            }
        }
        & .header {
            & .search-bar-wrapper {
                width: 100%;
                justify-content: center;
            }
        }
    }
}

@include mobile() {
    .favorites-page {
        & .brand-switcher {
            top: 0;
        }
    }
    .all-body{
        & .p-horizontal {
            padding: 0 2%;
        }
        & .header{
            position: initial;
            margin-top: 0;
        }
        & .div-favourite{
            grid-template-columns : repeat(1, 1fr) !important;
        }
        .btn-tri {
            top  : auto;
            bottom: -40px;
        }
        & .sub-header{
            margin-bottom: 4rem !important;
        }
    }
}