@use "../_colors.scss"as colors;
@use "../variables.scss"as variables;
@import "../others/animations.scss";
@import "./themes.scss";
@mixin badge($kind:"red") {
    @if($kind=="red") {
        font-size: variables.$pMobil * .8;
        font-family: Hurme-Bold;
        color: var(--main-white-color);
        background-color: colors.$alert;
        padding: 0px 8px;
        border-radius: 18px;
    }
}