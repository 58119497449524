@use '../../../../sass/variables' as v;
@import '../../../../sass/mixins';

.modal-enroll {
  transition: all 0.1s !important;
  position: relative;

  .child-modal-enroll {
    display: flex !important;
    width: 420px;
    height: 530px;
    flex-direction: column;
    align-items: center;
    background: var(--main-white-color);
    border-radius: 25px;
    box-shadow: 0px 10px 50px #D6D6D6;
    pointer-events: all;
    .header-modal {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      padding: 15px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background: linear-gradient(90deg, #FFDDAA 1.23%, #DD901D 50.1%, #D54214 100%);

      span{
        font-family: Hurme-Bold;
        font-size: calc(v.$h1 * 2);
        text-align: center;
        padding: 10px 0;
      }
      .close-btn {
        right: 25px;
        top: 20px;
        position: absolute;
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: var(--main-white-color);
        border: solid 1px var(--main-white-color);
        border-radius: 100%;
      }
    }
  
    .main-content {
      width: 80%;
      padding: 10px 0;
      padding: 15px;
      overflow: auto;
      h3 {
        font-family: Hurme-Bold;
        text-align: center;
        padding: 0 20px;
        color: var(--main-grey-color-text);
      }
  
      .desc {
        text-align: center;
        margin-top: 10px;
        padding: 0 10px;
        line-height: 19.53px;
        font-size: 15px;
        margin: auto;
        margin-top: 10px;
        font-family: Roboto;
        color: var(--main-grey-color-text);
        font-weight: 400;
      }
      .enroll-list{
        margin: 2rem 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .enroll-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 1rem;
          .enroll-item__name{
            font-family: Hurme-Bold;
            font-size: v.$h2Mob;
            color: var(--main-dark-color-text);
            margin: 0.5rem 0;
          }
          .enroll-item__info{
            background-color: var(--main-white-color);
            border: 1px solid #5777FF;
            color: var(--main-blue-color-bg);
            margin-top: 5px;
            width: 60%;
            min-width: 112px;
            border-radius: 22px;
            cursor: pointer;
            font-family: Hurme-Bold;
            font-size: 0.7rem;
            text-align: center;
            padding: 3px 0;
          }
        }
      }
    
    }
  }
}

@include mobile() {
  .modal-enroll {
    .child-modal-enroll {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 95%;
      max-width: 420px;
      margin: auto;
  
      .main-content {
        width: 100%;
        max-width: 300px;
  
        .desc {
          padding: 0;
        }
        .enroll-item{
          margin: .5rem !important;
        }
      }
    }
  }
}

@keyframes pulse-animation {
  10% {
    font-size: 35px;
  }
  18% {
    font-size: 38px;
  }
  26% {
    font-size: 35px;
  }
}

@keyframes pulse-animation-fast {
  0% {
    font-size: 35px;
  }
  50% {
    font-size: 38px;
  }
  100% {
    font-size: 35px;
  }
}