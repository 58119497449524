@use '../../../../sass/variables.scss'as v;
.disabled-link {
  pointer-events: none;
}
.header-table{
  font-family: Hurme-Bold !important;
  display: flex;
  justify-content: space-between;
}
.moderation-overlay {
  position: absolute;
  border-radius: 10px 10px 0 0;
  left: 1.6%;
  right: 0.33%;
  width: 310px;
  height: 570px;
  background: #000000;
  opacity: 0.5;
  z-index: 1;
}

.castings-body {
  min-height: 100%;
  margin-top: 2em;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5em;
}

.w80 {
  width: 80% !important;
}

.w60 {
  width: 60% !important;
}

.wf40 {
  width: 40em !important;
}

.wf40 {
  width: 40em !important;
}

.wf60 {
  width: 60em !important;
}

.wf80 {
  width: 80em !important;
}

.search-bar-casting {
  width: 27rem;
  height: 2.5rem;
  position: relative;
}

.search-bar-casting > input {
  border: 1px solid var(--main-blue-color-bg);
  height: 100%;
  padding-inline: 42px;
  width: 100%;
  border-radius: 12px;
}

.search-bar-casting > input:focus {
  outline: none;
}

.search-bar-casting > input::placeholder,
.search-bar-casting > .fa-search {
  color: var(--main-blue-color-bg);
}

#search-castings:focus + .search-bar-casting {
  border: 1px solid var(--main-blue-color-bg);
}

.search-bar-casting > .fa-search {
  font-size: 12px;
  position: absolute;
  left: 18px;
  top: calc(50% - 0.5em);
}

.castings-body .castings-body-content {
  margin-top: 2em;
  width: 100%;
}

.castings-body .castings-body-content .types-castings-filter {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  padding-inline: 2em;
}

.castings-body .castings-body-content .types-castings-filter .types-castings {
  display: flex;
  flex-wrap: wrap;
}

.castings-body
  .castings-body-content
  .types-castings-filter
  .types-castings
  > .types-casting {
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 40px;
  cursor: pointer;
  font-family: Hurme;
  color: var(--my-castings-thick-blue);
  transition: all 0.2s ease-in;
}

.castings-body
  .castings-body-content
  .types-castings-filter
  .types-castings
  > .types-casting.active {
  color: var(--main-white-color);
  background-color: var(--main-blue-color-bg);
}

.castings-body
  .castings-body-content
  .types-castings-filter
  > .filters-casting {
  width: 8em;
}

.filters-casting .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  border-radius: 8px !important;
}

/* grid cards castings */

.card-casting {
  //border: 5px solid white;
  background-color: var(--main-white-color);
  width: 320px;
  /* padding      : 1rem; */
  max-height: 37rem;
  border-radius: 20px;
  border-radius: 12px;
  /* overflow     : hidden; */
  box-shadow: 0px 4px 12px #f0f0f0;
  transition: all 0.3s ease 0s;
  min-height: 34em;
  overflow: hidden;
  position: relative;
  .card-casting-header-setting-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    transition: 0.2s all ease-in-out;
    z-index: 5;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 6px;
    > i {
      top: -45px;
      position: absolute;
      left: -23px;
      color: var(--blue-v1);
      &.fa-xmark {
        top: -46px;
        position: absolute;
        left: -18px;
      }
    }
    &:hover {
      // border-top-color: var(--blue-v1);
      // opacity: .9;
      > i {
        &.fa-gear {
          -webkit-animation: rotate 0.2s forwards;
          -moz-animation: rotate 0.2s forwards;
          animation: rotate 0.2s forwards;
        }
      }
    }
  }
  .card-casting-header-delete-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    transition: 0.2s all ease-in-out;
    z-index: 2;
    transition: 1s;
  }

  .card-casting-header-delete-btn:hover i {
    transform: scale(1.15);
  }
}

.card-casting:hover {
  transform: translateY(-7px);
}

.cards-casting {
  /* max-width: 1122px; */
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 3em 5vw;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  position: relative;
}

.cards-casting-container {
  display: flex;
  padding-inline: 40px;
}

.card-casting .card-casting-header {
  height: 50%;
  padding: 5px;
}

.card-casting .card-casting-header .status-card {
  position: absolute;
  font-family: 'Hurme-Semi-Bold';
  font-size: 12px;
  padding: 0px 12px;
  border-radius: 18px;
  margin: 8px;
  z-index: 2;
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  &.status-card-active {
    background: rgba(137, 224, 106, 0.85);
    color: white;
  }
  &.status-card-created {
    background: rgba(239, 239, 239, 0.85);
    color: var(--my-castings-card-grey-color-text);
  }
  &.status-card-closed {
    background: rgba(236, 240, 255, 0.85);
    color: var(--main-blue-color-bg);
  }
  &.status-card-step2 {
    background: rgba(137, 224, 106, 0.85);
    color: white;
  }
  &.status-card-to-validate {
    background: #eb9d29;
    color: white;
  }
}

/* .card-casting .card-casting-header span{
     border: 1px solid rgba( 255, 255, 255, 0.18 ); 
} 
*/

.card-casting .card-casting-header .illustration-card-casting {
  height: 100%;
  min-width: 100%;
  justify-content: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  overflow: hidden;
}

.card-casting .card-casting-header .illustration-card-casting img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-casting .card-casting-body {
  height: 50%;
  display: flex;
  flex-direction: column;
}

.card-casting .card-casting-body-detail-vogz {
  height: initial;
}

.card-casting .card-casting-body .top-part-card-casting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 12px;
}

.type-card-casting {
  font-family: 'HurmeBold';
  font-size: 12px;
  color: white;
  padding: 0px 12px;
  background-color: var(--my-castings-fair-green);
  border-radius: 6px;
  /* margin-top   : 8px; */
  line-height: 22px;
}

.card-casting .card-casting-body .top-part-card-casting .date-card-casting {
  font-size: 13px;
  color: var(--my-castings-blue-sky);
  font-family: 'Hurme-Bold';
  margin: 5px 0px;
}

/* acting type color */

.type-acting {
  background-color: var(--types-castings-color-acting);
}

/* creativity type color */

.type-creativity {
  background-color: var(--types-castings-color-creativity);
}

/* test product type color */

.type-test-product {
  background-color: var(--types-castings-color-test-product);
}

/* testimony type color */

.type-testimony {
  background-color: var(--types-castings-color-testimony);
}

/* choregraphy type color */

.type-choregraphy {
  background-color: var(--types-castings-color-choregraphy);
}

.card-casting .card-casting-body .top-part-card-casting .title-card-casting {
  font-size: 15px;
  font-family: 'Hurme-Bold';
  text-align: center;
}

.card-casting
  .card-casting-body
  .top-part-card-casting
  .desc-video-card-casting {
  font-size: 12px;
  text-align: center;
  color: var(--main-grey-color-text);
  line-height: 16px;
}

.card-casting .card-casting-body > hr {
  border-top: 0.6px solid var(--my-castings-grey-color-text);
  border-bottom: 0;
}

.card-casting .card-casting-body .bottom-part-card-casting {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px 5px;
  hr {
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgb(138 138 138 / 75%),
      rgba(255, 255, 255, 0)
    );
  }
  .center {
    justify-content: center !important;
  }
}

.card-casting
  .card-casting-body
  .bottom-part-card-casting
  .date-part-bottom-card-casting {
  display: flex;
  justify-content: space-between;
  color: var(--main-grey-color-text);
  padding-inline: 2em;
  margin: 0.7em 0;
}

.card-casting
  .card-casting-body
  .bottom-part-card-casting
  .date-part-bottom-card-casting
  .date-bottom-card-casting {
  font-size: 11px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-casting
  .card-casting-body
  .bottom-part-card-casting
  .date-part-bottom-card-casting
  .date-bottom-card-casting
  .date-text-card-casting {
  color: var(--main-grey-color-text);
  font-size: v.$low-size-text;
  font-family: roboto;
}

.card-casting
  .card-casting-body
  .bottom-part-card-casting
  .date-part-bottom-card-casting
  .date-bottom-card-casting
  .date-date-card-casting {
  font-size: 15px;
  font-family: 'Hurme-Bold';
  color: var(--main-dark-color);
}

.card-casting .card-casting-body .bottom-part-card-casting {
  .button-discover-vogz {
    background-color: var(--main-blue-color-text);
    padding: 7px 0px;
    text-align: center;
    font-family: Hurme-Bold;
    color: var(--main-white-color);
    font-size: 14px;
    text-decoration: none;
    border-radius: 0 0 12px 12px;
    transition: all 0.3s ease-in-out 0s;
    z-index: 3;
    &:hover {
      background-color: var(--my-castings-grey-color-text);
      color: var(--main-blue-color-text);
    }
  }
  .button-edit-casting {
    background-color: #ccd7ff;
    padding: 7px 0px;
    text-align: center;
    font-family: Hurme-Bold;
    color: var(--dashboard-blue-launch-casting);
    font-size: 14px;
    text-decoration: none;
    border-radius: 0 0 12px 12px;
    z-index: 1;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: var(--dashboard-blue-launch-casting);
      color: var(--main-grey-color-text);
    }
  }
  &.button-discover-vogz-client {
    background: linear-gradient(
      90.91deg,
      var(--gradient-blue-client) 0%,
      var(--gradient-blue-green) 100%
    );
  }
  &.button-discover-vogz-private {
    background-color: var(--home-violet-color-bg);
  }
}

// .tooltip-castings {
//   position: absolute;
//   background-color: var(--main-blue-fair-color-bg);
//   font-family: Hurme-Bold;
//   padding: 1em;
//   border-radius: 10px;
//   z-index: 5;
//   color: var(--main-violet-color-text);
//   transform: translateX(15px);
//   display: hidden;
// }

.button-disabled {
  background-color: var(--main-grey-placeholder-color-text) !important;
  color: var(--main-grey-color-text);
  cursor: not-allowed;
  pointer-events: none;
}

[contentEditable='true'] {
  /* border:1px solid grey;   */
}

[contentEditable='true']:empty:not(:focus):before {
  content: attr(placeholder);
  color: grey;
  font-size: 12px;
}

// overlays
.overlay-coloured {
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border-radius: 8px;
  position: absolute;
  backdrop-filter: blur(20px);
  z-index: 4;
  &.main-blue {
    background: rgba(140, 151, 189, 0.5);
  }
  &.main-black {
    background: rgba(0, 0, 0, 0.7);
  }
}

@media only screen and (max-width: 1058px) {
  .castings-body
    .castings-body-content
    .grid-cards-castings
    .card-casting-vogz {
    grid-column-end: span 12;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cards-casting {
    /* grid-template-columns: repeat(2, 1fr);  */
    display: flex;
    grid-gap: 2em 3rem;
    flex-direction: column;
  }
  .card-casting {
    max-width: 450px;
  }
}

@media (min-width: 1058px) {
  .cards-casting {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-casting {
    max-width: 18em;
    /* width : 22vw; */
  }
}

@media only screen and (max-width: 880px) {
  .search-bar-casting {
    width: 70%;
    height: 2.3rem;
  }
  .search-bar-casting > input {
    padding-right: 0;
    padding-left: 28px;
  }
  .search-bar-casting > .fa-search {
    left: 12px;
    top: calc(50% - 5px);
  }
  .ui.dropdown {
    display: inline-flex !important;
    align-items: center !important;
  }
  .castings-body .castings-body-content .grid-cards-castings {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    /* grid-gap          : 20px; */
  }
  .castings-body
    .castings-body-content
    .grid-cards-castings
    .card-casting-vogz {
    grid-column-end: span 6;
    /* width       : 70%; */
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  .card-casting .card-casting-header .illustration-card-casting {
    min-width: 0px;
  }
  .cards-casting-container {
    display: flex;
    padding-inline: 10px;
  }
}

/* Screen larger than 900px? 3 columns */

@media (min-width: 1477px) {
  .cards-casting {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 880px) {
  .open .card-content {
    flex-direction: column;
  }
  .content-container {
    width: 100%;
  }
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.card {
  position: relative;
  padding: 25px;
  height: 460px;
  flex: 0 0 40%;
  max-width: 40%;
}

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  /* overflow: scroll; */
}

.card-content-container.open {
  bottom: 0;
  width: 91%;
  right: 0;
  height: 95%;
  display: flex;
  position: fixed;
  margin: 0 auto;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.open .card-content {
  height: 100%;
  max-width: 98%;
  /* overflow-y   : scroll; */
  background-color: rgb(250 250 250 / 93%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(19px);
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-video-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex: 1;
  /* right        : 0; */
  flex-direction: column;
  background-color: var(--dashboard-blue-launch-casting);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(80deg);
  }
}

.card-video-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex: 1;
  /* right        : 0; */
  flex-direction: column;
  background-color: var(--dashboard-blue-launch-casting);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(80deg);
  }
}

.delete-draft-btn {
  align-items: center;
  background-color: #cd0000;
  color: var(--main-white-color);
  font-size: 1.2ch;
  padding: 4px 10px 4px 10px;
  border-radius: 2em;
}

.img-scope-card-casting-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0;
  bottom: 0;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
    background: white;
    border-radius: 100%;
    padding: 3px;

    img {
      height: 99%;
      width: 99%;
      border-radius: 100%;
    }

    &:nth-child(1) {
      img {
        padding: 15px;
        object-fit: contain;
        filter: brightness(120%);
      }
    }

    &:nth-child(2) {
      margin-left: 10%;
      img {
        object-fit: cover;
        background: white;
      }
    }
  }
}

.fair-green-background {
  background-color: var(--my-castings-real-fair-green);
}

.alice-blue-background {
  background-color: var(--my-castings-alice-blue);
}

.medium-champagne-background {
  background-color: var(--my-castings-medium-champagne);
}
