@use "../../../../../sass/colors" as colors;
@use "../../../../../sass/variables.scss"as v;
@import '../../../../../sass/partials/_buttons';
@import '../../../../../sass/mixins';
@import '../../../../../sass/_mixins/global';

.card-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
  padding: 1rem;
  width: 100%;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: var(--my-castings-fair-blue) !important;
  }
  &.current-chat {
    background-color: var(--my-castings-fair-blue);
    &:hover {
      background-color: transparent !important;
    }
  }
  .profil-info {
    margin-left: 1rem;
    width: 100%;
    h3 {
      font-family: Hurme-Bold;
      font-size: v.$h2;
      button {
        font-size: v.$medium-size-text;
      }
    }
    & p:nth-of-type(1) {
      font-size: v.$medium-size-text;
      font-family: Roboto;
      font-weight: 700;
      color: var(--main-blue-color-bg);
      &.sender-you {
        font-weight: 400;
        color: var(--main-grey-color-text);
        span {
          font-weight: 700;
        }
      }
    }
    & p:nth-of-type(2) {
      font-size: v.$px-ts-sm;
      font-family: Roboto;
      font-weight: 400;
      color: var(--main-grey-text);
    }
  }
}
