@use "../../../sass/variables.scss"as v;
@import '../../../sass/partials/_buttons';
@import '../../../sass/mixins';
@import '../../../sass/_mixins/global';
.purchases-page {
    height: inherit;
    width: inherit;
    display: flex;
}

.all-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    .sub-header {
        width: 100%;
        justify-content: center;
        display: flex;
        background: rgba(174, 147, 226, 0.5);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        .sub-header-scroll {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            button {
                min-width: 160px;
                margin: .75rem;
            }
        }
    }
    .header {
        position: sticky;
        z-index: 4;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px);
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: sticky;
        top: 0px;
        padding: 1rem 1rem 0 1rem;
        & .head-header {
            text-align: center;
            display: flex;
            width: 100%;
            flex-direction: row;
            max-width: 1000px;
            align-items: center;
            position: relative;
            justify-content: center;
            & p {
                font-family: Roboto;
                font-size: v.$p;
                color: var(--main-grey-color-text);
            }
            .btns-filter {
                position: absolute;
                right: 15px;
                &:nth-child(1) {
                    justify-content: center;
                    display: flex;
                    position: absolute;
                }
            }
        }
        .head-bar-vogz {
            position: relative;
            display: flex;
            margin: 50px 0 20px 0;
            gap: 40px;
            .btn-btn_filter {
                transition: all 0.2s ease-in;
                border-radius: 10px;
                border: 1px solid transparent;
                background-color: var(--dashboard-blue-launch-casting);
                color: var(--main-blue-color-bg);
                font-family: Hurme-Semi-Bold;
                font-size: 0.83em;
                /* min-width    : 198px; */
                width: max-content;
                gap: 18px;
                align-items: center;
                justify-content: space-between;
                display: flex;
                display: flex;
                padding: 9px 18px;
                cursor: pointer;
                >span {
                    height: 17px;
                    background-color: var(--main-blue-color-bg);
                    display: block;
                    border-color: transparent;
                    width: .95px;
                }
                &.active {
                    border-color: var(--main-blue-color-bg);
                    background-color: var(--main-white-color);
                    box-shadow: 0px 4px 15px #F0F0F0;
                }
                &:hover {
                    border-color: var(--main-blue-color-bg);
                    background-color: var(--main-white-color);
                    box-shadow: 0px 4px 15px #F0F0F0;
                }
            }
            .btn-btn_filter_ {
                background-color: #9065E4;
                border-radius: 12px;
                border-radius: 50px;
                display: flex;
                font-family: Hurme-Bold;
                font-size: v.$px-ts-md +1;
                color: var(--main-white-color);
                padding: 6px 20px;
                min-width: 140px;
                align-items: center;
                justify-content: space-between;
                border-color: #9065E4;
                border-width: 1px;
                width: fit-content;
                align-self: end;
                >span {
                    height: 17px;
                    background-color: var(--main-white-color);
                    display: block;
                    border-color: transparent;
                    width: .95px;
                }
                &:hover {
                    color: #9065E4;
                    background-color: var(--main-white-color);
                    box-shadow: 0px 4px 15px #F0F0F0;
                }
            }
        }
    }
    // & .section-favourite{
    //     width           : 100%;
    //     background-color: var(--my-castings-fair-blue);
    //     padding         : 2.5rem 0;
    //     display         : flex;
    //     justify-content : center;
    // }
    // .div-favourite{
    //     max-width               : 1440px;
    //     display                 : grid;
    //     grid-template-columns   : repeat(4, 1fr);
    //     column-gap              : 2rem;
    //     row-gap                 : 3rem;
    //     box-sizing              : border-box;
    //     justify-items           : center;
    //     margin: 1rem;
    // }
    & .section-search-user {
        max-width: 1440px;
        text-align: center;
        margin-top: 3rem;
        flex-direction: column;
        margin-bottom: 3rem;
        width: 100%;
        & .header-second-section {
            display: flex;
            align-items: center;
            flex-direction: column;
            & h2 {
                font-size: calc(v.$h1/1.3);
                font-family: Hurme-Black;
            }
            & p {
                font-family: Roboto;
                font-size: v.$p;
                color: var(--main-grey-color-text);
            }
        }
        & .sub-header {
            position: relative;
            margin: 2rem;
            & .btn-tri {
                position: absolute;
                right: 2rem;
            }
        }
    }
    .body {
        display: grid;
        margin: 2rem;
        gap: 2rem;
        max-width: 1440px;
        display: grid;
        grid-gap: 3em 5vw;
        grid-template-columns: repeat(3, 1fr) !important;
        .msg-loading {
            position: absolute;
            backdrop-filter: blur(10px);
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            top: 0px;
            left: 0px;
            text-align: center;
            justify-content: center;
            h3 {
                font-family: Hurme-Regular;
                font-size: v.$h2;
                color: #3A4161;
                flex-direction: column;
                align-items: center;
                display: flex;
                span {
                    font-family: Hurme-Black;
                    text-transform: uppercase;
                    font-size: v.$h1;
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .all-body {
        .header {
            .head-bar-vogz {
                gap: 10px
            }
            .tooltip_btn {
                margin-bottom: 1rem;
                left: 0;
                top: 229px;
            }
        }
        .sub-header {
            //justify-content: start !important;
        }
        & .body {
            grid-template-columns: repeat(3, 1fr) !important;
        }
    }
}

@include miniPc() {
    .all-body {
        & .head-header {
            // margin-top: 5rem !important;
        }
        & .body {
            grid-template-columns: repeat(2, 1fr) !important;
        }
    }
}

@include tablet() {
    .all-body {
        .head-header {
            .btns-filter {
                top: 50px;
            }
        }
        & .body {
            grid-template-columns: repeat(1, 1fr) !important;
        }
        .btn-tri {
            right: 1rem !important;
            top: auto;
            bottom: -70%;
            & .dropdown-menu {
                right: 0rem;
                margin-top: 0;
            }
        }
    }
}

@include tablet2() {
    .all-body {
        .head-header {
            .btns-filter {
                top: 50px;
            }
        }
        & .body {
            grid-template-columns: repeat(1, 1fr) !important;
        }
        .btn-tri {
            right: 1rem !important;
            top: auto;
            bottom: -70%;
            & .dropdown-menu {
                right: 0rem;
                margin-top: 0;
            }
        }
    }
}

@include mobile() {
    .all-body {
        margin-top: 0px;
        padding-bottom: 10em;
        .sub-header {
            .sub-header-scroll {
                flex-direction: column;
            }
        }
        .header {
            position: relative !important;
            top: unset;
            & .head-header {
                //margin-top: 3rem !important;
                .btns-filter {
                    top: 50px;
                }
            }
        }
        .btn-tri {
            top: auto;
            bottom: -40px;
        }
        & .sub-header {
            //margin-bottom: 4rem !important;
        }
    }
}