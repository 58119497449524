@use "../../../../sass/colors" as colors;
@use "../../../../sass/variables.scss" as variables;
@import '../../../../sass/partials/_buttons';
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
@import "../../../../sass/_mixins/animated-shine.scss";
$width-bar-messages: 420px;
$avtr-sze : 7rem;

.rate-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include tablet() {
        .modal {
            width: 90% !important;

            .rate-content {
                padding: 0px 10px !important;
            }

            .comment-field {
                textarea {
                    width: 100% !important;
                }
            }
        }
    }

    .modal {
        max-width: 600px;
        width: 100%;
        background-color: var(--main-white-color);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        gap: 2rem;
        box-shadow: 0px 4px 15px #F0F0F0;

        .rate-header {
            width: 100%;
            height: 100%;
            min-height: 120px;
            background-color: #FFE7B7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px 10px 0 0;

            span {
                font-size: 4rem;
                margin: 1rem;
            }

            i {
                position: absolute;
                top: 1rem;
                right: 1rem;
                cursor: pointer;
                padding: .5rem 11px;
                border-radius: 100%;
                transition: .2s ease-in-out;

                &:hover {
                    background-color: #39383840;
                }
            }
        }

        .rate-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                font-family: Hurme-Bold;
                font-size: 20px;
            }

            p {
                font-size: 16px;
                color: var(--main-grey-color-text);
            }
        }

        .rating-stars {
            width: 100%;

            .ratings {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                h3 {
                    font-family: Hurme-Bold;
                    font-size: 16px;
                    color: #131153;
                }

                @include tablet() {
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }

        .comment-field {
            justify-content: center;
            align-items: flex-start;
            margin: auto;
            width: 80%;

            label {
                font-family: Hurme-Bold;
                font-size: 16px;
                color: #131153;
                margin-left: 10px;
            }

            textarea {
                max-height: 200px;
                padding: 1rem;
                border-radius: 10px;
                width: 100%;
                background-color: #F6F9FF;
                resize: none;
            }
        }
    }
}

.all-page {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 1276px) {
        .info-profil-name h3 {
            text-align: center;
        }
    }

    &.not-connected {
        padding-top: 80px;
    }

    & .header-page {
        position: relative;
        left: 0;
        right: 0;
        width: 100%;
        // max-width: 1050px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 10px 20px;

        & button.step2-btn {
            background-color: colors.$lavender-blue;
            border: 1px solid var(--main-white-color);
            border-radius: 12px;
            cursor: pointer;
            box-shadow: 0px 4px 10px #F0F0F0;
            font-family: Hurme-Black;
            color: var(--main-white-color);
            transition: .2s ease-in-out;
            padding: 8px 16px;
            font-size: variables.$p;
            line-height: normal;

            &.active {
                background-color: colors.$blue-dark;
            }

            &:hover {

                background-color: colors.$perano-blue;
                box-shadow: 0px 3px 20px colors.$perano-blue
            }
        }

        .btn-favourite {
            position: absolute;
            top: 25px;
            right: 7em;
            cursor: pointer;
            z-index: 11;
            display: flex;
        }
    }

    & .blue-little-btn {
        @include little-btn(colors.$main-blue);
        border-radius: 50px;
        font-size: variables.$p !important;
        line-height: normal;
    }

    & .outlined-btn {
        @include outlined-btn
    }

    .profile {
        display: flex;
        width: 100%;
        justify-content: center;
        position: relative;
    }

    .offers-container {
        padding: 10px 40px;
        margin-bottom: 40px;
        width: 100% !important;
        max-width: 1050px;

        h1 {
            font-family: Hurme-Bold;
            font-size: 30px;
            line-height: normal;
            margin-bottom: 20px;
            text-align: center;
        }

        .offers {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
        }

        .btn-show-more {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px auto;
        }

        @include mobile {
            padding: 20px;
        }
    }

    .user-reviews-wrapper {
        width: 100%;
        max-width: 1050px;
        padding: 10px 40px;
        margin-bottom: 3rem;

        @include mobile {
            padding: 20px;
        }

        h1 {
            font-family: Hurme-Bold;
            font-size: 30px;
            line-height: normal;
            margin-bottom: 20px;
            text-align: center;
        }

        .user-reviews {
            .overall-section {
                .overall-section__top {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;

                    @include mobile() {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .overall-section__top__evals {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;

                        .numbers {
                            color: var(--main-grey-color-text);
                            font-weight: 600;
                        }

                        .count {
                            font-family: Hurme-Bold;
                            font-size: 16px;
                            color: var(--main-blue-color-bg);
                        }
                    }

                    .overall-section__top__button {
                        >button {
                            text-transform: capitalize;
                            width: 100%;
                            border-radius: 5px;
                            background-color: #FFECA7;
                            color: #000;
                            font-size: 16px;
                            font-family: Hurme-Bold;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px;
                            text-align: center;
                            border: none;
                            outline: none;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                background-color: #FFD700;
                            }
                        }
                    }
                }

                .overall-section__bottom {
                    padding: 2rem;
                    margin: 2rem auto;
                    background-color: #FAFAFF;
                    border-radius: 10px;
                }

            }

            .show-more-reviews {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 40px auto;
            }
        }
    }

    & aside {
        height: 100%;

        & .sticky {
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100% !important;
            position: sticky;
            top: 0;
            flex-direction: column;
            align-items: center;
            display: flex;
            z-index: 30;
        }
    }

    & .tip-container {
        border-radius: 12px;
        background-color: #FFFFFF !important;
        box-shadow: 0px 4px 15px darken($color: colors.$white, $amount: 10);
        height: 189px;
        width: 200px;
        padding: 12px;
        z-index: 1000;
        opacity: 1 !important;
        visibility: revert;
        overflow: scroll;

        &:before {
            content: "\A";
            border-style: solid;
            border-width: 10px 15px 10px 0;
            border-color: transparent white transparent transparent;
            position: absolute;
            top: -8px;
            left: 50%;
            transform: rotate(90deg);
            margin-left: -10px;
        }

        & .tip-content {
            display: flex;
            background-color: #FFFFFF !important;
            box-shadow: 0px 2px 6px #F5F5F5;
            border-radius: 2px;
            align-items: center;
            // width             : fit-content;
            // justify-content: center;
            padding: 5px 20px;
            width: 100%;
            color: colors.$black-dark;

            & img {
                width: 25px;
                height: 25px;
                border-radius: 100%;
            }

            & h4 {
                font-family: Hurme-Bold;
                font-style: normal;
                font-weight: 700;
                font-size: variables.$mid-title-size-text;
                line-height: 15px;
                text-align: center;
                margin-left: 10px;
            }
        }
    }

    @include tablet() {
        & .header-page {
            width: 100%;

            & button {
                max-width: 150px;

                &:nth-of-type(2) {
                    padding: 0 10px;
                    height: 44px;
                    line-height: initial;
                    font-size: variables.$pMobil;
                }
            }

            .btn-favourite {
                top: 12px;
                right: 8em;
            }
        }

        & aside:nth-of-type(1) {
            padding: 0 !important;
            width: 85% !important;
            min-width: auto !important;

            & section:nth-of-type(1) {
                min-width: auto !important;

                & .card-desc-applicant div {
                    flex-wrap: wrap;
                }

                & .profile-info {
                    & h3 {
                        font-family: Hurme-Black;
                        font-size: variables.$mid-title-size-text;
                    }
                }
            }
        }
    }

    & aside:nth-of-type(1) {
        padding: 0px 20px 0 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1050px;

        & section:nth-of-type(1) {
            justify-content: center;
            //margin-top: 2rem;
            display: flex;
            max-width: 1050px;
            width: 100%;

            & .profile-info {
                &>div {
                    display: flex;

                    & figure {
                        margin: 0px 12px 0px 0px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        & img {
                            @include avatar($avtr-sze);
                        }

                        & button {
                            @include little-btn(colors.$hard-grey-dark);
                            font-size: variables.$low-size-text;
                            padding: 3px 11px;
                            min-width: 100px;
                            border-radius: 25px;
                            line-height: normal;
                        }
                    }

                    & .card-desc-applicant {
                        align-items: center;
                        padding-top: 10px;
                        margin-left: 12px;

                        & .gradient-verified {
                            display: flex;
                            align-items: center;

                            & .gradient-text-verified {
                                @include gradient-text;
                                font-family: Hurme-Black;
                                margin: 0px 6px 0px 0px;
                            }

                            & img {
                                width: 20px;
                                height: 20px;
                            }

                            margin-bottom :10px;
                        }

                        & div {
                            display: flex;
                            color: colors.$blue-fair;
                            // margin-right: 20px;
                            align-items: center;

                            & h3 {
                                font-size: variables.$mid-title-size-text * 1.8;
                                font-family: Hurme-Bold;
                                color: #66B6FF;

                                &:nth-child(1) {
                                    color: black;
                                }
                            }

                            & span {
                                font-family: Roboto;
                                font-style: normal;
                                line-height: 17px;
                                margin-left: 6px;
                                margin-top: -10px;
                                font-weight: 400;
                                font-size: variables.$medium-size-text * .8;
                            }

                            & .vogzter-right {
                                background-color: #e9e9e9;

                                & div:nth-of-type(3) {
                                    margin-right: 12px;
                                }
                            }

                            & div {
                                & span:nth-of-type(1) {
                                    font-size: variables.$mid-title-size-text * 1.5;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }

                & h3 {
                    font-family: Hurme-Black;
                    font-size: variables.$h1;
                }
            }

            & .action-profile {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        & .options-set {
            margin: 20px 0 30px 0;
            display: flex;
            z-index: 5;
            width: 80%;
            justify-content: center;

            & button {
                width: fit-content !important;
                padding: 12px 30px;
                width: 60%;
                background: colors.$blue-btn;
                border: none;
                border-radius: 10px;
                width: 90%;
                cursor: pointer !important;
                pointer-events: all !important;
                font-family: Hurme-Bold;
                font-size: variables.$medium-size-text;
                margin: 0 8px;
                @include disabled;
                border-radius: 12px;
                transition: .3s all ease-in-out;
                cursor: pointer;
                z-index: 9;

                &.disabled {
                    @include disabled();
                    cursor: not-allowed !important;
                }

                &.active {
                    color: colors.$blue-dark;
                    background-color: var(--main-white-color);
                    box-shadow: 0px 4px 15px #F0F0F0;

                    &:hover {
                        background-color: darken($color: colors.$white, $amount: 2);
                        box-shadow: 0px 4px 15px darken($color: #F0F0F0, $amount: 8);
                    }
                }

                &:hover {
                    background-color: darken($color: colors.$blue-btn, $amount: 2);
                    // color: var(--main-white-color);
                }
            }
        }

        & section:nth-of-type(2) {
            width: 100%;

            & .medias-streamer {
                position: sticky;
                top: -2px;
                padding: 1rem 0 0.5rem 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                --streamer-width: 524px;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: var(--streamer-width);
                margin: auto;
                overflow: auto;
                align-items: center;
                z-index: 10;
                background: rgba(255, 255, 255, 0.947);
                backdrop-filter: blur(10px);

                &::-webkit-scrollbar {
                    height: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: colors.$new-blue-fair;
                    border-radius: 100px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #D1D8F2;
                    border-radius: 100px;
                }

                &::-webkit-scrollbar-thumb:active {
                    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
                }

                & div {
                    position: relative;
                    flex-wrap: nowrap;
                    flex-shrink: 0;
                    justify-content: flex-start;

                    & button {
                        display: flex;
                        flex-shrink: 0;
                        font-family: Hurme-Bold;
                        border-radius: 5px;
                        background: inherit;
                        transition: 0.1s;
                        color: #7C7C7C;
                        font-family: Hurme-Bold;
                        font-size: variables.$px-ts-sm;
                        padding: 0.15rem 1rem;
                        line-height: 15px;
                        transition: .4s;
                    }

                    & .selected {
                        color: colors.$blue-dark !important;
                    }
                }

                & .medias-streamer-line {
                    position: absolute;
                    bottom: 0;
                    width: 409px;
                    display: flex;
                    height: 1px;
                    background: #D9D9D9;

                    & span {
                        display: flex;
                        position: absolute;
                        top: -1px;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 3px;
                        width: 0%;
                        transition: .15s;
                        align-self: center;
                        background: colors.$blue-dark;
                        border-radius: 100px;
                    }
                }
            }

            & .grid2 {
                display: flex !important;
                row-gap: 60px;
                // row-gap: 41px;
            }
        }

        & hr {
            width: 70%;
            margin: 30px auto 10px auto;
        }
    }
}

.top-nav-bar {
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 20px;
    margin-top: -80px;
    box-shadow: 0px 4px 25px #F5F5F5;
    background: rgba(255, 255, 255, 0.947);
    backdrop-filter: blur(10px);
    z-index: 12;

    .logo a {
        background: -webkit-linear-gradient(#d1b2ff, #5777ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        transition: .3s;
        font-family: Hurme-Black;
        text-transform: uppercase;
        font-size: variables.$h1Mob;
        margin: 0 15px;
    }

    .menu-button {
        display: none;
        font-size: variables.$h1Mob;
        top: -80px;
        transition: .3s;
        cursor: pointer;

        &:hover {
            color: var(--main-blue-color-bg);
        }
    }

    .menu-container a {
        font-family: Hurme-Semi-Bold;
        text-transform: uppercase;
        font-size: variables.$pMobil;
        margin: 0 15px;
        color: var(--main-dark-color);
    }

    .btn-login {
        background: var(--main-white-color);
        border: 1px solid var(--main-blue-color-bg);
        box-shadow: 0px 2px 15px rgba(202, 202, 202, 0.25);
        border-radius: 100px;
        color: var(--main-blue-color-bg);
        text-transform: uppercase;
        min-width: 110px;
        font-size: variables.$pMobil;

        &:hover {
            background: var(--main-blue-color-bg);
            color: var(--main-white-color);
        }
    }
}

.card-vogz {
    //width: fit-content;
    //margin: auto;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.icon-chevron {
    display: flex;
    width: 35px;
    height: 35px;
    color: #66B6FF;
    font-size: 1.3ch;
    justify-content: center;
    align-items: center;
    border: solid 1px #66B6FF;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.2s;
}

.input-container-casting {
    flex-grow: 0;
    display: flex;
    padding-block: 1em;
    flex: .16;
}

.input-container-casting .input-content-casting {
    width: 100%;
    height: 3.5em;
    position: relative;
    border-radius: 12px;
    padding-bottom: 9px;
}

.input-content-casting>label {
    right: 18px;
    left: unset;
    cursor: pointer;
    font-size: 17px;
    position: absolute;
    top: calc(50% - 0.8em);
    color: var(--main-blue-color-bg);
}

.input-container-casting>textarea {
    width: 100%;
    vertical-align: center;
}

.input-container-casting .casting-message:focus {
    outline: 0;
}

.input-container-casting .casting-message {
    border: 1px solid #e9e9e9;
    padding-block: 19px;
    padding-inline: 19px 49px;
    width: 100%;
    border-radius: 16px;
    resize: none;
    font-size: 13px;
    font-family: Hurme-Bold;
}

.start-collab-mobile {
    display: none;
}

@include tablet {
    .start-collab-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: sticky;
        bottom: 0px;
        padding: 20px;
        box-shadow: 0px 4px 25px #F5F5F5;
        backdrop-filter: blur(10px);
        z-index: 12;
    }
}

@include tablet2 {
    .menu-button {
        display: flex !important;
    }

    .menu-container,
    .menu-mobile {
        top: -300%;
        transition: all 0.6s ease-in-out;
        transform: scale(0.9);
        width: 100%;
        position: absolute;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: rgba(255, 255, 255, 0.947);
        z-index: 20;
        box-shadow: 0px 4px 25px #F5F5F5;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

@include mobile() {
    .all-page {
        padding-top: 50px;
    }

    .start-collab-mobile {
        bottom: 0px;
    }

    .start-collab-mobile-safari {
        // bottom: 80px;
    }

    .start-collab-ios-chrome {
        bottom: 30px;
    }

    .main-profil-page {
        height: calc(100% - 75px);
        max-height: 100vh;

        .chat-container {
            top: -72px;
            display: block;
            position: absolute;
            width: 100% !important;
        }

        .all-page {
            height: 100%;

            & .profile-info {
                &>div {
                    flex-direction: column;

                    & figure {
                        margin: 0 !important;
                    }
                }

                & .card-desc-applicant {
                    & div {
                        justify-content: center;
                    }

                    margin-left: 0px !important;
                }

                & h3 {
                    text-align: center;
                }
            }

            .profile {
                display: block;

                aside {
                    // margin: 0 10px 0 10px !important;
                    width: 100% !important;

                    & .sticky {
                        position: initial;
                        z-index: 9;
                    }

                    & .options-set {
                        width: 100% !important;

                        & button {
                            padding: 8px 12px !important;
                        }
                    }
                }
            }

            & .medias-streamer {
                position: initial !important;
            }
        }

        &.not-connected {
            //height: 100% !important;

            .chat-container {
                top: 0px;
                height: 100%;
                //height: 100px !important;
            }
        }
    }
}