@import "../../../sass/mixins";

.galerie-vogzter-medias {
    padding  : 10px;
    max-width: 900px;
    margin   : auto;
    width: 100%;

    .grid-galerie-vogzter-medias {
        display              : grid;
        gap                  : 36px;
        row-gap: 45px; // Vertical spacing
        column-gap: 23px; // Horizontal spacing
        grid-template-columns: repeat(1, 1fr);

        // Default: Single column

        @media (min-width: 640px) {
            // Small screens: Two columns
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 1024px) {
            // Medium screens: Three columns
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 1280px) {
            // Large screens: Four columns
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media (min-width: 1280px) {
        max-width: 80%;  
    }
}