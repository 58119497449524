@import '../../../../sass/mixins';
@import '../../../../sass/others/animations.scss';
.modal-video {
    //z-index: 10000;
}

.content-modal-video {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    &>div {
        height: 100vh !important;
        width: 100% !important;
    }
}

.close-video {
    display: none;
}
.share-video {
    position: absolute;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    left: 15px;
    top: 10px;
    z-index: 999;
    padding: 5px;
    background: linear-gradient(270deg, #71a8fb, #d8b6ff);
    animation: wave 5s infinite;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    >i {
        position: absolute;
        width: fit-content;
        right: 0;
        left: 0;
        margin: auto;
        color: white;
    }
}

@include mobile {
    .content-modal-video {
        position: relative;
        height: unset;
        max-height: 90%;
    }
    .share-video {
        top: 60px;
    }
    .close-video {
        position: absolute;
        display: flex;
        align-items: center;
        width: 40px;
        height: 40px;
        right: 15px;
        top: 60px;
        z-index: 999;
        background: linear-gradient(270deg, #71a8fb, #d8b6ff);
        animation: wave 5s infinite;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
        >i {
            position: absolute;
            width: fit-content;
            right: 0;
            left: 0;
            margin: auto;
            color: white;
        }
    }
}