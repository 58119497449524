@use "../../../../../../sass/variables.scss" as variables;
@use "../../../../../../sass/colors" as colors;
@import '../../../../../../sass/mixins';

.ended-casting {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    & .primary-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 3rem 0;
        padding: 30px 0;
        background: #F8F8FF;
        border-radius: 5px;
        & * {
            color: #3A4161;
        }
        & .brief-planning {
            position: relative;
            display: flex;
            width: 540px;
            justify-content: space-between;
            align-items: flex-start;
            margin: 20px 0;
            padding: 15px;
            border-radius: 10px;
            background: colors.$white;
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                &:nth-of-type(1) * {
                    color: #38B2F6;
                }
                &:nth-of-type(2) * {
                    color: #7E44EE;
                }
                & > h4 {
                    font-size: 25px;
                    font-family: Hurme-Black;
                    line-height: normal;
                }
                & > b {
                    font-size: variables.$px-ts-md;
                    font-family: Hurme-Bold;
                }
                & > span {
                    font-size: 12px;
                    line-height: 8px;
                }
            }
            & > img {
                filter: grayscale(80%) contrast(2);
            }
        }
    }

}

@include tablet() {
    .ended-casting {
        width: 90%;
        & .primary-content {
            padding: 30px 0;
            background: #F8F8FF;
            border-radius: 5px;
            & .brief-planning {
                position: relative;
                display: flex;
                width: 540px;
                justify-content: space-between;
                align-items: center;
                margin: 20px 0;
                padding: 15px;
                border-radius: 10px;
                background: colors.$white;
                & > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    &:nth-of-type(1) * {
                        color: #38B2F6;
                    }
                    &:nth-of-type(2) * {
                        color: #7E44EE;
                    }
                    & > h4 {
                        font-size: 25px;
                        font-family: Hurme-Black;
                        line-height: normal;
                    }
                    & > b {
                        font-size: variables.$px-ts-md;
                        font-family: Hurme-Bold;
                    }
                }
                & > img {
                    filter: grayscale(80%) contrast(2);
                }
            }
        }
    
    }
}

@include mobile() {
    .ended-casting {
        width: 95%;
        justify-content: flex-start;
        & .primary-content {
            & .brief-planning {
                flex-direction: column;
                width: 95%;
                & > img {
                    position: relative;
                    width: 100px;
                    margin: 30px 0 80px 0;
                    transform: rotate(90deg);
                }
            }
        }
    
    }
}