@use '../../../../../../sass/colors'as colors;
@use '../../../../../../sass/variables'as variables;
@import '../../../../../../sass/mixins';
@import '../../../../../../sass/partials/buttons';

.content-section-body {
    padding: 0 22px;

    >h3 {
        font-family: Hurme-Bold;
        font-size  : variables.$h1Mob;
        line-height: normal;
        color      : var(--blue-gulf);
        text-align : center;
    }

    >p {
        text-align: center;
        margin    : 20px 0;
    }

    .btn-continue{
        color: var(--main-blue-color-bg);
        font-family: Hurme-Bold;
        margin-top: 23px;
        &:hover{
            opacity: .8;
        }
    }

    .icons-browsers div {
        align-items    : center;
        display        : flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;

        i {
            color: #48EC93;

            &[data-fa-circle-xmark] {
                color: #FF2929;
            }
        }

    }
}