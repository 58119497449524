@import '../../../../sass/mixins';

.modal-add-favorite {
  transition: all 0.1s !important;
  position: relative;

  .child-modal-add-favorite {
    display: flex !important;
    width: 420px;
    height: 464px;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    border: 2px solid #FFFFFF;
    border-radius: 25px;
    box-shadow: 0px 10px 50px #D6D6D6;
    background: linear-gradient(180deg, #E6F3FF 0%, #FFFFFF 100%);
    pointer-events: all;
  
    .close-btn {
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #2F2E41;
      border: solid 1px #2F2E41;
      border-radius: 100%;
    }
  
    .main-content {
      width: 80%;
      padding: 10px 0;
  
      h3 {
        font-family: Hurme-Bold;
        text-align: center;
        padding: 0 20px;
      }
  
      .desc {
        text-align: center;
        margin-top: 10px;
        padding: 0 10px;
        line-height: 19.53px;
        font-size: 15px;
        margin: auto;
        margin-top: 10px;
  
        b {
          font-family: Hurme-Black;
          color: #EF6262;
        }
      }
    
      .bottom-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 40px;
  
        span {
          width: 100%;
          text-align: right;
          font-size: 14px;
          margin: 10px 0 5px 0;
          padding-right: 15px;
        }
        button {
          i {
            position: absolute;
            left: calc(100% - 35px);
            right: 0;
            font-size: 35px;
            cursor: pointer;
            animation: pulse-animation 2.5s ease-in-out infinite ;
            text-align: center;
            z-index: 3;
            transition: 0.1s ease-in-out;
          }
          &:active {
            i {
              color: #F41A1A;
            }
          }
          &:hover {
            i {
              animation: pulse-animation-fast 0.5s ease-in-out infinite;
            }
          }
        }
        .pp {
          width: 120px;
          height: 120px;
          border: 2px solid #FFFFFF;
          box-shadow: 0px 4px 20px rgba(85, 85, 85, 0.25);
          object-fit: cover;
          border-radius: 100%;
          margin-top: 10px;
          z-index: 2;
        }
        .curved-arrow {
          position: absolute;
          right: 35px;
          top: 35px;
          z-index: 1;
        }
        .checkbox-desc {
          font-size: 12px;
          width: fit-content;
        }
      }
    }
  }
}

@include mobile() {
  .modal-add-favorite {
    .child-modal-add-favorite {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 95%;
      max-width: 420px;
      margin: auto;
  
      .main-content {
        width: 100%;
        max-width: 300px;
  
        .desc {
          padding: 0;
        }
      }
    }
  }
}

@keyframes pulse-animation {
  10% {
    font-size: 35px;
  }
  18% {
    font-size: 38px;
  }
  26% {
    font-size: 35px;
  }
}

@keyframes pulse-animation-fast {
  0% {
    font-size: 35px;
  }
  50% {
    font-size: 38px;
  }
  100% {
    font-size: 35px;
  }
}