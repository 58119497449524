@use "../../../../../sass/colors"as colors;
@use "../../../../../sass/variables.scss"as v;
@import '../../../../../sass/mixins';
@import '../../../../../sass/_mixins/global';

.team-page {
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
}
.all-body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  & .title {
    text-align: start;
  }
  table.body-list {
    tr {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        td:nth-of-type(1) {
          width: 50%;
          color: var(--main-dark-blue-text-color);
          font-family: Hurme-Bold;
          font-size: v.$px-ts-md;
          text-align: start;
          min-width: 180px;
        }
      }
      td {
        padding: 0.5rem;
        p {
          padding: 0.5rem;
        }
      }
      &.champs-all-team,
      &.champs-team {
        & .first-col-center {
          padding: 0.5rem 0;
          &>div {
            border-radius: 4px 0 0 4px;
          }
        }
      }
    }
    tr.header-table {
      border-bottom: 1px solid transparent;
      box-shadow: 0px 0.5px 0px var(--main-dark-blue-text-color);
      td> p {
        font-family: Hurme-Bold;
        font-size: v.$px-ts-md;
        color: var(--main-dark-color);
      }
      .center-col{
        min-width: 206px!important;
      }
   
    }
    tr.champs-all-team {
      border-bottom: 1px solid transparent;
      box-shadow: 0px 0.5px 0px var(--main-dark-blue-text-color);
      td:nth-of-type(1) > p {
        background-color: var(--main-light-blue-magenta);
        border-radius: 4px;
      }
      td {
        &> div {
          background-color: var(--main-light-blue-magenta);
        }
      }
    }
    .champs-team {
      .first-col,
      .second-col {
        text-align: start;
        p {
          background-color: var(--main-ghost-white);
          border-radius: 4px;
        }
      }
      .first-col {
        width: 20%;
        p {
          font-family: Hurme-Bold;
          font-size: v.$px-ts-md;
          color: var(--main-dark-blue-text-color);
        }
      }
      .second-col {
        width: 30%;
        p {
          font-family: Roboto;
          font-size: v.$px-ts-sm;
          color: var(--main-dark-blue-text-color);
        }
      }
      & td {
        &> div {
          background-color: var(--main-ghost-white);
        }
      }
    }
  }
  & .btn-delete{
    background-color: #1D1A66;
    &:hover{
      background-color: transparent;
      border: 1.5px solid #1D1A66;
      color: #1D1A66;
    }
  }
  & .table-mobile {
    display: none;
  }
  & .body-add-member {
    margin-bottom: 3rem;
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    & h2 {
      width: 100%;
      font-family: Hurme-Bold;
      font-size: v.$px-ts-md;
      color: var(--main-perano-purple);
      text-transform: uppercase;
    }
    & form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      & .input-add-member {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        margin-bottom: 30px;
        flex-wrap: wrap;
        flex-direction: column;
        & .div-input {
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
          & div {
            min-width: auto;
          }
          & input {
            width: 100%;
            height: 40px;
            background: var(--main-light-grey-bg);
            border: 1px solid var(--main-light-grey);
            font-size: v.$medium-size-text;
            border-radius: 4px;
            padding-left: 21px;
            padding-right: 21px;
            font-family: Roboto;
            color: var(--main-blue) !important;
            outline: none;
          }
          & input::placeholder {
            color: var(--main-grey-blue) !important;
          }
          & label{
            color: var(--main-dark-color);
            span{
              display: none;
            }
          }
          & .ErrorMsg {
            height: 25px;
            & .textdanger {
              font-family: Roboto;
              color: var(--main-alert-color);
              margin-left: 5px;
              font-size: v.$low-size-text * 1.2;
            }
          }
        }
        & button {
          display: flex;
          align-items: center;
          background: var(--main-white-color);
          border: 0.5px solid var(--main-blue-color-bg);
          border-radius: 19px;
          font-family: Hurme-Bold;
          height: 40px;
          margin-top: 2.5rem;
          font-size: v.$medium-size-text;
          color: var(--main-blue-color-bg);
          margin-left: auto;
          margin-right: auto;
          cursor: pointer;
          width: 20%;
          justify-content: center;
          min-width: 165px;
        }
      }
    }
  }
}
@include tablet() {
  .all-body {
    & p {
      font-size: v.$medium-size-text !important;
    }
    & .body-list {
      & .team-list {
        & .list {
          & .field {
            font-size: v.$low-size-text !important;
          }
          & .field:nth-of-type(2) {
            font-size: v.$low-size-text !important ;
          }
          & button {
            font-size: v.$low-size-text;
          }
        }
        & .no-member {
          font-size: v.$low-size-text;
        }
      }
    }
    .table-mobile{
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      tr{
        min-width: 360px;
      }
      td{
        min-width: 180px;
      }
      .tr-btn{
        margin-top: .5rem;
        background-color: var(--main-ghost-white);
        border: 0.5px solid rgb(58 65 97 / 70%);
        border-radius: 4px;
        font-size: v.$px-ts-sm;
        font-family: Roboto;
        color: var(--main-white-color);
        font-weight: 300;
        text-align: center;
        .tr-head-btn{
          &:first-child td:first-child{
            border-right: 0.5px solid rgb(255 255 255 / 70%);
          }
          background-color: var(--main-dark-blue-text-color);
          td{
            padding: .5rem;
          }
        }
        & .tr-body-btn td:first-child{
          border-right: 0.5px solid rgb(58 65 97 / 70%);

        }

      }
      .first-col{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--main-dark-blue-text-color);
        font-family: Hurme-Bold;
        font-size: v.$px-ts-sm;
        background-color: var(--main-ghost-white);
        border-radius: 4px;
        padding: .5rem;
        margin-bottom: .5rem;
        line-height: normal;
        .info-member{
          & p:last-child{
            font-family: Roboto;
            font-size: v.$px-ts-ul-sm;
            font-weight: 300;
          }
        }
      }
    }
    & .body-list {
      display: none;
    }
    & .body-add-member {
      & h2 {
        font-size: v.$medium-size-text !important;
      }
    }
    & .input-add-member {
      & .textdanger {
        font-size: v.$low-size-text;
      }
    }
    & button {
      font-size: v.$low-size-text !important;
    }
  }
}

@include tablet2() {
  .all-body {
    padding-top: 3rem;
    min-height: 100% !important;
    & .body-list {
      display: none;
    }
    & .list {
      flex-wrap: wrap;
      & .field:first-child {
        min-width: 110px;
      }
      & .field:nth-of-type(2) {
        min-width: 140px;
      }
    }
    & .input-add-member {
      flex-direction: column;
      margin-top: 1rem;
      align-items: center;
      & .div-input {
        width: 100% !important;
        flex-direction: column !important;
        & .ErrorMsg {
          height: 20px !important;
          & .textdanger {
            font-family: Roboto;
            color: var(--main-alert-color);
            margin-left: 5px;
            font-size: v.$low-size-text/1.2;
          }
        }
      }
    }
  }
}
