.not-clickable {
    pointer-events: none;
  }
  
  .not-clickable:hover {
    cursor: not-allowed !important;
  }
  
  .react-calendar {
    width: 300px;
    /* height: 350px; */
    border: none;
    align-self: center;
    background: white;
    padding:5px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em !important;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none !important;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:disabled {
    background: none;
    color: #CCC;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
  }
  .react-calendar__navigation * :not(i) {
    font-weight: bold;
    color: black !important;
  }
  .react-calendar__navigation i {
    font-size: 2.2ch;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    /* background-color: #f0f0f0; */
    color: #CCC;
    cursor: auto;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation__label {
    color: #000000;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    color: #777 !important;
    margin-bottom: 10px;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #000000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 7px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  }
  .react-calendar__tile:disabled {
    cursor: auto;
    color: #CCC;
  }
  .react-calendar__tile:enabled:hover {
    background-color: #e6e6e6;
  }
  .react-calendar__tile:enabled:focus {
    background-color: #5777FF;
  }
  .react-calendar__tile--now {
    background: rgba(123, 97, 255, 0.05);
    border: 2px #7B61FF solid !important;
  }
  .react-calendar__tile--now:enabled:hover {
    background: none;
  } 
  /* .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #5777FF;
  } */
  .react-calendar__tile--hasActive {
    /* background: #76baff; */
  }
  .react-calendar__tile--hasActive:enabled:hover :not(.react-calendar__tile--now *) :not(abbr),
  .react-calendar__tile--hasActive:enabled:focus :not(.react-calendar__tile--now *) :not(abbr) {
    background: #5777FF;
  }
  .react-calendar__tile--active {
    background: #5777FF !important;
    color: white;
    font-weight: bold;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #5777FF;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }