@use "../../../../../../sass/colors" as colors;
@use "../../../../../../sass/variables.scss" as variables;
@import '../../../../../../sass/mixins';
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/_mixins/animated-shine.scss";

.ratings {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .rating {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }

        >div {
            h3 {
                font-size: 18px;
                font-family: Hurme-Bold;
                color: #131153;
            }

            p {
                color: var(--main-grey-color-text)
            }
        }
    }
}