@use '../../../../../../sass/variables.scss' as variables;
@use '../../../../../../sass/colors' as colors;
@import '../../../../../../sass/partials/buttons';
@import '../../../../../../sass/mixins';
@import '../../../../../../sass/_mixins/global';
$body-card-width: 20rem;
$body-card-height: 23.5rem;
$medium-size-round: 3em;

.height-card {
    height: 614px;

    header {
        z-index: 0;
    }
}
.card-briefapplication-influence footer .card-body{
    width: 18rem !important;
}
.card-briefapplication {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    & .badge-red {
        position: absolute;
        bottom: 45px;
        cursor: pointer;
        @include badge("red");
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .5s;
        height: 20px;
        display: flex;
        align-items: center;
        z-index: 3;

        &:hover {
            background-color: darken(colors.$alert, 10%);
        }
    }

    & header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        gap: 15px;

        & figure {
            margin: 0px 12px 0px 0px;
            display: flex;
            flex-direction: column;
            height: 80px;
            width: 80px;
            align-items: center;
            position: relative;

            >a {
                height: 100%;
                width: 100%;
            }

            &.bought {
                margin: 0px !important;
            }

            & img {
                @include avatar;
                width: 100%;
                height: 100%;
            }

            & .badge-red-avatar {
                line-height: 10px;
                position: absolute;
                bottom: 0px;
                @include badge("red");
                padding: 2px 6px 2px;
                font-size: 10px;
            }
        }

        & h3 {
            font-family: Hurme-Black;
        }

        & .card-desc-user-actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            & button {
                @include outlined-btn;
                margin-top: 5px;
                //padding: 5px 12px;
                width: 60%;
                min-width: 112px;
                border-radius: 22px;
                cursor: pointer;
                font-family: Hurme-Bold;
                font-size: variables.$low-size-text;
            }
        }
    }

    & footer {
        position: relative;

        & .card-body {
            width: $body-card-width;
            height: $body-card-height;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            overflow: hidden;
            //margin-bottom: 24px;
            position: relative;

            & .open-btn {
                position: absolute;
                width: 35px;
                height: 35px;
                z-index: 0;
                background-color: var(--main-blue-color-bg);
                color: var(--main-white-color);
                right: 10px;
                bottom: 10px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: variables.$px-ts-sm;
                }

                &:hover {
                    opacity: .7;
                }
            }

            & .bookmarked_btn {
                position: absolute;
                width: 35px;
                height: 35px;
                z-index: 0;
                background-color: var(--main-blue-color-bg);
                color: var(--main-white-color);
                right: 10px;
                top: 10px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    background-color: var(--main-blue-color-bg);
                    color: colors.$red-ryb;

                    &:hover {
                        opacity: .9;
                    }
                }

                i {
                    font-size: variables.$px-ts-sm;
                }

                &:hover {
                    opacity: .7;
                }
            }

            & .open-btn {
                position: absolute;
                width: 35px;
                height: 35px;
                z-index: 0;
                background-color: var(--main-blue-color-bg);
                color: var(--main-white-color);
                right: 10px;
                // transform: translateY(870%);
                border: 0px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .5s;

                i {
                    font-size: variables.$px-ts-sm;
                }

                &:hover {
                    background-color: #94a7fc;
                    transition: all .5s;
                }
            }

            & .span {
                position: absolute;
                background-color: var(--main-white-color);
                padding: 0px 12px;
                margin-top: 16px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                color: colors.$blue-fair;
                font-size: variables.$medium-size-text;
                font-family: Hurme-Black;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            }
            & .span-bottom{
                margin-top: 0 !important;
                bottom: 20px !important;
                color: #333030 !important ;
                cursor: default;
            }
            
            & .play-btn {
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: $medium-size-round;
                height: $medium-size-round;
                cursor: pointer;
                background: #0000005b;
                backdrop-filter: blur(4px);
                border-radius: 50%;
                z-index: 1;

                i {
                    color: white;
                    width: 100%;
                    font-size: 25px;
                    transform: translate(9%, 37%);
                }
            }

            & img:nth-last-child(1) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & .card-body-bottom-radius {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            & video {
                object-fit: cover;
            }
        }

        & button {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            width: 100%;
            font-family: Hurme-Black;
            z-index: 4;
            overflow: hidden;
        }
        
        & .btn-calendar{
            width: 100%;
            border-radius: 50px;
            background-color: var(--main-blue-color-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                transform: scale(1.1);
                transition: all .3s;
            }
        }
        & .disable-rdv{
            opacity: 0.3;
            cursor: not-allowed;
            &:hover{
                transform: scale(1);
            }
        }
        & .link-post{
            border-radius: 5px;
            background: linear-gradient(91deg, #7AB9EE 0%, #8480FF 100%);
            text-align: center;
            box-shadow: 0px 0px 15px 0px rgba(200, 200, 200, 0.25);
            padding: 6px 24px;
            width: 75%;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            color: var(--main-white-color);
            font-size: variables.$p;
            font-family: Hurme-Bold;
            a {
                width: 25px;
                transition: all .3s;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        & .no-link{
            opacity: 0.2;
        }
        & .download-btn{
            background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);
            border-radius: 19px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            box-shadow: 0px 4px 15px 0px rgba(202, 202, 202, 0.25);
            &:hover {
                box-shadow: 0px 3px 20px colors.$perano-blue;
                opacity: .8;
                background: linear-gradient(91.3deg, #7AB8EF 0%, #8481FF 100%);
                color: var(--main-white-color);
                border: 0;
                -webkit-background-clip: initial;
                -webkit-text-fill-color: initial;
            }
        }
        .sub-footer {
            margin-top: 1rem;
            width: 20rem;
            border: 1px solid var(--main-blue-navy);
            box-shadow: 0px 4px 30px rgba(185, 185, 185, 0.25);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: var(--main-white-color);
            z-index: 2;

            .header {
                height: 60px;
                display: flex;
                width: 100%;
                flex-direction: column;
                text-align: center;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                justify-content: center;

                p {
                    font-family: Roboto;
                    font-size: variables.$pMobil;
                    color: var(--main-blue-navy);
                }
            }

            p {
                font-family: Roboto-Regular;
                font-size: variables.$pMobil;
                color: var(--main-grey-color-text);
                line-height: normal;
            }

            h3 {
                font-family: Hurme-Bold;
                font-size: variables.$pMobil;
                color: var(--main-blue-navy);
            }

            .fair-green-background {
                background-color: var(--my-castings-real-fair-green);
            }

            .alice-blue-background {
                background-color: var(--my-castings-alice-blue);
            }

            .medium-champagne-background {
                background-color: var(--my-castings-medium-champagne);
            }
        }
    }
}
.card-briefapplication-influe {
    & header {
        width: 20rem;
        & .card-desc-user-actions{
            align-items: flex-start;
            span{
                font-size: variables.$pMobil;
                font-family: Roboto;
            }
        }
        & figure {

            height: 50px;
            width: 50px;
            >a {
                height: 100%;
                width: 100%;

            }

            &.bought {
                margin: 0px !important;
            }

            & img:first-of-type {
                @include avatar;
                width: 48px!important;
                height: 48px!important;
                min-height: 48px!important;
                min-width: 48px !important;
            }

        }
    }
    & footer{
        position: relative;
        & .status-post{
            position: absolute !important;
            top: 15px;
            left: 15px;
            display: flex;
            cursor: pointer;
            & .badge-posted {
                border-radius: 20px;
                width: 25px;
                height: 25px;
                z-index: 4;
                overflow: hidden;
                text-align: center;
                border: 1px solid var(--main-white-color);
                font-size: variables.$p;
                font-weight: 400;
                background-color: var(--main-grey-text);
                color: var(--main-white-color);
            }
            > p{
                transition-timing-function: ease-out;
                transition: 0.25s;
                transform: translateY(-130%);
                max-width: 12rem;
                margin-left: 10px;

            }
            &:hover{
                > p{
                    > span{
                        color: var(--main-grey-text);
                        font-family: Hurme-Bold;
                    }
                    transition-timing-function: ease-in;
                    transform: translateY(0);
                    transition: all .3s;
                    display: flex;
                    flex-direction: column;
                    font-size: variables.$pMobil;
                    font-family: Roboto;
                    color: var(--main-grey-color-text);
                    line-height: normal;
                    background-color: var(--main-white-color);
                    padding: 5px 10px;
                    border-radius: 5px;
                }
            }
            &.posted {
                & .badge-posted{
                    background-color: var(--main-aquamarine-green);
                    color: var(--main-white-color);
                }
                & p{
                    background-color: #EDFFEA;
                    color: var(--main-grey-approx);
                    & span{
                        color: var(--main-reptile-green);
                    }
                }
            }
            &.declined{
                & .badge-posted{
                    background-color: var(--main-tangerine-yellow);
                    color: var(--main-white-color);
                }
                & p{
                    background-color: #FFF7EA;
                    color: var(--main-grey-approx);
                    & span{
                        color: var(--main-tangerine-yellow);
                    }
                }
            }
        }

        &.card-ba-influence{
            display: flex;
            & .option-media{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: -10px;
                padding: 15px 10px 15px 20px;
                box-shadow: 0px 4px 35px 0px rgba(152, 152, 152, 0.25);
                border-radius: 10px;
                height: 23.5rem;
                width: 4rem;
                & .part-post{
                    & a{
                        border: 1px solid var(--main-dark-color);
                    }
                    button,a  {
                        width: 100%;
                        border-radius: 50px;
                        display: flex;
                        padding: 8px;
                        align-items: center;
                        justify-content: center;
                        &:hover{
                            transform: scale(1.1);
                            transition: all .3s;
                        }
                        &.btn-exclamation{
                            background-color: var(--main-grey-charcoal);
                            color: var(--main-white-color);
                        }
                        &.btn-check{
                            background-color: #E7FFF4;
                            border: 1px solid var(--main-reptile-green);
                            color: var(--main-reptile-green);
                        }
                        &.btn-disabled{
                            opacity: 0.3;
                            background-color: var(--main-grey-charcoal);
                            color: var(--main-white-color);
                        }
                        &:disabled{
                            opacity: 0.3;
                            cursor: not-allowed;
                            &:hover{
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
    }
    
}