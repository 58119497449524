@use "../../../sass/colors" as colors;
@use "../../../sass/variables.scss"as v;
@import '../../../sass/partials/_buttons';
@import '../../../sass/mixins';
@import '../../../sass/_mixins/global';
$width-bar-messages: 492px;
$avtr-sze: 7rem;
.all-page {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    & aside {
        height: 100%;
    }
    .input-container-casting {
        flex-grow: 0;
        display: flex;
        padding-block: 0.5em;
        flex: 0.16;
    }
    .input-container-casting .input-content-casting {
        width: 100%;
        max-height: 5.5em;
        height: 100%;
        position: relative;
        border-radius: 12px;
        display: flex;
        align-items: center;
        // padding-bottom: 9px;
    }
    .input-container-casting div:nth-of-type(2) {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        >button>i {
            cursor: pointer;
            font-size: 17px;
            color: var(--main-blue-color-bg);
        }
        >button:disabled {
            cursor: not-allowed;
            i {
                cursor: not-allowed;
            }
        }
    }
    .input-container-casting>textarea {
        width: 100%;
        vertical-align: center;
    }
    .input-container-casting .casting-message:focus {
        outline: 0;
    }
    .input-container-casting .casting-message {
        border: 1px solid #e9e9e9;
        padding-block: 10px;
        padding-inline: 19px 20px;
        width: 100%;
        border-radius: 16px;
        resize: none;
        font-size: 13px;
        font-family: Hurme-Bold;
    }
    & .section-list-messages {
        padding: 0px 50px 0 50px;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        padding-top: 2rem;
        .header-bar {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: space-between;
            position: relative;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-items: center;
            &>div:nth-of-type(1) {
                position: initial;
                &>div:nth-of-type(3) {
                    right: 0em !important;
                }
            }
            & >div:nth-of-type(2) {
                position: initial !important;
                & div:nth-of-type(3) {
                    right: 0 !important;
                }
            }
        }
        .header-list {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-evenly;
            margin: 2rem 0;
            button {
                position: relative;
                font-family: Hurme-Bold;
                font-size: v.$p;
                p {
                    color: var(--main-blue-color-bg);
                    opacity: 1;
                }
                &:hover {
                    p {
                        opacity: .3;
                    }
                }
                &[aria-pressed='true'] {
                    p {
                        opacity: .3;
                    }
                }
                span {
                    position: absolute;
                    font-size: v.$pMobil;
                    color: var(--main-white-color);
                    background-color: var(--main-alert-color);
                    display: flex;
                    border-radius: 1.25rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    line-height: initial;
                    opacity: 1 !important;
                    align-items: center;
                    justify-content: center;
                    top: -15px;
                    right: -15px;
                }
            }
            hr {
                border-left: 1px solid var(--main-grey);
                height: 1.5rem;
            }
        }
        .list-messages {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            & .placeholder-list-msg {
                display: flex;
                position: absolute;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: var(--main-white-color);
                z-index: 3;
                width: 100%;
                height: 100%;
                h3 {
                    font-family: Hurme-Black;
                    font-size: v.$h2;
                    text-transform: uppercase;
                    color: var(--main-dark-blue-text-color);
                    text-align: center;
                    margin: 1rem 0;
                }
                p {
                    font-family: Roboto;
                    text-align: center;
                    font-size: v.$px-ts-md;
                    color: var(--main-grey-color-text);
                    max-width: 500px;
                }
                button {
                    text-transform: uppercase;
                    margin: 3rem 0;
                }
            }
        }
    }
    & aside:nth-of-type(1) {
        width: 100%;
        max-width: 43%;
        height: 100vh;
        min-width: $width-bar-messages;
        background-color: var(--main-white-color);
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding: 22px 12px 0px;
        -webkit-box-shadow: -6px 0px 24px 9px rgba(204, 204, 204, 0.67);
        box-shadow: -6px 0px 24px 9px rgba(204, 204, 204, 0.67);
        z-index: 11;
        position: fixed;
        .btn-close-msg {
            display: none;
            z-index: 20;
        }
        /*half the width*/
        & section:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
            .profil {
                display: flex;
                align-items: center;
                .profil-pic {
                    display: flex;
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                    min-height: 50px;
                    border-radius: 100%;
                    align-self: center;
                    background: var(--main-white-color);
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        background: var(--main-white-color);
                        border: solid var(--main-white-color) 2.5px;
                        border-radius: 100%;
                        z-index: 2;
                        box-shadow: 0px 6px 11px #f5f5f5;
                        transform: matrix(-1, 0, 0, 1, 0, 0);
                    }
                }
                .profil-info {
                    margin-left: 1rem;
                    width: 100%;
                    h3 {
                        font-family: Hurme-Bold;
                        font-size: v.$h2;
                        button {
                            font-size: v.$medium-size-text;
                        }
                    }
                    & p:nth-of-type(1) {
                        background: linear-gradient(91.03deg, #a685fe 0%, #5f79ff 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        text-shadow: 0px 2px 8px rgba(152, 146, 146, 0.25);
                        font-family: Hurme-Black;
                        font-size: v.$low-size-text;
                    }
                }
            }
            & .btn-header {
                min-width: 180px;
                transition: color 0.2s ease-in-out;
                padding: 6px 10px;
                width: 100%;
                &.btn-deal {
                    background-color: var(--main-yellow);
                    color: var(--main-dark-color);
                    padding: 5px;
                    width: auto;
                    &:hover {
                        background-color: transparent;
                        border: 1px solid var(--main-yellow);
                    }
                }
            }
        }
        & section:nth-of-type(2) {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
            &>div:nth-of-type(1)>div>div div:not(:last-child) {
                margin: 0px 0 0px 0 !important;
                & div {
                    //margin-bottom: 6px;
                }
                & svg {
                    display: none !important;
                }
            }
        }
        & .placeholder-msg {
            position: absolute;
            width: 100%;
            height: 90%;
            background: #ffffff40;
            z-index: 4;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            backdrop-filter: blur(10px);
            & .placeholder-msg-text {
                display: flex;
                text-align: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                &>span {
                    font-size: calc(v.$h1 * 3);
                    margin-bottom: 1rem;
                    line-height: normal;
                }
                h3 {
                    font-size: v.$h1;
                    font-family: Hurme-Black;
                    color: var(--main-dark-blue-text-color);
                    background: linear-gradient(180deg, var(--main-white-color) 50%, var(--main-gargoyle-gas) 50%);
                    margin-bottom: 0.5rem;
                    padding: 5px 10px;
                    text-transform: uppercase;
                }
                p {
                    font-size: v.$h1Mob;
                    font-weight: 300;
                    font-family: Roboto;
                    text-transform: uppercase;
                    color: var(--main-dark-blue-text-color);
                }
            }
        }
    }
}

@include miniPc {
    .all-page {
        & aside:nth-of-type(1) {
            max-width: 920px;
            min-width: $width-bar-messages;
            width: $width-bar-messages;
        }
    }
}

@include tablet {
    .all-page {
        & .section-list-messages {
            width: 100%;
          }
        & aside:nth-of-type(1) {
            min-width: 26.25rem;
            position: fixed;
            &.message-deal {
                right: -1300px;
            }
            .btn-close-msg {
                display: flex;
                z-index: 20;
            }
        }
    }
}

@include mobile {
    .container-messaging {
        margin-top: 20px;
    }
    .all-page {
        & aside:nth-of-type(1) {
            width: 100%;
            min-width: unset;
        }
        & .section-list-messages {
            padding: 0px 20px 0 20px !important;
            .header-bar {
                &>div:nth-of-type(1) {
                    position: initial;
                    &>div:nth-of-type(2) {
                        top: 0em !important;
                        right: 0px;
                    }
                    &>div:nth-of-type(3) {
                        top: 0em !important;
                    }
                }
            }
        }
        .header-list {
            flex-direction: column !important;
            & button {
                margin-top: 1rem;
            }
        }
        & .btn-header {
            min-width: 130px !important;
            font-size: v.$pMobil;
            line-height: initial;
        }
        & section:nth-of-type(1) {
            .profil {
                .profil-info {
                    h3 {
                        font-size: v.$h2Mob !important;
                        line-height: inherit;
                    }
                    & p:nth-of-type(1) {
                        line-height: initial;
                        font-size: v.$px-ts-ul-sm !important;
                    }
                }
            }
        }
    }
    .other {
        margin-bottom: 80px;
    }
    .mobile-safari {
        margin-bottom: 35px;
    }
    .ios-chrome {
        margin-bottom: 120px;
    }
}