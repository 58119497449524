@use '../../../../sass/colors'as colors;
@use '../../../../sass/variables'as variables;
@import '../../../../sass/mixins';
@import '../../../../sass/partials/buttons';

.content-modal-cart {
  width: 60vw;
  max-width: 800px;
  //height          : 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-white-color);
  padding: 63px 10px 22px 10px;
  border-radius: 12px;
  position: relative;
  transition: 0.2s;
  & h3 {
    font-family: Hurme-Bold;
    font-size: variables.$mid-title-size-text * 1.3;
    z-index: 2;
  }
  & p:nth-of-type(1) {
    color: colors.$grey-dark;
    z-index: 12;
    & strong {
      color: colors.$black-dark;
    }
  }
  & .bubleProfile {
    background-color: var(--main-white-color);
    padding: 30px 35px;
    position: absolute;
    border-radius: 100%;
    top: -64px;
    & img {
      @include avatar(90px);
    }
  }
  & .content-card-modal-body {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    & article {
      margin: 0 12px;
    }
    & .selection-vogzs {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 50% 50%;
    }
    & .info{
      background-color: colors.$blue-dark !important;
    }
    & .alert-info {
      width: 60%;
      display: flex;
      background-color: colors.$warning-info;
      align-items: center;
      border-radius: 12px;
      padding: 20px 40px;
      & i {
        font-size: variables.$medium-size-text * 3;
        color: var(--main-white-color);
      }
      & p {
        color: var(--main-white-color);
        margin-left: 20px;
        font-size: variables.$medium-size-text;
        & strong {
          color: var(--main-white-color);
        }
      }
    }
    & .btn-animate {
      pointer-events: all !important;
      @include effect-btn(colors.$blue-dark, 12px, '\f058');
      margin-top: 32px;
      &::before {
        font-weight: 300;
      }
      &.loading {
        padding-inline: 45px;
        text-indent: -20px;
        &:before {
          content: '\f3f4';
          opacity: 1;
          text-indent: 0px;
          transform: rotate(30deg);
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
        }
      }
    }
    & .caroussel-vogz {
      width: 100%;
    }
    & .card-select{
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      & h4{
        font-family: Hurme-Bold;
        font-size: variables.$h2;
        color: var(--main-grey-placeholder-color-text);
      }
      & label{
        font-family: Hurme-Bold;
        font-size: variables.$p;
        color: var(--main-dark-color);
      }
      & input{
        border-radius: 5px;
        border: 0.5px solid #D2DBFF;
        background: var(--main-white-color);
        margin: 5px 0;
        padding: 5px;
        text-transform: uppercase;
      }
      & .btn-animate{
        margin-top: 15px;
        border-radius: 5px;
        background: linear-gradient(91deg, #7AB9EE 0%, var(--main-text-condition-color) 100%);
        width: 100%;
        &:disabled{
          background: var(--main-lavender-color) !important;
          border: none !important;
          color: var(--main-white-color) !important;
          cursor: not-allowed ;
          &:hover{
            background: var(--main-lavender-color) !important;
            
          }
        }
      }
      & .btn-submit{
        &:hover{
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      & .btn-validate{
        background: var(--main-blue-color-bg) !important;
        &:hover{
          background: none !important;
        }
      }
    }
  }
}

@include tablet {
  .content-modal-cart {
    width: 80vw;
    & .content-card-modal-body {
      & .selection-vogzs {
        width: 100%;
      }
      & .alert-info {
        width: 80%;
      }
      & .caroussel-vogz {
        width: 80%;
      }
      & .card-select{
        flex-direction: column;
        align-items: center;
        & article{
          margin-top: 1rem;
        }
        & h4{
          text-align: center;
        }
        & label{
          text-align: center;
        }
        & input{
          width: 100%;
        }
        & .btn-animate{
          width: 100%;
        }
      }
    }
  }
}

@include mobile {
  .content-modal-cart {
    width: 95vw;
    & .content-card-modal-body {
      width: 100%;
      & h3 {
        text-align: center;
      }
      & * {
        max-width: 100%;
      }
      & .selection-vogzs {
        width: 100%;
      }
      & .alert-info {
        width: 90%;
        flex-direction: column;
        & p {
          margin-top: 20px;
          margin-left: auto;
          text-align: center;
        }
      }
      & .caroussel-vogz {
        width: 80vw;
      }
    }
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
