@use '../../../../../../sass/colors'as colors;
@use "../../../../../../sass/variables"as variables;
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/_mixins/global";

.modal-decline-publication{
    top: 70px !important;
    right: auto !important;
}
.parent-modal{
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(7.5px);
    position: absolute;
    z-index: 100000;
    display: flex;
    top: -70px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .decline-publication-modal{
        display: flex;
        width: 420px;
        flex-direction: column;
        border-radius: 1rem;
        --custom-black: #2F2E41;
        border-radius: 25px;
        border: 4px solid #FFF;
        box-shadow: 0px 4px 35px 0px rgba(152, 152, 152, 0.25);
        > div {
            padding: 5vh 4vw 5vh 4vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            &.top-part {
                flex: 1;
                background: var(--main-grey-charcoal);
                position: relative;
                align-self: auto !important;
                border-top-right-radius: 25px;
                border-top-left-radius: 25px;
                .icon-head-modal {
                    color: var(--main-white-color);
                }
                button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: var(--main-white-color);
                }
            }
            &.bottom-part {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: var(--main-white-color);
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
                .form-part {
                    text-align: center;
                    align-items: center;
                    h3 {
                        font-family: Hurme-Bold;
                        font-size: variables.$px-ts-nml;
                        margin: 0.6ch 0;
                        color: var(--main-grey-color-text);
                        width: 60%;
                    }
                    p {
                        font-family: Roboto;
                        font-size: variables.$px-ts-sm;
                        color: var(--main-grey-color-text);
                        width: 60%;
                        line-height: normal;
                        margin-top: 1rem;
                    }
                    select {
                        width: 80%;
                        border-radius: 5px;
                        border: 1px solid var(--main-blue-color-bg);
                        padding: 10px;
                        font-family: Hurme-Bold;
                        font-size: variables.$px-ts-sm;
                        color:var(--main-blue-color-bg);
                        option,::-ms-value {
                            font-family: Roboto;
                            border-radius: 5px;
                            background-color: rgba(255, 255, 255, 0.50);
                            box-shadow: 0px 4px 10px 0px rgba(173, 173, 173, 0.25);
                            backdrop-filter: blur(10px);
                        }
                    }
                    button {
                        min-width: 80%;
                        text-transform: capitalize;
                    }
                }
                br {
                    display: block;
                    content: '';
                    margin-top: 1.7ch;
                }
                .form {
                    padding: 0;
                    margin-top: 0.1ch;
                    * {
                        border: none;
                    }
                    input {
                        height: 2.5rem;
                        border: solid 0.1rem #D2D2D2;
                        border-radius: 0.3rem;
                        background: white;
                        padding: 0 1ch;
                        transition: 0.15s;
                        &::placeholder {
                            opacity: 0.7;
                        }
                        &:focus {
                            border-color: inherit;
                        }
                    }
                }
            }
        }
    }
}

@include tablet { 
    .parent-modal> .decline-publication-modal {
        margin-left: 270px;
    }
    .parent-modal> .decline-publication-modal{
        .bottom-part {
            flex-direction: column!important;
            align-items: center;
            & .form-part{
                margin-bottom: 1rem;
                width: 100%;
            }
        }
    }
    .absolute-container {
        width: 90%;
    }
}
@include tablet2(){
    .parent-modal> .decline-publication-modal{
        width: 60%;
    }

}
@include mobile {
    .parent-modal> .decline-publication-modal {
        width: 95%;
        margin-left: 0 !important;
    }
}

@media (max-width: 1440px) {
    .parent-modal> .decline-publication-modal {
        width: 80%;
        margin-left: 116px;
        max-width: 500px;
        right: 0;
    }
}