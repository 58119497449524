@use "../../../../../sass/variables.scss"as v;
@import "../../../../../sass/mixins";

.btn {
  display        : flex;
  position       : relative;
  justify-content: center;
  padding        : 10px 40px;
  border-radius  : 100px;
  overflow       : hidden;
  box-shadow     : 0px 4px 10px rgba(190, 190, 190, 0.5);
  cursor         : pointer;
  z-index        : 1;
  transition     : .2s ease-in-out;

  div * {
    align-self    : center !important;
    font-family   : Hurme-Black;
    text-transform: uppercase;
    font-size     : max(70%, 1ch);
    text-align    : center;
  }

  &.btn-white {
    background: white;

    a {
      background             : -webkit-linear-gradient(135deg, #79BBED, #9F74FF);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::before {
      border-radius: 50% 50% 0 0;
      background   : linear-gradient(135deg, #9F74FF, #79BBED);
      bottom       : 0;
      height       : 0%;
    }

    &:hover {
      a {
        color                  : white;
        background             : none;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
      }

      &::before {
        height: 180%;
      }
    }
  }

  &.btn-violet {
    * {
      color: white;
    }

    &::before {
      border-radius: 0 0 50% 50%;
      background   : linear-gradient(135deg, #9F74FF, #79BBED);
      top          : 0;
      height       : 180%;
    }

    &:hover {
      background: white;

      a {
        background             : -webkit-linear-gradient(135deg, #79BBED, #9F74FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &::before {
        height: 0%;
      }
    }
  }

  &:before {
    content   : "";
    position  : absolute;
    width     : 100%;
    left      : 0;
    z-index   : -1;
    transition: .2s ease-in-out;
  }

  &.disabled,
  &.disabled * {
    cursor: not-allowed;
    filter: grayscale(100%);
  }

  // &:hover:before {
  //   animation: shine .7s ease-out;
  // }
}

@include tablet2 {
  .btn {
    padding: 12px 23px;
  }
}

@include mobile {
  .btn {
    // min-height: 10vw !important;
    padding   : 10px 30px;

    a {
      font-size: v.$pMobil;
    }
  }
}