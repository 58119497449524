@use "../../../../../sass/colors" as colors;
@use '../../../../../sass/variables.scss' as variables;
@import "../../../../../sass/mixins";
@import "../../../../../sass/_mixins/global";

.bubble {
    position: relative;
    max-width: 60%;
    margin: 0px 0 0 0;
    display: flex;
    flex-direction: column;
    color: var(--main-white-color);
    overflow: hidden;
    line-height: 18px;

    > p {
        overflow-wrap: anywhere;
    }

    > a {
        &:hover {
            text-decoration: underline;
        }
    }

    >span {
        color: var(--main-grey-color-text);
        font-size: variables.$pMobil;
        font-weight: 600;
    }

    &-content {
        width: 100%;
        padding: 10px 12px;
        max-width: 100%;
        border-radius: 12px;
        font-size: 16px;
        font-family: Roboto;
    }
}

.--client,
.--app {
    background-color: var(--main-blue-light);
    float: right;

    &-container {
        background: none;
        float: right;
        align-items: flex-end;
    }

    & svg {
        position: absolute;
        bottom: -12px;
        width: 20px;
        height: 20px;
        right: 12px;
    }
}

.--user {
    background-color: var(--main-blue-chat);
    float: left;

    &-container {
        background: none;
        float: left;
        align-items: flex-start;
    }

    & svg {
        position: absolute;
        bottom: -12px;
        width: 20px;
        height: 20px;
        left: 12px
    }
}

.offer-container {
    float: left;
    .offer {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        background-color: white;
        border-radius: 12px;
        padding: 10px 12px;
        width: 100%;
        color: black;
        transition: all 0.2s ease-in-out;
        &[aria-disabled="true"]{
            &:hover{
                cursor: not-allowed;
                transform: none;
            }
            opacity: 0.5;
        }
        &:hover {
            transform: scale(.95);
            cursor: pointer;
            transition: all 0.2s ease-in-out;
        }

        .offer-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 30px;

            > h2 {
                font-size: 15px;
                font-weight: 700;
                color: #1a1a1a;
                margin: 0;
                font-family: Hurme-Black;
                margin: 0px;
                width: 50%;
            }

            > p {
                border-radius: 5px;
                background: linear-gradient(135deg, #A073FF 0%, #78BCED 100%);
                color: white;
                padding: 5px 10px;
                font-family: Hurme-Black;
                font-size: 13px;

                > span {
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 300;
                }
            }
        }

        .offer-body {
            padding: 0px 10px;

            > p {
                font-size: 13px;
                font-weight: 300;
                color: #1a1a1a;
                margin: 5px auto;
            }

            .offer-duration {
                color: var(--main-text-condition-color);
                font-size: 12px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                margin: 10px auto;

                > span {
                    font-size: 12px;
                    font-family: Hurme-Black;
                    color: var(--main-text-condition-color);
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    @include mobile() {
        .offer {
            padding: 2px;
            .offer-header {
                padding: 5px;
                flex-direction: column-reverse;
                gap: 10px;
            }
            .offer-body {
                padding: 5px
            }
        }
    }
}