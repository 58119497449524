@use "/src/sass/colors" as colors;
@use "../../../../../../sass/variables.scss"as v;
@import '../../../../../../sass/others/animations.scss';
@import '../../../../../../sass/mixins';

$checkbox-size: 16px;
$margin: 16px;
$margin-small: $margin / 2;
$text-lighter: #ccc;
$brand: #12cbc4;
$linear-illustrate: rgba(215, 231, 255, 1);
.loading {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.342);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    z-index: 51;
    display: flex;
    overflow: hidden;
    margin: auto;
    display: flex;
    justify-content: center;
    .loading-modal {
        background-color: white;
        padding: 2em;
        display: flex;
        width: 20vw;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        border-radius: 24px;
        text-align: center;
        margin: auto;
        box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
        transition: height 3s ease-in-out;
        .loading-img-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.5em;
            .loading-img {
                border: 4px dotted #9effd8;
                position: absolute;
                border-top: 4px solid #46ffb1;
                border-bottom: 4px solid #46ffb1;
                margin: 0 auto;
                border-radius: 50%;
                width: 210px;
                height: 210px;
                animation: spin 2s linear infinite;
                transition: 0.7s ease-in-out;
                // z-index: 1;
            }
            .error {
                border: 4px dotted #ff9e9e;
                border-top: 4px solid #ff4646;
                border-bottom: 4px solid #ff4646;
                animation-duration: 10s;
                transition: 0.7s ease-in-out;
            }
            img {
                margin: auto;
                width: 180px;
                object-fit: contain;
            }
            .eyes {
                position: absolute;
                display: flex;
                width: 49px;
                justify-content: space-between;
                transform: translate(1px, 10px);
                .right-eye {
                    border-radius: 50%;
                    background-color: #48ff00fb;
                    width: 12px;
                    height: 12px;
                    z-index: 2;
                    animation: glow 0.7s infinite alternate;
                }
                .left-eye {
                    width: 1px;
                    height: 1px;
                    border-radius: 50%;
                    background-color: #48ff00fb;
                    width: 12px;
                    height: 12px;
                    z-index: 2;
                    animation: glow 0.7s infinite alternate;
                }
                .eye-error {
                    box-shadow: 0 0 10px 10px #ff00008c !important;
                    background-color: #ff0000e3;
                    width: 12px;
                    height: 12px;
                    transition: 0.2s ease-in-out;
                    animation: none !important;
                }
            }
        }
        h1 {
            font-family: Hurme-Bold;
            line-height: 1.8;
        }
        p {
            font-size: min(12px, 4vw);
            color: #7c7c7c;
            line-height: normal;
        }
    }
}

section {
    &:nth-of-type(2) {
        .section-first-form {
            max-width: 400px;
        }
    }
    .word-count {
        float: right;
        right: 35px;
        padding: 4px 10px;
        & span {
            color: var(--main-blue-color-text);
            font-family: Hurme-Bold;
            font-size: v.$px-ts-sm;
        }
    }
    .text-area-describe-brief {
        border: 1px solid var(--main-light-grey);
        // margin-top: 20px;
        outline: 0;
        width: 100%;
        min-height: 200px;
        border-radius: 0.75rem;
        padding: 7px;
        font-size: v.$px-ts-sm;
    }
    .desc-brief {
        .examples-brief {
            display: flex;
            >div {
                box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
                >p {
                    font-size: v.$px-ts-sm;
                    color: var(--main-grey-placeholder-color-text);
                }
            }
        }
        .ai {
            background-color: var(--main-violet-color);
            border-radius: 10px;
            .ai-container {
                display: flex;
                padding: 1em 2em;
                width: 85%;
                margin: auto;
                // justify-content: space-evenly;
                hr {
                    border: none;
                    border-left: 1px solid rgba(255, 255, 255, 0.445);
                    height: 8em;
                    width: 1px !important;
                    margin: auto 3em auto auto;
                }
                .ai-img {
                    img {
                        background: none;
                        max-height: none;
                        width: min(250px, 30vw);
                        margin: auto 1em auto auto;
                        object-fit: contain;
                        padding: 0px;
                        cursor: auto;
                        max-height: 300px;
                    }
                }
                .ai-text {
                    display: flex;
                    flex-direction: column;
                    margin: 1em auto;
                    width: 100%;
                    h1 {
                        font-weight: bold;
                        color: white;
                        margin-bottom: 0.2em;
                        font-family: Hurme-Bold;
                    }
                    p {
                        color: white;
                        font-size: min(16px, 1.5vw);
                    }
                    p:nth-child(3) {
                        font-family: Hurme-Bold;
                    }
                    input {
                        height: 2em !important;
                        padding: 1.5em 1.5em;
                        font-size: min(16px, 3.5vw);
                    }
                    .buttons {
                        button:first-child {
                            width: 40%;
                        }
                    }
                    .ai-btn {
                        width: 80%;
                        color: white;
                        box-shadow: 0px 3px 18px rgba(31, 31, 31, 0.178);
                        border: 1px solid white;
                        font-family: Hurme-Bold;
                        border-radius: 10px;
                        font-size: min(20px, 1.4vw);
                        margin-top: 1.5em;
                        margin-bottom: 0.5em;
                        padding: 0.5em 1em;
                        transition: all 0.3s ease;
                        &:hover {
                            background-color: white;
                            color: var(--main-violet-color);
                        }
                    }
                }
            }
        }
    }
}

.rem-header {
    // width: 70%;
    >p {
        
    }
}

.input-coupon-value {
    border: 1px solid var(--main-light-grey);
    padding: 10px;
    border-radius: 4px;
    outline: 0;
    text-align: center;
    font-family: roboto;
}

.remuneration-brief {
    width: 500px;
    .input-rem {
        input {
            font-family: Hurme-Bold;
            font-size: 35px !important;
            width: 100%;
            margin: auto;
            padding-right: 1.2em !important;
        }
        > span {
            font-size: 35px;
            font-family: Hurme-Bold;
            position: absolute;
            right: 1em;
            transform: translateY(-187%);
        }
    }
    .rem-label {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 5px;
        margin: 5px auto;
        width: 100%;
        >span {
            color: var(--blue-gulf);
            text-align: center;
            font-size: 12px;
            line-height: 1;
            &:nth-child(1) {
                font-weight: bold;
                font-size: 15px;
            }
        }
        hr {
            border: none;
            border-left: 1px solid rgba(0, 0, 0, 0.25);
            height: 1.3em;
            width: 1px !important;
            margin: auto 1em;
        }
    }
    @include tablet() {
        width: 100%;
    }
}

.rem-indicator {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    align-items: center;
    height: 180px;
    animation: wave 5s ease infinite;

    .indicator-content {
        text-align: center;
        width: 40%;
        margin: auto;
        h3 {
            text-transform: uppercase;
            font-size: min(20px, 2.5vw) !important;
            font-weight: bold;
            font-family: Hurme-Bold;
            transition: .3s;
            display: inline !important;

            &::after{
                display: none;
            }
            i {
                font-size: min(20px, 2.5vw);
                margin-right: 5px;
            }
        }
        p {
            font-size: min(16px, 1.5vw);
            color: var(--blue-gulf);
            font-family: Roboto;
            font-weight: 300;
        }
        @include tablet() {
            width: 100%!important;
        }
    }
    @include tablet() {
        // height: 230px;
        h3 {
            font-size: 18px !important;
        }
        p {
            font-size: 14px !important;
        }
    }
}

.indicator-default {
    background: linear-gradient(270deg, #CEE4F9, #EAD0FA);
    background-color: #CEE1F9;
    background-size: 300% 300% !important;
    .indicator-content {
        h3 {
            color: var(--blue-gulf) !important;
        }
    }
}

.indicator-low {
    background: linear-gradient(90deg, #FFCFCD, #FF6660);
    background-color: #FFCFCD;
    background-size: 130% 130% !important;
    .indicator-content {
        h3 {
            color: #D91D1D!important;
        }
    }
}

.indicator-medium {
    background: linear-gradient(90deg, #FFEFB6, #FFDE66);
    background-color: #FFEFB6;
    background-size: 300% 300% !important;
    .indicator-content {
        h3 {
            color: #FFA234 !important;
        }
    }
}

.indicator-high {
    background: linear-gradient(90deg, #D3FFE5, #86FFB6);
    background-color: #D3FFE5;
    background-size: 180% 180% !important;
    .indicator-content {
        h3 {
            color: #13C557 !important;
}
    }
}

.name-brief {
    >h4 {
        font-size: v.$px-ts-nml !important;
        >span {
            color: var(--text-conditions-color);
            font-size: 13px;
            margin-left: 6px;
        }
    }
    .remarks {
        background: var(--main-blue-very-pale);
        box-shadow: 0px 4px 10px rgba(215, 215, 215, 0.25);
        border-radius: 5px;
        padding: 5px 15px;
        font-size: v.$px-ts-sm !important;
    }
    h3 {
        font-family: Hurme-Bold;
        font-style: normal;
        font-size: v.$px-ts-md;
        line-height: 26px;
        color: var(--main-dark-color);
    }
    .steps-scenario {
        .step-scenario {
            background-color: var(--blue-v1);
            color: var(--main-white-color);
            border-radius: 8px;
            display: flex;
            align-items: center;
            max-width: 400px;
            >span {
                font-family: Hurme-Bold;
                font-size: v.$px-ts-sm;
                line-height: 20px;
                text-align: center;
                padding: 4px 8px;
                >strong {
                    font-size: v.$px-ts-nml;
                }
            }
            >input {
                height: 95%;
                width: 100%;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                margin: 1px;
                outline: none;
                padding: 0 10px;
                color: var(--main-dark-color);
                font-size: v.$px-ts-md;
                &::placeholder {
                    font-size: v.$px-ts-sm;
                }
            }
        }
    }
    .desc-remuneration {
        text-align: center;
        color: #7c7c7c;
        font-family: Roboto;
        font-weight: 300;
        font-size: v.$px-ts-sm;
        max-width: 320px;
    }
}

.btn-minus:hover {
    color: var(--main-alert-color) !important;
}

.btn-plus:hover {
    border: 2px solid rgba(132, 128, 255, 1) !important;
    color: rgba(132, 128, 255, 1) !important;
    border-radius: 50px;
}

.requis {
    background-color: #45eaea;
}

.recommended {
    background-color: #9bc6f9;
}

.info-file {
    position: absolute;
    top: 3px;
    border-radius: 20px;
    font-family: Roboto;
    font-size: v.$px-ts-sm;
    color: var(--main-white-color);
    line-height: normal;
    right: 3px;
    padding: 3px 8px;
}

.div-input-file-photo {
    border: double 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #d7e7ff 0%, #96c0ff 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
    .btn-close {
        position: absolute;
        background: var(--main-blue-color-bg);
        border: 0.5px solid #ffffff;
        border-radius: 40px;
        z-index: 1;
        top: 5px;
        right: 5px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        >i {
            color: var(--main-white-color);
            font-size: v.$px-ts-md;
        }
    }
    & .label-file {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 140px;
        width: 150px;
        font-size: v.$px-ts-ul-sm;
        cursor: pointer;
        justify-content: center;
        padding: 20px;
        font-family: Hurme-Bold;
        color: #96c0ff;
        background: #96c0ff;
        background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 100%);
        -webkit-background-clip: text;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
        & p>strong {
            font-family: Roboto;
            color: colors.$periwinkle-blue;
        }
    }
}

.content-medias {
    & .div-media {
        max-width: 470px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(----main-text-condition-color);
        font-family: Hurme-Bold;
        font-size: v.$low-size-text;
        & i:nth-of-type(2) {
            color: #afc0ff;
            cursor: pointer;
            &:hover {
                color: var(--main-text-condition-color);
            }
        }
    }
}

.div-input-file {
    & label {
        width: 100%;
    }
    & .label-file {
        border: 2.5px dashed #67a4ff;
        border-radius: 4px;
        background: colors.$alice-blue3;
        height: 250px;
        width: 100%;
        max-width: 890px;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-direction: column;
        font-size: v.$px-ts-sm;
        justify-content: center;
        padding: 20px;
        font-family: Hurme-Bold;
        color: #67a4ff;
        text-align: center;
        & i {
            color: colors.$periwinkle-blue !important;
            font-size: 45px;
            line-height: normal;
            -webkit-text-fill-color: colors.$periwinkle-blue;
        }
        & p {
            color: #67a4ff;
        }
        & p>span {
            font-family: Roboto;
            font-weight: 400;
            color: colors.$periwinkle-blue;
        }
    }
}

.section-first-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    width: 95%;
    max-width: 95%;
    .active-plus-casting {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .age-inputs {
            display: flex;
            flex-direction: row;
        }
        .optionnal::after {
            font-size: v.$px-ts-sm;
            content: '(optionnel)';
            margin-left: 5px;
            color: var(--main-blue-color-bg);
        }
        .checkboxes {
            display: flex;
            margin: 0 12px;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: fit-content;
            >label {
                span {
                    &.casting-plus span {
                        background: linear-gradient(93.26deg, #a785f0 0%, #627aff 100%);
                        color: var(--main-white-color);
                        border-radius: 3px;
                        font-family: Hurme-Bold;
                        text-transform: uppercase;
                        padding: 3px 9px;
                        margin: 0 5px 2px 5px;
                    }

                    &.deal-plus span {
                        background: linear-gradient(151.35deg, #FFD806 0%, #FFEC84 100%);
                        color: var(--main-white-color);
                        border-radius: 3px;
                        font-family: Hurme-Bold;
                        padding: 3px 9px;
                        margin: 0 5px 2px 5px;
                        color: black;
                    }

                    &.casting-on-spot span {
                        background: linear-gradient(151.35deg, #5EDCE4 0%, #33A8FC 100%);
                        color: var(--main-white-color);
                        border-radius: 3px;
                        font-family: Hurme-Bold;
                        text-transform: uppercase;
                        padding: 3px 9px;
                        margin: 0 5px 2px 5px;
                        color: white;
                    }
                }
            }
        }
    }
    input[data-gradient-input='true'] {
        width: 80px;
        outline: 0;
        font-size: 50px;
        text-align: center;
        font-family: Hurme-Bold;
        background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .content-time-vogz {
        align-items: center;
        display: flex;
        margin-top: 20px;
        justify-content: center;
        width: 100%;
        &>div {
            max-width: 170px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                font-size: v.$px-ts-md;
                color: var(--dashboard-blue-v1);
                &:nth-of-type(1) {}
                &:nth-of-type(2) {
                    padding: 3px 12px;
                    font-family: Hurme-Bold;
                    background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    box-shadow: 0px 2px 7px rgba(190, 190, 190, 0.5);
                    border-radius: 4px;
                }
            }
        }
    }
    img {
        max-height: 180px;
        display: block;
        cursor: pointer;
        background: #ffffff;
        border-radius: 12px;
        padding: 20px;
        width: 180px;
        height: fit-content;
        width: fit-content;
        transition: 0.4s all ease-in-out;
        &.active {
            box-shadow: 0px 4px 12px rgba(190, 190, 190, 0.5);
        }
    }
    fieldset {
        border: none;
        display: flex;
        align-items: flex-start;
    }
    label {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .card-add-fav {
        &:nth-child(1) {
            border: double 2px transparent;
            background-image: linear-gradient(white, white), radial-gradient(circle at top left, #d7e7ff 0%, #96c0ff 100%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            cursor: pointer;
            & img {
                border-radius: 0px;
            }
            & p {
                background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-family: Hurme-Bold;
                font-size: v.$px-ts-ul-sm;
            }
        }
        position: relative;
        display: flex;
        box-shadow: 0px 4px 50px rgba(190, 190, 190, 0.25);
        border-radius: 10px;
        width: 146px;
        height: 140px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        & i {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            &:hover {
                color: #7c7c7c;
            }
        }
        & img {
            padding: 0;
            width: 72px;
            height: 72px;
            border-radius: 50px;
        }
        & .name-vogzter {
            font-family: Hurme-Bold;
            margin-top: 10px;
            font-size: calc(v.$p * 1.25);
            color: var(--main-dark-color) !important;
        }
        .btn-selection {
            bottom: 0px;
            //positon: absolute;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: row;
        }
    }
}

.checkbox-casting {
    display: flex;
    align-items: center;
    margin-top: 10px;
    label {
        font-family: Hurme-Bold;
        margin-left: 5px;
        font-size: v.$px-ts-sm;
        color: var(--main-dark-color) !important;
    }
}
.targeted-social{
    & input {
        display: none;
        &:checked + label {
            background-color: var(--main-blue-mystified);
            color: var(--main-white-color);
            box-shadow: 0px 4px 10px 0px rgba(215, 215, 215, 0.25);
        }
    }
    & label{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 190px;
        height: 100%;
        border-radius: 5px;
        border: 1px solid var(--main-blue-color-bg);
        color: var(--main-blue-color-bg);
        padding: 10px;
        font-family: Hurme-Bold;
        cursor: pointer;
        text-transform: capitalize;
        font-weight: 700;
        transition: 0.2s all ease-in-out;
        box-shadow: 0px 4px 10px 0px rgba(215, 215, 215, 0.25);
        &:hover{
            background-color: var(--main-blue-mystified);
            color: var(--main-white-color);
        }
        & i{
            margin-right: 15px;
        }

    }
}
.property-rights-checkbox{
    display: flex;
    align-items: flex-start;
    select{
        margin-right: .5rem;
        width: auto!important;
        font-family: Hurme-Bold;
        color: var(--main-blue-mystified);
        line-height: initial;
        font-size:v.$medium-size-text;
    }
    label{
        font-family: Hurme-Bold;
        color: var(--main-blue-mystified);
        line-height: initial;
        font-size:v.$medium-size-text;
        margin-left:.2rem;
    }
    p{
        color: var(--main-grey-text);
        font-family: Roboto;
        font-size: v.$pMobil;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.countries-part{
    display: flex;
    align-items: flex-start;
    input{
        margin-right: .5rem;
        width: auto!important;
    }
    label{
        font-family: Hurme-Bold;
        color: var(--main-blue-mystified);
        line-height: initial;
        font-size:v.$medium-size-text;
    }
    p{
        color: var(--main-grey-text);
        font-family: Roboto;
        font-size: v.$pMobil;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.Modal {
    position: absolute;
    display: flex;
    top: 40px;
    left: 30%;
    right: 40px;
    bottom: 40px;
    max-width: 900px;
    max-height: 700px;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    & .div-card-select {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        & .card-select-fav {
            display: grid;
            flex-direction: row;
            flex-wrap: wrap;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
            justify-content: space-around;
            grid-column-gap: 0rem;
            column-gap: 0rem;
            grid-row-gap: 3rem;
            row-gap: 3rem;
            box-sizing: border-box;
            justify-items: center;
            margin: 1rem;
        }
    }
}

.Overlay {
    position: fixed;
    top: 0;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.card-end {
    width: 100%;
    & .head-card-end {
        & .title {
            font-family: Hurme-Bold;
            font-size: v.$h1;
            color: var(--main-violet-color-text);
            text-align: center;
        }
        .casting-plus {
            background: linear-gradient(93.26deg, #a785fe 0%, #627aff 100%);
            color: var(--main-white-color);
            border-radius: 5px;
            font-size: v.$low-size-text;
            font-family: Hurme-Bold;
            padding: 5px 9px;
        }
        & p {
            text-align: center;
            color: var(--main-grey-color-text);
            font-family: Roboto;
            font-size: v.$small-size-p;
            margin-top: 0.5rem;
        }
    }
    & .footer-card-end {
        display: flex;
        justify-content: center;
        .link-summary-brief {
            background: #ffffff;
            box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
            border-radius: 4px;
        }
    }
}

.card-image {
    height: 300px;
    width: 100%;
    & .slick-track:nth-child(1) {
        opacity: 0.2;
    }
    & .div-card-image {
        max-width: 380px;
        max-height: 250px;
        border: 2px solid #ffffff;
        filter: drop-shadow(0px 4px 25px rgba(190, 190, 190, 0.25));
        border-radius: 10px;
        & img {
            width: 100%;
            height: 100%;
        }
    }
}

.card-settings {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    & .div-settings {
        display: flex;
        height: 100px;
        max-width: 700px;
        width: 100%;
        align-items: center;
        & .div-ratio-landscape {
            & img {
                max-height: 80px;
            }
        }
        & .div-ratio-portrait {
            & img {
                max-width: 40px;
            }
        }
        & .div-ratio {
            border: 1.5px solid #e5eaff;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & .div-time {
            border: 1.5px solid #e5eaff;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & p {
                font-family: Roboto;
                color: var(--main-grey-color-text);
                font-size: v.$p;
                & strong {
                    background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    font-family: Hurme-Bold;
                    font-size: v.$h1;
                }
                span {
                    font-size: v.$p;
                    background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    font-family: Hurme-Bold;
                }
            }
        }
    }
}

.card-brief {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    & h4 {
        font-family: Hurme-Bold;
        font-size: v.$h2;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
    & p {
        font-size: v.$p;
        font-family: Roboto;
        max-width: 800px;
        color: var(--main-grey-color-text);
    }
    & li {
        font-size: v.$p;
        font-family: Roboto;
        max-width: 800px;
        color: var(--main-grey-color-text);
        &::marker {
            font-size: 10px;
            line-height: 16px;
        }
    }
    .div-scenario .step-scenario {
        background-color: var(--blue-v1);
        color: var(--main-white-color);
        border-radius: 8px;
        display: flex;
        align-items: center;
        max-width: 400px;
        >span {
            font-family: Hurme-Bold;
            font-size: v.$px-ts-sm;
            line-height: 20px;
            text-align: center;
            padding: 4px 8px;
            >strong {
                font-size: v.$px-ts-nml;
            }
        }
        >p {
            height: 95%;
            width: 100%;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            margin: 1px;
            background-color: var(--main-white-color);
            outline: none;
            padding: 10px;
            font-size: v.$px-ts-md;
        }
    }
}

.section-media {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    & h4 {
        font-family: Hurme-Bold;
        font-size: v.$h2;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
    & p {
        font-size: v.$p;
        font-family: Roboto;
        max-width: 800px;
        color: var(--main-grey-color-text);
    }
    .div-inspiration {
        box-shadow: 0px 4px 20px rgba(190, 190, 190, 0.25);
        width: 100%;
        padding: 3rem 0;
        & .card-inspiration {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
        }
    }
    .card-music-script {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        width: 80%;
        & .div-media {
            max-width: 470px;
            width: 320px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--text-conditions-color);
            font-family: Hurme-Bold;
            & p {
                margin-left: 1rem;
            }
            & i {
                margin-left: 1rem;
            }
        }
    }
    .div-link {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        & a {
            max-width: 470px;
            width: 320px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #0182ca;
            font-family: Hurme-Bold;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    & h4 {
        font-family: Hurme-Bold;
        font-size: v.$h2;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
    & p {
        font-size: v.$p;
        font-family: Roboto;
        max-width: 800px;
        color: var(--main-grey-color-text);
    }
    .div-inspiration {
        box-shadow: 0px 4px 20px rgba(190, 190, 190, 0.25);
        width: 100%;
        padding: 3rem 0;
        & .card-inspiration {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
        }
    }
    .card-music-script {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        width: 80%;
        & .div-media {
            max-width: 470px;
            width: 320px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--text-conditions-color);
            font-family: Hurme-Bold;
            & p {
                margin-left: 1rem;
            }
            & i {
                margin-left: 1rem;
            }
        }
    }
    .div-link {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        & a {
            max-width: 470px;
            width: 320px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #0182ca;
            font-family: Hurme-Bold;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}

.section-brief-type-radio {
    display: flex;
    flex-wrap: wrap;
}

@include tablet {
    .Overlay {
        left: 0%;
    }
    .Modal {
        & .card-select-fav {
            grid-template-columns: repeat(2, 1fr) !important;
        }
    }
    .desc-brief {
        .ai {
            .ai-container {
                padding: 0.5em !important;
                width: 100% !important;
                .ai-img {
                    margin: auto;
                    img {
                        margin: auto !important;
                        width: 350px !important;
                    }
                }
                .ai-text {
                    .buttons {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        button:first-child {
                            width: 80% !important;
                        }
                        button:nth-child(2) {
                            margin-top: 10px !important;
                        }
                    }
                }
            }
        }
    }
    .loading {
        .loading-modal {
            width: min(400px, 50vw);
            .loading-img-wrapper {
                .loading-img {
                    width: 12em;
                    height: 12em;
                }
            }
        }
    }
    .section-first-form {
        .active-plus-casting {
            .checkboxes {
                width: 100%;
            }
        }
    }
    .section-brief-type-radio {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            display: flex;
            align-self: center;
            flex-direction: column;
            margin-top: 0.2rem;

            * {
                align-self: flex-start;
            }
        }
    }
}

@include mobile {
    section {
        .section-first-form {
            margin-top: 20px;
            flex-direction: column;
            .content-time-vogz {
                flex-direction: column;
            }
            .active-plus-casting {
                .age-inputs {
                    flex-direction: column;
                }
            }
        }
        .section-brief-type-radio {
            h4 {
                text-align: center;
            }
        }
    }
    .Overlay {
        left: 0%;
    }
    .Modal {
        max-width: 900px;
        max-height: 600px;
        & .card-select-fav {
            grid-template-columns: repeat(1, 1fr) !important;
        }
    }
    .desc-brief {
        .ai {
            .ai-container {
                padding: 1.5em !important;
                width: 100% !important;
                flex-direction: column !important;
                
                .ai-img {
                    margin: auto;
                    img {
                        margin: auto !important;
                        width: 200px !important;
                    }
                }
                hr {
                    display: none;
                }
                .ai-text {
                    p {
                        font-size: min(13px, 6vw) !important;
                    }
                }
                .ai-btn {
                    width: 100% !important;
                    font-size: min(15px, 6vw) !important;
                }
            }
        }
    }
    .loading {
        .loading-modal {
            width: 70%;
            .loading-img-wrapper {
                .loading-img {
                    img {
                        width: 40vw;
                    }
                }
            }
        }
    }
}