@use "../../../../../../sass/variables.scss"as v;
@import '../../../../../../sass/others/animations.scss';
@import '../../../../../../sass/mixins';

.container-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .container-notification-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 30rem;
    h1 {
      font-family: Hurme-Bold;
      font-size: calc(v.$h1Mob + 5px);
      text-align: center;
      margin-bottom: 0.5rem;
    }
    p {
      font-family: Roboto;
      font-size: v.$px-ts-md;
      text-align: center;
      color: var(--main-grey-color-text);
      line-height: initial;
    }
  }
  .array-notification {
    margin-top: 4rem;
    max-width: 900px;
    width: 100%;
    .table-desktop {
      tr {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          td:nth-of-type(1) {
            width: 50%;
            color: var(--main-dark-blue-text-color);
            font-family: Hurme-Bold;
            font-size: v.$px-ts-md;
            text-align: start;
          }
        }
        td {
          text-align: center;
          padding: 0.5rem;
          &.center-col {
            border: 1px solid transparent;
            box-shadow: -0.5px 0 0 var(--main-dark-blue-text-color),
              0.5px 0 0 var(--main-dark-blue-text-color);
            padding: 0.5rem 0;
          }
          &.last-col {
            padding: 0.5rem 0.5rem 0.5rem 0.5px;
            border-right: none !important;
            &>div {
              border-radius: 0 4px 4px 0;
            }
          }
          p {
            padding: 0.5rem;
          }
        }
        &.champs-all-team,
        &.champs-team {
          & .first-col-center {
            padding: 0.5rem 0;
            &>div {
              border-radius: 4px 0 0 4px;
            }
          }
        }
      }
      tr.header-table {
        border-bottom: 1px solid transparent;
        box-shadow: 0px 0.5px 0px var(--main-dark-blue-text-color);
        td {
          font-family: Roboto;
          color: var(--main-dark-blue-text-color);
          font-size: v.$px-ts-md;
          &:nth-child(4) {
            border-right: none !important;
          }
        }
        td:nth-of-type(1) {
          font-size: v.$h2Mob;
          color: var(--main-dark-color);
          font-family: Hurme-Bold;
        }
      }
      tr.champs-all-team {
        border-bottom: 1px solid transparent;
        box-shadow: 0px 0.5px 0px var(--main-dark-blue-text-color);
        td:nth-of-type(1) > p {
          background-color: var(--main-light-blue-magenta);
          border-radius: 4px;
        }
        td {
          &> div {
            background-color: var(--main-light-blue-magenta);
          }
        }
      }
      .champs-team {
        .first-col,
        .second-col {
          text-align: start;
          p {
            background-color: var(--main-ghost-white);
            border-radius: 4px;
          }
        }
        .first-col {
          width: 20%;
          p {
            font-family: Hurme-Bold;
            font-size: v.$px-ts-md;
          }
        }
        .second-col {
          width: 30%;
          p {
            font-family: Roboto;
            font-size: v.$px-ts-sm;
            color: var(--main-dark-blue-text-color);
          }
        }
        & td {
          &> div {
            background-color: var(--main-ghost-white);
          }
        }
      }
    }
    .table-mobile {
      display: none;
    }
    .container-btn{
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      padding-bottom: 2rem;
      button{
        width: 100%;
        max-width: 20rem;
        min-width: 15rem;
      }
    }
  }
}
@include tablet{
  .container-notification { 
    justify-content:flex-start;
    margin-top: 4rem;
    .container-notification-header {
      h1 {
        font-size: calc(v.$h2);
      }
      p {
        font-size: v.$px-ts-sm;
      }
    }
    .array-notification {
      .table-mobile{
        display: flex;
        justify-content: center;
        tr{
          min-width: 365px;
        }
        td{
          min-width: 120px;
        }
        & .champs-all-team td{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: var(--main-dark-blue-text-color);
          font-family: Hurme-Bold;
          font-size: v.$px-ts-sm;
          background-color: var(--main-light-blue-magenta);
          border-radius: 4px;
          padding: 1rem 0.5rem;;
          line-height: normal;
        }
        .tr-btn:nth-child(1), .tr-btn:nth-child(2){
          background-color: var(--main-light-blue-magenta);
        }
        .tr-btn{
          margin-top: .5rem;
          background-color: var(--main-ghost-white);

          border: 0.5px solid rgb(58 65 97 / 70%);
          border-radius: 4px;
          font-size: v.$px-ts-sm;
          font-family: Roboto;
          color: var(--main-white-color);
          font-weight: 300;
          text-align: center;
          .tr-head-btn{
            background-color: var(--main-dark-blue-text-color);
            td{
              padding: .5rem;
            }
            .td-center{
              border-inline: 0.5px solid rgb(255 255 255 / 70%);
            }
          }
          .tr-body-btn{
            .td-center{
              border-inline: 0.5px solid rgb(58 65 97 / 70%);
            }
          }
            
        }
        .first-col{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: var(--main-dark-blue-text-color);
          font-family: Hurme-Bold;
          font-size: v.$px-ts-sm;
          background-color: var(--main-ghost-white);
          border-radius: 4px;
          padding: .5rem;
          margin-bottom: .5rem;
          line-height: normal;
          .info-member{
            & p:last-child{
              font-family: Roboto;
              font-size: v.$px-ts-ul-sm;
              font-weight: 300;
            }
          }
        }
        hr{
          margin: 1rem 0;
          border-bottom: 0.5px solid var(--main-dark-blue-text-color);
        }

      }
      .table-desktop{
        display: none;
      }
    }
  }
}
