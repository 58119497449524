
@use "../../../../../sass/variables.scss"as v;
@import "../../../../../sass/mixins";
@import "../../../../../sass/_mixins/global";

.main-profile{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  margin-right: 1rem;
}

.form-container{
  display: flex;
  min-height: 56px;
  max-width: 600px;
  justify-content: space-between;
}

.form-input{
  height: auto;
  width: 100%;
  max-width: 450px;
  background: #FCFCFF;
}

form:last-child .form-input {
  cursor: pointer;
}

.img-picker-container{
  height: auto;
  padding: 7%;
  border: #F5F4FF solid 3px;
  border-radius: 10px;
  max-height: 150px;
  background: #FCFCFF;
}

.form-btn{
  width: 160px;
  align-self: center;
  display: flex;
  margin-left: 1em;
  align-items: center;
  justify-content: flex-end;
  button{
    min-width: auto;
    width: 110px;
  }
}

.form-label{
  font-family: Hurme-Bold;
  font-weight: bold;
  color: var(--main-dark-color);
}
.line{
  display: none;
}
.part-cancel-subcription{
  h3{
    font-family: Hurme-Bold;
    font-size: v.$h2Mob;
    color: var(--main-perano-purple);
  }
  p{
    font-family: Roboto;
    font-weight: 300;
    font-size: v.$pMobil;
    color: var(--main-grey-color-text);
    line-height: normal;
    margin-right: .5rem;
  }
  button{
    font-family: Roboto;
    font-weight: 500;
    font-size: v.$pMobil;
    border-radius: 35px;
    border: .5px solid var(--main-blue-mobile);
    color: var(--main-blue-mobile);
    background-color: transparent;
    height: 1.5rem;
    min-width: 38%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 9.5rem;
    &:hover{
      color: var(--main-white-color);
      border: .5px solid var(--main-blue-mobile);
      background-color: var(--main-blue-mobile);
    }
  }
}
.part-credit{
  background: linear-gradient(95.67deg, #9C7AFE 0%, #7AB9EE 100%);
  border-radius: 10px;
  height: 120px;
  min-width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    color: var(--main-white-color);
    font-size: calc(v.$h1*2.5);
    font-family: Hurme-Bold;
    margin-right: .5rem;
  }
  h3{
    display: flex;
    flex-direction: column;
    color: var(--main-white-color);
    font-family: Roboto;
    font-size: v.$h2;
    span{
      font-family: Hurme-Bold;
      font-size: v.$h2;
      text-transform: uppercase;
    }
  }
}
@media screen  and (max-width: 1530px){
  .main-profile{
    flex-direction: column;
}
}
@media screen  and (max-width: 1024px){
  .part-credit{
    margin-top: 1rem;
    width: 80%;
    min-width: 0;
    padding: 0 .3em;
    span {
      font-size: calc(v.$h1*1.6);
    }

    h3 {
      font-size: calc(v.$h2*.7);
    }
  }
}
@include mobile{
.part-cancel-subcription{
  p{
    margin-left: 0;
    margin-bottom: .5rem;
  }
}
.main-profile{
  padding: 0;
  margin: 0;
}
}

