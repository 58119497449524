@use "../../../../../../sass/variables.scss"as v;
@import '../../../../../../sass/others/animations.scss';
@import '../../../../../../sass/mixins';

.no-scroll {
  overflow: hidden;
  height: 100%;
}
.card-end {
  width: 100%;
  & .head-card-end {
    & .title {
      font-family: Hurme-Bold;
      font-size: v.$h1;
      color: var(--main-violet-color-text);
      text-align: center;
    }
    .casting-plus {
      background: linear-gradient(93.26deg, #a785fe 0%, #627aff 100%);
      color: var(--main-white-color);
      border-radius: 5px;
      font-size: v.$low-size-text;
      font-family: Hurme-Bold;
      padding: 5px 9px;
    }
    & p {
      text-align: center;
      color: var(--main-grey-color-text);
      font-family: Roboto;
      font-size: v.$small-size-p;
      margin-top: 0.5rem;
    }
  }
  & .footer-card-end {
    display: flex;
    justify-content: center;
    .link-summary-brief {
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
      border-radius: 4px;
    }
  }
}

.card-image {
  height: auto;
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  min-height: 250px !important;
  flex-wrap: wrap;
  .content {
    overflow-x: scroll;
    display: flex;
  }
  .slider {
    margin: 3rem 0px;
  }

  & .div-card-image {
    // opacity: 0.2;
    margin: 1rem;
    min-width: 320px;
    max-height: 250px;
    height: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 25px rgba(190, 190, 190, 0.25));
    & img,
    video {
      border-radius: 10px;
      border: 2px solid #ffffff;
      width: auto;
      height: 100%;
    }
  }
}
.update{
  border: 1.5px solid transparent;
  position: relative;
  &:hover{
    border: 1.5px solid blue !important;
    button{
      position: absolute;
      top: 10px;
      min-width: 95px;
      width: 19px;
      right: 10px;
      display: flex;
      text-transform: capitalize;
      border-radius: 30px;
      justify-content: center;
      padding: 0px;
      z-index: 99999;
    }
  }
  button{
    display: none;
  }
}
.card-settings {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0 2rem;
  & .div-settings {
    display: flex;
    height: 100px;
    max-width: 700px;
    width: 100%;
    align-items: center;
    & .div-ratio-landscape {
      & img {
        max-height: 80px;
      }
    }
    & .div-ratio-portrait {
      & img {
        max-width: 40px;
      }
    }
    & .div-ratio {
      border: 1.5px solid #e5eaff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    & .div-time {
      border: 1.5px solid #e5eaff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      & p {
        font-family: Roboto;
        color: var(--main-grey-color-text);
        font-size: v.$p;
        display: flex;
        flex-direction: row;
        gap: 10px;
        & strong {
          background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-family: Hurme-Bold;
          font-size: v.$h1;
        }
        span {
          font-size: v.$p;
          background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-family: Hurme-Bold;
        }
      }
    }
  }
}
.section-favourite {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  .div-favourite {
    box-shadow: 0px 4px 20px rgb(190 190 190 / 25%);
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .card-add-fav {
      position: relative;
      display: flex;
      border-radius: 10px;
      width: 200px;
      height: 200px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      & img {
        padding: 0;
        width: 108px;
        height: 108px;
        border-radius: 54px;
      }
      & .name-vogzter {
        font-family: Hurme-Bold;
        margin-top: 10px;
        font-size: calc(v.$p * 1.25);
        color: var(--main-dark-color) !important;
      }
      &:nth-child(1) {
        border: double 2px transparent;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left, #d7e7ff 0%, #96c0ff 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        cursor: pointer;
        & img {
            border-radius: 0px;
        }
        & p {
            background: linear-gradient(119.05deg, #d7e7ff 0%, #96c0ff 99.56%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-family: Hurme-Bold;
            font-size: v.$px-ts-ul-sm;
        }
    }

    }
  }
  &.div-favourite-error{
    &:hover{
      border: 1.5px solid var(--main-alert-color) !important;
    }
  }
}
.card-brief {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 2rem;
  & h4 {
    font-family: Hurme-Bold;
    font-size: v.$h2Mob;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  & p {
    font-size: v.$p;
    font-family: Roboto;
    max-width: 800px;
    line-height: normal;
    color: var(--main-grey-color-text);
  }
  & li {
    font-size: v.$p;
    font-family: Roboto;
    max-width: 800px;
    color: var(--main-grey-color-text);
    &::marker {
      font-size: 10px;
      line-height: 16px;
    }
  }
  .div-scenario .step-scenario {
    background-color: var(--blue-v1);
    color: var(--main-white-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    max-width: 400px;
    > span {
      font-family: Hurme-Bold;
      font-size: v.$px-ts-sm;
      line-height: 20px;
      text-align: center;
      padding: 4px 8px;
      > strong {
        font-size: v.$px-ts-nml;
      }
    }
    > p {
      height: 60px;
      width: 100%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin: 1px;
      background-color: var(--main-white-color);
      outline: none;
      padding: 10px;
      font-size: v.$p;
      display: flex;
      align-items: center;
    }
  }
}

.section-media {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  & h4 {
    font-family: Hurme-Bold;
    font-size: v.$h2Mob;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  & p {
    font-size: v.$p;
    font-family: Roboto;
    max-width: 800px;
    color: var(--main-grey-color-text);
  }
  .div-inspiration {
    box-shadow: 0px 4px 20px rgba(190, 190, 190, 0.25);
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    h4 {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    & .card-inspiration {
      // display: flex;
      // overflow-x: scroll;
      width: 100%;
      // flex-direction: row;
      .card-body {
        position: relative;
        justify-content: center;
        display: flex;
        height: 200px;
        width: 350px;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
        video {
          width: 100%;
          height: 100%;
          position: relative;
          img {
            position: absolute;
          }
        }
        p {
          font-family: Hurme-Bold;
          text-transform: uppercase;
          font-size: v.$pMobil;
          color: var(--text-conditions-color);
        }
      }
    }
  }
  .card-music-script {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
    & .div-media {
      max-width: 470px;
      width: 320px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--text-conditions-color);
      font-family: Hurme-Bold;
      & p {
        margin-left: 1rem;
        font-family: Hurme-Bold;
        text-transform: uppercase;
        font-size: v.$pMobil;
        color: var(--text-conditions-color);
      }
      & i {
        margin-left: 1rem;
      }
    }
  }
  .div-link {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & a {
      max-width: 470px;
      width: 100%;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(190, 190, 190, 0.25);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--main-blue-mobile);
      font-family: Hurme-Bold;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: v.$p;
      line-break: anywhere;
      text-align: center;
    }
  }
}
.section-in-app {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  height: 100%;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 10;
  & .in-app {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .modal {
      position: relative;
      animation: fadeIn ease 2s;
      -webkit-animation: fadeIn ease 2s;
      -moz-animation: fadeIn ease 2s;
      -o-animation: fadeIn ease 2s;
      -ms-animation: fadeIn ease 2s;
      .content {
        position: absolute;
        overflow-x: scroll;
        top: 18px;
        background: var(--main-white-color);
        z-index: 1;
        border-radius: 34px;
        left: 22px;
        width: 275px;
        height: 89%;
        transform-origin: top;
        transform: scaleY(1.06);
        .btnFeed{
          width: 100%;
          box-shadow: 0px 8px 10px rgb(190 190 190 / 50%);
          button{
            width: 100%;
            border-radius: 0;
            text-transform: none;
          }
        }
        .param-brief {
          padding: .5rem;
          box-shadow: 0px 0px 16px rgba(190, 190, 190, 0.25);
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          text-align: center;
          width: 100%;
          p {
            color: var(--main-grey-color-text);
            font-family: Roboto;
            font-size: v.$px-ts-ul-sm;
            line-height: normal;
          }
          span {
            font-family: Hurme-Bold;
            font-size: v.$pMobil;
            line-height: normal;
          }
        }
        .slide-img{
          display: flex;
          width: 275px;
          height: 250px;
          justify-content: center;
          align-items: center;
          img{
            object-fit: contain;
            width: auto;
            height: 250px;
          }
        }
        .card-inspiration{
          width: 275px;
          height: 250px;
        }
        .info-brief {
          h2 {
            color: var(--main-dark-color);
            font-size: v.$px-ts-sm !important;
          }
          p{ 
            text-transform: none;
          }
          .header{
            display: flex;
            justify-content: space-between;
            width: 100%;
            box-shadow: 0px 0px 15px rgb(190 190 190 / 50%);
            padding-top: 2rem;
            padding: 1.5rem 1rem 1rem;
            align-items: center;
            align-content: center;
            img{
              height: auto;
              max-width: 50px;
            }
          }
        }
        .head {
          button{
            margin-left: .5rem;
            margin-top: 1rem;
          }
          .info-brief {
            padding: 1rem;
            img {
              border-radius: 20px;
              width: auto;
              height: 40px;
            }
            h2 {
              margin-top: 0.5rem;
              color: var(--main-dark-color);
              font-size: v.$px-ts-md !important;
            }
            p {
              font-size: v.$px-ts-md !important;
              font-family: Roboto;
            }
          }
          
        }
        .body {
          display: flex;
          flex-direction: column;
          // height: 55%;
          .bg-card{
            background: #DAF4FF;
            color: var(--main-dark-color) !important ;
            li{
              color: var(--main-dark-color);
              &::marker{
                color: var(--main-dark-color);
              }
            }
            img{
              position: absolute;
              right: 20px;
              top: 25%;
              z-index: 1;
            }
            p{
              width: 80%;
            }
          }
          h4 {
            font-family: Hurme-Bold;
            // margin-bottom: .5rem;
            color: var(--main-dark-color);
            font-size: v.$px-ts-md !important;
          }
          p {
            color: var(--main-grey-color-text);
            font-family: Roboto;
            font-size: v.$pMobil;
            line-height: initial;
            z-index: 2;
            strong{
              color: var(--main-blue-color-bg);
            }
          }
          button{
            background-color: var(--main-blue-color-bg);
            border: 1px solid transparent;
            color: var(--main-white-color);
            font-family: Hurme-Regular;
            font-size: v.$pMobil;
            padding: 2px 6px;
            border-radius: 7px;
            min-width: 50%;
            transition: all 0.2s ease-in-out;
            line-height: normal;
            margin-top: .5rem;
            z-index: 2;
            &:hover{
              background-color: var(--main-white-color);
              border: 1px solid var(--main-blue-color-bg);
              color: var(--main-blue-color-bg);
            }
          }
          li{
            color: var(--main-grey-color-text);
            font-family: Roboto;
            font-size: v.$pMobil;
            line-height: initial;
            &::marker{
              font-size: 20px;
              color: var(--main-grey-color-text);
            }
          }
          .step-scenario{
            &:nth-child(odd){
              background-color:var(--dashboard-blue-v1);
              span{
                color: var(--main-white-color);
              }
            }
            &:nth-child(even){
              background-color:#DCEEFF;
              & span{
                color: var(--main-dark-color);
                border-right: 1px solid var(--main-dark-color);
              }
              p{
                color: var(--main-dark-color);
              }
            }
            span{
              font-size: v.$px-ts-ul-sm;
              padding: 0.5rem;
              display: flex;
              width: 70px;
              border-right: 1px solid var(--main-white-color);
              flex-direction: column;
              align-items: center;
              font-family: Roboto;
              line-height: normal;
              height: 60px;
              justify-content: center;
              strong{
                font-family: Hurme-Bold;
                font-size: v.$px-ts-md;
              }
            }
            p{
              padding: .5rem;
              color: var(--main-white-color);
              width: 100%;
            }
          }
          a{
            color: var(--main-blue-mobile);
            font-family: Hurme-Semi-Bold;
            font-size: v.$pMobil;
          }
        }
      }
    }
  }
}
.modal-credit-vogz{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.3);
  position: relative;
}

.modal-header {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: white;
  overflow: hidden;
  > img {
    z-index: 2;
    height: 75px;
    &:nth-of-type(2) {
      translate: 0 5%;
    } 
  }
  > span {
    position: absolute;
    border-radius: 100%;
    filter: blur(45px);
    z-index: 1;
    &:nth-of-type(1) {
      width: 310.75px;
      height: 119.98px;
      top: -20%;
      left: -25%;
      background: #ABBBF4;
    }
    &:nth-of-type(2) {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 228.33px;
      height: 131.98px;
      margin: auto;
      background: #BAB7FC;
    }
    &:nth-of-type(3) {
      bottom: -35%;
      left: -30%;
      width: 291.49px;
      height: 122.46px;
      background: #CFD9FE;
    }
    &:nth-of-type(4) {
      top: -20%;
      right: -30%;
      width: 236.89px;
      height: 93.74px;
      background: #CFD9FE;
    }
    &:nth-of-type(5) {
      bottom: -40%;
      right: -30%;
      width: 253.85px;
      height: 122.97px;
      background: #BAB7FC;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@include tablet {
  .card-image {
    min-height: auto !important;
  }
  .card-end {
    text-align: unset;
    button {
      position: sticky;
      top: 20px;
      left: 20px;
    }
  }
  .card-brief {
    & p {
      font-size: v.$pMobil;
    }
    & li {
      font-size: v.$pMobil;
    }
  }
  .div-scenario .step-scenario {
    > span {
      font-size: v.$p !important;
      > strong {
        font-size: v.$px-ts-nml;
      }
    }
    > p {
      font-size: v.$pMobil !important;
    }
  }
  .section-media {
    & p {
      font-size: v.$pMobil;
    }

    .div-link {
      & a {
        font-size: v.$pMobil;
        padding: 3px;
      }
    }
  }
}
@include mobile {
  section {
    .section-first-form {
      margin-top: 20px;
      flex-direction: column;
      .content-time-vogz {
        flex-direction: column;
      }
    }
  }
  .card-settings {
    .div-settings {
      .div-time {
        & p {
          flex-direction: column;
        }
      }
    }
  }
}
