@use "../../../../sass/colors" as colors;
@use "../../../../sass/variables.scss" as variables;
@import '../../../../sass/partials/_buttons';
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
@import "../../../../sass/_mixins/animated-shine.scss";

.section-btm {
    min-height: 73px;
    background-color: rgb(255 255 255 / 23%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 4;
    bottom: 0;
    left: 0;
    padding: 0 40px;
    border-top: 1px solid #EEEEEE;

    >button {
        min-width: 151px;
    }
    @include mobile {
        & {
            position: relative;
            padding-bottom: 130px !important;
            padding-top: 20px;
        }
    }
}

.page-setting {
    height: 100%;
    display: flex;
    flex-direction: column;

    section {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        &:nth-of-type(2) {
            box-shadow: 0px 4px 20px rgba(190, 190, 190, 0.25);
        }

        &:nth-of-type(4) {
            align-items: flex-end;
            flex: .4;
            padding: 0px 80px;
            border-top: 1px solid #EEEEEE;

            button {
                text-transform: none;
                min-width: 151px;
                font-size: variables.$px-ts-md;
            }
        }
    }

    @include mobile {
        height: 90%;
        section {
            h2 {
                font-size: variables.$px-ts-md*1.2 !important;
            }

            &:nth-of-type(4) {
                align-items: flex-end;
                flex: .3;
                padding: 0px 30px;
            }
        }
    }
}

.skip-container {
    .btn-skip {
        background-color: white;
        border-radius: 10px;
        margin-right: 20px;
        padding: 10px 20px;
        font-size: 16px;
        font-family: Hurme-bold;
        color: var(--main-blue-color-bg);

        @include mobile {
            margin-right: 0px;
        }
    }

    justify-content: flex-end;
    display: flex;
    position: sticky;
    bottom: 120px;

    @include mobile {
        justify-content: center;
        position: relative;
        bottom: 0px;
    }
}