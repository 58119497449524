.segmented-controls {
    display   : flex;
    position  : relative;
    overflow  : hidden;
    transition: all .3s ease;
    width     : 450px;
}

/*
   * Vissualy hidden radiobuttons
   */
.segmented-controls input {
    position   : absolute !important;
    height     : 1px;
    width      : 1px;
    overflow   : hidden;
    clip       : rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip       : rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    /* added line */
}

/* 
   * Labels need to be the same size
   */
.segmented-controls label {
    grid-row       : 1;
    display        : flex;
    justify-content: center;
    align-items    : center;
    text-align     : center;
    cursor         : pointer;
    flex           : 1 1 0px;
    position       : relative;
    z-index        : 2;
    transition     : inherit;
}

/* 
   * Adjust z-index of last label since that contains 
   * the paddle that needs to go beneath all other labels 
   */
.segmented-controls label:last-of-type {
    z-index: 1;
}

/*
   * Paddle 
   */
.segmented-controls label:last-of-type::after {
    content         : "";
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    z-index         : -2;
    background-color: black;
    transition      : inherit;
}

/*
   * Move paddle depending on which option is selected
   */
@for $i from 1 through 9 {
    .segmented-controls input:nth-last-of-type(#{$i + 1}):checked~label:last-of-type::after {
        transform: translateX($i * -100%);
    }
}


/*
   * IOS 13 Theme
   * Special thanks to https://www.figma.com/community/file/768365747273056340
   */

/*
   * Container
   */
.segmented-controls {
    border-radius: 1.3rem;
    background   : #e2e2e9;
    height       : 2.6rem;
    padding      : 0.225rem;
}

/*
   * Labels 
   */
.segmented-controls label {
    font-size  : 0.8125rem;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
}


/*
   * Selected option 
   */
.segmented-controls input:checked+label {
    font-weight: 600;
    font-size  : 0.875rem;
    color      : var(--color-text-active);
    font-family: Hurme-Bold;
}

/*
   * Hide dividers before and after the selected option
   */
.segmented-controls input:checked+label::before,
.segmented-controls input:checked+label+input+label::before {
    opacity: 0;
}



/*
   * Paddle
   */
.segmented-controls label:last-of-type::after {
    background   : var(--color-slider);
    border-radius: 1.3rem;
    border       : 0.5px solid rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 750px) {
    .segmented-controls {
    width: 90%;
    }
}