@use "./../../../../../../sass/colors"as colors;
@use "./../../../../../../sass/variables.scss"as variables;
@import './../../../../../../sass/partials/_buttons';
@import './../../../../../../sass/mixins';
@import './../../../../../../sass/_mixins/global';
@import './../../../../../../sass/_mixins/animated-shine.scss';
$width-bar-messages: 420px;

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}
@include miniPc(){
  .info-profile-container .section-name{
    justify-content: space-between !important;
  }
}
@include mobile(){
  .info-profile-container .desc-applicant{
    display: flex;
    flex-direction: column;
  }
}

.selection-done {
  display: flex;
  flex:1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 5%;

  .part-one {
    display: flex;
    width: 100%;
    flex: 1;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  
    h4 {
      font-size: 2.5ch;
      font-family: Hurme-Bold;
    }
  
    .separator {
      width: 1px;
      height: 100%;
      background: #C5C5C5;
      margin: 0 70px;
    }

    .casting-type {
      width: fit-content;
      font-family: Hurme-Bold;
      border-radius: 0.3rem;
      padding: 0.1rem 1rem;
      font-size: 1ch;
      margin-bottom: 0.5rem;
    }

    h3 {
      font-family: Hurme-Bold;
      font-size: 2ch;
      text-align: center;
    }

    .desc-brand {
      width: 95%;
      text-align: center;
    }
  }

  .part-two {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 3rem 10% 4rem 10%;
    margin-bottom: 8rem;
    background: #FFF5D2;
    
    h1 {
      font-family: Hurme-Black;
      font-size: 2rem;
    }

  }

  .white-light {
    pointer-events: none;
    z-index: 12;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 90%);
    position: fixed;
    bottom: 0;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    transition: 0.3s;
  }

  .yellow-light {
    pointer-events: none;
  }
}
.body-first-step{
  margin-right: 26.25rem;
}
 section.sub-head {
  position: sticky;
  width: 80%;
  display: flex;
  justify-content: center;
  top: 12px;
  //top: 28px;
  z-index: 10;
 .steps {
      display: flex;
      // width: 80%;
      // max-width: 700px;
      align-self: center;
      height: auto;
      & .step {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 12px;
          height: auto !important;
          & h2 {
              transition: 0.2s color ease-in;
              color: colors.$blue-dark;
              &.not-selected-text {
                  color: colors.$blue-not-selected;
              }
          }
          @include btn-ctr;
          & h2.disabled {
              @include disabled;
          }
          & span {
              margin-top: 6px;
              font-size: variables.$medium-size-text;
              font-family: roboto;
              display: flex;
              align-items: center;
              color: colors.$main-blue;
              &>i {
                  font-size: variables.$medium-size-text * 0.85;
                  margin: 0.2px 0 0 3px;
              }
              &.disable {
                  color: colors.$grey-dark;
              }
          }
      }
  }
}

aside.message-deal {
  width: $width-bar-messages;
  background-color: var(--main-white-color);
  position: fixed;
  top: 0;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 22px 12px 0px;
  -webkit-box-shadow: -6px 0px 24px 9px rgba(204, 204, 204, 0.67);
  box-shadow: -6px 0px 24px 9px rgba(204, 204, 204, 0.67);
  z-index: 10;
  .btn-close-msg{
    display: flex;
  }
  /*half the width*/
  & section:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      & div {
        margin: 3px 5px;
      }
    }
    & > h2 {
      font-size: variables.$mid-title-size-text * 0.8;
      font-family: Hurme-Bold;
    }
    & p {
      color: colors.$grey-dark;
      font-size: variables.$medium-size-text;
      line-height: 18px;
      margin-top: 6px;
      text-align: center;
      font-family: Roboto;
    }
    & a{
      color: var(--main-blue-color-bg);
      font-family: Hurme-Bold;
      font-size: variables.$low-size-text;
    }
  }
  & button:nth-of-type(1) {
    border: 0;
    background: none;
    position: absolute;
    right: 10px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    & i {
      font-size: variables.$medium-size-text * 1.5;
    }
    &:hover {
      color: colors.$grey-fair;
    }
  }
  & section:nth-of-type(2) {
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
    &:hover{
      .description{
        display: flex;
        flex-direction: column;
        top: 15px;
        align-items: center;
        justify-content: center;
        overflow: visible;
        transform:translateY(100%);
        opacity: 1;
      }
      p{
        display: block !important;
        overflow: visible !important;
      }
    }
    h2{
      display: flex;
      color: var(--text-conditions-color);
      border: 1px solid var(--text-conditions-color);
      border-radius: 5px;
      font-family: Hurme-Bold;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 3px 15px;
    }
    .description{
      position: absolute;
      opacity: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 3;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 4px 12px rgba(130, 127, 127, 0.25);
      animation: 3s;
      transform:translateY(-700px);
      p{
        font-family: Roboto;
        font-size: 14px;
        color: var(--text-conditions-color);
        padding: 10px;
        width: 100%;
        border: 1px solid;
        background-color: var(--main-white-color);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0px 4px 12px rgba(130, 127, 127, 0.25);
      }
    }
  }

  & section:nth-of-type(3) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    & > div:nth-of-type(1) > div > div div:not(:last-child) {
      margin: 0px 0 0px 0 !important;
      & div {
        margin-bottom: 6px;
      }
      & svg {
        display: none !important;
      }
    }
  }
  .input-container-casting {
    flex-grow: 0;
    display: flex;
    padding-block: 0.5em;
    flex: 0.16;
  }
  
  .input-container-casting .input-content-casting {
    width: 100%;
    max-height: 5.5em;
    height: 100%;
    position: relative;
    border-radius: 12px;
    display: flex;
    align-items: center;
    // padding-bottom: 9px;
  }
  
  .input-container-casting div:nth-of-type(2) {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > p {
      cursor: pointer !important;
      font-size: 17px;
      color: var(--main-blue-color-bg);
    }
  }
  
  .input-container-casting > textarea {
    width: 100%;
    vertical-align: center;
  }
  
  .input-container-casting .casting-message:focus {
    outline: 0;
  }
  
  .input-container-casting .casting-message {
    border: 1px solid #e9e9e9;
    padding-block: 10px;
    padding-inline: 19px 20px;
    width: 100%;
    border-radius: 16px;
    resize: none;
    font-size: 13px;
    font-family: Hurme-Bold;
  }
}
.deadline-streamer {
  display: flex;
  width: 30rem;
  justify-content: center;
  padding: 0.4rem 0;
  border-radius: 0.3rem;
  background: colors.$gargoyle-gas;

  * {
    font-size: 1rem;
  }

}

@include mobile {
  aside.message-deal {
    width: 100% !important;
  }
  .part-one{
    flex-direction: column;

    div {
      align-items: center;
    }

    .separator {
      width: 80% !important;
      height: 1px !important;
      margin: 1.5rem 0 !important;
    }

    p {
      margin-top: 1rem;
    }

  }
  .deadline-streamer {
    width: 20rem !important;
  }
}
@include tablet {
  .selection-done {
    margin-top: 7%;
  }
  .part-two{
    padding-bottom: 10rem !important;
    div{
      justify-content: center;
    }
  }
  .yellow-light {
    pointer-events: none;
    z-index: 12;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF5D2 90%);
    position: fixed;
    bottom: 0;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    transition: 0.3s;
  }
  .body-first-step{
    margin-right: 0;
  }
  aside.message-deal {
    margin: 0 10px 0 10px !important;
    right: -10px !important;

    & .sticky {
      position: initial;
      z-index: 9;
    }
    & .options-set {
      width: 100% !important;
      & button {
        padding: 8px 12px !important;
      }
    }
  }
  .deadline-streamer {
    width: 25rem;
  }
}
