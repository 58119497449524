@use "../../../sass/variables.scss"as v;
@import '../../../sass/mixins';
@import '../../../sass/_mixins/global';
.main-credit {
  background: var(--main-white-color);
}

.section-credit {
  display: flex;
  padding-block: 40px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-inline: 1rem;
    .card-premium {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      border: 1px solid #ffc554;
      box-shadow: -10px 10px #ffc554;
      padding: 2rem 4rem;
      width: 90%;
      .content {
        display: flex;
        width: 75%;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: start;
          width: 80%;
          align-items: flex-start;
          > h1 {
            font-family: Hurme-Black;
            font-size: v.$h2;
            line-height: normal;
          }
          > p {
            font-family: Roboto;
            font-size: v.$px-ts-md;
            color: var(--main-grey-color-text);
            font-weight: 300;
          }
        }
        > img {
          height: 6rem;
        }
      }
      > hr {
        width: 100%;
        margin-bottom: 1rem;
      }
      .card-premium__avantage {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        > p {
          border-radius: 100px;
          background: #3bdda3;
          color: var(--main-white-color);
          font-family: Roboto;
          font-size: v.$pMobil;
          text-align: center;
          width: 48%;
          padding-inline: 0.5rem;
          > i {
            margin-right: 0.5rem;
          }
        }
      }
      .btn-premium {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
        border-radius: 50px;
        background: linear-gradient(135deg, #a073ff 0%, #78bced 100%);
        color: var(--main-white-color);
        font-family: Hurme-Bold;
        position: relative;
        min-width: 220px !important;
        &:hover {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          border: 1.5px solid transparent !important;
        }
        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          inset: 0;
          border: 2px solid transparent;
          border-radius: 50px;
          background: inherit;
          background-origin: border-box;
          background-clip: border-box;
          -webkit-mask: linear-gradient(135deg, #a073ff 0%, #78bced 100%)
              padding-box,
            linear-gradient(135deg, #a073ff 0%, #78bced 100%);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          -webkit-mask-repeat: no-repeat;
        }
      }
    }
    .title-head{
      display: flex;
      flex-direction: column;
      >h3 {
        color: var(--main-violet-color-text);
        font-family: Hurme-Black;
        font-size: v.$h1;
        margin-bottom: 0.5rem;
        text-align: center;
      }
      >p {
          font-family: Roboto;
          font-size: v.$px-ts-nml;
          color: var(--main-grey-color-text);
      }
    }
  }
  > hr {
    width: 70%;
    margin-bottom: 1rem;
  }
  & .swiper-navigation-bubble {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border: #d4ddff solid 1px;
    border-radius: 100%;
    transition: 0.7s;
  }
  & .swiper-navigation-bubble__active {
    width: 10px;
    height: 10px;
    position: relative;
    background: radial-gradient(#3a4161 30%, transparent 60%, #3a4161 20%);
    border: none;
  }
}

.swiper-slide {
  & {
    z-index: 2 !important;
  }
}

.swiper-inactive {
  opacity: 0.3;
  transition: 0.1s;
}

.swiper-active {
  position: relative;
  transform: scale(1.15) translateX(-1.35vw);
  opacity: 1;
  z-index: 1;
}

.swiper-scrollbar {
  display: none !important;
}

.swiper-btn {
  display: block;
}

.swiper-btn-mobile {
  display: none;
}

.swiper-btn-mobile-container {
  display: none;
}

@include tablet {
  .section-credit {
    .header {
      margin-bottom: 0;
      flex-direction: column;
      .title-head{
        h3 {
          text-align: center;
          font-size: v.$h2;
      }
      p {
          text-align: center;
      }
      }
      & div {
        justify-content: center;
        align-items: center;
        &:nth-child(1) {
          margin-bottom: 1rem;
        }
      }

      p {
        font-size: v.$px-ts-md;
      }
      .card-premium {
        padding: 2rem 1rem;
        .card-premium__avantage > p {
          font-size: v.$low-size-text;
        }
      }
    }
  }
}
@include tablet2 {
  .card-premium {
    .content {
      flex-direction: column;
    }
    .card-premium__avantage {
      width: 100% !important;
      flex-direction: column;
      > p {
        width: 80% !important;
      }
    }
  }
}
