@use "_colors.scss"as colors;
@use "variables.scss"as v;
$avatar-size: 4.4em;
@mixin mobile() {
    @media (max-width: 600px) {
        @content;
    }
}

@mixin miniPc() {
    @media (max-width: 1425px) {
        @content;
    }
}

@mixin tablet() {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin tablet2() {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin disabled {
    color: colors.$grey-dark;
    cursor: not-allowed;
    pointer-events: none;
}

@mixin giantScreen() {
    @media (min-width: 2500px) {
        @content;
    }
}

@function user-if($condition, $true-statement, $false-statement) {
    $output: $false-statement;
    @if ($condition) {
        $output: $true-statement;
    }
    @return $output;
}

@mixin avatar($size:undefined) {
    width: user-if(type-of($size)=='number', $size, $avatar-size);
    height: auto;
    object-fit: cover;
    border-radius: 100%;
    -webkit-box-shadow: 0px 0px 15px 0px rgb(179 179 179 / 25%);
    box-shadow: 0px 0px 15px 0px rgb(179 179 179 / 25%)
}

@mixin gradient-text {
    font-style: normal;
    font-weight: 900;
    text-align: center;
    background: linear-gradient(91.03deg, #A685FE 0%, #5F79FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

@mixin little-btn($color:null) {
    border: 1px solid $color;
    border-radius: 12px;
    cursor: pointer;
    font-family: Hurme-Bold;
    color: $color;
    background-color: unset;
    padding: 3px 16px;
    transition: all .2s ease-in-out;
    &:hover {
        background-color: $color;
        color: var(--main-white-color);
    }
}
@mixin little-btn-inverse($color:null) {
    border: 1px solid $color;
    border-radius: 12px;
    cursor: pointer;
    padding: 3px 16px;
    transition: all .2s ease-in-out;
    background-color: $color;
    color: var(--main-white-color);
    font-family: Hurme-Bold;
    &:hover {
        background-color: var(--main-white-color);
        color: $color;
    }
}
@mixin h1Global {
    transition: .2s color ease-in;
    color: colors.$blue-dark2;
    font-size: v.$h1;
    font-family: Hurme-Black;
    line-height: v.$h1;
    text-align: center;
}

.invisible {
    display: none
}