@use "../../../../../../sass/colors" as colors;
@use "../../../../../../sass/variables.scss" as v;
@import "../../../../../../sass/mixins";
@import "../../../../../../sass/others/animations.scss";
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/_mixins/global";
@import "../../EditCasting/components/ElementPicker/styles.module.scss";

.section-wrapper {
  padding-top: 4em;
  padding-bottom: 6em;

  .step-content {
    .category {
      margin-top: .5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(45deg, var(--dashboard-blue-color-footer-sidemenu), var(--dashboard-blue-color-active-menu));
      background-size: 200% 200%;
      border-radius: 5px;
      animation: wave 3s ease-in-out infinite;

      div {
        border-radius: 5px;
      }

      hr {
        border-top-width: 1px;
      }

      h3 {
        font-family: Hurme-Bold;
        font-size: min(2.5vw, 16px);
        color: var(--main-dark-blue-text-color);
        margin-left: 1rem;
      }
    }

    .intro-brand {
      width: 100%;
      text-align: center;

      h1 {
        font-family: Hurme-Bold;
        margin-bottom: .5em;
      }

      p {
        font-size: min(1.5vw, 16px);
        color: var(--main-grey-color-text);
        text-align: center;
        line-height: normal;

        &:nth-of-type(1) {
          margin: auto;
          width: 50%;
        }

        &:nth-of-type(2) {
          margin: auto;
          width: 90%;
        }
      }

      textarea {
        width: 100%;
        height: 150px;
        border: 1px solid var(--main-light-grey);
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        color: black;
        resize: none;
        margin-top: 1em;
      }

      .good-to-know {
        color: var(--home-violet-dark-color-text);
        font-size: 12px;
      }
    }

    .socials-brand {
      width: 100%;
      //   margin: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: .5em;

      h1 {
        font-family: Hurme-Bold;
        margin-top: .5em;
        margin-bottom: .5em;
      }

      hr {
        border-top: 1px solid black;
        margin: 1em auto;
        width: 70%;
      }

      .site {
        label {
          color: black !important;
          background-color: var(--main-light-grey) !important;
        }
      }

      .social-input {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: auto;
        width: 100%;
        position: relative;

        label[for="info.social_links[1].url"] {
          background: linear-gradient(45deg,
              #69C9D0,
              #010101,
              #EE1D52,
              #69C9D0,
              #010101,
              #EE1D52,
            );
          animation: wave 40s infinite alternate !important;
          text-shadow: 1px 1px 2px #010101;
        }


        label {
          position: absolute;
          width: 8em;
          padding: 6.8px 1em;
          color: white;
          background-color: black;
          font-size: min(2.5vw, 16px);
          font-weight: 600;
          left: 0px;
          top: 0px;
          //   transform: translateY(1px);
          text-align: center;
          border-radius: 4px 0px 0px 4px;
          background-size: 200% 200% !important;
          animation: wave 10s ease-in-out infinite alternate;
        }

        input {
          border: 1px solid var(--main-light-grey);
          border-radius: 5px;
          background: white;
          padding: 6px 2em;
          width: 100%;
          padding-left: 9em;
          font-size: min(1.5vw, 16px);
        }

        &:first-of-type {
          label {
            text-shadow: 1px 1px 2px #010101;
            background: linear-gradient(45deg,
                #405de6,
                #5851db,
                #833ab4,
                #c13584,
                #e1306c,
                #fd1d1d);
          }
        }
      }

      button {
        &:hover {
          background-color: var(--my-castings-thick-blue);
          color: white;
        }

        i {
          margin-right: 15px;
        }

        font-family: hurme-bold;
        color: var(--my-castings-thick-blue);
        border: 1px solid var(--my-castings-thick-blue);
        padding: 0.3em 1.3em;
        border-radius: 60px;
        transition: 0.3s;

        font-size: 15px;
      }

      .check-to-save {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: flex-start;
        margin-top: .5em;

        input {
          margin-top: 5px;
          accent-color: white;
        }

        span {
          font-family: hurme-bold;
          color: var(--main-dark-blue-text-color);
        }
      }
    }
    .content-usage, .content-type {
      width: 100%;
      text-align: center;
      h1 {
        font-family: Hurme-Bold;
        margin-bottom: .5em;
      }
      p {
        font-size: min(3.5vw, 16px);
        color: var(--main-grey-color-text);
        text-align: center;
        line-height: normal;

        &:nth-of-type(1) {
          margin: auto;
          width: 90%;
        }
      }
      .select{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 3rem;
        flex-wrap: wrap;
        .type{
          min-width: 220px !important;
          width: 32% !important;
          &.selected, &:hover{
            box-shadow: 0px 10px 31px 0px #a5bbdb;
            button{
              background-color: var(--main-white-color);
              border: 1px solid var(--main-blue-color-bg);
              color: var(--main-blue-color-bg);
            }
          }
          .img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #EBFAFF !important;
            img{
              height: 100px;
            }
          }
        }
        .influence{
          .img{
            background-color: #EEEBFF !important; 
          }
        }
        .usage{
          &.selected, &:hover{
            box-shadow: 0px 10px 31px 0px #a5bbdb;
            button{
              background-color: var(--main-blue-fair-color-bg);
              color: var(--main-white-color);
              border: 1px solid var(--main-blue-fair-color-bg);
            }
          }
        }
        .usage, .type{
          min-width: 250px;
          width: 40%;
          min-height: 400px;
          border-radius: 5px;
          box-shadow: 0px 10px 31px 0px #F1F1F1;
          padding: 10px;
          margin-bottom: .5rem;
          &.selected, &:hover{
            box-shadow: 0px 10px 31px 0px #a5bbdb;
          }
          .img{
            height: 170px;
            border-radius: 5px;
            background: var(--main-ghost-white);
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;
          }
          b{
            font-family: Hurme-Black;
            font-size: min(3.5vw, 16px);
            margin-bottom: 5px;
            color: var(--main-dark-blue-text-color);
            text-transform: uppercase;
          }
          p{
            color: var(--main-dark-blue-text-color);
            font-family: Roboto;
            font-weight: 300;
            height: 60px;
          }
          button{
            margin-top: 2rem;
          }
        }
      }
    }
    .content-right{
      margin-top: 4rem;
      margin-bottom: 2rem;
      h1 {
        font-family: Hurme-Bold;
        margin-bottom: 1.5rem;
      }
      .right{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
        button{
          &.isSelect{
            box-shadow: 0px 10px 31px 0px #a5bbdb;
            background-color: var(--main-blue-fair-color-bg);
            color: var(--main-white-color);
            border: 1px solid var(--main-blue-fair-color-bg);
          }
          min-width: 0;
          width: 224px;
          margin-bottom: 1rem;
        }
      }
    }
    
  }

}

@include tablet() {
  .section-wrapper {
    .step-content {
      .intro-brand {
        h1 {
          font-family: Hurme-Bold;
          margin-bottom: .5em;
        }

        p {
          font-size: min(1.8vw, 16px);

          &:nth-of-type(1) {
            width: 70%;
          }

          &:nth-of-type(2) {
            width: 90%;
          }
        }
      }

      .socials-brand {
        .social-input {
          .social {
            font-size: min(1.8vw, 16px);
          }

          input {
            font-size: min(1.8vw, 16px);
            padding-left: 11em;
          }
        }
      }
    }
  }

  .intro-brand,
  .socials-brand {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@include mobile {
  .section-wrapper {
    .step-content {
      .intro-brand {
        p {
          font-size: min(3.5vw, 16px);

          &:nth-of-type(1) {
            width: 90%;
          }
        }
      }

      .socials-brand {
        .social-input {
          label {
            font-size: min(3vw, 16px);
            padding: 7px 1em;
          }

          input {
            padding-left: 27vw;
            padding-right: 1px;
            font-size: min(3.5vw, 16px);
          }
        }
      }

      .check-to-save {
        span {
          line-height: normal;
          font-size: min(3.5vw, 16px);
        }
      }
    }
  }
}