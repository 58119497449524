@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.div-search{
    & input{
        width        : 250px;
        height       : 40px;
        border-radius: 4px;
        border       : 1px solid var(--main-blue-color-bg);
        padding      : 5px 20px;
        font-size    : v.$p;
        font-family  : 'Hurme-Bold', FontAwesome, sans-serif;
        outline: none;
        &::placeholder{
            display     : flex;
            align-items : center;
            font-size   : v.$p;
            color       : var(--main-blue-color-bg);
        }
    }
}
