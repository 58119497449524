@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
.main-page-login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    input {
        outline: none;
    }
    & .all-video {
        position: absolute;
        z-index: 1;
        width: 100vw;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        // min-width: ;
        @include tablet() {
            & .video:nth-of-type(2) {
                display: none;
            }
        }
        @include mobile() {
            & .video:nth-of-type(3) {
                display: none;
            }
        }
        & .video {
            flex: 1;
            height: 100%;
            & video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    & section {
        width: 100%;
        max-width: 2000px;
        display: flex;
        justify-content: center;
        z-index: 3;
        & .login-register {
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 6rem;
            padding: 4rem;
            width: 40%;
            max-width: 570px;
            background-color: var(--main-white-color-trans);
            border-radius: 10px;
            backdrop-filter: blur(20px);
            & p:nth-child(2) {
                font-family: Roboto;
                font-weight: 400;
                line-height: normal;
                color: var(--main-grey-darken);
                font-size: 13px;
                text-align: center;
                margin-bottom: 20px;
            }
            .link-register{
                margin-top: .5rem;
                font-size: inherit;
                line-height: inherit;
                color: var(--main-grey-darken);
                font-family: Roboto;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                -webkit-text-decoration: none;
                text-decoration: none;
                display: flex;
                justify-content: center;
                cursor: pointer;
                & span {
                    color: var(--main-blue-color-text);
                    line-height: 14px;
                    font-family: Hurme-Black;
                }
            }
            & .form {
                & .FormFieldCGV {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    & input {
                        margin-top: 22px;
                        margin-right: 10px;
                    }
                    & p {
                        margin-top: 17px;
                    }
                    & label {
                        margin-top: 17px;
                        font-family: Roboto;
                        font-weight: 400;
                        color: var(--main-grey-darken);
                        font-size: 13px;
                        & a {
                            color: var(--main-blue-color-bg);
                        }
                    }
                    @include mobile() {
                        & input {
                            margin-top: 19px;
                            margin-right: 5px;
                        }
                    }
                }
                & .label-field {
                    font-family: Hurme-Bold;
                    color: var(--main-grey-darken);
                    width: 70%;
                    margin-bottom: 5px !important;
                    margin-left: 5px;
                    font-size: v.$low-size-text*1.2;
                    & span {
                        color: var(--main-blue-color-bg);
                    }
                }
                & .divInput {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    & .ErrorMsg {
                        height: 25px;
                        & .textdanger {
                            font-family: Roboto-Regular;
                            color: var(--main-alert-color);
                            margin-left: 5px;
                            font-size: v.$low-size-text*1.2;
                        }
                    }
                    @include tablet() {
                        & .textdanger {
                            width: 75%;
                        }
                    }
                    @include mobile() {
                        & .textdanger {
                            width: 100%;
                        }
                    }
                }
                & .div-login-btn {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }
                & input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                & .TextInput::placeholder {
                    color: var(--main-grey);
                }
                & .forgot-password {
                    line-height: inherit;
                    font-size: 13px;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    color: var(--main-blue-color-bg);
                    font-family: Hurme-Black;
                }
                & .Button {
                    width: 100%;
                    border-radius: 7px;
                    font-family: Hurme-Bold;
                    margin-top: .5rem;
                    cursor: pointer;
                    padding: 8px 32px;
                    font-size: 20px;
                    line-height: 26px;
                    background-color: var(--main-blue-color-bg);
                    color: var(--main-white-color);
                    border: 2px solid var(--main-blue-color-bg);
                    transition: all .3s ease-out;
                }
                & .Button:hover {
                    transform: translate(0, -5px);
                }
            }
        }
        & .img-landing {
            display: flex;
            align-items: center;
        }
        @include tablet() {
            // flex-direction: column-reverse;
            // text-align    : start;
            // align-items   : center;
            & .img-landing {
                width: 80vw;
                margin-bottom: 2rem;
                display: none;
            }
            & .login-register {
                margin: 0 4rem;
                width: auto;
                & p:nth-child(2) {
                    text-align: center;
                }
                & h1 {
                    font-size: 2.2rem;
                    text-align: center;
                }
                & p {
                    font-size: v.$low-size-text*1.16;
                }
                & .form {
                    & p.code {
                        margin: auto;
                        width: 90%;
                    }
                    & .Button {
                        font-size: 18px;
                    }
                    & .btn-back {
                        font-size: 16px;
                    }
                }
            }
        }
        @include mobile() {
            & .login-register {
                margin: 0 2rem;
                padding: 2rem;
                & h1 {
                    font-size: 1.8rem;
                }
                & p {
                    font-size: 11px !important;
                    margin-bottom: 15px !important;
                }
                & .form {
                    & p.code {
                        text-align: center;
                    }
                    & .Button {
                        font-size: 16px;
                        line-height: 16px;
                    }
                    & .btn-back {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
                & a {
                    font-size: 11px !important;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}

main {
    .input-auth {}
}