@use "../_colors.scss"as colors;
@use "../variables.scss"as variables;
@use "sass:color";
@import "../mixins";
@mixin outlined-btn {
    background-color: var(--main-white-color);
    border: 1px solid colors.$blue-dark;
    color: var(--main-blue-color-bg);
}

@mixin btn-min {
    background-color: var(--main-blue-color-bg);
    padding: 10px 24px;
    padding: 6px 24px;
    transition: all .2s ease-in;
    border-radius: 10px;
    border: 1px solid transparent;
    color: var(--main-white-color);
    font-family: Hurme-Semi-Bold;
    font-size: 14px;
    cursor: pointer;
}

.btn {
    @include btn-min;
    &:hover {
        @include outlined-btn;
    }
    &.disabled {
        @include disabled;
        background-color: colors.$blue-btn;
    }
}

.btn-txt {
    color: var(--main-blue-color-bg);
    background-color: unset;
    border: none;
    padding: 7px 18px;
    cursor: pointer;
    transition: all .2s ease-in;
    text-decoration: none;
    font-size: 14px;
    width: fit-content;
    &:hover {
        color: rgba(colors.$blue-dark, .5);
    }
}

@include mobile() {
    .btn-txt {
        padding: 5px 0;
        margin-right: 10px;
    }
}

@mixin effect-btn($color, $border-radius, $content:undefined, $width:undefined) {
    display: inline-block;
    position: relative;
    width: user-if(type-of($width)=='number', $width, "none");
    border-radius: $border-radius;
    overflow: hidden;
    &.effect-left {
        transition: all 0.2s linear 0s;
        &:before {
            content: user-if(type-of($content)=='string', $content, "\f178");
            font-family: FontAwesome;
            font-size: 15px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            top: -1px;
            opacity: 0;
            height: 100%;
            width: 50px;
            transition: all 0.2s linear 0s;
        }
        &:hover {
            text-indent: -20px;
            padding-inline: 45px;
            &:before {
                opacity: 1;
                text-indent: 0px;
            }
        }
    }
}

@mixin basic-btn() {
    min-width: 295px;
    background-color: var(--main-blue-color-bg);
    font-family: Hurme-Black;
    font-size: variables.$medium-size-text;
    color: var(--main-white-color);
    padding: 8px 0;
    text-align: center;
    border-radius: 7px;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
    &:hover {
        @include outlined-btn
    }
}

@mixin btn-ctr {
    min-width: 174px;
    //min-height: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: variables.$medium-size-text;
    font-family: Hurme-Bold;
    transition: all .2s ease-in-out;
    &.active-btn {
        background-color: #F5F7FF;
        color: #5777FF;
        &:hover {
            border: none;
        }
    }
    &.disabled {
        @include disabled;
        background-color: var( --dashboard-blue-launch-casting);
    }
    & .not-selected {
        background: #F5F7FF;
        color: var(--main-blue-color-bg);
        position: relative;
        &:hover {
            background-color: #CCD7FF;
            border: 1px solid transparent;
            color: var(--main-white-color)
        }
    }
}

button.btn-outlined_ {
    @include outlined-btn;
    margin-top: 5px;
    //padding: 5px 12px;
    width: 60%;
    min-width: 112px;
    border-radius: 22px;
    width: 90%;
    cursor: pointer;
    font-family: Hurme-Bold;
    font-size: variables.$low-size-text;
}