@use "../../../../../../sass/colors" as colors;
@use "../../../../../../sass/variables.scss" as v;
@import '../../../../../../sass/others/animations.scss';
@import '../../../../../../sass/mixins';
@import '../../../../../../sass/partials/_buttons';
@import '../../../../../../sass/_mixins/global';



.brief-type-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .section-header {
        display: flex;
        justify-content: space-between;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 5px;

            >h3 {
                font-size: 20px;
                font-family: Hurme-Bold;
                text-transform: uppercase;
            }

            >p {
                font-size: v.$px-ts-sm;
                color: rgba(103, 164, 255, 1);
                line-height: 17px;
            }
        }

        >hr {
            border: 0;
            height: 80px;
            width: 1px;
            background-color: #C0C0C0;
            margin: 0 20px;

        }
    }

    .section-brief-type {
        padding: 3rem;
        padding-top: 1rem;
    }

    .casting-plus h3 span {
        background: linear-gradient(93.26deg, #a785f0 0%, #627aff 100%);
        color: var(--main-white-color);
        border-radius: 3px;
        font-family: Hurme-Black;
        text-transform: uppercase;
        padding: 3px 9px;
        margin: 0 5px 2px 5px;
    }

    .deal-plus span {
        background: linear-gradient(151.35deg, #FFD806 0%, #FFEC84 100%);
        color: var(--main-white-color);
        border-radius: 3px;
        font-family: Hurme-Black;
        padding: 3px 9px;
        margin: 0 5px 2px 5px;
        color: black;
    }

    .casting-on-spot h3 span {
        background: linear-gradient(151.35deg, #5EDCE4 0%, #33A8FC 100%);
        color: var(--main-white-color);
        border-radius: 3px;
        font-family: Hurme-Black;
        text-transform: uppercase;
        padding: 3px 9px;
        margin: 0 5px 2px 5px;
        color: white;
    }

    .title-section-form {
        display: flex;
        align-items: baseline;
        text-align: center;
    }

    .required-section {
        display: block;
    }
}
.page-main {
    // height: 100%;
    display: flex;
    flex-direction: column;
    // background: linear-gradient(45deg, #daccff 0%, #F2F9FF 100%);
    // background-size: 400% 400%;
    // animation: wave 5s ease infinite;
    // overflow: hidden;
    // min-height: 90vh;
    header {
        position: sticky;
        top: 0px;
        z-index: 3;
        backdrop-filter: blur(3px);
        background: rgba(255, 255, 255, 0.7);

        .page-container {
            display: flex;
            flex-direction: column;

            .tag {
                align-self: center;

                h3 {
                    font-size: 30px;
                }
            }

            .progress-bar-container {
                display: flex;
                margin-top: 30px;
                justify-content: center;

                @-moz-document url-prefix() {
                    margin-top: 50px;
                }

                .progress-bar {
                    max-width: 500px;
                    width: 90%;

                    .dot-step-container {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        height: 78px;
                        align-items: center;
                        top: -29px;
                        justify-content: center;

                        .dot-step-title {
                            position: relative;
                            width: 140px;
                            font-family: Hurme-Bold;
                            font-size: 15px;
                            line-height: 19px;
                            text-align: center;
                            color: rgba(188, 201, 255, 1);
                            // color: rgba(0, 0, 0, 0.377);

                            &.accomplished {
                                color: var(--main-blue-color-bg);
                                // color: black;
                            }
                        }

                        .dot-step {
                            width: 20px;
                            height: 20px;
                            background-color: var(--main-blue-color-bg);
                            border-radius: 100px;
                            border: 3px solid var(--main-white-color);
                            cursor: pointer;
                            position: absolute;
                            bottom: 0;
                        }

                        .dot-step-done {
                            background-color: var(--dashboard-green-color);
                        }

                        i {
                            position: absolute;
                            font-size: 15px;
                            z-index: 99999;
                            transform: translate(1px, 28px);
                        }
                    }
                }
            }
        }

        >button {
            position: sticky;
            top: 20px;
            left: 20px;
        }
    }

    .page-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0px 90px 0;

        // flex: 1;
        // overflow: scroll;
        section {
            margin: 10px 0;

            &[data-required='true'] {
                &.section-form {
                    .casting-plus span {
                        background: linear-gradient(93.26deg, #a785f0 0%, #627aff 100%);
                        color: var(--main-white-color);
                        border-radius: 5px;
                        font-family: Hurme-Bold;
                        padding: 3px 9px;
                        margin: 0 5px 2px 5px;
                    }

                    .deal-plus span {
                        background: linear-gradient(151.35deg, #FFD806 0%, #FFEC84 100%);
                        color: var(--main-white-color);
                        border-radius: 5px;
                        font-family: Hurme-Bold;
                        padding: 3px 9px;
                        margin: 0 5px 2px 5px;
                        color: black;
                    }

                    .title-section-form::after {
                        content: '(requis)';
                        margin-left: 12px;
                        top: -1px;
                        position: relative;
                        font-size: 13px;
                        color: rgba(132, 128, 255, 1);
                    }

                    .title-section-form {
                        display: flex;
                        align-items: baseline;
                    }

                    .required-section {
                        display: block;
                    }
                }
               
            }

            &[data-errored='true'] {
                border: 1px solid var(--main-error-txt);
            }

            &[data-recommended='true'] {
                &.section-form .title-section-form::after {
                    content: '(recommandé)' !important;
                    margin-left: 5px;
                    color: rgba(132, 128, 255, 1);
                }
            }

            &[data-optional='true'] {
                padding-top: 3em !important;
                &.brief-type-form:before {
                    content: "Optionnel";
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    font-size: 12px;
                    color: black;
                    font-family: Hurme-Bold;
                    background: #FCD81A;
                    border-radius: 5px 5px 0 0;
                    top: 0;
                    left: 0;
                }
            }

            p[data-conditions='true'] {
                font-size: v.$px-ts-sm;
                line-height: 17px;
                max-width: 75%;
                margin-top: 2em;
                color: var(--text-conditions-color);
                font-family: Roboto;

                strong {
                    font-family: Hurme-Bold;
                }
            }

            p[data-required='true'] {
                font-size: v.$px-ts-sm;
                color: rgba(103, 164, 255, 1);
                line-height: 17px;
                max-width: 75%;

                //margin: 10px 0;
                strong {
                    font-family: Hurme-Bold;
                }
            }

            input {
                &[data-input-brief] {
                    font-size: v.$px-ts-sm;
                    padding: 10px 10px;
                    outline: 0;
                    //width: 80%;
                    border-radius: 4px;
                    border: 1px solid var(--main-light-grey);
                }

                &[data-input-media] {
                    overflow: hidden;
                    opacity: 0;
                    position: absolute;
                    z-index: -1;
                }
            }

            button {
                &[data-btn-add] {
                    font-family: Hurme-Bold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    text-align: center;
                    color: var(--main-blue-fair-color-bg);
                    padding: 8px 20px;
                    width: 100%;
                    justify-content: center;
                    max-width: 237.79px;
                    border: 1px solid var(--main-blue-fair-color-bg);
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    transition: 0.2s all ease-in-out;

                    >i {
                        font-size: v.$px-ts-md;
                        margin-right: 8px;
                    }

                    font-size: v.$px-ts-sm;

                    &:hover {
                        color: var(--main-white-color);
                        background-color: var(--main-blue-fair-color-bg);
                    }
                }

                &[data-btn-minus] {
                    color: #bcc9ff;
                    font-size: v.$px-ts-sm;

                    &:hover {
                        color: var(--main-alert-color) !important;
                    }
                }
            }

            &.section-form,
            &.brief-type-form {
                background: var(--main-white-color);
                box-shadow: 0px 4px 50px rgba(190, 190, 190, 0.25);
                border-radius: 10px;
                width: 90%;
                max-width: 1061px;
                padding: 30px;
                position: relative;

                .required-section {
                    color: var(--text-conditions-color);
                    font-size: v.$px-ts-sm;
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    display: none;
                }

                .info p {
                    background-color: var(--text-conditions-color);
                    border-radius: 20px;
                    margin-left: 10px;
                    font-size: v.$px-ts-sm;
                    padding: 1px 15px;
                    color: var(--main-white-color);
                    position: relative;
                    line-height: initial;
                    font-family: Roboto;
                    font-style: initial;
                    display: flex;
                    align-items: center;
                    font-weight: 300;

                    i {
                        margin-left: 5px;
                    }

                    span {
                        display: none;
                    }

                    &:hover span {
                        position: absolute;
                        z-index: 2;
                        width: 220px;
                        background: var(--text-conditions-color);
                        border-radius: 10px;
                        padding: 10px;
                        display: flex;
                        top: -10px;
                        left: 0;
                        flex-wrap: nowrap;
                        flex-direction: column;
                        align-items: flex-start;
                        line-height: initial;
                        font-family: Roboto;
                        font-style: initial;

                        b {
                            background-color: var(--main-white-color);
                            border-radius: 20px;
                            margin-bottom: 5px;
                            font-size: v.$px-ts-sm;
                            padding: 5px 15px;
                            color: var(--text-conditions-color);
                            position: relative;
                        }
                    }
                }

                h4,
                .title-section-form,
                label[data-h4='true'] {
                    font-family: 'Hurme-Bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: v.$px-ts-nml;
                    line-height: 26px;
                    color: var(--main-dark-color);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    &.show-optional::after {
                        font-size: v.$px-ts-sm;
                        content: '(optionnel)';
                        margin-left: 5px;
                        color: var(--main-blue-color-bg);
                    }

                    &.show-recommanded::after {
                        font-size: v.$px-ts-sm;
                        content: '(recommandé)';
                        margin-left: 5px;
                        color: var(--main-blue-color-bg);
                    }
                }

                h4 {
                    font-size: v.$px-ts-md;
                }
            }

            .tip-section-form {
                background-color: var(--main-blue-color-bg);
                color: white;
                font-size: v.$px-ts-sm;
                padding: 0px 20px;
                text-align: center;
                border-radius: 40px;

                i {
                    margin-left: 10px;
                }
            }

            .form-aside-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                >.section-first-form {
                    max-width: 400px;

                    &.first-part {
                        display: flex;
                        align-items: center;
                        // width: 50%;
                        justify-content: space-between;

                        >p {
                            color: #67a4ff;
                        }
                    }

                    // &.btns-next {
                    //     display: flex;
                    //     width: 50%;
                    //     justify-content: center;
                    //     align-items: center;
                    //     button {
                    //         min-width: 0;
                    //         width: 100%;
                    //         max-width: 151px;
                    //         height: fit-content;
                    //         text-transform: capitalize;
                    //     }
                    // }
                }
            }

            [data-styleform='true'] {
                color: var(--text-conditions-color);
                font-size: v.$px-ts-sm;
            }

            &.active {}
        }

        .btn-bottom-fl {
            z-index: 10;
            width: 100%;
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: 0;
            padding: 30px 0;
            backdrop-filter: blur(10px);

            >div {
                max-width: 80%;
                display: flex;

                button {
                    min-width: 174px;
                }
            }
        }
    }
}
@include mobile {
    .page-main {
        header {
            top: 70px;
            position: initial;

            .page-container {
                .progress-bar-container {
                    .progress-bar {
                        width: 65%;
                    }
                }
            }
        }
        .page-body {
            .btn-bottom-fl {
                position: initial;
                margin-top: 1rem;
    
                >div {
                    max-width: none;
                }
            }
        }
    }
    .brief-type-form {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .section-brief-type {
            div {
                width: 100% !important;
            }
        }
    }
    
}

@include tablet {
    .page-main {
        header {
            .page-container {
                .progress-bar-container {
                    .progress-bar {
                        max-width: 500px;
                        width: 70%;
                    }
                }
            }
        }
    }
    .brief-type-form {
        .section-brief-type {
            display: flex;
            flex-direction: column;
            hr {
                height: 1px !important;
                width: 50% !important;
                max-width: 220px !important;
                margin: 1.5rem auto !important;
            }
            div {
                display: flex;
                flex-direction: column;
                width: 70%;
                align-self: center;
            }
        }
    }
}

