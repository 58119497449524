@use './variables.scss'as variables;
$alice-blue: #F1F4FF;
$alice-blue2: #EFF8FF;
$azureish-white: #D7DFFF;
$blue-dark: #5777FF;
$blue-dark2: #615AF6;
$blue-very-dark-violet :#231350;
$blue-menthe: #38B2F6;
$blue-fair: #A9BBFF;
$blue-client: #01C6FE;
$blue-shamrock:#2CC1D6;
$blue-light: #57C3FF;
$maya-blue:#5ABAFF;
$blue-navy: #0A1F54;
$blue-chat:#082535;
$blue-not-selected: #5777ff66;
$blue-btn: #F5F7FF;
$main-blue: #2A42A7;
$main-blue-mystified:#2B3974;
$main-blue-dark: #0E408B;
$blue-gulf:#3A4161;
$black-russian:#141532;
$medium-champagne: #FFECA7;
$new-blue-fair: #F6F8FF;
$blue-time: #8AB5F4;
$electric-blue:#7787fd;
$fair-green :#F1FFFF;
$grey-fair: #DBDBDB;
$grey-text :#BFBFBF;
$grey-dark: #7C7C7C;
$grey-blue: #9FAAD9;
$gargoyle-gas: #FDD443;
$tangerine-yellow: #EC8E00;
$blue-v1: #67ACFE;
$blue-very-pale:#dee4ff;
$light-blue-magenta: #d7d7ff;
$lavender-blue:#ced7ff;
$perano-blue: #b2c0f7;
$alice-blue3:#f8faff;
$periwinkle-blue:#c0d3ff ;
$grey: #C4c4c4;
$light-grey: #F5F4FF;
$light-grey-bg: #FCFCFF;
$hard-grey-dark: #868080;
$grey-darken :#5A5A5A;
$grey-charcoal: #494949;
$approx-grey: #404040;
$input-border-grey: #D2D2D2;
$perano-purple:#A39EFF;
$btn-product-send: #AE85FE;
$alt-info-violet: #7E44EE;
$purple-anemone: #8E66FF;
$alert :#CD0000;
$black-dark: #000000;
$white: #ffffff;
$white-semi-trans: #ffffffe6;
$ghost-white: #F8F8FF;
$apple-green: #32EE7E;
$reptile-green: #23E08F;
$bright-turquoise-offered: #00FFC2;
$aquamarine-green: #2EFFB4;
$green-honeydew: #F2FFF5;
$yellow:#FFECA7;
$ghost-white: #F6F7FF;
$pastel-red: #EF6262;
$lavender-floral: #A685FE;
$vodka: #C0C8EC;
$magnolia: #F2F1FF;
$favorite:#E94F4F;
$gunmetal: #2F2E41;
$azure: #EFFEFF;
$tiffany-blue: #0FABB5;
$independance: #535175;
// gradient vogz
$gradient-orange: #A785FE;
$gradient-blue: #5A78FF;
// gradient client
$gradient-blue-client: #01C8FD;
$gradient-blue-green: #01FBE1;
// tag type castings
$tag-color-acting :#FF778F;
$tag-color-choregraphy :#FFF96E;
$tag-color-testimony :#85E9FF;
$tag-color-creativity :#E8A5FF;
$tag-color-test-product:#B3FF85;
//Status contract
$green-status :#89E06A;
$apply-status :#8FFEC9;
$orange-status :#FFD363;
$blue-status :#78E7FF;
$yellow-info: #FDD136;
// error text
$error-txt: #ff002f;
//check green 
$green :#4AEE5B;
//modal color 
$warning-info: #FEBA2C;
$warning-brief-info: #D9564E;
$launch-casting-info: #162358;
$stripe-valid-info: #57D782;
$stripe-error-info: #D9564E;
$close-casting: #0E408B;
$no-favorite-vogzter: #000;
$confirmation-rdv-info:#5EDCE4;
//brief color
$creator-brief-bg: #F1F4FF;
$exclusive-brief-bg: #F1FFFF;
$private-brief-bg: #FEEBAB;
$text-file-conditions-color: #8480FF;
$red-ryb: #FF1F1F;
@mixin getTagProperties($tag) {
    @if $tag=="acting" {
        background-color: $tag-color-acting
    }
    @else if $tag=="product-review-unboxing" {
        background-color: $green-status
    }
    @else if $tag=="choregraphy" {
        background-color: $tag-color-choregraphy
    }
    @else if $tag=="testimony" {
        background-color: $tag-color-testimony
    }
    @else if $tag=="creativity" {
        background-color: $tag-color-creativity;
    }
    @else if $tag=="vip" {
        background: linear-gradient(93.91deg, #5BD7F6 0%, #57F5DF 100%);
        color: $white;
    }
}

$tags: "acting",
"product-review-unboxing",
"choregraphy",
"testimony",
"creativity",
"vip";
.tag {
    border-radius: 5px;
    padding: 5px 9px;
    width: fit-content;
    & h3 {
        font-size: variables.$low-size-text;
        font-family: Hurme-Bold;
    }
}

@each $tag in $tags {
    .tag-#{$tag} {
        @include getTagProperties($tag);
    }
}