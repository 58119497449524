.hidden-item {
  position: absolute;
  opacity: 0;
}

.render-overflow {
  cursor: zoom-in;
  z-index: 1;
}

.overflow-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
  opacity: 1;
  transition: .15s;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  z-index: 5;
  cursor: default;
  > div {
    flex-grow: 0;
  }
}