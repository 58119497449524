@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import '../../../../sass/mixins';
@import '../../../../sass/_mixins/global';
// @import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
.err-msg1 {
  color: var(--main-alert-color);
  font-size: v.$small-size-p;
  font-family: 'Roboto', sans-serif;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  min-width: 370px;
  width: 100%;
  margin-bottom: 10px;
  label {
    font-family: Hurme-Bold;
    font-size: v.$medium-size-text;
    color: var(--main-grey-color-text);
    .text-input-required {
      color: var(--main-blue-color-bg);
    }
  }
  &.errored {
    .text-input {
      background-color: lighten(colors.$alert, 50%);
    }
  }
  .text-input {
    border: 1px solid var(--main-light-grey);
    border-radius: 7px;
    font-size: 0.9em;
    padding: 6px 19px;
    outline: none;
    background: #fcfcff;
    line-height: 24px;
    font-weight: 500;
  }
  .text-input-required {
  }
  & i {
    margin-top: -30px;
    margin-right: 15px;
    margin-left: auto;
    width: 20px;
    cursor: pointer;
    margin-bottom: 30px;
  }
}

.div-search {
  & input {
    width: 250px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid colors.$blue-dark;
    padding: 5px 20px;
    font-size: v.$low-size-text/1.1;
    font-family: 'Hurme-Bold', FontAwesome, sans-serif;
    &::placeholder {
      display: flex;
      align-items: center;
      font-size: v.$low-size-text/1.1;
      color: colors.$blue-dark;
    }
  }
}

.input-search-container {
  max-width: 27rem;
  width: 100%;
  height: 2.5rem;
  position: relative;
  & > label {
    font-size: 12px;
    position: absolute;
    left: 18px;
    top: calc(50% - 0.5em);
    color: var(--main-blue-color-bg);
  }
  .input-search {
    border: 1px solid var(--main-blue-color-bg);
    height: 100%;
    padding-inline: 42px;
    width: 100%;
    border-radius: 12px;
    font-size: v.$px-ts-md;
    &::placeholder {
      color: var(--main-blue-color-bg);
    }
  }
}

.toast-custom {
  display: flex;
  aside:nth-of-type(2) {
    margin-left: 12px;
    h3 {
      font-family: Hurme-Semi-Bold;
      font-size: v.$mid-title-size-text;
      color: var(--main-blue-dark);
    }
    p {
      font-family: roboto;
      font-size: v.$px-ts-sm;
      line-height: 20px;
      color: var(--main-blue-dark);
      opacity: 0.5;
    }
  }
  aside:nth-of-type(1) {
    i {
      font-size: 20px;
      margin-top: 2px;
      &.success {
        color: var(--my-castings-fair-green);
      }
      &.error {
        color: var(--main-alert-color);
      }
    }
  }
}

.box-swtich {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 27px;
  }
  .switch input {
    display: none;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@include mobile() {
  .input-search-container {
    width: 95%;
  }
  .text-input-container {
    display: flex;
    flex-direction: column;
    min-width: 0px;
    width: 100%;
    margin-bottom: 10px;
  }
}
