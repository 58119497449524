@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import '../../../../sass/mixins';
@import '../../../../sass/_mixins/global';

.body-favourite {
  display: flex;
  width: 100%;
  height: 95vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & .div-card-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80%;
    overflow-y: auto;
    & .card-select-fav {
      display: grid;
      flex-direction: row;
      flex-wrap: wrap;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      justify-content: space-around;
      grid-column-gap: 2rem;
      column-gap: 2rem;
      grid-row-gap: 3rem;
      row-gap: 3rem;
      box-sizing: border-box;
      justify-items: center;
      margin: 1rem;
    }
  }
  .btn-selection{
    margin:.5em;
  }
}
@include tablet {
  .body-favourite {
    & .card-select-fav {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

@include mobile {
  .body-favourite {
    //   max-width: 900px;
    //   max-height: 600px;
    & .card-select-fav {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}
