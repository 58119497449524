@use "../../../../sass/variables.scss"as v;
@import '../../../../sass/partials/_buttons';
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";

.favourite{
    &.profil-fav{
        top     : auto !important;
        right   : auto !important;
        padding: 3px;
        background-color: var(--main-red-favorite);
        color: var(--main-white-color);
        border-radius: 5px;
        font-family: Hurme-Bold;
        font-size:v.$p;
        display: flex;
        transition: 0.3s;
        width: 110px;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        & i{
            filter: none !important;
            color: var(--main-white-color);
            font-size: v.$p;
            height: 12px;
            margin-right: 5px;
            transition: none;
        }
        &:hover{
            background-color: var(--main-white-color);
            color: var(--main-red-favorite);
            font-size: v.$px-ts-md;
            border: 1px solid var(--main-red-favorite);
            & i{
                color: var(--main-red-favorite);
                font-size: v.$px-ts-md;
                height: 14px;
            }
        }    
        &.is-favourite{
            color: var(--main-red-favorite);
            background-color: var(--main-white-color);
            border: 1px solid var(--main-red-favorite);
            & i{
                filter: none !important;
                font-size: v.$p;
                height: 12px;
                color: var(--main-red-favorite);
            }
        }
        &.is-favourite:hover{
            background-color: var(--main-red-favorite);
            color: var(--main-white-color);
            & i{
                filter: none !important;
                color: var(--main-white-color);
                font-size: v.$px-ts-md;
                height: 14px;
            }
        }
    }
    position: absolute;
    top     : 13px;
    right   : 13px;
    & i{
        height  : 17px;
        cursor  : pointer;
        font-size: 1.6rem;
        transition: 0.3s;
        filter  : invert(77%) sepia(3%) saturate(1830%) hue-rotate(314deg) brightness(98%) contrast(142%);
    }
    & i:hover{
        filter: invert(46%) sepia(83%) saturate(6651%) hue-rotate(348deg) brightness(92%) contrast(108%);
        font-size: 1.8rem;
    }
    & .is-favourite{
        filter: invert(46%) sepia(83%) saturate(6651%) hue-rotate(348deg) brightness(92%) contrast(108%);
    }
    & .is-favourite:hover{
        filter  : invert(77%) sepia(3%) saturate(1830%) hue-rotate(314deg) brightness(98%) contrast(142%);
    }
}
 .favourite-cursor-none{
    cursor: default !important;
    & i{
        cursor: default !important;
    }
    & .is-favourite:hover{
        filter: invert(46%) sepia(83%) saturate(6651%) hue-rotate(348deg) brightness(92%) contrast(108%) !important;
    }
}