@use "../_colors.scss" as colors;
@import "../others/animations.scss";
$checkbox-size: 16px;
$margin: 16px;
$margin-small: $margin / 2;
$text-lighter: #ccc;
$brand: linear-gradient(135deg, colors.$lavender-floral 0%, #6B7CFF 100%);
input {
    &.app-checkbox {
        position: relative !important;
        appearance: none;
        padding: $margin-small;
        box-sizing: content-box;
        overflow: hidden;
        // circle
        &:before {
            content: '';
            display: block;
            box-sizing: content-box;
            width: $checkbox-size;
            height: $checkbox-size;
            border: 2px solid colors.$lavender-floral;
            transition: 0.2s border-color ease;
        }
        &:checked:before {
            border: 2px solid colors.$lavender-floral;
            transition: 0.5s border-color ease;
        }
        &:disabled:before {
            border-color: $text-lighter;
            background-color: $text-lighter;
        }
        // dot
        &:after {
            content: '';
            display: block;
            position: absolute;
            box-sizing: content-box;
            top: 50%;
            left: 50%;
            transform-origin: 50% 50%;
            background: $brand;
            width: $checkbox-size;
            height: $checkbox-size;
            border-radius: 100vh;
            transform: translate(-50%, -50%) scale(0);
        }
        &[type="radio"] {
            &:before {
                border-radius: 100vh;
            }
            &:after {
                width: $checkbox-size;
                height: $checkbox-size;
                border-radius: 100vh;
                transform: translate(-50%, -50%) scale(0);
            }
            &:checked:after {
                animation: toggleOnRadio 0.2s ease forwards;
            }
        }
        &[type="checkbox"] {
            &:before {
                border-radius: $checkbox-size / 4;
            }
            &:after {
                width: $checkbox-size * 0.6;
                height: $checkbox-size;
                border-radius: 0;
                transform: translate(-50%, -85%) scale(0) rotate(45deg);
                background-color: transparent;
                box-shadow: 4px 4px 0px 0px $brand;
            }
            &:checked:after {
                animation: toggleOnCheckbox 0.2s ease forwards;
            }
        }
        &[type="checkbox"].filled {
            &:before {
                border-radius: $checkbox-size / 4;
                transition: 0.2s border-color ease, 0.2s background-color ease;
            }
            &:checked:not(:disabled):before {
                background-color: $brand;
            }
            &:not(:disabled):after {
                box-shadow: 4px 4px 0px 0px white;
            }
        }
    }
}