@use './sass/_colors.scss'as c;
@import './sass/partials/input';
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import './sass/global.scss';
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    --main-blue: #{c.$main-blue};
    --main-blue-color-text: #{c.$blue-dark2};
    --main-blue-color-bg: #{c.$blue-dark};
    --main-blue-dark: #{c.$main-blue-dark};
    --main-blue-client: #{c.$blue-client};
    --main-blue-light: #{c.$blue-light};
    /* Gradient */
    --main-gradient-orange: #{c.$gradient-orange};
    --main-gradient-blue: #{c.$gradient-blue};
    // Gradient//
    --main-blue-fair-color-bg: #BCC9FF;
    --main-blue-low-color-bg: #90D7FF;
    --main-gargoyle-gas: #{c.$gargoyle-gas};
    --main-dark-blue-text-color: #3A4161;
    --main-blue-mystified: #{c.$main-blue-mystified};
    --main-blue-mobile: #0182CA;
    --main-blue-maya: #{c.$maya-blue};
    --main-blue-chat: #{c.$blue-chat};
    --main-grey-color-text: #{c.$grey-dark};
    --main-light-blue-magenta: #{c.$light-blue-magenta};
    --main-alert-color: #{c.$alert};
    --main-error-txt: #{c.$error-txt};
    --main-violet-color-text: #615AF6;
    --violet-deep-bg: #{c.$alt-info-violet};
    --main-grey-placeholder-color-text: #C4C4C4;
    --main-white-color: #fff;
    --main-white-color-trans: #{c.$white-semi-trans};
    --main-ghost-white: #{c.$ghost-white};
    --dashboard-blue-v1: #{c.$blue-v1};
    --main-dark-color: #000;
    --main-grey-darken :#{c.$grey-darken};
    --main-grey-medium: #969696;
    --main-grey-fair :#{c.$grey-fair};
    --main-grey :#{c.$grey};
    --main-grey-text :#{c.$grey-text};
    --main-light-grey-bg :#{c.$light-grey-bg};
    --main-grey-blue :#{c.$grey-blue};
    --blue-v1: #{c.$blue-v1};
    --main-yellow: #FFECA7;
    --main-gargoyle-gas:#{c.$gargoyle-gas};
    --main-tangerine-yellow:#{c.$tangerine-yellow};
    --main-blue-navy: #{c.$blue-navy};
    --main-violet-color: #9b86eb;
    --main-blue-very-pale: #{c.$blue-very-pale};
    --main-blueberry-color: #8287FF;
    --electric-blue: #{c.$electric-blue};
    --blue-gulf: #{c.$blue-gulf};
    --black-russian: #{c.$black-russian};
    --main-perano-purple:#{c.$perano-purple};
    --main-bright-turquoise-offered: #{c.$bright-turquoise-offered};
    --main-yellow-info: #{c.$yellow-info};
    /* gradient client */
    --gradient-blue-client: #{c.$gradient-blue-client};
    --gradient-blue-green: #{c.$gradient-blue-green};
    --main-grey-darken:#{c.$grey-darken};
    --main-grey-fair:#{c.$grey-fair};
    --main-grey:#{c.$grey};
    --main-light-grey-bg:#{c.$light-grey-bg};
    --main-light-grey2:#{c.$light-grey};
    --main-grey-blue:#{c.$grey-blue};
    --main-apple-green:#{c.$apple-green};
    --main-reptile-green:#{c.$reptile-green};
    --main-apply-status:#{c.$apply-status};
    --main-light-grey: #D6D6D6;
    --main-grey-charcoal: #{c.$grey-charcoal};
    --main-grey-approx: #{c.$approx-grey};
    --main-red-favorite:#{c.$favorite};
    --main-text-condition-color:#{c.$text-file-conditions-color};
    --main-lavender-color:#{#E7EBFF};
    --main-very-dark-violet:#{c.$blue-very-dark-violet};
    --main-aquamarine-green:#{c.$aquamarine-green};
    /* side menu launch casting */
    --dashboard-blue-launch-casting: #{c.$blue-btn};
    /* dashboard color */
    --dashboard-blue-fair: #{c.$blue-fair};
    --dashboard-green-color: #{c.$green-status};
    --dashboard-blue-color-footer-sidemenu: #CDD8FF;
    --dashboard-blue-color-active-menu: #ECF0FF;
    /* home page dashboard */
    --home-violet-color-bg: #AE85FE;
    --home-violet-dark-color-text: #67A4FF;
    /* my castings page color */
    --my-castings-thick-blue: #B6C6FF;
    --my-castings-fair-blue: #{c.$new-blue-fair};
    --my-castings-fair-green: #{c.$green-status};
    --my-castings-real-fair-green: #{c.$fair-green};
    --my-castings-alice-blue: #{c.$alice-blue};
    --my-castings-medium-champagne: #{c.$medium-champagne};
    --my-castings-blue-sky: #8AB5F4;
    /* types castings color main */
    --types-castings-color-acting: #FF778F;
    --types-castings-color-choregraphy: #FFF96E;
    --types-castings-color-testimony: #85E9FF;
    --types-castings-color-creativity: #E8A5FF;
    --types-castings-color-test-product: #B3FF85;
    --my-castings-grey-color-text: #DBDBDB;
    --my-castings-card-grey-color-text: #999999;
    //Status contract
    --contrat-status-green: #{c.$green-status};
    --contrat-status-orange: #{c.$orange-status};
    --contrat-status-blue: #{c.$blue-status};
    // skeleton loading
    --base-color: var(--main-white-color) !important;
    --highlight-color: #eee !important;
    --text-conditions-color: #{c.$text-file-conditions-color};
    // modal warning color
    --modal-close-casting: #{c.$close-casting};
    --modal-warning-info: #{c.$warning-info};
    --modal-warning-brief-info: #{c.$warning-brief-info};
    --modal-launch-casting-info: #{c.$launch-casting-info};
    --modal-stripe-valid-info: #{c.$stripe-valid-info};
    --modal-stripe-error-info: #{c.$stripe-error-info};
    --modal-no-favorite-vogzter: #{c.$no-favorite-vogzter};
    --modal-confirmation-rdv-info: #{c.$confirmation-rdv-info};
    // vogzter profile
    --vogzter-followers-nano: #C5FFD5;
    --vogzter-followers-micro: #C0FBFF;
    --vogzter-followers-macro: #C0CAFF;
    --vogzter-followers-unclassified: #F2F4FF;
    --vogzter-languages-stroke: #ADE0E9;
    --vogzter-languages-fill: #F1FDFF;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

html {
    overflow: hidden;
}

.__react_component_tooltip {
    background: none !important;
    &.show {
        opacity: 1 !important;
    }
    &.place-bottom::after {
        border-bottom-color: #fff !important;
        top: 2px !important;
    }
    &.place-right::after {
        border-right-color: #fff !important;
        left: 15px !important;
    }
}

#overlay {
    z-index: 20;
    display: none;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    will-change: opacity;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    /* left        : 50%; */
    /* transform   : translateX(-50%); */
    backdrop-filter: blur(6px) saturate(180%);
}

#overlay a {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
}

.chat-messages {
    gap: 8px;
    display: flex;
    flex-direction: column;
}

/* Safari */
@supports (-webkit-touch-callout: none) {
    body {
      /* The hack for Safari */
      min-height: -webkit-fill-available;
    }
  }

/* Firefox */

input[type='number'] {
    -moz-appearance: textfield;
}

.container-router {
    display: flex;
    overflow: hidden;
    height: 100vh;
}


/* toastify css override to have better toast in front */

.Toastify__toast-theme--colored.Toastify__toast--error {
    backdrop-filter: blur(6px) saturate(180%);
    background: rgba(231, 76, 60, 0.8) !important;
}

.react-loading-skeleton {
    background-color: var(--base-color);
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
    /* Necessary for overflow: hidden to work correctly in Safari */
}

.bg-linear-yellow_ {
    background: linear-gradient(180deg, transparent 50%, var(--main-gargoyle-gas) 50%);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.px1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.group {
    display: flex;
    justify-content: space-between;
}

.c-nt-allowed {
    cursor: not-allowed !important;
}


/* dashboard styles  */

.dashboard-content .loading-spinner {
    height: 97vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboard-content .loading-spinner .loading-spinner-content {
    fill: var(--main-blue-color-bg) !important;
}

.dashboard-styles {
    width: 100%;
    min-height: 100%;
    display: flex;
    position: inherit;
}

.dashboard-styles .side-menu * {
    margin: 0;
    padding: 0;
}

.dashboard-styles .side-menu ul {
    margin: 0;
    padding: 0;
}

.dashboard-styles .side-menu li {
    list-style: none;
}

.dashboard-styles .dashboard-content {
    /* margin-left: 300px; */
    padding-left: 300px;
    /* padding-right: 50px; */
    /* padding-top  : 30px; */
    width: 100%;
    /* height       : 100%; */
    /* padding      : 42px; */
    /* transition   : margin-left .5s ease-in; */
    transition: padding-left 0.5s ease-in;
    /* margin       : 0; */
    position: absolute;
    z-index: -1;
    min-height: 100%;
}

.dashboard-styles .dashboard-content.inactive {
    padding-left: 85px;
}

.dashboard-styles .center-other-card {
    height: 61.7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-styles .side-menu.inactive .center-other-card {
    height: 73.7vh;
}

.dashboard-styles .side-menu.inactive .center-other-card .launch-casting-div {
    padding: 12px 8px;
}

.dashboard-styles .side-menu.inactive .center-other-card .launch-casting-div br {
    display: none;
}

.dashboard-styles .side-menu .center-other-card .launch-casting-div svg {
    width: 100%;
    transition: 0.5s width ease-in;
}

.dashboard-styles .side-menu.inactive .center-other-card .launch-casting-div svg {
    width: 30px;
}

.dashboard-styles .center-other-card .launch-casting-div {
    background-color: var(--dashboard-blue-launch-casting);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    top: 10%;
    padding: 9vh 30px;
    transition: 0.5s padding ease-in;
    /* transform    : translateY(-10%); */
}

.dashboard-styles .center-other-card .launch-casting-div button {
    padding: 12px 8px;
    background-color: var(--main-blue-color-bg);
    border: 1px solid var(--main-blue-color-bg);
    border-radius: 12px;
    width: 100%;
    color: #fff;
    font-family: Hurme;
    cursor: pointer;
    transition: 0.2s ease-in;
}

.dashboard-styles .side-menu .center-other-card .launch-casting-div button {
    display: flex;
    justify-content: center;
    /* transition  : opacity, width, height .2s ease-out; */
    opacity: 1;
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 12px 8px;
}

.dashboard-styles .side-menu.inactive .center-other-card .launch-casting-div button {
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
    padding: 0;
    /* display    : none; */
}

.dashboard-styles .center-other-card .launch-casting-div button:hover {
    background-color: #fff;
    border: 1px solid var(--main-blue-color-bg);
    border-radius: 12px;
    color: var(--main-blue-color-bg);
    cursor: pointer;
}

.dashboard-styles .center-other-card .question-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Hurme;
    text-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
}

.dashboard-styles .side-menu .center-other-card .question-style h4 {
    color: var(--dashboard-blue-color-text);
    text-align: center;
    font-size: 14px;
}

.dashboard-styles .center-other-card .question-style .tel-icon {
    transition: 0.5s ease-in;
}

.dashboard-styles .side-menu .center-other-card .question-style .tel-icon {
    /* margin-block: 1em;  */
    width: 0;
    height: 0;
    cursor: pointer;
    transition: 0.5s ease-in;
}

.dashboard-styles .side-menu.inactive .center-other-card .question-style .tel-icon {
    width: auto;
    height: auto;
}

.dashboard-styles .side-menu.inactive .center-other-card .question-style h4 {
    display: none;
}

.dashboard-styles .center-other-card .question-style a {
    color: var(--main-blue-color-bg);
    text-decoration: none;
    font-size: 18px;
}

.dashboard-styles .side-menu.inactive .center-other-card .question-style a {
    display: none;
}

.side-menu-footer .side-menu-footer-content .side-left-footer-content .item-selectable {
    transition-property: background-color, color;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
}

.side-menu-footer .side-menu-footer-content .side-left-footer-content .item-selectable:hover {
    background-color: var(--dashboard-blue-color-text);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.side-menu-footer .side-menu-footer-content .side-left-footer-content .item-selectable>li:nth-last-child(2)>li:hover {
    background-color: var(--dashboard-blue-color-text);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


/* open menu react styles */

.szh-menu {
    border-radius: 12px;
}

.szh-menu-container>ul {
    left: -13px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.dashboard-styles .side-menu .item-selectable-last {
    font-size: 11px;
    align-self: center;
    font-family: 'Hurme';
    opacity: 0.25;
    width: 100%;
    display: flex;
    justify-content: center;
}

.dashboard-styles .side-menu .item-selectable-last:first-child {
    opacity: 1;
}


/* bootstrap modal css */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
    margin: 0.25rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-header {
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal-fullscreen .modal-footer {
    border-radius: 0;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }
}

.btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline;
}

.btn-link:hover {
    color: #0a58ca;
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
        transition: none;
    }
}

.dropdown,
.dropend,
.dropstart,
.dropup {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
    margin: 0.25rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-header {
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal-fullscreen .modal-footer {
    border-radius: 0;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1440px) {
    .dashboard-styles .top-section .toggle-menu-button>button {
        display: none;
    }
}

// shape css
.arrow {
    width: 15px;
    height: 15px;
    border: 2px solid #ffff;
    border-left: 0;
    border-top: 0;
    .arrow-up {
        transform: rotate(225deg);
    }
    .arrow-down {
        transform: rotate(45deg);
    }
}

.triangle {
    &.triangle-top-left {
        border-top: 50px solid var(--main-white-color);
        border-right: 100px solid transparent;
    }
    &.triangle-top-right {
        border-top: 50px solid var(--main-white-color);
        // border-top-right-radius: 7px;
        border-left: 50px solid transparent;
    }
    &.triangle-bottom-left {
        border-bottom: 100px solid var(--main-white-color);
        border-right: 100px solid transparent;
    }
    &.triangle-bottom-right {
        border-bottom: 100px solid var(--main-white-color);
        border-left: 100px solid transparent;
    }
}

.shadow-access-platform {
    .shadow-access {
        box-shadow: -7px 7px 0px 0px rgba(220, 224, 239, 1);
    }
    .fit-height {
        height: fit-content;
    }
    .fit-width {
        width: fit-content;
    }
    .text-ellipsis {
        line-height: 1.3rem;
        overflow: hidden;
        display: -webkit-box; 
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .width-half-description {
        width: calc((100% - 20px) / 2) ;
    }
}

.next-arrow-slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #F8F8FF !important;
    border-radius: 9999px !important;
    background:#FAFAFF !important;
    color: #7885ED !important;
    width: 45px !important;
     height: 45px !important;
     right: 0px !important;
}

.perv-arrow-slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #F8F8FF !important;
    border-radius: 9999px !important;
    background:#FAFAFF !important;
    color: #7885ED !important;
    width: 45px !important;
     height: 45px !important;
     left: 0px !important;
     z-index: 1;
}

.slick-button {
    .slick-next::before{
        display: none!important;
    }
    .slick-prev::before {
        display: none !important;
    }
}
.slick-logo {
    .current-logo {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

.show-banner-price{
    display: flex;
    opacity: 1;
}

.slick-button .inner-item-slick {
    margin: 0 20px;
  }
@media screen and (max-width: 768px) {
    .swiper-step2 .swiper-slide{
        display: flex;
        justify-content: center;
    }
    
}