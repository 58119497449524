@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import '../../../../sass/partials/_buttons';
@import '../../../../sass/mixins';
@import '../../../../sass/_mixins/global';
.right-panel {
  min-width: 270px;
  min-height: 100vh;
  z-index: 50;
  background: #ffffff;
  box-shadow: 9px 0px 20px rgba(222, 222, 222, 0.25);
  border-radius: 0 24px 24px 0;
  transition: all 0.2s ease-in-out;
  position: relative;
  &.mobile {
    margin-left: 0px !important;
  }
  &.active {
    min-width: 116px;
    main {
      .gradient-vogz {
        img {
          height: 16px;
        }
      }
      section {
        &:nth-of-type(2) {
          margin: 0;
        }
        .list-menu-links {
          li a {
            display: flex;
            justify-content: center;
            span {
              display: none;
            }
          }
        }
        .card-vogz {
          background: linear-gradient(
            98.26deg,
            rgba(92, 111, 253, 0.5) 0%,
            rgba(166, 85, 255, 0.5) 100%
          );
          border: 2px solid #ffffff;
          box-shadow: 0px 2px 14px #eeeeee;
          height: 105px;
          max-width: 120px;
          max-height: 120px;
          border-radius: 50px;
          img {
            width: 60%;
          }
          button {
            display: none !important;
          }
        }
        .ask-vogz {
          flex-direction: column;
          align-items: center;
          i {
            font-size: 34px !important;
          }
          div {
            margin-top: 12px;
            span {
              text-align: center;
              font-size: v.$px-ts-ul-sm !important;
            }
            margin-left: 0 !important;
            a {
              display: none !important;
            }
          }
        }
        &:nth-of-type(3) {
          justify-content: space-around;
          //height: 50%;
          .crown-svg {
            margin-top: 0px !important;
          }
          .shadow-fx-btn {
            span {
              display: none;
            }

            h3 {
              font-size: 0.75rem;
            }
          }
          .logout-btn {
            * {
              font-size: 0.7rem;
            }
          }
          
        }
      }
      .new-collab {
        position: relative;
        display: flex;
        width: 4.5rem;
        min-width: 4.5rem;
        height: 4.5rem;
        min-height: 4.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        padding: 0;

        span {
          display: flex;
          width: 60% !important;
          height: 60% !important;
          justify-content: center;
          align-items: center;
          margin-right: 0;

          b {
            position: relative;
            font-size: 1.5rem;
          }
        }

        h3 {
          display: none;
        }
      }
      .available-credits {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        font-family: Roboto;
        font-weight: 300;
        color: colors.$main-blue;

        h4 {
          font-family: Hurme-Bold;
          font-size: 1.6rem;
          margin: auto;
          border: none;
        }
        span {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 0.9rem;
          b {
            font-family: Hurme-Black;
            font-size: 1rem;

            &::before {
              display: none;
            }
          }
        }
      }
      footer {
        display: flex;
        justify-content: center;
        background-color: transparent;
        margin-bottom: 20px;
        padding: 0;
        & > div {
          display: none;
        }
        button {
          background-color: var(--my-castings-fair-blue);
          padding: 3px;
          border-radius: 6px;
          width: 90%;
          &.btn-2 {
            display: flex;
            font-size: v.$px-ts-sm * 0.9;
            font-family: Hurme-Bold;
            justify-content: center;
            align-items: center;
            color: var(--main-blue-color-bg);
            transition: all 0.2s ease-in-out;
            i {
              margin-left: 3px;
            }
          }
          .tag-guest {
            top: 0;
          }
          &:hover {
            background-color: darken(
              $color: colors.$new-blue-fair,
              $amount: 30
            );
            color: var(--main-white-color);
          }
        }
        img {
          margin-top: 1rem;
          width: 60px;
        }
      }
    }
  }
  hr {
    border-color: #C5C5C5;
  }
  main {
    width: 100%;
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
    height: 100%;
    display: flex;
    section, hr {
      margin: 0 8%;
    }
    & .disabled-section {
      cursor: not-allowed !important;
    }
    & header {
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 70px;
        object-fit: contain;
        max-width: 100%;
      }
      .btn-toggle {
        position: absolute;
        margin-right: -350px;
        display: block;
        background-color: var(--dashboard-blue-launch-casting);
        color: var(--main-white-color);
        padding: 0 8px;
        border-radius: 8px;
      }
    }
    .gradient-vogz {
      background: linear-gradient(
        91.73deg,
        var(--main-gradient-orange) 0%,
        var(--main-gradient-blue) 100%
      );
      animation: gradient 8s ease infinite;
      background-size: 400% 400%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > section {
      //min-height: 70%;
      &:nth-of-type(1) {
        display: flex;
        flex: 1;
        overflow: scroll;
        padding: 0;
        margin-top: 20px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        
        &::-webkit-scrollbar-track {
          background: colors.$new-blue-fair; 
          border-radius: 100px;
        }
         
        &::-webkit-scrollbar-thumb {
          background: #E4EAFF;
          border-radius: 100px;
        }
        
        &::-webkit-scrollbar-thumb:active {
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
        }
        position: relative;
        &::after {
          content: '';
          position: sticky;
          left: 0;
          bottom: 0;
          pointer-events: none;
          background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(0, 0, 0, 0.096));
          width: 100%;
          height: 30px;
          display: block;
        }
        padding: 30px 0 0;
        display: block;
      }
      .list-menu-links {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;


        li {
          display: flex;
          flex: 1;
          margin-bottom: 12px;
          margin-right: 10px;
          list-style: none;
          &.disabled {
            cursor: not-allowed !important;
            a{
              pointer-events: none;
              box-shadow: none;
            }
          }
          a {
            position: relative;
            padding-left: calc(90% - 159px);
            height: 49px;
            width: 100%;
            border-radius: 7px;
            display: flex;
            align-items: center;
            // justify-content: center;
            text-decoration: none;
            color: var(--dashboard-blue-fair);

            * {
              z-index: 2;
            }
            
            &::before {
              position: absolute;
              left: -100%;
              right: 0;
              display: block;
              content: '';
              width: 100%;
              height: 100%;
              background: colors.$azureish-white;
              border-radius: inherit;
              transition: 0.15s;
              z-index: 1;
            }
            &::after {
              position: absolute;
              left: -100%;
              right: 0;
              display: block;
              content: '';
              width: 100%;
              height: 100%;
              background: linear-gradient(90deg, #fdfdff 0%, #f9faff 40%);
              background-size: 250px;
              transition: 0.15s;
              z-index: 0;
            }

            i {
              min-width: 40px;
              font-size: 24px;
              display: flex;
              justify-content: center;
            }
            span {
              font-family: Hurme-Bold;
              margin-left: 12px;
              font-size: v.$px-ts-md;
            }
            &:hover {
              &::after {
                left: 0;
              }
            }
            &.list-menu-active {
              color: var(--main-blue);
              &::before {
                left: 0;
              }
              &:hover {
                background-position: 280px;
              }
              span {
                font-family: Hurme-Bold;
              }
            }
            .nb-msg-n-read{
              position: absolute;
              font-size: v.$pMobil;
              color: var(--main-white-color);
              background-color: var(--main-alert-color);
              display: flex !important;
              border-radius: 1.25rem;
              width: 1.25rem;
              height: 1.25rem;
              line-height: initial;
              opacity: 1 !important;
              align-items: center;
              justify-content: center;
              top: -10px;
              right: -5px;
            }
          }
          .tooltip-menu {
            font-family: Hurme-Bold;
          }
        }
      }
      &:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding-top: 15px;

        & .disabled {
          cursor: not-allowed !important;
          background: linear-gradient(92.76deg, #ECEAFF 0%, #DDEEFF 100%);
          box-shadow: none;
        }
      }
      &:nth-of-type(3) {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        // height: 50%;
        .crown-svg {
          margin-top: 20px;
        }
        .ask-vogz {
          display: flex;
          justify-content: center;
          margin-top: 22px;
          i {
            color: var(--dashboard-blue-launch-casting);
            font-size: v.$ico-md;
          }
          & > div {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            span {
              font-style: normal;
              font-weight: 700;
              font-family: Hurme-Bold;
              line-height: 15px;
              font-size: v.$px-ts-sm;
              position: relative;
              &:nth-of-type(1) {
                color: var(--main-blue);
              }
            }
            & a {
              color: var(--dashboard-blue-fair);
              font-family: Hurme-Bold;
              margin-top: 5px;
              display: block;
              position: relative;
              font-size: v.$px-ts-md;
              text-decoration: none;
              &:before {
                content: ' ';
                position: absolute;
                width: 100%;
                z-index: -1;
                height: 58%;
                bottom: 0px;
                background-color: var(--dashboard-blue-launch-casting);
              }
            }
          }
        }
      }
      &:nth-of-type(3) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex: 1;
        // height: 50%;
        .round-vogz {
          background: linear-gradient(
            98.26deg,
            rgba(92, 111, 253, 0.5) 0%,
            rgba(166, 85, 255, 0.5) 100%
          );
          border: 2px solid #ffffff;
          box-shadow: 0px 2px 14px #eeeeee;
          cursor: pointer;
          max-width: 120px;
          max-height: 120px;
          width: 100%;
          height: 100%;
          border-radius: 60px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          position: relative;
          & .img-add {
            position: absolute;
            top: 3px;
            right: 3px;
            width: 25% !important;
          }
          & .img-logo {
            width: 50%;
            filter: drop-shadow(0px 4px 20px #ddd5ff);
            margin-left: 10%;
          }
          button {
            margin-top: 20px;
            min-width: 150px;
            font-size: v.$px-ts-sm;
          }
        }
        .btn-bottom {
          position: relative;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          padding: 0.5rem 5px;
          background: white;
          cursor: pointer;

          h3 {
            width: 100%;
            font-family: Hurme-Bold;
            font-size: 0.8rem;
            color: inherit;
            text-align: center;
          }

          * {
            z-index: 1;
          }

          &:after {
            position: absolute;
            left: 0;
            content: '';
            display: block;
            width: 0%;
            height: 100%;
            z-index: 10;
            background: colors.$new-blue-fair;
            z-index: 0;
            border-radius: inherit;
            transition: 0.2s;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
        .shadow-fx-btn {
          position: relative;
          border: solid 1px;

          h3 {
            text-transform: uppercase;
          }

          span {
            width: 15%;
            position: absolute;
            right: 0;
            font-family: Hurme-Bold;
            border-left-width: 1px;
            border-color: inherit;
            color: inherit;
            text-align: center;
            font-size: 1rem;
          }

          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: translateX(-3.5%) translateY(15%);
            border-radius: inherit;
            transition: 0.2s;
          }
          &:hover {
            &::before {
              transform: translateX(-6%) translateY(30%);
            }
          }
        }
        .premium {
          color: #FFAD0E;
          &::before {
            background: #FFC554;
            border: inherit
          }
        }
        .vogz-coin-btn {
          color: colors.$blue-dark2;
          &::before {
            background: #5951FF;
            border: inherit
          }
        }
        .faq-btn {
          color: colors.$blue-dark;
          &::before {
            background: colors.$blue-fair;
            border: inherit
          }
        }
        .logout-btn {
          background: colors.$new-blue-fair;
          color: colors.$blue-dark;

          i {
            margin-left: 0.3rem;
            font-size: 0.65rem;
          }
        }
        .crown-svg {
          margin-top: 20px;
        }
        .ask-vogz {
          display: flex;
          justify-content: center;
          margin-top: 22px;
          i {
            color: var(--dashboard-blue-launch-casting);
            font-size: v.$ico-md;
          }
          & > div {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            span {
              font-style: normal;
              font-weight: 700;
              font-family: Hurme-Bold;
              line-height: 15px;
              font-size: v.$px-ts-sm;
              position: relative;
              &:nth-of-type(1) {
                color: var(--main-blue);
              }
            }
            & a {
              color: var(--dashboard-blue-fair);
              font-family: Hurme-Bold;
              margin-top: 5px;
              display: block;
              position: relative;
              font-size: v.$px-ts-md;
              text-decoration: none;
              &:before {
                content: ' ';
                position: absolute;
                width: 100%;
                z-index: -1;
                height: 58%;
                bottom: 0px;
                background-color: var(--dashboard-blue-launch-casting);
              }
            }
          }
        }
      }
    }
    .new-collab {
      display: flex;
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 12px rgba(130, 127, 127, 0.25);
      border-radius: 22.5px;
      padding: 0.8rem 1.3rem;
      margin-bottom: 0.7rem;
      transition: 0.3s;
      background: linear-gradient(92.76deg, #A073FF 5%, #78BCED 100%);
      background-size: 150% 150%;
      cursor: pointer;
      animation: new-collab-animation 4s ease infinite;

      * {
        z-index: 1;
      }

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: inherit;
        opacity: 0;
        transition: 0.2s;
        background: inherit;
        animation: inherit;
        animation-duration: 1.5s;
        animation-timing-function: linear;
      }

      &:hover {
        &::after {
          opacity: 1;
          background-size: 130% 130%;
        }
      }

      &:hover {
        background-position-x: right;
      }

      span {
        position: relative;
        width: 1.35rem;
        height: 1.35rem;
        border: solid 1px white;
        border-radius: 100%;
        margin-right: 0.5rem;

        b {
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          font-family: Hurme-Bold;
          color: white;
          line-height: initial;
          font-size: 0.85rem;
          display: flex;
          align-items: center;
          justify-content: center;        }
      }

      h3 {
        width: fit-content;
        font-family: Hurme-Bold;
        font-size: 0.87rem;
        color: white;
      }
    }
    .available-credits {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      font-family: Roboto;
      font-weight: 300;
      color: var(--main-blue);
      font-size: 0.8rem;
      flex-direction: column;

      h4 {
        display: flex;
        flex: 1;
        justify-content: center;
        font-family: Hurme-Bold;
        font-size: 1.6rem;
        margin-right: 1rem;
      }
      b {
        position: relative;
        font-family: Hurme-Black;
        font-size: 1rem;
        
        &::before {
          display: block;
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          transform: translateX(-10px);
          z-index: 1;
          background: colors.$main-blue;
        }
      }
    }
    footer {
      display: flex;
      justify-content: center;
      padding: 20px 18px;
      width: 100%;
      div {
        h3 {
          color: var(--main-blue);
          font-weight: 900;
          font-family: Hurme-Bold;
          font-size: v.$btn-txt-md;
          line-height: 12px;
        }
        span {
          color: var(--main-blue);
          font-family: Hurme-Regular;
          font-size: v.$px-ts-md * 0.9;
        }
      }
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        &.btn-2 {
          display: none;
        }
      }
      .tag-guest {
        font-size: v.$px-ts-sm * 0.9;
        width: fit-content;
        display: block;
        line-height: 22px;
        padding: 0px 7px;
      }
      .menu-item-selectable {
        font-family: Hurme-Bold;
        font-size: v.$px-ts-sm;
        padding: 0 12px;
        //border-radius: 12px;
      }
    }
    hr {
      &:nth-of-type(2) {
        margin-top: 10px;
      }
    }
  }
  @media screen and (max-height: 900px) {
    main {
      section {
        &:nth-of-type(3) {
          height: inherit;
          flex: 1 1;
          justify-content: center;
          padding: 0;
        }
      }
    }
  }
  @include tablet() {
    main {
      section {
        .round-vogz {
          height: 16vh !important;
          img {
            width: 50%;
          }
          & .img-add {
            width: 25% !important;
          }
          button {
            display: none !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    main {
      section {
        .round-vogz {
          max-width: 80px !important;
          max-height: 80px !important;
          & .img-logo {
            width: 40% !important;
          }
        }
      }
    }
  }
  @include mobile() {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -280px;
    main {
      header {
        img {
          max-width: 70px;
        }
      }
      section {
        .card-vogz {
          width: 60% !important;
          height: 70px !important;
          img {
            width: 60%;
          }
          button {
            display: none !important;
          }
        }
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 10% 70%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 10% 70%;
  }
}

@keyframes new-collab-animation {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
