@use '../../../../../sass/variables.scss'as variables;
@use '../../../../../sass/colors'as colors;
@import '../../../../../sass/others/animations.scss';
@import '../../../../../sass/mixins';
$width-bar-messages: 420px;

.placeholder-msg {
    position       : absolute;
    width          : 100%;
    height         : 90%;
    background     : #ffffff40;
    z-index        : 4;
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    backdrop-filter: blur(10px);

    & .placeholder-msg-text {
        display        : flex;
        text-align     : center;
        justify-content: center;
        flex-direction : column;
        position       : absolute;

        &>span {
            font-size    : calc(variables.$h1 * 3);
            margin-bottom: 1rem;
            line-height  : normal;
        }

        h3 {
            font-size     : variables.$h1;
            font-family   : Hurme-Black;
            color         : var(--main-dark-blue-text-color);
            background    : linear-gradient(180deg, var(--main-white-color) 50%, var(--main-gargoyle-gas) 50%);
            margin-bottom : 0.5rem;
            padding       : 5px 10px;
            text-transform: uppercase;
        }

        p {
            font-size     : variables.$h1Mob;
            font-weight   : 300;
            font-family   : Roboto;
            text-transform: uppercase;
            color         : var(--main-dark-blue-text-color);
        }
    }
}
.chat-public{
    top: 80px;
    height: calc(100vh - 80px);
    .chat-container{
        overflow-y: auto;
        & .section:nth-of-type(3){
            overflow: unset;
        }
    }
}
.chat-container-wrapper {
    // position: sticky;
    position  : relative;
    //top: 0px;
    min-width : 400px;
    width     : 100%;
    max-width : 400px;
    height    : 100vh;
    max-height: 100vh;
    z-index   : 100;
    @media screen and (min-width:1464px ){
        @media screen and (max-width: 1550px) {
            min-width : 325px;
        }
    }

    .chat-container {
        padding-inline: 1em;
        display       : flex;
        flex-direction: column;
        width         : 100%;
        height        : 100%;
        background    : colors.$white;
        box-shadow    : -5px 0px 20px #F5F5F5;
        z-index       : 100;

        & section:nth-of-type(1) {
            display        : flex;
            // flex: 0.3;
            height         : 50px;
            align-items    : center;
            justify-content: flex-end;

            & button {
                display     : flex;
                border      : 0;
                margin-right: 0.5rem;
                background  : none;
                cursor      : pointer;
                transition  : color 0.2s ease-in-out;

                & i {
                    font-size: variables.$medium-size-text * 1.5;
                }

                &:hover {
                    color: colors.$grey-fair;
                }
            }
        }

        & section:nth-of-type(2) {
            display       : flex;
            flex          : 2;
            flex-direction: column;
            align-items   : center;

            & .tags {
                display        : flex;
                flex-wrap      : wrap;
                justify-content: center;

                & div {
                    margin: 3px 5px;
                }
            }

            &>h2 {
                margin-top : 12px;
                font-size  : variables.$mid-title-size-text * 0.8;
                font-family: Hurme-Bold;
            }

            & p {
                color      : colors.$grey-dark;
                font-size  : variables.$medium-size-text;
                line-height: 18px;
                margin-top : 12px;
                text-align : center;
                font-family: Roboto;
            }

            & .header-vogzter {
                display        : flex;
                flex-direction : column;
                justify-content: center;
                align-items    : center;
                hr{
                    display: none;
               }
                .brand-switcher-profil{
                   top: 10px;
                   right: 10px;
                   height: 60px;
                   img{
                        width: 60px;
                        height: 60px;
                   }
                   & div:last-of-type{
                        right: 0em;
                   }
                }
                & img {
                    width        : 100px;
                    height       : 100px;
                    border       : solid 2px colors.$white;
                    border-radius: 100%;
                    filter       : drop-shadow(0px 2px 25px rgba(170, 170, 170, 0.25));
                    margin-top   : 0.5rem;
                }

                & p {
                    font-family   : Roboto;
                    color         : colors.$black-dark;
                    text-transform: uppercase;
                    font-size     : variables.$px-ts-sm;
                }

                & b {
                    font-family: Hurme-Bold;
                    font-size  : variables.$mid-title-size-text;
                    font-weight: 400;
                }

                & .offer-deal {
                    position       : relative;
                    display        : flex;
                    width          : 248px;
                    height         : 40px;
                    justify-content: center;
                    align-items    : center;
                    margin-bottom  : 1rem;
                    border-radius  : 5px;
                    background     : colors.$medium-champagne;
                    transition     : .2s;
                    border-color   : colors.$medium-champagne;
                    border-width   : 1px;

                    & b {
                        position     : absolute;
                        left         : 0;
                        right        : 0;
                        font-family  : Hurme-Bold;
                        font-size    : variables.$px-ts-md;
                        color        : colors.$black-dark;
                        border-radius: 5px;
                        transition   : 0.3s;
                        z-index      : 2;
                    }

                    &:hover:not(:disabled) {
                        & b {
                            //color: colors.$medium-champagne;
                        }

                        background-color: transparent;
                    }

                    &:hover:disabled {
                        filter: grayscale(1);
                        cursor: not-allowed;
                    }
                }
            }
        }
        & section:nth-of-type(3) {
            display       : flex;
            flex          : 6;
            width         : 100%;
            flex-direction: column;
            overflow      : auto;

            &>div:nth-of-type(1)>div>div div:not(:last-child) {
                margin: 0px 0 0px 0 !important;

                & div {
                    //margin-bottom: 6px;
                }

                & svg {
                    display: none !important;
                }
            }

            & .not-favourite {
                position       : relative;
                display        : flex;
                flex-direction : column;
                height         : 100%;
                padding        : 0 10%;
                justify-content: center;
                align-items    : center;

                & * {
                    text-align: center;
                }

                & .send-msg-desc {
                    width      : 90%;
                    line-height: 20px;
                    margin-top : 0.5rem;

                    & b {
                        color      : colors.$pastel-red;
                        font-family: Hurme-Bold;
                    }
                }

                & span {
                    font-size   : variables.$ico-sm-2;
                    margin-top  : 1.5rem;
                    margin-right: 10px;
                }

                & .heart {
                    position : absolute;
                    font-size: 60px;
                    animation: pulse-animation 2.5s ease-in-out infinite;

                    & :hover {
                        animation: pulse-animation-fast 0.5s ease-in-out infinite;
                    }
                }
            }
            & .guest {
                position       : relative;
                display        : flex;
                flex-direction : column;
                height         : 100%;
                padding        : 0 10%;
                justify-content: center;
                align-items    : center;
                & b {
                    color      : var(--main-blue-color-bg);
                    font-family: Hurme-Black;
                    font-size  : variables.$px-ts-nml;
                    margin-bottom: 1rem;
                }
                & .offer-deal {
                    position       : relative;
                    display        : flex;
                    width          : 248px;
                    height         : 40px;
                    justify-content: center;
                    align-items    : center;
                    margin-bottom  : 1rem;
                    border-radius  : 5px;
                    background     : colors.$medium-champagne;
                    transition     : .2s;
                    border-color   : colors.$medium-champagne;
                    border-width   : 1px;

                    & b {
                        position     : absolute;
                        left         : 0;
                        right        : 0;
                        font-family  : Hurme-Bold;
                        font-size    : variables.$px-ts-md;
                        color        : colors.$black-dark;
                        border-radius: 5px;
                        transition   : 0.3s;
                        z-index      : 2;
                        margin: 0;
                    }

                    &:hover:not(:disabled) {
                        background-color: transparent;
                    }

                    &:hover:disabled {
                        filter: grayscale(1);
                        cursor: not-allowed;
                    }
                }
                li{
                    font-family: Roboto;
                    font-size: variables.$px-ts-md;
                    color: var( --main-dark-blue-text-color);
                    margin-bottom: 1rem;
                    font-weight: 500;
                    &::before{
                        background: var(--main-dark-blue-text-color) !important;
                        width: 1rem;
                        color:var(--main-white-color);
                        border-radius: 50%;
                        padding: 0.25rem 0.6rem;
                        margin-right: 0.5rem;
                        font-family: Hurme-Black;
                        font-size: variables.$px-ts-sm;
                    }
                    &[data-nb]::before{
                        content: attr(data-nb);
                    }
                    p{
                        font-family: Roboto;
                        color: var(--main-grey-color-text);
                        font-size: variables.$p;
                        font-weight: 300;
                        width: 80%;
                        margin-left: 2rem;
                        line-height: 18px;
                    }
                }

            }
            .input-container-casting {
                flex-grow    : 0;
                display      : flex;
                align-items  : center;
                width        : 100%;
                padding-block: 0.5em;
            }

            .input-container-casting .input-content-casting {
                width            : 100%;
                max-height       : 5.5em;
                height           : 100%;
                position         : relative;
                border-radius    : 12px;
                display          : flex;
                align-items      : center;
                // padding-bottom: 9px;
            }

            .input-container-casting div:nth-of-type(2) {
                width          : 30px;
                display        : flex;
                align-items    : center;
                justify-content: center;
                height         : 100%;

                >p {
                    cursor   : pointer !important;
                    font-size: variables.$px-ts-md;
                    color    : var(--main-blue-color-bg);
                }
            }

            .input-container-casting>textarea {
                width         : 100%;
                vertical-align: center;
            }

            .input-container-casting .casting-message:focus {
                outline: 0;
            }

            .input-container-casting .casting-message {
                border        : 1px solid #e9e9e9;
                padding-block : 10px;
                padding-inline: 19px 20px;
                width         : 100%;
                border-radius : 16px;
                resize        : none;
                font-size     : variables.$px-ts-sm;
                font-family   : Hurme-Bold;
            }
        }

        & hr {
            width     : 40%;
            align-self: center;
        }
    }
}

@include tablet() {
    .chat-container-wrapper {
        position : absolute;
        left     : 0;
        right    : 0;
        top      : 0;
        bottom   : 0;
        width    : 100%;
        max-width: 100%;
        overflow : visible;
        .chat-container {
            top      : 0;
            width    : 100%;
            position : absolute;
            top      : 50%;
            right    : 50%;
            transform: translate(50%, -50%);
            height   : 100vh;
            min-width: 0 !important;
            & .header-vogzter {
                align-items    : center;
                hr{
                    display:block !important;
               }
            }
            .brand-switcher-profil{
                top: 0!important; ;
                right: 0px;
                height: 60px;
                margin-top: 0 !important;
                img{
                     width: 60px;
                     height: 60px;
                     margin-top: 0 !important;
                }
                & div:first-of-type{
                    width: 60px !important;
                }
                & div:last-of-type{
                    right: -3em !important;
               }
            }
        }

    }
}

@include mobile() {
    .chat-container-wrapper {
        min-width: 0 !important;
        position: relative;
        .chat-container {
            section:nth-of-type(3) {
                &.other {
                    margin-bottom: 80px;
                }
                &.mobile-safari {
                    margin-bottom: 290px;
                }
                &.ios-chrome {
                    margin-bottom: 80px;
                }
            }
            &.mobile-safari{
                margin-top:-30px;
                height: 93%;
            }
            & .chat-public .mobile-safari{
                overflow-y: scroll;
            }
        }
    }
}