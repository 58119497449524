@use "../../../sass/variables.scss"as v;
@import "../../../sass/mixins";
@import "../../../sass/_mixins/global";

.items-profile{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    div{
        border: 1px solid var(--main-violet-color-text);
        border-radius: 15px;
        margin: 2rem;
        max-width: 15rem;
        max-height: 19rem;
        button{
            padding: 1.7rem;
            border-radius: 15px;
            width: 100%;
            transition:all .5s ease;
            box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: var(--main-violet-color-text);
            text-transform: uppercase;
            font-family: Hurme-Black;
            font-size: v.$h2;
            position: relative;
            &:hover{
                box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
             } 
            img{
                margin-bottom: 2rem;
                max-width: 9.5rem;
            }
            span{
                font-size: v.$px-ts-ul-sm;
                position: absolute;
                text-transform: capitalize;
                top: 10px;
                right: 10px;
                border-radius: 5px;
                padding: 4px 25px;
                line-height: normal;
                color: var(--main-dark-color);
                font-family: Hurme-Black;
            }

        }
    }
    
}
@include tablet{
    .items-profile{
        div{
            margin: 1.5rem;
            width: 100%;
            max-width: 13.5rem;
            max-height: 16.5rem;
            button{
                // font-size: v.$h2Mob;
                padding: 1.5rem;
                img{
                    width: 8.25rem;
                }
            }
        }
    }
}
@include tablet2{
    .items-profile{
        flex-wrap: nowrap;
        min-height: 100%;
        flex-direction: column;
        div{
            margin: 1rem;
            max-width: 12rem;
            max-height: 15rem;
            button{
                font-size: v.$h2Mob;
                padding: 1rem;
                img{
                    width: 6.25rem;
                }
            }
        }
    }
}
@include mobile{
    .items-profile{
        align-items: center;
        padding-bottom: 2.5rem;
    }
}