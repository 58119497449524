@use '../../../../../../sass/colors'as colors;
@use "../../../../../../sass/variables"as variables;
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/_mixins/global";
$min-card-width: 800px;
$max-card-width: 1000px;
$min-height-card: 300px;
$grey-dark-card: #BFBFC1;
$grey-fair-card:#ededed;
.card-send-product {
    display: flex;
    min-width: $min-card-width;
    max-width: $max-card-width;
    min-height: 300px;
    width: 80%;
    -webkit-box-shadow: 0px 0px 16px 4px #ededed;
    box-shadow: 0px 0px 16px 4px #ededed;
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid #EEEEEE;
    margin-bottom: 12px;
    & h4 {
        font-family: Hurme-Bold;
    }
    & h5 {
        font-size: 14px;
        color:  #A57CF4;
        font-family: Hurme-Bold;
    }
    p {
        color: var(--main-dark-color);
        font-size: variables.$medium-size-text;
        font-family: roboto;
        line-height: 14px;
    }
    & .card-info-solo {}
    & button {
        background-color: #A57CF4;
        &:hover {
            border: 1px solid colors.$btn-product-send;
            color: colors.$btn-product-send;
        }
    }
    & aside {
        display: flex;
        flex-direction: column;
        & img {
            @include avatar;
        }
    }
    & aside:nth-of-type(1) {
        align-items: center;
        justify-content: center;
        width: $max-card-width * .3;
        -webkit-box-shadow: 0px 0px 30px 19px #EDEDED;
        background-color: #A57CF4;
        box-shadow: 0px 0px 30px 19px #EDEDED;
        border-radius: 12px;
        padding: 12px;
    }
    & aside:nth-of-type(2) {
        width: 100%;
        background-color: #F6F4FF;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & .sent-tag {
            background-color: colors.$green;
            font-size: variables.$low-size-text;
            color: var(--main-white-color);
            border-radius: 12px;
            padding: 0px 10px;
            font-family: Hurme-Bold;
            position: absolute;
            top: 15px;
            right: 20px;
            &>i {
                font-size: variables.$low-size-text * .8;
            }
        }
    }
    & aside:nth-of-type(2)>div {
        width: 80%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &>div:nth-of-type(1) {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        & .card-input-follow {
            width: 100%;
            & input {
                width: 100%;
                height: 40px;
                border-radius: 6px;
                border: 1px solid colors.$input-border-grey;
                padding: 0 12px;
                &::placeholder {
                    font-size: 15px;
                    color: colors.$blue-dark;
                }
                &:focus {
                    outline-style: none
                }
            }
        }
    }
}

@keyframes full-sd {
    0% {
        width: $min-height-card;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}

@include tablet {
    .card-send-product {
        flex-direction: column;
        min-width: 0;
        -webkit-box-shadow: 0px 4px 16px 4px #ededed;
        box-shadow: 0px 4px 16px 4px #ededed;
        overflow: initial;
        p {
            line-height: 22px;
        }
        h4 {
            display: block;
            margin-bottom: 12px;
        }
        & aside:nth-of-type(1) {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: #A57CF4;
            position: sticky;
            top: 0;
            z-index: 10;
            align-items: center;
            justify-content: center;
            width: 100%;
            -webkit-box-shadow: 0px 0px 30px 2px #EDEDED;
            box-shadow: 0px 0px 30px 2px #EDEDED;
            padding: 35px 0;
        }
        & aside:nth-of-type(2) {
            padding: 40px 0;
            &>div {
                &>div {
                    flex-direction: column;
                    margin: 5px 0;
                    & div {
                        align-self: start !important;
                        margin: 5px 0;
                    }
                }
            }
        }
    }
}

@include mobile {
    .card-send-product {
        flex-direction: column;
        min-width: 0;
        width: 90%;
        & aside:nth-of-type(1) {
            width: 100%;
        }
        & aside:nth-of-type(2) {
            & button {
                margin: 0 !important;
                max-width: 100% !important;
            }
        }
    }
}