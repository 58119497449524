@use "../../../../sass/colors"as colors;
@use "../../../../sass/variables.scss"as v;
@import "../../../../sass/mixins";
@import "../../../../sass/_mixins/global";
.h2-heading {
    color: colors.$blue-dark2;
    font-family: Hurme-Black;
    font-size: v.$h1;
    @include mobile() {
        font-size: v.$h1Mob !important;
    }
}

.p-sub-heading {
    color: colors.$grey-dark;
    font-size: v.$p;
    font-family: roboto;
    @include mobile() {
        font-size: v.$pMobil;
        text-align: center;
        width: 90%;
    }
}