@use "../../../../../../../sass/colors" as colors;
@use "../../../../../../../sass/variables.scss" as v;
@import "../../../../../../../sass/mixins";
@import "../../../../../../../sass/_mixins/global";

.page-main {
  --main-blue: #67a4ff;
  --main-blue-light: #c0d3ff;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //margin-bottom: 113px;
  //padding-bottom: 40px;
  // padding: 1em 0px 6em 0px;
  section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // padding-bottom: 20px;
      text-align: center;
      .cards-types {
          width: 90%;
          max-width: 1050px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 20px;
      }
      .card_products {
          display: flex;
          width: 100%;
          max-width: 1050px;
          overflow-y: visible;

              .card_product {
                position: relative;
                  min-height: 330px;
                  max-width: 305px;
                  min-width: 260px;
                  width: 100%;
                  padding: 10px;
                  margin: 15px 10px;
                  border-radius: 11px;
                  box-shadow: 0px 4px 15px #ECECEC;
                  cursor: pointer;
                  transition: all .2s ease-in-out;
                  background: white;
                  &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 7px;
                    background: colors.$azureish-white;
                    border-radius: inherit;
                    opacity: 0;
                    transition: .3s;
                  }
                  &:hover {
                    &::after {
                      opacity: 1;
                    }
                  }
                  &.add-product {
                    position: relative;
                    box-shadow: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border: solid 3px colors.$white;
                    background: #f4f7ff;
                    overflow: hidden;
                    filter: drop-shadow(0 0 6px #E4E3F4);
                    transition: .5s;
                    &:active {
                      &::before {
                        animation: move-animation ease-in-out 5s infinite;
                      }
                      &::after {
                        animation: move-animation-reverse ease-in-out 5s infinite;
                      }
                    }
                    &::before {
                      content: '';
                      opacity: 1;
                      display: flex;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      width: 50%;
                      height: 30%;
                      align-self: flex-end;
                      background: colors.$blue-fair;
                      border-radius: 0;
                      border-top-right-radius: 100%;
                      z-index: 1;
                      filter: blur(60px);
                    }
                    &::after {
                      content: '';
                      opacity: 1;
                      display: flex;
                      position: absolute;
                      top: 0;
                      right: 0;
                      left: auto;
                      width: 50%;
                      height: 30%;
                      align-self: flex-end;
                      background: colors.$blue-fair;
                      border-radius: 0;
                      border-bottom-left-radius: 100%;
                      z-index: 1;
                      filter: blur(60px);
                      transition: 1s;
                    }
                    &:hover {
                      filter: drop-shadow(0 0 0 #E4E3F4);
                      >span {
                        transform: rotate(180deg);
                      }
                    }

                    >span {
                        border-radius: 99999px;
                        display: block;
                        height: 80px;
                        width: 80px;
                        border: 4px solid var(--main-blue-color-bg);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 2px 20px #D0D6E6;
                        transition: .4s;
                        i {
                            font-size: 35px;
                            color: var(--main-blue-color-bg);
                        }
                      }
                      >div {
                          margin-top: 10px;
                      }
                  }
                  >.deleteBtn {
                    position: absolute;
                    top: -3%;
                    right: -3%;
                    z-index: 3;
                    display: flex;
                    width: 30px;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    background: colors.$blue-dark;
                    transition: .2s;
                    &:hover {
                      background: colors.$azureish-white;
                      >i {
                        color: colors.$blue-dark;
                      }
                    }
                    >i {
                      transition: 0.3s;
                      font-size: 17px;
                      color: colors.$white;
                    }
                  }
                  >img {
                      border-radius: 10px;
                      height: 175px;
                      width: 100%;
                      object-fit: cover;
                  }
                  >div {
                      text-align: left;
                      padding: 12px 8px;
                      h4 {
                          font-family: Hurme-Black;
                          font-size: v.$px-ts-nml;
                          text-transform: uppercase;
                      }
                      p {
                          margin-top: 13px;
                          font-family: Roboto;
                          font-weight: 300;
                          font-size: v.$px-ts-md;
                          line-height: 15px;
                      }
                  }
                  .address-nth-1 {
                    font-size: 0.9rem;
                    color: colors.$blue-dark2;
                    text-transform: uppercase;
                    font-family: Hurme-Black;
                    margin-bottom: 0.2rem;
                  }
                  .address-nth-2 {
                    font-size: 1rem;
                    font-weight: 300;
                    color: colors.$blue-gulf;
                  }
              }
              .selected {
                  background: var(--main-blue-color-bg);
                  &:hover {
                    &::after {
                      opacity: 0;
                    }
                  }
                  * {
                    color: var(--main-white-color) !important;
                  }
              }
          
      }
      .card-type-casting {
          width: 351px;
          min-height: 121px;
          margin: 20px;
          border-radius: 10px;
          background-color: var(--main-white-color);
          box-shadow: 0px 0px 20px rgba(184, 184, 184, 0.25);
          overflow: hidden;
          cursor: pointer;
          opacity: .5;
          transition: all .2s ease-in-out;
          border: 1px solid transparent;
          &.selected {
              opacity: 1;
              &.border {
                  border: 3px solid var(--main-blue-color-bg);
              }
          }
          &>div {
              h4 {
                  font-family: Hurme-Bold;
                  font-size: 15px;
                  text-align: center;
                  color: #000000;
              }
              p {
                  width: 100% !important;
                  line-height: 17px;
              }
              &:nth-of-type(1) {
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
              &:nth-of-type(2) {
                  height: 70%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex: 1;
                  padding: 0px 15px;
              }
          }
      }
      @media screen and (min-width: 768px) {
        &:nth-of-type(1) {
          flex: 0;
      }
      }
      &:nth-of-type(2) {
          flex: .1;
          align-items: flex-end;
          min-height: 113px;
          width: 100%;
          //width: calc(100% - 280px);
          position: absolute !important;
          bottom: 0;
          padding: 0px 80px;
          border-top: 1px solid #EEEEEE;
          button {
              text-transform: none;
              min-width: 151px;
              font-size: 16px;
          }
      }
       .box-casting-type {
          //transition: 0.5s;
          transition: 0.3s;
          opacity: 0.5;
          border-color: transparent !important;
          margin: 15px;
          filter:
              drop-shadow(0 0px 1px rgba(0, 0, 0, 0.125))
              drop-shadow(0 4px 1px rgba(0, 0, 0, 0.125));
      }
      .box-selected {
          opacity: 1;
      }
      .vogzter-picker-wrapper {
          display: flex;
          flex: 1;
          width: 100%;
          margin-top: 3%;
          flex-wrap: wrap;
          justify-content: center;
          overflow-y: visible;
          &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 7px;
          }
          &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, .5);
              -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
          }
      }
      .vogzter-picker-item {
        margin-left: 4%;
        margin-right: 4%;
      }
    
      .btn-back-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin-top: 5em;
        margin-left: 5rem;
      }
  }

  @include tablet() {
      height: inherit;
      //margin: 50px 0 0 0;
      padding: 20px 0 0 0;
      section {
        .card_products {
          display: flex;
          flex-direction: column;
          padding: 10px 0;
            .card_product {
              min-height: 330px;
              max-width: 305px;
              min-width: 260px;
              width: 100%;
              padding: 10px;
              margin: 15px 10px;
              border-radius: 11px;
              box-shadow: 0px 4px 35px #ECECEC;
              cursor: pointer;
              transition: all .2s ease-in-out;
              &.add-product {
                display: flex;
                width: 85%;
                min-width: 0;
                max-width: none;
                min-height: 0;
                height: 50px;
                align-self: center;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                & div {
                  margin: 0;
                  h4 {
                    font-size: 1rem;
                  }
                }
                & span {
                  width: 25px;
                  height: 25px;
                  border-width: 2px;
                  & i {
                    font-size: 15px;
                  }
                }
              }
            }
          }
      }
  }
}

.content-modal {
  max-height: 100%;
  overflow-y: auto;
  padding: 60px 20px;
  >button {
      width: 100%;
  }
  .header-content-modal>hr {
      height: 2px;
      margin: 8px 0;
      border: none;
      background-color: var(--main-blue-color-text);
  }
  .content-modal-btn {
      position: absolute;
      top: 19px;
      right: 20px;
      background: var(--main-blue-color-text);
      height: 30px;
      width: 30px;
      color: var(--main-white-color);
      border-radius: 40px;
  }
}

.div-input-file {
  width: 165px;
  height: 116px;
    & label {
        width: 100%;
    }
    & .label-file {
        border: 2px solid var(--home-violet-dark-color-text);
        border-radius: 4px;
        background: colors.$alice-blue3;
        max-height: 250px;
        width: 100%;
        max-width: 200px;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-direction: column;
        font-size: v.$px-ts-sm;
        justify-content: center;
        padding: 20px;
        font-family: Hurme-Bold;
        color: var(--home-violet-dark-color-text);
        text-align: center;
        & i {
            color: colors.$periwinkle-blue !important;
            font-size: 45px;
            line-height: normal;
            -webkit-text-fill-color: colors.$periwinkle-blue;
        }
        & p {
            color: var(--home-violet-dark-color-text);
        }
        & p>span {
            font-family: Roboto;
            font-weight: 400;
            color: colors.$periwinkle-blue;
        }
    }
    & .img-file {
      border: 2px solid var(--home-violet-dark-color-text);
      border-radius: 4px;
      background: colors.$alice-blue3;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .xmark {
      position: absolute;
      transform: translate(600%, -240%);
      background: var(--home-violet-dark-color-text);
      border: 2px solid white;
      color: #fff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      cursor: pointer;
      z-index: 1;
    }
}
.body-content-input-modal {
    display: grid;
    margin: 20px 0;
    label {
        font-family: Hurme-Bold;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        color: #2F2E41;
    }
    input,
    textarea {
        background: #FFFFFF;
        border: 1px solid #D6D6D6;
        border-radius: 2px;
        padding: 8px;
        margin-top: 7px;
        &.error {
            opacity: .9;
            background-color: var(--main-error-txt);
        }
    }
    .body-content-input-modal-error {
        color: var(--main-error-txt);
        font-size: v.$px-ts-sm;
        font-weight: 500;
    }
    .body-content-input-modal-infos {
        display: flex;
        padding-top: 10px;
        justify-content: space-around;
        span {
            color: var(--text-conditions-color);
            text-align: center;
            font-size: v.$px-ts-sm;
        }
    }
}

.slider {
    width: 100%;
    & .slider-wrapper {
        width: 100%;
        height: 175px;
        & .slider-item-wrapper {
            position: relative;
            width: 0;
            height: 100%;
            transition: 0.1s;
            opacity: 0.5;
            & .slider-item {
                width: 100%;
                height: 100%;
                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        & .slider-item-wrapper-active {
            width: 100% !important;
            transition: 0.2s;
            opacity: 1;
        }
    }
}

@include mobile {
.content-modal {
  .body-content-modal {
    .div-input-file {
      width: 100%;
      justify-content: center;

      .xmark {
        transform: translate(300%, -240%);
      }

      label {
        max-width: 100% !important;
      }
    }
  }
}
}

@keyframes move-animation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    font-size: 60px;
    transform: translateY(0%);
  }
}

@keyframes move-animation-reverse {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    font-size: 60px;
    transform: translateY(0%);
  }
}