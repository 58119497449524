
.slick-register{
    .each-slide{
        transition: ease-in-out 0.5s;
        height: calc(100vh - 140px);
        transform: scale(0.8);
        @media (max-width: 768px) {
            height: 550px;
        }
    }
    .slick-center{

    }
    .slick-center{ 
        .each-slide{
        transform: scale(1);
        
    }

}
// .slick-slide {
//     margin-top: calc(25vh - 40px);
//     margin-bottom: calc(25vh - 40px);
//   }
// .slick-track {
//     display: flex;
//     flex-direction: column;
//     gap: 100px;
// }
}