@use "../../../../../../../../sass/variables.scss" as v;
@import '../../../../../../../../sass/mixins';
.vogzter-picker-item {
    --mygreen: #8FFEC9;
    position: relative;
    display: flex;
    min-width: 260px;
    min-height: 260px;
    width: 260px;
    height: 290px;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 30px;
    margin: 30px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 35px #ECECEC;
    &:after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        height: 25%;
        border-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        z-index: 1;
        background: linear-gradient(94.27deg, #CEE4F9 0%, #EAD0FA 100%);
    }
    .profile-pic-container {
        display: flex;
        width: 90px;
        height: 90px;
        border-radius: 100%;
        align-self: center;
        background: white;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: white;
            border: solid white 2.5px;
            border-radius: 100%;
            z-index: 2;
            filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.25));
        }
    }
    h1 {
        text-align: center;
        font-family: Hurme-Bold;
        color: black;
        font-size: 2ch;
        margin-top: 10px;
    }
    b {
        background: cadetblue;
        font-family: Hurme-Black;
        text-transform: uppercase;
        font-size: 1ch;
        margin-right: 5px;
        background: linear-gradient(91.03deg, #A685FE 0%, #5F79FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 2px 8px rgba(152, 146, 146, 0.25);
    }
    .icon-certif {
        color: #6179FF;
        align-self: center;
        font-size: 1.2ch;
    }
    button {
        & i {
            transition: 0.2s;
        }
    }
    button:active:nth-of-type(1) {
        & i {
            animation: heart-animation 0.4s ease-in-out infinite;
        }
    }
    .btn {
        transition: 0.5s;
        margin: 5px 0;
        &:hover {
            color: white !important;
            background: var(--mygreen) !important;
            border-color: transparent !important;
        }
    }
    .btnSelected {
        background: var(--mygreen) !important;
        &:hover {
            color: white !important;
            border-color: transparent !important;
        }
    }
}

.vogzter-picker-item-small {
    height: 260px !important;
    padding-bottom: 25px;
    border: solid white 2.5px;
    border-radius: 6px;
    &::after {
        height: 30%;
    }
    .btn-small {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.not-favorite {
    &:after {
        background: none !important;
        border-bottom: solid 1px #c5c5c57e;
    }
}

// @include giantScreen() {
//     .vogzter-picker-item {
//         width: 334px;
//         height: 434px;
//         padding: 20px 40px;
//         margin: 30px;
//         border-radius: 10px;
//         box-shadow: 0px 4px 35px #ECECEC;
//         i:first-of-type {
//             font-size: 0.9vw;
//             top: 1vw;
//             right: 1vw;
//         }
//         .profile-pic-container {
//             display: flex;
//             width: 90px;
//             width: 5.5vw;
//             height: 5.5vw;
//             border-radius: 100%;
//             align-self: center;
//             background: white;
//             img {
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 background: white;
//                 border: solid white 2.5px;
//                 border-radius: 100%;
//                 z-index: 2;
//                 filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.25));
//             }
//         }
//         h1 {
//             text-align: center;
//             font-family: Hurme-Bold;
//             color: black;
//             font-size: v.$h1Mob;
//             margin-top: 10px;
//         }
//         b {
//             background: cadetblue;
//             font-family: Hurme-Black;
//             text-transform: uppercase;
//             font-size: 1ch;
//             margin-right: 5px;
//             background: linear-gradient(91.03deg, #A685FE 0%, #5F79FF 100%);
//             -webkit-background-clip: text;
//             -webkit-text-fill-color: transparent;
//             background-clip: text;
//             text-fill-color: transparent;
//             text-shadow: 0px 2px 8px rgba(152, 146, 146, 0.25);
//         }
//         .icon-certif {
//             color: #6179FF;
//             align-self: center;
//             font-size: 1.2ch;
//         }
//         button {
//             font-size: 0.7vw;
//             padding: 0.6vw 0;
//             margin: 0.3vw 0;
//         }
//         .btnSelected {
//             background: var(--mygreen) !important;
//             &:hover {
//                 color: white !important;
//                 border-color: transparent !important;
//             }
//         }
//     }
//     .vogzter-picker-item-small {
//         height: 434px !important;
//     }
// }
@keyframes heart-animation {
    0% {
        font-size: 1rem;
    }
    50% {
        font-size: 1.2rem;
    }
    100% {
        font-size: 1rem;
    }
}