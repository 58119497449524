@use "../../../../../../sass/colors" as colors;
@use "../../../../../../sass/variables.scss" as variables;
@import '../../../../../../sass/partials/_buttons';
@import "../../../../../../sass/mixins";
@import "../../../../../../sass/_mixins/global";
@import "../../../../../../sass/_mixins/animated-shine.scss";

.review-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0px;
    padding: 0px 30px;

    @include mobile() {
        padding: 0px;
        width: 95%;
    }

    .logo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .review-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 1rem;

        .review-content__header {
            h2 {
                font-size: 20px;
                font-weight: 700;
                color: #1a1a1a;
                margin: 0;
                font-family: Hurme-Black;
                margin: 0px;
                gap: 20px;

                span {
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--main-grey-color-text);
                    margin: 0px 10px;
                    font-family: roboto;
                }
            }

            .date {
                font-weight: 500;
                font-size: 16px;
                font-family: roboto;
            }

        }

        .review-content__body {
            max-width: 600px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 1rem;

            .comment {
                margin-left: 70px;
                font-weight: 400;
                font-size: 18px;
                font-family: roboto;
                color: var(--main-grey-color-text);

                @include mobile() {
                    margin-left: 0px;
                }
            }
        }
    }
}

.accordion {
    width: 100%;
    border: none;
    box-shadow: none !important;

    &::before {
        content: none !important;
    }

    .vogzter-name {
        background-color: var(--main-blue-color-bg);
        margin: 0px;
        padding: 0px 100px;
        border-radius: 10px;

        @include mobile() {
            padding: 0px 20px;
        }

        h2 {
            color: white;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            font-family: Hurme-Black;
            margin: 0px;
        }
    }

    .reply-section {
        background-color: #F8F9FF;
        border: 1px solid var(--main-blue-color-bg);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin: 20px 0px;
        padding: 20px 40px;

        @include mobile() {
            padding: 20px;
        }

        .reply-section__pfp {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: fill;
                border: 5px solid white;
            }
        }

        .reply-section__content {
            display: flex;
            justify-content: start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            .reply-section__header {

                h2 {
                    font-size: 20px;
                    font-weight: 700;
                    color: #1a1a1a;
                    margin: 0;
                    font-family: Hurme-Black;
                    margin: 0px;

                    span {
                        font-size: 15px;
                        font-weight: 500;
                        color: var(--main-grey-color-text);
                        margin: 0px 10px;
                        font-family: roboto;
                    }
                }

                .date {
                    font-weight: 500;
                    font-size: 16px;
                    font-family: roboto;
                }
            }

            .reply-section__body {
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .comment {
                    font-weight: 400;
                    font-size: 18px;
                    font-family: roboto;
                    color: var(--main-grey-color-text);
                }
            }
        }
    }
}