$medium-size-text: .9rem;
$low-size-text: .7rem;
$mid-title-size-text: 1.2em;
$xxl-size: 1.8em;
$small-size-p: .8em;
$btn-txt-md :17px;
// $low-size-text   : .7rem;
// variables for container or block div
$medium-size: .9rem;
$p: .83em;
$pMobil :13px;
$h1: 2.5rem;
$h2: 1.5rem;
$h1Mob: 25px;
$h2Mob: 1.25rem;
// icons size
$ico-sm :24px;
$ico-sm-2: 34px;
$ico-md :44px;
// px size text
$px-ts-ul-sm: 9px;
$px-ts-sm: 13px;
$px-ts-md: 16px;
$px-ts-nml: 19px;